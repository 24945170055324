export const selectOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const NotificationTh = [
  {
    id: 'th-notification',
    label: 'No',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'th-category',
    label: 'Category',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'th-title',
    label: 'Title',
    align: 'left',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'th-registration',
    label: 'Registration Date',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const NotificationTd = [
  {
    id: 'NotificationTd-1',
    data01: '[fix]',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-24 ',
  },
  {
    id: 'NotificationTd-2',
    data01: '13',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-22 ',
  },
  {
    id: 'NotificationTd-3',
    data01: '12',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-11 ',
  },
  {
    id: 'NotificationTd-4',
    data01: '[fix]',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-24 ',
  },
  {
    id: 'NotificationTd-5',
    data01: '13',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-22 ',
  },
  {
    id: 'NotificationTd-6',
    data01: '12',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-11 ',
  },
];

export const NotificationFaqTh = [
  {
    id: 'th-notification',
    label: 'No',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'th-category',
    label: 'Category',
    align: 'left',
    width: '20%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'th-title',
    label: 'Title',
    align: 'left',
    width: '40%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'th-registration',
    label: 'Registration Date',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const NotificationFaqTd = [
  {
    id: 'FaqTd-1',
    data01: '[fix]',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-24 ',
  },
  {
    id: 'FaqTd-2',
    data01: '13',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-22 ',
  },
  {
    id: 'FaqTd-3',
    data01: '12',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-11 ',
  },
  {
    id: 'FaqTd-4',
    data01: '[fix]',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-24 ',
  },
  {
    id: 'FaqTd-5',
    data01: '13',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-22 ',
  },
  {
    id: 'FaqTd-6',
    data01: '12',
    data02: 'Manual',
    data03: 'Noti title_1',
    data04: '2024-04-11 ',
  },
];
