import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Button from 'components/component/BasicButton';
import SelectBox from 'components/component/BasicSelectBox';
import SearchField from 'components/component/BasicSearchField';

const ExtraFilter = React.memo(({ total, selectOptions, searchPlaceholder }) => (
    <Box className='white-box-content-top-area'>
      <Box className='left-area'>
        <Box className='total-count'>
          <Box component='span' className='label'>
            Total
          </Box>
          <Box component='span' className='value'>
            {total}
          </Box>
        </Box>
      </Box>
      <Box className='right-area'>
        <Button
            color='tertiary'
            type='outline'
            size='small'
            label='Excel Download'
            isIcon={false}
            autosize
        />
        <SelectBox
            id='extra-filter-select-search'
            size='medium'
            selectOptions={selectOptions}
            label=''
            maxWidth='180px'
            disabled={false}
            placeholder=''
            aria-label='Select filter'
        />
        <SearchField
            id='extra-filter-search'
            size='medium'
            maxWidth='230px'
            placeholder={searchPlaceholder}
            aria-label='Search field'
        />
      </Box>
    </Box>
));

ExtraFilter.propTypes = {
  total: PropTypes.number.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  searchPlaceholder: PropTypes.string,
};

ExtraFilter.defaultProps = {
  searchPlaceholder: 'Search...',
};

export default ExtraFilter;