// src/data.js
import imgDummy01 from "assets/images/No-Image_378.png";

export const CategoryTableTh = [
  {
    id: "categoryOrder",
    label: "Category Order",
    align: "left",
    width: "12%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "categoryCode",
    label: "Category Code",
    align: "left",
    width: "13%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "categoryName",
    label: "Name",
    align: "left",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "contentType",
    label: "Content Type",
    align: "left",
    width: "12%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "enable",
    label: "Enable",
    align: "left",
    width: "12%",
    checkbox: false,
    sortable: false,
  },
  {
    id: "action",
    label: "Action",
    align: "left",
    width: "12%",
    checkbox: false,
    sortable: false,
  },
];

export const CategoryListTableTh = [
  {
    id: "categoryOrder",
    label: "Category Order",
    align: "left",
    width: "15%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "categoryCode",
    label: "Category Code",
    align: "left",
    width: "15%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "categoryName",
    label: "Name",
    align: "left",
    width: "28%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "img",
    label: "Preview",
    align: "left",
    width: "32%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "enable",
    label: "Enable",
    align: "left",
    width: "10%",
    checkbox: false,
    sortable: false,
  },
];

export const CategoryListBannerTh = [
  {
    id: "categoryOrder",
    label: "Category Order",
    align: "left",
    width: "14%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "categoryCode",
    label: "Category Code",
    align: "left",
    width: "13%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "categoryName",
    label: "Name",
    align: "left",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "startDate",
    label: "Start Date",
    align: "left",
    width: "14%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "endDate",
    label: "End Date",
    align: "left",
    width: "14%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "enable",
    label: "Enable",
    align: "left",
    width: "11%",
    checkbox: false,
    sortable: false,
  },
];

export const BannerOrderTh = [
  {
    id: "categoryOrder",
    label: "Category Order",
    align: "left",
    width: "14%",
    checkbox: false,
    sortable: false,
  },
  {
    id: "categoryCode",
    label: "Category Code",
    align: "left",
    width: "13%",
    checkbox: false,
    sortable: false,
  },
  {
    id: "categoryName",
    label: "Name",
    align: "left",
    width: "auto",
    checkbox: false,
    sortable: false,
  },
  {
    id: "startDate",
    label: "Start Date",
    align: "left",
    width: "14%",
    checkbox: false,
    sortable: false,
  },
  {
    id: "endDate",
    label: "End Date",
    align: "left",
    width: "14%",
    checkbox: false,
    sortable: false,
  },
];

export const selectOption = [
  { id: "all", value: "All", option: "All", type: "text" },
  { id: "option-2", value: "option-2", option: "option-2", type: "text" },
  { id: "option-3", value: "option-3", option: "option-3", type: "text" },
];

export const TabCategoryListTh = [
  {
    id: "TabCategoryListTh-1",
    label: "Tab Order",
    align: "left",
    width: "10%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "TabCategoryListTh-2",
    label: "Tab Code",
    align: "left",
    width: "8%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "TabCategoryListTh-3",
    label: "Name",
    align: "left",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "TabCategoryListTh-4",
    label: "Location Type",
    align: "left",
    width: "9%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "TabCategoryListTh-5",
    label: "Skeleton Type",
    align: "left",
    width: "9%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "TabCategoryListTh-6",
    label: "Theme Apply",
    align: "left",
    width: "9%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "TabCategoryListTh-7",
    label: "Random",
    align: "left",
    width: "9%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "TabCategoryListTh-8",
    label: "Enable",
    align: "left",
    width: "10%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "TabCategoryListTh-9",
    label: "Action",
    align: "left",
    width: "9%",
    checkbox: false,
    sortable: true,
  },
];

export const TabCategoryListTd = [
  {
    id: "TabCategoryListTd-1",
    data01: "1",
    data02: "T-MY",
    data03: "MY",
    data04: "Top",
    data05: "My",
    data06: "Off",
    data07: "-",
    data08: "On",
    data09: "",
  },
  {
    id: "TabCategoryListTd-2",
    data01: "2",
    data02: "T-MY",
    data03: "MY",
    data04: "Top",
    data05: "My",
    data06: "Off",
    data07: "-",
    data08: "On",
    data09: "",
  },
  {
    id: "TabCategoryListTd-3",
    data01: "3",
    data02: "T-MY",
    data03: "MY",
    data04: "Top",
    data05: "My",
    data06: "Off",
    data07: "-",
    data08: "On",
    data09: "",
  },
  {
    id: "TabCategoryListTd-4",
    data01: "4",
    data02: "T-MY",
    data03: "MY",
    data04: "Top",
    data05: "My",
    data06: "Off",
    data07: "-",
    data08: "On",
    data09: "",
  },
  {
    id: "TabCategoryListTd-5",
    data01: "5",
    data02: "T-MY",
    data03: "MY",
    data04: "Top",
    data05: "My",
    data06: "Off",
    data07: "-",
    data08: "On",
    data09: "",
  },
  {
    id: "TabCategoryListTd-6",
    data01: "6",
    data02: "T-MY",
    data03: "MY",
    data04: "Top",
    data05: "My",
    data06: "Off",
    data07: "-",
    data08: "On",
    data09: "",
  },
  {
    id: "TabCategoryListTd-7",
    data01: "7",
    data02: "T-MY",
    data03: "MY",
    data04: "Top",
    data05: "My",
    data06: "Off",
    data07: "-",
    data08: "On",
    data09: "",
  },
];

export const CategoryListDragTh = [
  {
    id: "CategoryOrder",
    label: "Category Order",
    width: "15%",
    align: "left",
    checkbox: true,
    sortable: true,
  },
  {
    id: "Category Code",
    label: "Category Code",
    align: "left",
    width: "18%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "CategoryName",
    label: "Name",
    align: "left",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "ContentType",
    label: "Content Type",
    align: "left",
    width: "18%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "Mapping Type",
    label: "Mapping Type",
    align: "left",
    width: "18%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "Category Type",
    label: "CategoryType",
    align: "left",
    width: "15%",
    checkbox: false,
    sortable: true,
  },
];

export const CategoryListDragTd = [
  {
    id: "CategoryListDragTd-1",
    data01: "1",
    data02: "C-Favorite",
    data03: "My Favorite",
    data04: "TvShow, Movie, Tv Series,Live",
    data05: "Slider",
    data06: "Top",
  },
  {
    id: "CategoryListDragTd-2",
    data01: "1",
    data02: "C-Favorite",
    data03: "My Favorite",
    data04: "Live",
    data05: "Auto",
    data06: "Favorite",
  },
  {
    id: "CategoryListDragTd-3",
    data01: "1",
    data02: "C-Favorite",
    data03: "My Favorite",
    data04: "Live",
    data05: "manual",
    data06: "Favorite",
  },
  {
    id: "CategoryListDragTd-4",
    data01: "1",
    data02: "C-Favorite",
    data03: "My Favorite",
    data04: "Live",
    data05: "manual",
    data06: "Favorite",
  },
];

export const DeviceTypeData = [
  { id: "device-1", value: "TV", option: "TV", type: "text" },
  { id: "device-2", value: "device-2", option: "device-2", type: "text" },
  { id: "device-3", value: "device-3", option: "device-3", type: "text" },
];

export const Region = [
  { id: "all", value: "All", option: "All", type: "text" },
  { id: "Eu", value: "Eu", option: "Eu", type: "text" },
  { id: "Asia", value: "Asia", option: "Asia", type: "text" },
];

export const PlatformVersionData = [
  { id: "platform-version-1", value: "3.4.0", option: "3.4.0", type: "text" },
  { id: "platform-version-2", value: "3.3.5", option: "3.3.5", type: "text" },
  { id: "platform-version-3", value: "3.3.0", option: "3.3.0", type: "text" },
];

export const statusData = [
  { id: "status-1", value: "status-1", option: "status-1", type: "text" },
  { id: "status-2", value: "status-2", option: "status-2", type: "text" },
  { id: "status-3", value: "status-3", option: "status-3", type: "text" },
];

export const CategoryListTh = [
  {
    id: "CategoryListTh-1",
    label: "Category Order",
    align: "left",
    width: "10%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "CategoryListTh-2",
    label: "Category Code",
    align: "left",
    width: "10%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "CategoryListTh-3",
    label: "Name",
    align: "left",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "CategoryListTh-4",
    label: "Centent Type",
    align: "left",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "CategoryListTh-5",
    label: "Mapping Type",
    align: "left",
    width: "9%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "CategoryListTh-6",
    label: "Category Type",
    align: "left",
    width: "9%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "CategoryListTh-7",
    label: "Fix Flag",
    align: "left",
    width: "6%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "CategoryListTh-8",
    label: "Schedule",
    align: "left",
    width: "9%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "CategoryListTh-9",
    label: "Action",
    align: "left",
    width: "9%",
    checkbox: false,
    sortable: true,
  },
];

export const CategoryListTd = [
  {
    data01: { text: "1", status: true },
    data02: "C-favorite",
    data03: "My Favorie",
    data04: "tvshow, movie, tvshow-series, live",
    data05: "slider",
    data06: "top",
    data07: "On",
    data08: "Scheduled",
    data09: "",
  },
  {
    data01: { text: "2", status: false },
    data02: "C-favorite",
    data03: "My Favorie",
    data04: "live",
    data05: "Auto",
    data06: "favorite",
    data07: "On",
    data08: "Scheduled",
    data09: "",
  },
  {
    data01: { text: "3", status: false },
    data02: "C-favorite",
    data03: "My Favorie",
    data04: "live",
    data05: "manual",
    data06: "favorite",
    data07: "On",
    data08: "Scheduled",
    data09: "",
  },
  {
    data01: { text: "4", status: true },
    data02: "C-favorite",
    data03: "My Favorie",
    data04: "live",
    data05: "manual",
    data06: "favorite",
    data07: "Off",
    data08: false,
    data09: "",
  },
  {
    data01: { text: "5", status: false },
    data02: "C-favorite",
    data03: "My Favorie",
    data04: "live",
    data05: "manual",
    data06: "favorite",
    data07: "Off",
    data08: "Ongoing",
    data09: "",
  },
  {
    data01: { text: "6", status: false },
    data02: "C-favorite",
    data03: "My Favorie",
    data04: "live",
    data05: "manual",
    data06: "favorite",
    data07: "Off",
    data08: "Ongoing",
    data09: "",
  },
  {
    data01: { text: "7", status: false },
    data02: "C-favorite",
    data03: "My Favorie",
    data04: "live",
    data05: "manual",
    data06: "favorite",
    data07: "Off",
    data08: "Ongoing",
    data09: "",
  },
  {
    data01: { text: "8", status: false },
    data02: "C-favorite",
    data03: "My Favorie",
    data04: "live",
    data05: "manual",
    data06: "favorite",
    data07: "Off",
    data08: "Completed",
    data09: "",
  },
  {
    data01: { text: "9", status: false },
    data02: "C-favorite",
    data03: "My Favorie",
    data04: "live",
    data05: "manual",
    data06: "favorite",
    data07: "Off",
    data08: "Completed",
    data09: "",
  },
];

export const ContentListTh = [
  {
    id: "contentTh-01",
    align: "left",
    label: "CP Name",
    width: "24%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "contentTh-02",
    align: "left",
    label: "Content ID",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "contentTh-03",
    align: "left",
    label: "Content Name",
    width: "26%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "contentTh-04",
    align: "left",
    label: "Image",
    width: "18%",
    checkbox: false,
    sortable: false,
  },
];

export const ContentListTd = [
  {
    id: "cl-1",
    data01: "AMG1",
    data02: "aliennationbydust_dustx_US",
    data03: "Alien Nation by DUST",
    data04: imgDummy01,
  },
  {
    id: "cl-2",
    data01: "AMG1",
    data02: "aliennationbydust_dustx_US",
    data03: "Alien Nation by DUST",
    data04: imgDummy01,
  },
  {
    id: "cl-3",
    data01: "AMG1",
    data02: "aliennationbydust_dustx_US",
    data03: "Alien Nation by DUST",
    data04: imgDummy01,
  },
  {
    id: "cl-4",
    data01: "AMG1",
    data02: "aliennationbydust_dustx_US",
    data03: "Alien Nation by DUST",
    data04: imgDummy01,
  },
  {
    id: "cl-5",
    data01: "AMG1",
    data02: "aliennationbydust_dustx_US",
    data03: "Alien Nation by DUST",
    data04: imgDummy01,
  },
];

export const SelectedContentTh = [
  {
    id: "SelectedTh-01",
    align: "left",
    label: "Order",
    width: "18%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "SelectedTh-02",
    align: "left",
    label: "Type",
    width: "12%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "SelectedTh-03",
    align: "left",
    label: "CP Name",
    width: "16%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "SelectedTh-04",
    align: "left",
    label: "Content ID",
    width: "auto",
    checkbox: false,
    sortable: false,
  },
  {
    id: "SelectedTh-05",
    align: "left",
    label: "Content Name",
    width: "23%",
    checkbox: false,
    sortable: false,
  },
];

export const SelectedContentTd = [
  {
    id: "order-1",
    data01: "1",
    data02: "Live",
    data03: "AMG",
    data04: "aliennationbydust_dustx_US",
    data05: "Alien Nation by DUST",
  },
  {
    id: "order-2",
    data01: "2",
    data02: "Live",
    data03: "AMG",
    data04: "aliennationbydust_dustx_US",
    data05: "Alien Nation by DUST",
  },
  {
    id: "order-3",
    data01: "3",
    data02: "Live",
    data03: "AMG",
    data04: "aliennationbydust_dustx_US",
    data05: "Alien Nation by DUST",
  },
  {
    id: "order-4",
    data01: "4",
    data02: "Live",
    data03: "AMG",
    data04: "aliennationbydust_dustx_US",
    data05: "Alien Nation by DUST",
  },
];
