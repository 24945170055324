import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import SearchField from 'components/component/BasicSearchField';

import TextLink from 'components/component/BasicTextLink';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// data
import {
  dtypeOption,
  CountryData,
  selectOption,
  TermsofUseTh,
  TermsofUseTd,
} from 'pages/Configuration/TermsOfUse/data/TermsData';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';

// Button Data
const buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Reset',
    isIcon: true,
    Icon: RefreshIcon,
    iconPos: 'left',
    className: 'custom-save-icon',
    autosize: true,
  },
  {
    minWidth: '120px',
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Search',
    isIcon: false,
  },
];

const tableTopButton = [
  {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Add',
    isIcon: false,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'small',
    label: 'Publish',
    isIcon: false,
    autosize: true,
  },
];

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// ************************** 메인 컨텐츠 ***********************************
function TermsOfUse() {

  // BreadCrumb Data
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Terms of Use',
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  // 테이블 내림, 오름차순
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 테이블 정렬 함수
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // 테이블 본문에 정렬된 데이터
  const sortedTermsOfUseTdRows = sortRows([...TermsofUseTd], orderBy, order);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography className='title'>Terms of Use</Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap' component='div'>
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <div className='form-area left'>
                <Box component='div' className='component-wrap col-5'>
                  <Box component='div' className='col'>
                    <SelectBox
                      id='select-device'
                      size='large'
                      shrink={true}
                      label='Device type'
                      required={false}
                      selectOptions={dtypeOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  <Box component='div' className='col'>
                    <SelectBox
                      id='tab1-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      selectOptions={CountryData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                </Box>
              </div>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons} />
              </div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <SelectBox
                    id='select-search'
                    size='medium'
                    selectOptions={selectOption}
                    label=''
                    maxWidth='180px'
                    disabled={false}
                    placeholder=''
                  />

                  <SearchField id='search-2' size='medium' maxWidth='230px' placeholder='Please enter a search term' />
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={tableTopButton} />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {TermsofUseTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTermsOfUseTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {TermsofUseTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 5 ? (
                                <TextLink href='' text={td[`data${colIndex + 1}`]} />
                              ) : colIndex === 10 ? (
                                <Box display='flex' gap={1}>
                                  <Button
                                    color='tertiary'
                                    type='outline'
                                    size='small'
                                    label='EDIT'
                                    isIcon={false}
                                    autosize
                                  />
                                  <Button
                                    color='tertiary'
                                    type='outline'
                                    size='small'
                                    label='HISTORY'
                                    isIcon={false}
                                    autosize
                                  />
                                </Box>
                              ) : (
                                td[`data${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              <Pagination id='pagination-01' count={5} selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default TermsOfUse;
