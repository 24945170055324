import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import TextField from 'components/component/BasicTextField';
import TextLink from 'components/component/BasicTextLink';
import Tooltip from 'components/component/BasicTooltip';
import Tag from 'components/component/BasicTag';

import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { RefreshIcon, PrimaryInfoIcon, CalendarIcon } from 'components/component/BasicIcon';

import { styled } from '@mui/material/styles';

// Dummy Data //
import {
  DeviceTypeData,
  Region,
  PlatformVersionData,
  CategoryListTh,
  selectOption,
} from 'pages/Organization/WebService/data/CategoryListData';

const FilterButtons = [
  {
    color: 'tertiary',
    type: 'outline icon',
    size: 'medium',
    label: 'Reset',
    isIcon: true,
    Icon: RefreshIcon,
    iconPos: 'left',
    autosize: false,
    minWidth: '107px',
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Search',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: false,
    minWidth: '120px',
  },
];

const Buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Reorder List',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Delete',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    disabled: true,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Schedule',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Add Category',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Save',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Testing',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// Tooltip 내부에 출력될 자식 컴포넌트 //
const ScheduleTooltip = (props, ref) => {
  return (
    <div className='info-list-box'>
        <div className='info'>
          <Typography className='label'>{`Status : `}</Typography>
          <span className='info-field'>{props.list.scheduleStatus}</span>
        </div>
        <div className='info'>
          <Typography className='label'>{`Schedule : `}</Typography>
          <span className='info-field'>{`${props.list.reqStartDate} ~ ${props.list.reqEndDate}`}</span>
        </div>
    </div>
  );
};

const MappingTypeTooltip = (props, ref) => {
  return (
    <div className='info-list-box'>
        <div className='info'>
          <Typography className='label'>{`Order Type : `}</Typography>
          <span className='info-field'>{props.list.orderType}</span>
        </div>
        <div className='info'>
          <Typography className='label'>{`Rule : `}</Typography>
          <span className='info-field'>{props.list.categoryMappingRule.rule}</span>
        </div>
        <div className='info'>
          <Typography className='label'>{`CP List : `}</Typography>
          <span className='info-field'>{props.list.cpList}</span>
        </div>
    </div>
  );
};

// 메인 컨텐츠
function CategoryList(props) {
  const { message } = props;
  const { tab, categorys, region, deviceType, platformVersion, status } = message;

  useEffect(() => {
    
    // eslint-disable-next-line
  }, []);

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Category List'],
  };

  // useState
  const [openSelect1, setOpenSelect1] = useState(false);
  const [openSelect2, setOpenSelect2] = useState(false);
  const [openSelect3, setOpenSelect3] = useState(false);
  const [openSelectResult, setOpenSelectResult] = useState(false);

  const navigate = useNavigate();

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const handleOrganizeButtonClick = (row) => {
    let openComponent = 'ManualOrganize';

    if (row.mappingType === 'auto') {
      openComponent = 'AutoOrganize';
    } else if (row.mappingType === 'carousel') {
      openComponent = 'CarouselOrganize';
    } else if (row.mappingType === 'slider') {
      openComponent = 'SliderOrganize';
    } else if (row.mappingType === 'upcoming') {
      openComponent = 'UpcomingOrganize';
    }

    navigate('/TabCategoryList', {
      replace: true, state: {
        move: true,
        targetComponent: openComponent,
        message: {
          categoryIdx: row.idx,
          tab: tab,
          categorys: row,
          region: region,
          deviceType: deviceType,
          platformVersion: platformVersion,
          status: status,
        }
      }
    });
  };

  // TableBody Row
  const sortedBnrTdRows = sortRows([...categorys], orderBy, order);

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Tab / Category
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                  <React.Fragment key='1'>
                    <ListItem className='list-item'>
                      <Typography className='label'>#Tab</Typography>
                      <Typography className='value'>{tab.tabName}</Typography>
                    </ListItem>
                    {true && <Divider className='divider' />}
                  </React.Fragment>
                  <React.Fragment key='2'>
                    <ListItem className='list-item'>
                      <Typography className='label'>#Random</Typography>
                      <Typography className='value'>{tab.randomOrderFlag === 'Y' ? 'On' : 'Off'}</Typography>
                    </ListItem>
                    {true && <Divider className='divider' />}
                  </React.Fragment>
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Content Type */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Content Type'
                      required={false}
                      selectOptions={DeviceTypeData}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openSelect1}
                      setOpen={setOpenSelect1}
                    />
                  </Box>
                  {/* Category Type */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Category Type'
                      required={false}
                      selectOptions={Region}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openSelect2}
                      setOpen={setOpenSelect2}
                    />
                  </Box>
                  {/* Mapping Type*/}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Mapping Type'
                      required={false}
                      selectOptions={PlatformVersionData}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openSelect3}
                      setOpen={setOpenSelect3}
                    />
                  </Box>

                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Name'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'>
                <CustomButtonGroup buttons={FilterButtons} />
              </Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      {categorys.length}
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SelectBox
                      id='select-search'
                      size='medium'
                      selectOptions={selectOption}
                      label=''
                      maxWidth='180px'
                      disabled={false}
                      isFirstSelected={true}
                      open={openSelectResult}
                      setOpen={setOpenSelectResult}
                      placeholder=''
                    />

                    <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={Buttons} />
                </Box>
                {/* right-area */}
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {CategoryListTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedBnrTdRows.map((row, index) => (
                        <TableRow className='tr' key={row.id || index}>
                          {CategoryListTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <Box display='flex' gap='3px'>
                                  {/* CheckBox 컴포넌트 */}
                                  <CheckBox
                                    label={row.categoryOrder} // 데이터의 텍스트를 label로 사용
                                    value='value'
                                    defaultChecked={false}
                                  />

                                  {row.status && (
                                    <Tag type='testing' label='Unsaved' badgeType='' sx={{ marginLeft: '2px' }} />
                                  )}
                                </Box>
                              ) : colIndex === 1 ? (
                                row.categoryCode
                              ) : colIndex === 2 ? (
                                <TextLink href='' text={row.categoryName} />
                              ) : colIndex === 3 ? (
                                row.contentType
                              ) : colIndex === 4 ? (
                                <>
                                  <Typography
                                    component='span'
                                    sx={{ verticalAlign: 'bottom' }}
                                  >
                                    {row.mappingType}
                                  </Typography>
                                  {/* Auto일 경우에만 Tooltip 출력 */}
                                  {row.mappingType === 'auto' && (
                                    <>
                                      {/* Icon Tooltip */}
                                      <Tooltip title={<MappingTypeTooltip list={row} />} placement='top'>
                                        <IconButton
                                          size='small'
                                          sx={{ width: '24px', height: '24px', marginLeft: '4px' }}
                                        >
                                          <PrimaryInfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </>
                              ) : colIndex === 5 ? (
                                row.categoryType
                              ) : colIndex === 6 ? (
                                row.fixFlag === 'Y' ? 'on' : 'off'
                              ) : colIndex === 7 ? (
                                <>
                                  {row.categorySchedules && (
                                    row.categorySchedules.map((column, colIndex) => (
                                      <>
                                      <Typography
                                        component='span'
                                        sx={{
                                          color:
                                              column.scheduleStatus === 'Completed'
                                              ? '#0D9488 !important'
                                              : '#525252',
                                          verticalAlign: 'bottom',
                                        }}
                                      >
                                        {column.scheduleStatus}
                                      </Typography>
                                      {/* Icon Tooltip */}
                                      {column.idx !== null && (
                                        <Tooltip title={<ScheduleTooltip list={column} />} placement='top'>
                                          <IconButton
                                            size='small'
                                            sx={{ width: '24px', height: '24px', marginLeft: '4px' }}
                                          >
                                            <CalendarIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                    ))
                                  )}
                                </>
                              ) : colIndex === 8 ? (
                                <>
                                  {row.mappingType !== '' && (
                                    <>
                                      <Button
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label='ORGANIZE'
                                      isIcon={false}
                                      Icon=''
                                      iconPos={undefined}
                                      onClick={() => handleOrganizeButtonClick(row)}
                                      autosize
                                      />
                                    </>
                                  )}
                                </>
                              ) : (
                                row.categoryIdx
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default CategoryList;
