import React, { useEffect, useMemo, useState } from 'react';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
// data
import { statusData, PrTh, areaTypeOption } from '../data/OrganizationData';
import PopCreateArea from './PopCreateArea';
import PopEditArea from './PopEditArea';
import BasicDialog from 'components/component/BasicDialog';
import BasicSnackbar from 'components/component/BasicSnackbar';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteWebPromotionArea, getWebPromotionArea, getWebPromotionAreaCode, publishWebPromotionArea } from '../api/restApi';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Button
const crudButtons = [
  {
    color: "tertiary",
    type: "outline",
    size: "medium",
    label: "Delete",
    isIcon: false,
    autosize: true,
  },
  {
    color: "tertiary",
    type: "box",
    size: "medium",
    label: "Create Area",
    isIcon: false,
    autosize: true,
  },
  {
    color: "tertiary",
    type: "box",
    size: "medium",
    label: "Testing",
    isIcon: false,
    autosize: true,
  },
];

const sortRows = (rows, orderBy, order) => {
  return rows.sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    }
    return 0;
  });
};

// 메인 컨텐츠
function PRPage(props) {
  const { webServiceProps: { message = {} }, serviceCountryList } = props;
  const { country, region, status } = message;
  const navigate = useNavigate();

  // TableBody Row
  const [editWebProArea, setEditWebProArea] = useState([]);

  const [actionDialog, setActionDialog] = useState("");
  const [showSnakBar, setShowSnakBar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [webPromotionArea, setWebPromotionArea] = useState({
    data: [],
    total: 0,
  });

  const [areaCodes, setAreaCodes] = useState([]);
  const [checkedWebProArea, setCheckedWebProArea] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState(region ? region : 'All');
  const [selectedCountry, setSelectedCountry] = useState(country ? country : 'US');
  const [selectedStatus, setSelectedStatus] = useState(status ? status : statusData[0].value);
  const [regionOption, setRegionOption] = useState([]);
  const [countryOption, setCountryOption] = useState({});

  useEffect(() => {
    if (serviceCountryList?.length > 0) {
      let areaCountriesObj = {};
      let allCountries = [];
      serviceCountryList.forEach((data) => {
        allCountries.push(data.countryCode);
        if (areaCountriesObj.hasOwnProperty(data.bizArea)) {
          areaCountriesObj[data.bizArea].push(data.countryCode);
        } else {
          areaCountriesObj[data.bizArea] = [data.countryCode];
        }
      });

      const areaList = Object.keys(areaCountriesObj).sort();
      const newRegionOption = areaList.map((area) => {
        return { id: area, value: area, option: area, type: "text" };
      });

      areaList.forEach(area => {
        areaCountriesObj[area] = areaCountriesObj[area].sort().map(item => ({ id: item, value: item, option: item, type: "text" }));
      });
      areaCountriesObj['All'] = allCountries.sort().map(item => ({ id: item, value: item, option: item, type: "text" }));

      newRegionOption.unshift({ id: "All", value: "All", option: "All", type: "text" });
      setRegionOption(newRegionOption);
      setCountryOption(areaCountriesObj)
    }
  }, [serviceCountryList]);

  const areaCodeList = useMemo(() => {
    return areaCodes.map((option) => ({
      id: option,
      value: option,
      option: option,
      type: "text",
    }));
  }, [areaCodes]);

  const handleRequestSort = (property) => {
    const isAsc = params.order === property && params.order_type === "asc";
    setParams((prevState) => ({
      ...prevState,
      order: property,
      order_type: isAsc ? "desc" : "asc",
    }));
  };

  const [params, setParams] = useState({
    order_type: "asc",
    order: "areaOrder",
    region: "",
    country: country? country : 'US',
    status: status ? status : statusData[0].value,
    page: 1,
    size: 10,
  });

  const onDeleteOKBtn = async () => {
    const res = await deleteWebPromotionArea(checkedWebProArea);
    setActionDialog("");
    getTData();
    setShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    setParams({ ...params });
  };

  const onActionCancelBtn = () => {
    setActionDialog("");
  };

  const onPublishOKBtn = async () => {
    const res = await publishWebPromotionArea(
      selectedCountry,
      checkedWebProArea
    );
    setActionDialog("");
    setShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    setParams({ ...params });
  };

  const handelSetShowSnakBar = ({ open, message, severity }) => {
    setShowSnakBar({
      open,
      message,
      severity,
    });
    setParams({ ...params });
  };

  const handleSnackbarClose = () => {
    setShowSnakBar({ open: false, message: "", severity: "" });
  };

  const deleteActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onDeleteOKBtn,
    },
  ];

  const publishActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      minWidth: 103,
      disabled: false,
      onClick: onPublishOKBtn,
    },
  ];

  // Function to handle the button click event
  const handleButtonClick = (label) => {
    setActionDialog(label);
  };

  const handlePaginationChange = ({ type, value }) => {
    if (type === "page") {
      setParams((prevState) => ({
        ...prevState,
        page: value,
      }));
    } else if (type === "size") {
      setParams((prevState) => ({
        ...prevState,
        page: 1,
        size: parseInt(value),
      }));
    }
  };

  const handleCheckBox = (event) => {
    let idxs = [];
    if (event.target.checked && event.target.name === "select-all") {
      idxs = webPromotionArea.data.map((row) => row.idx);
    } else if (!event.target.checked && event.target.name === "select-all") {
      idxs = [];
    } else if (event.target.checked) {
      idxs = [...checkedWebProArea, parseInt(event.target.name)];
    } else if (!event.target.checked) {
      idxs = checkedWebProArea.filter(
        (row) => row !== parseInt(event.target.name)
      );
    }
    setCheckedWebProArea(idxs);
  };

  const onChangeRegion = (eValue) => {
    const value = eValue;
    let defaultCountry = countryOption[value][0].value;
    if (value === "All") {
      defaultCountry =
        countryOption[value][countryOption[value].length - 1].value;
    }
    setSelectedRegion(value);
    setSelectedCountry(defaultCountry);
  };

  const getTData = async () => {
    const res = await getWebPromotionArea({
      ...params,
      country: selectedCountry,
      status: selectedStatus,
    });
    setCheckedWebProArea([]);
    setWebPromotionArea(res);
  };

  const getAreaCode = async () => {
    const res = await getWebPromotionAreaCode();
    setAreaCodes(res.data);
  };

  const customCrudButtons = useMemo(() => {
    return crudButtons.map((crudButton) => {
      crudButton.onClick = () => handleButtonClick(crudButton.label);
      if (crudButton.label === "Delete") {
        crudButton.disabled = checkedWebProArea.length !== 0 ? false : true;
      }
      return crudButton;
    });
  }, [checkedWebProArea]);

  useEffect(() => {
    getTData();
    // eslint-disable-next-line
  }, [selectedCountry, selectedStatus]);

  useEffect(() => {
    getAreaCode();
    // eslint-disable-next-line
  }, []);

  let sortedPrTdRows = sortRows(
    [...webPromotionArea.data],
    params.order,
    params.order_type
  );
  const count = Math.ceil(webPromotionArea.total / params.size);
  const total = webPromotionArea.total || 0;
  const pageIndex = params.page > 1 ? (params?.page - 1) * params?.size : 0;
  const sizeIndex = params?.size || 10;
  sortedPrTdRows = sortedPrTdRows.splice(pageIndex, sizeIndex);

  // exist
  const handelLinkClick = (rowData) => {
    if (selectedStatus === 'S') {
      const seletedRow = sortedPrTdRows.find(row => row.idx === rowData.idx);
      setEditWebProArea(seletedRow);
      if (seletedRow) {
        setActionDialog('Edit Area');
      }
    }
  };

  const handleCategoryClick = (rowdata) => {
    if (rowdata.areaType === 'banner') {
      navigate('/WebService', {
        replace: true, state: {
          move: true,
          targetComponent: 'ListBanner',
          message: {
            idx: rowdata.idx,
            areaName: rowdata.areaName,
            areaType: rowdata.areaType,
            country: selectedCountry,
            selectedStatus: selectedStatus,
            region: selectedRegion
          }
        }
      });
    } else if (rowdata.areaType === 'content_channel') {
      navigate('/WebService', {
        replace: true, state: {
          move: true,
          targetComponent: 'WebLiveManual',
          message: {
            idx: rowdata.idx,
            areaName: rowdata.areaName,
            areaType: rowdata.areaType,
            country: selectedCountry,
            selectedStatus: selectedStatus,
            region: selectedRegion
          }
        }
      });
    } else if (rowdata.areaType === 'content_vod') {
      navigate('/WebService', {
        replace: true, state: {
          move: true,
          targetComponent: 'WebVodManual',
          message: {
            idx: rowdata.idx,
            areaName: rowdata.areaName,
            areaType: rowdata.areaType,
            country: selectedCountry,
            selectedStatus: selectedStatus,
            region: selectedRegion
          }
        }
      });
    } else if (rowdata.areaType === 'brand_a') {
      navigate('/WebService', {
        replace: true, state: {
          move: true,
          targetComponent: 'BrandingImageA',
          message: {
            idx: rowdata.idx,
            areaName: rowdata.areaName,
            areaType: rowdata.areaType,
            country: selectedCountry,
            selectedStatus: selectedStatus,
            region: selectedRegion
          }
        }
      });
    } else if (rowdata.areaType === 'brand_b') {
      navigate('/WebService', {
        replace: true, state: {
          move: true,
          targetComponent: 'BrandingImageB',
          message: {
            idx: rowdata.idx,
            areaName: rowdata.areaName,
            areaType: rowdata.areaType,
            country: selectedCountry,
            selectedStatus: selectedStatus,
            region: selectedRegion
          }
        }
      });
    }

  }

  return (
    // Tab 1
    <div className='tab-panel pr-page'>
      <div className='panel-wrap'>
        {/* 필터영역 */}
        <Box component='div' className='white-box filter-box'>
          <Box className='form-wrap-box'>
            <Box className='form-area left'>
              <Box className='component-wrap col-5'>
                {/* region */}
                <Box component='div' className='col'>
                  <SelectBox
                    id='slt-country'
                    size='large'
                    shrink={true}
                    label='Region'
                    required={false}
                    selectOptions={regionOption}
                    disabled={false}
                    placeholder=''
                    selected={selectedRegion}
                    onChange={onChangeRegion}
                  />
                </Box>
                {/* Country */}
                <Box component='div' className='col'>
                  <SelectBox
                    id='slt-country'
                    size='large'
                    shrink={true}
                    label='Country'
                    required={false}
                    selectOptions={countryOption[selectedRegion]}
                    disabled={false}
                    placeholder=''
                    selected={selectedCountry}
                    onChange={(value) => setSelectedCountry(value)}
                  />
                </Box>
                {/* status */}
                <Box component='div' className='col'>
                  <SelectBox
                    id='slt-country'
                    size='large'
                    shrink={true}
                    label='Status'
                    required={false}
                    selectOptions={statusData}
                    disabled={false}
                    placeholder=''
                    selected={selectedStatus}
                    onChange={(value) => setSelectedStatus(value)}
                  />
                </Box>
              </Box>
            </Box>
            <div className='form-area right'></div>
          </Box>
        </Box>

        <Box className='white-box white-box-wrap'>
          <div className='box-content'>
            <Box className='white-box-content-top-area'>
              <Box className='left-area'>
                <Box className='total-count'>
                  <Box component='span' className='label'>
                    Total
                  </Box>
                  <Box component='span' className='value'>
                    {total || 0}
                  </Box>
                </Box>
              </Box>

              <Box className='right-area'>
                <CustomButtonGroup
                  buttons={customCrudButtons.filter(button => !(selectedStatus === "P"))}
                  justifyContent='flex-end'
                  onClick={(button) => handleButtonClick(button)}
                />
              </Box>
            </Box>
            <Box className='content-inner-wrap'>
              {/* Table */}
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  {/* table Head */}
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      {PrTh.map((column) => (
                        <TableCell
                          key={column.id}
                          className='th'
                          align={column.align}
                          sx={{ width: column.width }}
                        >
                          {column.checkbox ? (
                            <>
                              <CheckBox
                                label=''
                                value='select-all'
                                checked={checkedWebProArea.length !== 0 && sortedPrTdRows.length === checkedWebProArea.length}
                                onChange={handleCheckBox}
                                indeterminate={checkedWebProArea.length !== 0 && sortedPrTdRows.length !== checkedWebProArea.length}
                                inputProps={{
                                  'aria-label': 'select all',
                                }}
                              />
                              {column.sortable && <TableSortLabel
                                active={params.order === column.id}
                                direction={params.order === column.id ? params.order_type : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>}
                            </>
                          ) : (
                            <>
                              {column.sortable ? (
                                <TableSortLabel
                                  active={params.order === column.id}
                                  direction={params.order === column.id ? params.order_type : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* table Body */}
                  <TableBody className='tbody'>
                    {sortedPrTdRows.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        {PrTh.map((column, colIndex) => (
                          <TableCell key={column.id} className='td' align={column.align}>
                            {colIndex === 0 ? (
                              <CheckBox
                                label={td['areaCode']}
                                value={td['idx']}
                                onChange={handleCheckBox}
                                checked={checkedWebProArea.indexOf(td['idx']) > -1}
                              />
                            ) : colIndex === 1 ? (
                              <>
                                <span onClick={() => handelLinkClick(td)} style={{ color: '#2196f3', cursor: selectedStatus === 'P' ? 'default' : 'pointer' }} >
                                  {td[column.id]}
                                </span>
                              </>
                            ) : colIndex === 2 ? (
                              <>
                                {
                                  (() => {
                                    const selectedOption = areaTypeOption.find(data => data.value === td[column.id]);
                                    return selectedOption ? selectedOption.option : "";
                                  })()
                                }
                              </>

                            ) : colIndex === 4 ? (
                              <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='Category'
                                isIcon={false}
                                Icon=''
                                iconPos={undefined}
                                autosize
                                onClick={() => handleCategoryClick(td)}
                              />
                            ) : (
                              td[column.id]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
              {/* // Table */}
              <div>
                {actionDialog === 'Create Area' && (
                  <PopCreateArea
                    selectedCountry={selectedCountry}
                    regionOption={regionOption}
                    countryOption={countryOption}
                    selectedRegion={selectedRegion}
                    areaCodeList={areaCodeList}
                    getTData={getTData}
                    onClose={() => setActionDialog('')}
                    handelSetShowSnakBar={handelSetShowSnakBar}
                  />
                )}
              </div>
              <div>
                {actionDialog === 'Edit Area' && (
                  <PopEditArea
                    selectedCountry={selectedCountry}
                    regionOption={regionOption}
                    countryOption={countryOption}
                    selectedRegion={selectedRegion}
                    areaCodeList={areaCodeList}
                    editWebProArea={editWebProArea}
                    getTData={getTData}
                    onClose={() => setActionDialog('')}
                    handelSetShowSnakBar={handelSetShowSnakBar}
                  />
                )}
              </div>
              <div>
                {(actionDialog === 'Delete' || actionDialog === 'Testing') && <BasicDialog
                  title={actionDialog === 'Delete' ? 'Delete Area' : actionDialog === 'Testing' ? 'Publish Area' : ''}
                  contentTitle={actionDialog === 'Delete' ? ' Are you sure to delete?' : actionDialog === 'Testing' ? ' Are you sure to publish?' : ''}
                  size={'small'}
                  closeX={true}
                  open={true}
                  handleClose={() => setActionDialog('')}
                  buttons={actionDialog === 'Delete' ? deleteActionButtons : actionDialog === 'Testing' ? publishActionButtons : []}
                />}
              </div>
              <div>
                <BasicSnackbar onClose={handleSnackbarClose} severity={showSnakBar.severity} message={showSnakBar.message} open={showSnakBar.open} duration={1000} />
              </div>
            </Box>
            <Pagination id='pagination-01' count={count} selected={params.size} onChangeHandle={handlePaginationChange} page={params.page} />
          </div>
        </Box>
      </div>
    </div>


  );
}

PRPage.propTypes = {
  serviceCountryList: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  serviceCountryList: state.common.country.serviceCountry,
});

export default connect(mapStateToProps)(PRPage);
