import { combineReducers } from 'redux';
import { param } from './param';
import { tabCategory } from './tabCategory';
import { regionList } from './regionList';
import { regionPlatformList } from './regionPlatformList';

export default combineReducers({
  param
  , tabCategory
  , regionList
  , regionPlatformList
});
