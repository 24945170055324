import { BASE_URL } from "constants/index";
import dayjs from "dayjs";
import { getHeaders } from "utils/Header";

export const saveCreateArea = async (createAreaData) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area`;
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(createAreaData),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Created Area!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const updateEditArea = async (editAreaData) => {
  editAreaData.enable = editAreaData.enable === "ON" ? "Y" : "N";
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${editAreaData.idx}`;
  const response = await fetch(apiUrl, {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify(editAreaData),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Updated Area!!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getWebPromotionArea = async (params) => {
  const apiUrl = `${BASE_URL}/webpromotion/web-promotion-area?country=${params.country}&status=${params.status}`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });

  const body = await response.json();

  if (body.result === "SUCCESS") {
    const transformedData = body.data.map((item) => ({
      ...item,
      enable: item.enable === "Y" ? "ON" : "OFF",
    }));

    return {
      data: transformedData,
      total: body.total || transformedData.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const deleteWebPromotionArea = async (params) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area`;
  const response = await fetch(apiUrl, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify(params),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Deleted!!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const publishWebPromotionArea = async (country, params) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/publish/${country}`;
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(params),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Published!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getWebPromotionCountry = async () => {
  let apiUrl = `${BASE_URL}/webpromotion/sdepth/biz-area-countries`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const getWebPromotionAreaCode = async () => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area-code`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const getWebLiveManualCategory = async (idx) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${idx}/categories`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    const transformedData = body.data.map((item) => ({
      ...item,
      enable: item.enable === "Y" ? "ON" : "OFF",
    }));
    return {
      data: transformedData,
      total: body.total || transformedData.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const saveWebContentChannelCategory = async (
  idx,
  contentCategoryData
) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${idx}/categories`;
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(contentCategoryData),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Created Category!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const updateWebContentChannelCategory = async (idx, updateCategory) => {
  updateCategory.enable = updateCategory.enable === "ON" ? "Y" : "N";
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${idx}/categories/${updateCategory.idx}`;
  const response = await fetch(apiUrl, {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify(updateCategory),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Updated Category!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getWebPromotionAreaCategory = async (idx) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${idx}/categories`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    const transformedData = body.data.map((item) => ({
      ...item,
      enable: item.enable === "Y" ? "ON" : "OFF",
    }));
    return {
      data: transformedData,
      total: body.total || transformedData.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const deleteWebLiveContentCategory = async (idx, params) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${idx}/categories`;
  const response = await fetch(apiUrl, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify(params),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Deleted!!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getWebContentOrganizeResult = async (categoryId, organizedId) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${organizedId}/categories/${categoryId}/result`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data.sort((a, b) => a.resultOrder - b.resultOrder),
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const saveWebContentOrganizeResult = async (
  categoryId,
  organizeId,
  organizeResult
) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${categoryId}/categories/${organizeId}/result`;
  const response = await fetch(apiUrl, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(organizeResult),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Successfully Created Area!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

export const getAreaCodesByCountry = async (country) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${country}/area-codes`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const getAreaTypesByCountry = async (country) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${country}/area-types`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const getWebContentOrganizeData = async (searchWebContent) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/category/content?country=${searchWebContent.country}&type=${searchWebContent.type}&cp=${searchWebContent.cp}&id=&name=&status=S`;
  const response = await fetch(apiUrl, {
    method: "GET",
    headers: getHeaders(),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      data: body.data,
      total: body.total || body.data.length,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
};

export const saveCreateBannerImage = async (
  areaId,
  createBrandingImageData
) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${areaId}/categories-image`;
  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      webPromotionCategoryIdx: createBrandingImageData.webPromotionCategoryIdx,
      categoryName: createBrandingImageData.categoryName,
      contentType: createBrandingImageData.contentType,
    })
  );
  if (createBrandingImageData.image) {
    formData.append("image", createBrandingImageData.image);
  }
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: getHeaders().get("Authorization"),
      },
      body: formData,
    });
    const body = await response.json();
    if (body.result === "SUCCESS") {
      return {
        severity: "success",
        message: body.message || "Successfully Created Area!",
      };
    } else {
      return {
        severity: "error",
        message: body.message || "Something went wrong!",
      };
    }
  } catch (error) {
    return {
      severity: "error",
      message: "An error occurred while saving the data.",
    };
  }
};

export const updateCreateBannerImage = async (
  areaId,
  idx,
  updateBrandingImageData
) => {
  updateBrandingImageData.enable =
    updateBrandingImageData.enable === "ON" ? "Y" : "N";
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${areaId}/categories-image/${idx}`;
  const formData = new FormData();

  formData.append(
    "data",
    JSON.stringify({
      webPromotionCategoryIdx: updateBrandingImageData.webPromotionCategoryIdx,
      resultIdx: updateBrandingImageData.resultIdx,
      categoryName: updateBrandingImageData.name,
      enable: updateBrandingImageData.enable,
      contentType: updateBrandingImageData.contentType,
    })
  );
  if (updateBrandingImageData.image) {
    formData.append("image", updateBrandingImageData.image);
  }
  try {
    const response = await fetch(apiUrl, {
      method: "PATCH",
      headers: {
        Authorization: getHeaders().get("Authorization"),
      },
      body: formData,
    });
    const body = await response.json();
    if (body.result === "SUCCESS") {
      return {
        severity: "success",
        message: body.message || "Successfully Created Area!",
      };
    } else {
      return {
        severity: "error",
        message: body.message || "Something went wrong!",
      };
    }
  } catch (error) {
    return {
      severity: "error",
      message: "An error occurred while saving the data.",
    };
  }
};

export const saveBannerImage = async (areaId, createBrandingImageData) => {
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${areaId}/categories-image`;
  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      webPromotionCategoryIdx: createBrandingImageData.webPromotionCategoryIdx,
      categoryName: createBrandingImageData.categoryName,
      startDate: createBrandingImageData.startDate
        ? formatDateForBackend(createBrandingImageData.startDate)
        : null,
      endDate: createBrandingImageData.endDate
        ? formatDateForBackend(createBrandingImageData.endDate)
        : null,
      url: createBrandingImageData.url,
      resultIdx: "",
      contentType: createBrandingImageData.contentType,
      enable: "N",
    })
  );
  if (createBrandingImageData.image) {
    formData.append("image", createBrandingImageData.image);
  }
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: getHeaders().get("Authorization"),
      },
      body: formData,
    });
    const body = await response.json();
    if (body.result === "SUCCESS") {
      return {
        severity: "success",
        message: body.message || "Successfully Created Area!",
      };
    } else {
      return {
        severity: "error",
        message: body.message || "Something went wrong!",
      };
    }
  } catch (error) {
    return {
      severity: "error",
      message: "An error occurred while saving the data.",
    };
  }
};

export const updateBannerImage = async (
  areaId,
  idx,
  updateBrandingImageData
) => {
  updateBrandingImageData.enable =
    updateBrandingImageData.enable === "ON" ? "Y" : "N";
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/${areaId}/categories-image/${idx}`;
  const formData = new FormData();
  formData.append(
    "data",
    JSON.stringify({
      webPromotionCategoryIdx: updateBrandingImageData.webPromotionCategoryIdx,
      categoryName: updateBrandingImageData.name,
      enable: updateBrandingImageData.enable,
      startDate: updateBrandingImageData.startDate
        ? formatDateForBackend(updateBrandingImageData.startDate)
        : null,
      endDate: updateBrandingImageData.endDate
        ? formatDateForBackend(updateBrandingImageData.endDate)
        : null,
      url: updateBrandingImageData.url,
      resultIdx: updateBrandingImageData.resultIdx,
      contentType: updateBrandingImageData.contentType,
    })
  );
  if (updateBrandingImageData.image) {
    formData.append("image", updateBrandingImageData.image);
  }
  try {
    const response = await fetch(apiUrl, {
      method: "PATCH",
      headers: {
        Authorization: getHeaders().get("Authorization"),
      },
      body: formData,
    });
    const body = await response.json();
    if (body.result === "SUCCESS") {
      return {
        severity: "success",
        message: body.message || "Successfully Created Area!",
      };
    } else {
      return {
        severity: "error",
        message: body.message || "Something went wrong!",
      };
    }
  } catch (error) {
    return {
      severity: "error",
      message: "An error occurred while saving the data.",
    };
  }
};

export const reOrderCategoryList = async (reOrderedData) => {
  const filteredReOrderedData = reOrderedData.map((item) => {
    return {
      categoryIdx: item.categoryIdx,
      categoryOrder: item.categoryOrder
    };
  });
  let apiUrl = `${BASE_URL}/webpromotion/web-promotion-area/re-order-category-list`;
  const response = await fetch(apiUrl, {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify(filteredReOrderedData),
  });
  const body = await response.json();
  if (body.result === "SUCCESS") {
    return {
      severity: "success",
      message: body.message || "Re-ordered Successfull!",
    };
  } else {
    return {
      severity: "error",
      message: body.message || "Something went Wrong!!",
    };
  }
};

const formatDateForBackend = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};
