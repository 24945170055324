import React, {useState, useEffect, useRef} from 'react';
import {Box, List, ListItem, ListItemText, Typography} from "@mui/material";
import SelectBox from "../../../components/component/BasicSelectBox";
import { SchedulesTime } from "./data/ChannelStatusData";
import DatePicker from "../../../components/component/BasicDatePicker";
import Button from "../../../components/component/BasicButton";
import styles from "../../../assets/scss/page/channelStatus.module.scss";
import Tooltip from "../../../components/component/BasicTooltip";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { styled } from '@mui/material/styles';
import PopChannelDetail from "./PopChannelDetail";
import Loading from "../../../components/component/Loading";

const ProgramList = ({ programs, current }) => (
  <Box className={styles['program-item-wrap']}>
    <Box component='div' className={styles.row}>
      {programs.map((program) => (
        <Box
          component='div'
          className={`${styles['program-item']} ${
            // 지난 시간 클래스: past / 현재 방송: active
            program.current === 'active' ? styles.active : program.current === 'past' ? styles.past : ''
          } ${parseInt(program.width) < 25 ? styles.tiny : ''}`}
          key={program.id}
          sx={{ width: program.width }}
        >
          <Tooltip title={
            <>
              <div>{program.title}</div>
              <div><b>{program.time}</b></div>
            </>
          } placement='top'>
            <Typography className={styles['program-title']}>{program.title}</Typography>
            <Typography className={styles['program-time']}>
              <span className={styles.text}>{program.time}</span>
            </Typography>
          </Tooltip>
        </Box>
      ))}
    </Box>
  </Box>
);

const CustomListItemText = styled(ListItemText)({
  '& .MuiTypography-root': {
    fontSize: '1.2rem',
    lineHeight: '16px',
  },
});

const hourMinutes = 60;
const HOUR_PX_SIZE = 130.5;
const MINUTE_PX_SIZE = HOUR_PX_SIZE / hourMinutes;

function ProgramScheduleGrid({ country, provider }) {

  const [countryByRegion, setCountryByRegion] = useState({});
  const [paramRegion, setParamRegion] = useState([]);
  const [paramCountry, setParamCountry] = useState([]);
  const [paramChannelMapId, setParamChannelMapId] = useState([]);
  const [paramProvider, setParamProvider] = useState([]);
  const [paramChannelName, setParamChannelName] = useState([]);
  const [paramDate, setParamDate] = useState(dayjs());

  const [selectedRegion, setSelectedRegion] = useState('All')
  const [selectedCountry, setSelectedCountry] = useState('US')
  const [selectedChannelMapId, setSelectedChannelMapId] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedChannelName, setSelectedChannelName] = useState('All');

  const [currentTimeBar, setCurrentTimeBar] = useState('')
  const [timeBarPosition, setTimeBarPosition] = useState(0);
  const [channelInfo, setChannelInfo] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState([]);

  const [channelDetailOpen, setChannelDetailOpen] = useState(false);
  const [popupChannelId, setPopupChannelId] = useState('');
  const [isDisplaySchedule, setIsDisplaySchedule] = useState(false);

  const bodyAreaRef = useRef(null);
  const currentTimeRef = useRef(null);

  const getToday = () => {
    const days = ['일', '월', '화', '수', '목', '금', '토'];
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayOfWeek = days[date.getDay()];
    return `${year}.${month}.${day} (${dayOfWeek})`;
  }

  const getProgramWidth = (startDateTime, endDateTime) => {
    const startOfDay = paramDate.toDate();
    startOfDay.setHours(9, 0, 0, 0);
    if (new Date(startDateTime) < startOfDay) {
      return (new Date(endDateTime) - startOfDay) / (1000 * 60) * MINUTE_PX_SIZE;
    }
    else return (new Date(endDateTime) - new Date(startDateTime)) / (1000 * 60) * MINUTE_PX_SIZE;
  }

  // 시간선
  useEffect(() => {
    const updateHeight = () => {
      if (bodyAreaRef.current && currentTimeRef.current) {
        const bodyAreaHeight = bodyAreaRef.current.offsetHeight + (40 - 16); //헤더높이 - time-text 높이
        currentTimeRef.current.style.height = `${bodyAreaHeight}px`;
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  useEffect(() => {
    const updateTime = () => {
      const date = new Date();
      const utcDate = new Date(date.getTime() - 9 * 60 * 60 * 1000);
      const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZone: 'UTC', hour12: false };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
      setCurrentTimeBar(formattedDate.replace(',', '').replace(/\//g, '/').replace(' ', ' ') + '(UTC)');
      setTimeBarPosition(-56.5 + HOUR_PX_SIZE * utcDate.getHours() + MINUTE_PX_SIZE * utcDate.getMinutes());
      SchedulesTime.forEach((time) => {time.timeCurrent = false})
      SchedulesTime.find((Schedule) => Number(Schedule.id) === new Date().getUTCHours()).timeCurrent = true
    }
    updateTime();
    const intervalId = setInterval(updateTime, 30000)

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (country.serviceCountry) {
      let region = [...new Set(country.serviceCountry.map(area => area.bizArea))].sort();
      const regions = region.map(reg => ({id: reg, value: reg, option: reg, type: 'text'}));
      setParamRegion([{ id: 'all', value: 'All', option: 'All', type: 'text' }, ...regions])

      let reduce = country.serviceCountry.reduce((acc, cur) => {
        const { bizArea, countryCode } = cur;
        if (!acc[bizArea]) {
          acc[bizArea] = []
        }
        acc[bizArea].push(countryCode)
        acc['All'].push(countryCode)
        return acc
      }, {'All': []});
      Object.keys(reduce).forEach(region => {
        reduce[region].sort()
      })
      setCountryByRegion(reduce)

      let countries = reduce['All'].map(country => (
        { id: country, value: country, option: country, type: 'text' }
      ));

      setParamCountry(countries)
    }
  }, [country]);

  useEffect(() => {
    if (selectedRegion && countryByRegion[selectedRegion]) {
      const b = countryByRegion[selectedRegion].map(country => (
        { id: country, value: country, option: country, type: 'text'}
      ))
      setSelectedCountry(countryByRegion[selectedRegion][0])
      setParamCountry(b)
    }
    // eslint-disable-next-line
  }, [selectedRegion]);

  useEffect(() => {
    if (provider && provider.country) {
      let find = provider.country.find(item => item.countryCode === selectedCountry);
      if (find.providerList) {
        let providers = find.providerList.map(item => ({
          id: item.providerId,
          value: item.providerId,
          option: item.providerId,
          type: 'text'
        }));
        setParamProvider([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...providers])
      }
    }
    if (country && country.serviceCountry) {
      const item = country.serviceCountry.find(item => item.countryCode === selectedCountry);
      setParamChannelMapId(item.channelMapList.map(item => (
        { id: item.channelMapId, value: item.channelMapId, option: item.channelMapId, type: 'text' }
      )))
      setSelectedChannelMapId(item.channelMapList[0].channelMapId)
    }
    // eslint-disable-next-line
  }, [selectedCountry, provider, country]);

  useEffect(() => {
    if (paramDate && selectedChannelMapId) {
      getSchedules();
    }
    // eslint-disable-next-line
  }, [paramDate, selectedChannelMapId, selectedProvider]);

  const getSchedules = () => {
    const param = `?countryCode=${selectedCountry}&channelMapId=${selectedChannelMapId}&providerId=${selectedProvider}&channelName=${selectedChannelName}&date=${paramDate}`;
    fetch(`${BASE_URL}/channel-status/channel/schedules${param}`, {
      method: 'GET',
      headers: getHeaders()
    })
      .then(res => res.json())
      .then(body => {
        if (body.result === 'SUCCESS') {
          const schedules = body.data
          // eslint-disable-next-line
          schedules.map(schedule => {
            schedule.schedules.sort((a, b) => new Date(a.endDateTime) - new Date(b.endDateTime))
          })
          setScheduleInfo(schedules)
          setIsDisplaySchedule(true)
        }
      })
  }

  useEffect(() => {
    const channelInfos = scheduleInfo.map(schedule => (
      { id: schedule.channelId, number: schedule.channelNumber, title: schedule.channelName }
    ))
    setChannelInfo(channelInfos)
    const channelNames = scheduleInfo.map(schedule => (
      { id: schedule.channelName, value: schedule.channelName, option: schedule.channelName, type: 'text' }
    ))
    setParamChannelName([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...channelNames])

  }, [scheduleInfo]);

  const channelDialogOpen = (channelId) => {
    setPopupChannelId(channelId)
    setChannelDetailOpen(true)
  }

  return (
    <div className='tab-panel schedule'>
      <div className='panel-wrap'>
        {/* 필터영역 */}
        <Box component='div' className='white-box filter-box'>
          <Box component='div' className='form-wrap-box'>
            <Box component='div' className='form-area left'>
              {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
              <Box component='div' className='component-wrap col-5'>
                <Box component='div' className='col'>
                  <SelectBox
                    id='select-business'
                    size='large'
                    shrink={true}
                    label='Region'
                    required={true}
                    selectOptions={paramRegion}
                    disabled={false}
                    selected={selectedRegion}
                    placeholder=''
                    onChange={setSelectedRegion}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='tab1-country'
                    size='large'
                    shrink={true}
                    label='Country'
                    required={true}
                    selectOptions={paramCountry}
                    disabled={false}
                    placeholder=''
                    selected={selectedCountry}
                    onChange={setSelectedCountry}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='channel-map'
                    size='large'
                    shrink={true}
                    label='Channel Map ID'
                    required={false}
                    selectOptions={paramChannelMapId}
                    disabled={false}
                    placeholder=''
                    selected={selectedChannelMapId}
                    onChange={setSelectedChannelMapId}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='select-cp'
                    size='large'
                    shrink={true}
                    label='CP Name'
                    selectOptions={paramProvider}
                    disabled={false}
                    placeholder=''
                    selected={selectedProvider}
                    onChange={setSelectedProvider}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='tab2-channel-name'
                    size='large'
                    shrink={true}
                    label='Channel Name'
                    selectOptions={paramChannelName}
                    disabled={false}
                    placeholder=''
                    selected={selectedChannelName}
                    onChange={setSelectedChannelName}
                  />
                </Box>
              </Box>

              {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
              <Box component='div' className='component-wrap '>
                <Box component='div' className='col auto'>
                  <DatePicker
                    className='input-date-picker'
                    format='MM/DD/YYYY'
                    size='large'
                    sx={{maxWidth: '220px'}}
                    shrink
                    label='Date'
                    singleDate={true}
                    value={paramDate}
                    onChange={(newValue) => setParamDate(newValue)}
                  />
                  <Button
                    color='tertiary'
                    type='outline'
                    size='medium'
                    label='Today'
                    isIcon={false}
                    autosize
                    onClick={() => setParamDate(dayjs())}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {isDisplaySchedule ? (
          <Box className={`program-schedule-container ${styles['program-schedule-container']}`}>
            {/* Header Row */}
            <Box component='div' className='align-set-wrap'>
              <Box className='total-count' marginRight='45px'>
                <Box component='span' className='label'>
                  Total
                </Box>
                <Box component='span' className='value'>
                  {channelInfo.length}
                </Box>
              </Box>
              <Box component='div' className={styles['date-mark']}>
                <Typography className={styles['date']} component='span'>
                  {getToday()}
                </Typography>
              </Box>
            </Box>

            <div className={styles['schedule-container']}>
              <Box className={styles['schedule-wrap']}>
                <Box className={styles['channel-area']}>
                  <List className={styles['channel-list-container']} disablePadding>
                    <ListItem className={styles['top-area']}>
                      <Typography component='p' className={styles.text}>
                        Channel
                      </Typography>
                    </ListItem>
                    {channelInfo.map((channel) => (
                      <ListItem
                        className={styles['channel-item']}
                        key={channel.id}
                        onClick={() => channelDialogOpen(channel.id)}
                      >
                        <Box className={styles['info-box']}>
                          <Typography component='span' className={styles['channel-number']}>
                            {channel.number}
                          </Typography>
                          <CustomListItemText className={styles['channel-name']} primary={channel.title}/>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Box component='div' className={styles['schedule-area']}>
                  <Box component='div' className={styles['program-wrap']}>
                    <Box className={styles['program-header-wrap']} wrap='nowrap'>
                      {/* 현재시간 - 시간표시 */}
                      <Box
                        className={styles['current-time']}
                        ref={currentTimeRef}
                        sx={{
                          left: `${timeBarPosition}px`, // 현재시간 위치
                        }}
                      >
                        <div className={styles['time-area']}>
                          <span className={styles['time-text']}>{currentTimeBar}</span>
                          <span className={styles['time-bar']}></span>
                        </div>
                      </Box>
                      <Box className={styles['header-inner']}>
                        {SchedulesTime.map((Schedule) => (
                          // 현재시간 표시 클래스: current
                          <div
                            className={`${styles['header-item']} ${Schedule.timeCurrent ? styles.current : ''}`}
                            key={Schedule.id}
                          >
                            <Typography component='span' className={styles.text}>
                              {Schedule.time}
                            </Typography>
                          </div>
                        ))}
                      </Box>
                    </Box>
                    <Box ref={bodyAreaRef} className={styles['program-body-wrap']}>
                      <Box className={styles['body-area']}>
                        {
                          scheduleInfo.map((schedule) => (
                            <ProgramList programs={schedule.schedules.map((program, idx) => (
                              { id: program.programTitle + ' ' + program.startDateTime,
                                current: new Date(program.startDateTime) <= new Date() && new Date() < new Date(program.endDateTime) ? 'active' : 'past',
                                width: `${getProgramWidth(program.startDateTime, program.endDateTime)}px`,
                                title: program.programTitle,
                                time: program.startDateTime.substring(11, 16) + '~' + program.endDateTime.substring(11, 16)}
                            ))} />
                          ))
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
        ) : <Loading />}
      </div>
      <PopChannelDetail channelId={popupChannelId} channelDetailOpen={channelDetailOpen} closeChannelDetail={() => setChannelDetailOpen(false)} />
    </div>
  )
}

const mapStateToProps = state => ({
  country: state.common.country,
  provider: state.common.provider,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramScheduleGrid);