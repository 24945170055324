import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Pagination from 'components/component/BasicPagination';
import CustomImage from 'components/component/BasicImage';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from 'components/component/BasicTextField';
import CustomRadio from 'components/component/BasicRadio';
import SelectBox from 'components/component/BasicSelectBox';
import SearchField from 'components/component/BasicSearchField';
import Button from 'components/component/BasicButton';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CheckBox from 'components/component/BasicCheckBox';
import TextButton from 'components/component/BasicTextButton';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Link,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

// Data
import {
  CountryData,
  contentTypeOption,
  CommonAutoTh,
  CommonAutoTd,
  selectOption,
} from 'pages/Organization/TabCategory/data/TabCategoryData';

// images
import { AddPlus, DelMinus } from 'components/component/BasicIcon';
import { ReactComponent as Icinfo24 } from 'assets/images/icon/ic_info_24_outline_primary.svg';

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Radio Data
const radioOptions = [
  { id: 1, checked: true, value: 'option1', label: 'Latest first', name: 'radio' },
  { id: 2, checked: false, value: 'option2', label: 'Popular first', name: 'radio' },
  { id: 3, checked: false, value: 'option3', label: 'Start time first', name: 'radio2', disabled: true },
];

// 메인 컨텐츠
function CommonAuto({ name }) {
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Category List'],
  };

  // Select
  const [openSelect1, setOpenSelect1] = useState(false);
  const [openContentType, setOpenContentType] = useState(false);
  const [openCriteria, setOpenCriteria] = useState(false);
  const [openCriteria2, setOpenCriteria2] = useState(false);
  const [openCP, setOpenCP] = useState(false);
  const [openCP2, setOpenCP2] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  // Area 정보 데이터
  const infos = [
    {
      index: 1,
      label: '#Region',
      value: 'US_Auto',
      divide: true,
    },
    {
      index: 2,
      label: '#Tab',
      value: ' Watch Now',
      divide: true,
    },
    {
      index: 3,
      label: '#Category',
      value: 'Featured',
      divide: true,
    },
    {
      index: 4,
      label: '#Tab',
      value: 'Live, TvShow',
      divide: false,
    },
  ];

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  // Table Body Data

  // radio
  const initialCheckedValue = radioOptions.find((option) => option.checked)?.value || 'option1';
  const [selectedValue, setSelectedValue] = useState(initialCheckedValue);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const sortedTableTdRows = sortRows([...CommonAutoTd], orderBy, order);

  const buttons = [
    {
      index: 1,
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Delete',
      fontWeight: 'bold',
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: true,
    },
    {
      index: 2,
      color: 'tertiary',
      type: 'box',
      size: 'medium',
      label: 'CHECK KEYWORD',
      fontWeight: 'bold',
      isIcon: false,
      autosize: true,
    },
    {
      index: 3,
      color: 'tertiary',
      type: 'box',
      size: 'medium',
      label: 'CHECK GENRE',
      fontWeight: 'bold',
      isIcon: false,
      autosize: true,
    },
    {
      index: 4,
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Save',
      fontWeight: 'bold',
      isIcon: false,
      autosize: true,
    },
  ];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container common-auto' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Category List
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={CountryData}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openSelect1}
                      setOpen={setOpenSelect1}
                    />
                  </Box>

                  {/* Content Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='common-Content'
                      size='large'
                      shrink={true}
                      label='Content Type'
                      required={false}
                      selectOptions={contentTypeOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openContentType}
                      setOpen={setOpenContentType}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>

          <Box className='white-box filter-box'>
            <Box className='setting-container'>
              <Box className='layout-container'>
                {/* Ordering */}
                <Box display='flex' className='row'>
                  <Box className='left-area'>
                    <Typography className='filter-label'>Ordering</Typography>
                  </Box>
                  <Box className='right-area' display='flex'>
                    <Box sx={{ flexShrink: 0 }}>
                      <Box>
                        <RadioGroup value={selectedValue} onChange={handleRadioChange} className='align-h'>
                          {radioOptions.map((option) => (
                            <CustomRadio
                              key={option.value}
                              value={option.value}
                              checked={selectedValue === option.value}
                              label={option.label}
                              onChange={handleRadioChange}
                              disabled={option.disabled}
                              name={name}
                            />
                          ))}
                        </RadioGroup>
                      </Box>
                      <Box className='update-date'>
                        <Typography component='p'>
                          <Typography component='span' className='date label'>
                            Duration, Batch time :
                          </Typography>
                          <Typography component='span' className='date value'>
                            daily UTC 19:10
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    {/* Latest first 선택할 시에 노출 */}
                    <Box>
                      <TextButton
                        label='Information'
                        isIcons={true}
                        icon={Icinfo24}
                        underline
                        iconWidth='24px'
                        iconHeight='24px'
                      />
                    </Box>

                    {/* Popular first 선택할 시에 버튼 노출 */}
                    <CustomButtonGroup buttons={buttons} justifyContent='flex-end' />
                  </Box>
                </Box>
                <Divider sx={{ margin: '24px 0' }} />

                {/* Criteria */}
                <Box display='flex' className='row'>
                  <div className='left-area'>
                    <Typography className='filter-label'>Criteria</Typography>
                  </div>
                  <div className='right-area'>
                    <Box component='div' className='field-box flex-start'>
                      <SelectBox
                        id='Criteria-01'
                        fullWidth
                        required={false}
                        size='large'
                        maxWidth='300px'
                        selectOptions={selectOption}
                        disabled={false}
                        label='Criteria'
                        isFirstSelected={false}
                        open={openCriteria}
                        setOpen={setOpenCriteria}
                        shrink
                      />

                      <TextField
                        type='outlined'
                        size='large'
                        placeholder=''
                        defaultValue=''
                        maxWidth='500px'
                        label='Detail Criteria'
                        autoComplete='off'
                        required={true}
                        InputLabelProps={{ shrink: true }}
                        disabled={false}
                      />
                      {/* 행 추가 버튼 */}
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={AddPlus}
                        autosize
                      />
                    </Box>
                    <Box component='div' className='field-box flex-start'>
                      <SelectBox
                        id='Criteria-02'
                        fullWidth
                        required={false}
                        size='large'
                        maxWidth='300px'
                        selectOptions={selectOption}
                        disabled={false}
                        label='Criteria'
                        isFirstSelected={false}
                        open={openCriteria2}
                        setOpen={setOpenCriteria2}
                        shrink
                      />

                      <TextField
                        type='outlined'
                        size='large'
                        placeholder='Detail Criteria'
                        defaultValue=''
                        maxWidth='500px'
                        label=''
                        autoComplete='off'
                        required={true}
                        InputLabelProps={{ shrink: false }}
                        disabled={false}
                      />
                      {/* 행 추가 버튼 */}
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={AddPlus}
                        autosize
                      />
                      {/* 행 삭제 버튼 */}
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={DelMinus}
                        autosize
                      />
                    </Box>
                  </div>
                </Box>

                {/* CP */}
                <Divider sx={{ margin: '24px 0' }} />
                <Box display='flex' className='row'>
                  <div className='left-area'>
                    <Typography className='filter-label'>CP</Typography>
                  </div>
                  <div className='right-area'>
                    <Box component='div' className='field-box flex-start'>
                      <SelectBox
                        id='select-01'
                        fullWidth
                        size='large'
                        maxWidth='300px'
                        selectOptions={selectOption}
                        disabled={false}
                        label='CP Name'
                        isFirstSelected={false}
                        open={openCP}
                        required={false}
                        setOpen={setOpenCP}
                        shrink
                      />

                      <TextField
                        type='outlined'
                        size='large'
                        placeholder=''
                        defaultValue=''
                        maxWidth='500px'
                        label='Count'
                        autoComplete='off'
                        required={false}
                        InputLabelProps={{ shrink: true }}
                      />
                      {/* 행 추가 버튼 */}
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={AddPlus}
                        autosize
                      />
                    </Box>
                    <Box component='div' className='field-box flex-start'>
                      <SelectBox
                        id='select-01'
                        fullWidth
                        size='large'
                        maxWidth='300px'
                        selectOptions={selectOption}
                        disabled={false}
                        label='CP Name'
                        isFirstSelected={false}
                        required={false}
                        open={openCP2}
                        setOpen={setOpenCP2}
                        shrink
                      />

                      <TextField
                        type='outlined'
                        size='large'
                        placeholder='Count'
                        defaultValue=''
                        maxWidth='500px'
                        label=''
                        autoComplete='off'
                        required={false}
                        InputLabelProps={{ shrink: false }}
                      />
                      {/* 행 추가 버튼 */}
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={AddPlus}
                        autosize
                      />
                      {/* 행 삭제 버튼 */}
                      <Button
                        color='tertiary'
                        type='outline icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={DelMinus}
                        autosize
                      />
                      <Box display='flex' gap={1} marginLeft={'auto'}>
                        <Button
                          color='primary'
                          type='box'
                          size='medium'
                          label='Search'
                          iconPos={undefined} //left, right
                          isIcon={false} //true, false
                          Icon=''
                        />
                        <Button
                          color='primary'
                          type='box'
                          size='medium'
                          label='Save'
                          iconPos={undefined} //left, right
                          isIcon={false} //true, false
                          Icon=''
                        />
                      </Box>
                    </Box>
                  </div>
                </Box>
              </Box>
              <Box className=''></Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            {/* Box Content Area */}
            <Box className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box display='flex' alignItems={'center'} gap={1}>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        6
                      </Box>
                    </Box>
                    <Typography className='sm-sub-text' ml={3}>
                      *The expected results based on the above configuration are displayed below.
                    </Typography>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SelectBox
                      id='select-01'
                      maxWidth='180px'
                      fullWidth
                      size='medium'
                      selectOptions={selectOption}
                      disabled={false}
                      label=''
                      isFirstSelected={true}
                      open={openSearch}
                      setOpen={setOpenSearch}
                      shrink
                    />

                    <SearchField
                      id='search-1'
                      placeholder='Please enter a search term'
                      label='Ch.no or ch.Name'
                      maxWidth='230px'
                      size='medium'
                      required={false}
                      shrink={false}
                    />
                  </Box>
                </Box>
                <Box className='right-area'></Box>
                {/* right-area */}
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  {/* thead Th 줄바꿈 클래스: auto */}
                  <Table className='custom-table auto' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {CommonAutoTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTableTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {CommonAutoTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 7 ? (
                                <CustomImage
                                  src={td[`data0${colIndex + 1}`]}
                                  fit={false}
                                  alt=''
                                  wrapWidth='100px'
                                  wrapHeight='64px'
                                  rounded
                                />
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CommonAuto;
