import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';

import {
  Box,
  Link,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

// Data
import {
  shelfDeviceOption,
  RegionData,
  PlatformVersionOption,
  StatusOption,
  EnableOption,
  selectOption,
  HistoryContentTh,
  HistoryContentTd,
} from 'pages/Promotion/FreeOnLGChannelsShelf/data/FreeOnData';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Button Data
const buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Reset',
    isIcon: true,
    Icon: RefreshIcon,
    iconPos: 'left',
    className: 'custom-save-icon',
    width: 107,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Search',
  },
];

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function FreeOnLGChannelsShelfHistory() {
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Free On LG Channels Shelf',
    normalMenu: ['Free On LG Channels Shelf History'],
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const sortedContentTdRows = sortRows([...HistoryContentTd], orderBy, order);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Free On LG Channels shelf History
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'></Box>
            <Box className='right-area'>
              <Button color='tertiary' type='outline' size='medium' label='Snapshot Download' isIcon={false} />
            </Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5, col-6  */}
                <Box className='component-wrap col-5'>
                  {/* Device type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='historyTab-device'
                      size='large'
                      label='Device type'
                      shrink={true}
                      required={false}
                      selectOptions={shelfDeviceOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* Region */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='historyContent-Region'
                      size='large'
                      shrink={true}
                      label='Region'
                      selectOptions={RegionData}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* Platform Version */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='historyContent-Platform'
                      size='large'
                      shrink={true}
                      label='Platform Version'
                      selectOptions={PlatformVersionOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Category Code */}
                  <Box component='div' className='col'>
                    <TextField
                      id='historyContent-code'
                      type='outlined'
                      size='large'
                      placeholder='Category Code'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>

                  {/* Category Name */}
                  <Box component='div' className='col'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Category Name'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                </Box>

                {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box className='component-wrap col-5'>
                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='historyContent-Status'
                      size='large'
                      shrink={true}
                      label='Status'
                      selectOptions={StatusOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>
                  {/* Enable */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='historyContent-Enable'
                      size='large'
                      shrink={true}
                      label='Enable'
                      selectOptions={EnableOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>
                  <Box component='div' className='col auto'>
                    <DatePicker
                      format='MM/DD/YYYY'
                      label='Date'
                      size='large'
                      singleDate={true}
                      rangeDate={false}
                      wave={false}
                    />

                    <Button color='tertiary' type='outline' size='medium' label='Today' isIcon={false} autosize />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons} />
              </div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <SelectBox
                    id='select-search'
                    size='medium'
                    selectOptions={selectOption}
                    label=''
                    maxWidth='180px'
                    disabled={false}
                    placeholder=''
                  />

                  <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                </Box>
                <Box className='right-area'>
                  <Button color='tertiary' type='outline' size='small' label='Download' isIcon={false} autosize />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {HistoryContentTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedContentTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {HistoryContentTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 7 ? (
                                <Box display='flex'>
                                  <Typography component={'p'} className='result-value'>
                                    <Typography component={'span'} className='label'>
                                      Deleted
                                    </Typography>
                                    <Typography component={'span'} className='val-deleted'>
                                      {td.data08.deleted}
                                    </Typography>
                                  </Typography>
                                  <Box component={'span'} margin={'0 4px'}>
                                    /
                                  </Box>
                                  <Typography component={'p'} className='result-value'>
                                    <Typography component={'span'} className='label'>
                                      Created
                                    </Typography>
                                    <Typography component={'span'} className='val-created'>
                                      {td.data08.created}
                                    </Typography>
                                  </Typography>
                                  <Box component={'span'} margin={'0 4px'}>
                                    /
                                  </Box>
                                  <Typography component={'p'} className='result-value'>
                                    <Typography component={'span'} className='label'>
                                      Updated
                                    </Typography>
                                    <Typography component={'span'} className='val-updated'>
                                      {td.data08.updated}
                                    </Typography>
                                  </Typography>
                                </Box>
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default FreeOnLGChannelsShelfHistory;
