import { combineReducers } from 'redux';
import common from './common';
import homeapp from './homeapp';

const cmp = combineReducers({
  common,
  homeapp,
});

export default cmp;
