import React, {useEffect, useState} from 'react';
import Dialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import SelectCheckBox from 'components/component/BasicSelectCheckBox';
import { connect } from "react-redux";

import { Grid, Box } from '@mui/material';

import {getHeaders} from "../../../utils/Header";
import {BASE_URL} from "../../../constants";

// 메인 컨텐츠
function PopAddRegion({ addRegionOpen, onClose, paramDeviceType, country, afterSave }) {

  const handleClose = () => {
    onClose();
  };

  const [paramCountry, setParamCountry] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [regionInput, setRegionInput] = useState('');
  const [countryByRic, setCountryByRic] = useState({});
  const [paramRic, setParamRic] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState(paramDeviceType[0]?.value);
  const [selectedRic, setSelectedRic] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');



  useEffect(() => {
    if (country && country.supportedCountry) {
      let reduce = country.supportedCountry.reduce((acc, cur) => {
        const { ric, countryCode } = cur;
        if (!acc[ric]) {
          acc[ric] = [];
        }
        acc[ric].push(countryCode);
        return acc;
      }, {});
      setCountryByRic(reduce);
      setParamRic(Object.keys(reduce).map(ric => ({id: ric, value: ric, option: ric, type: 'text'})));
      setSelectedRic(Object.keys(reduce)[0]);
    }
  }, [country]);

  useEffect(() => {
    if (selectedRic && countryByRic[selectedRic]) {
      setParamCountry(countryByRic[selectedRic].map(country => ({
        id: country,
        value: country,
        option: country,
        type: 'checkbox'
      })));
      setSelectedCountries([]);
    }
  }, [selectedRic, countryByRic]);

  const handleSelectedCountries = (country) => {
    if (country && country.length > 0) {
      setSelectedCountries([...country]);
    }
  }

  const saveRegion = () => {
    if (selectedCountries.length === 0) {
      setAlertTitle('Warning')
      setAlertMessage('At least one country must be selected.');
      setAlertOpen(true);
    } else if (regionInput === '') {
      setAlertTitle('Warning')
      setAlertMessage('Region is required.');
      setAlertOpen(true);
    }
    const region = {
      deviceType: selectedDeviceType,
      region: regionInput,
      ricCode: selectedRic,
      countryList: selectedCountries
    };

    fetch(`${BASE_URL}/code-management/region`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(region)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Success')
        setAlertMessage('Region has been saved successfully.');
        setAlertOpen(true);
        onClose();
      }
    })
    .finally(() => {
      afterSave();
    })
  }

  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      disabled: false,
      onClick: saveRegion
    },
  ];

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <div>
      <Dialog
        open={addRegionOpen}
        closeX={true}
        title='Add Region' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className=''
        contentTextProps={
          <Grid container item xs={12}>
            {/* Device Type */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='region-DeviceType'
                  size='large'
                  shrink={true}
                  label='Device Type'
                  required={true}
                  selectOptions={paramDeviceType}
                  disabled={false}
                  placeholder='Select'
                  selected={selectedDeviceType}
                  onChange={(newValue) => setSelectedDeviceType(newValue)}
                />
              </Box>
            </Grid>

            {/* Region */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='outlined'
                  size='large'
                  placeholder='ex) EU'
                  defaultValue=''
                  label='Region'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={regionInput}
                  onChange={(e) => setRegionInput(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                    id='region-Ric'
                    size='large'
                    shrink={true}
                    label='RIC Code'
                    required={true}
                    selectOptions={paramRic}
                    disabled={false}
                    placeholder='Select'
                    selected={selectedRic}
                    onChange={(newValue) => setSelectedRic(newValue)}
                />
              </Box>
            </Grid>

            {/* Country */}
            <Grid item xs={12}>
              <Box component='div' className='field-box'>
                <SelectCheckBox
                  id='region-Country'
                  size='large'
                  shrink={true}
                  label='Country'
                  required={true}
                  selectOptions={paramCountry}
                  disabled={false}
                  multiple={true}
                  placeholder='Select'
                  onChange={handleSelectedCountries}
                />
              </Box>
            </Grid>
          </Grid>
        }
        buttons={dialogButtons}
      />
      <Dialog
          open={isAlertOpen}
          handleClose={handleAlertClose}
          closeX={false}
          size='small'
          title={alertTitle}
          contentTitle={false}
          bottomDivider={false}
          contentText={alertMessage}
          buttons={alertDialogButtons}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  country: state.common.country,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PopAddRegion);
