import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CheckBox from 'components/component/BasicCheckBox';
import SelectBox from 'components/component/BasicSelectBox';
import Button from 'components/component/BasicButton';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import TextLink from 'components/component/BasicTextLink';
import SearchField from 'components/component/BasicSearchField';
import { styled } from '@mui/system';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

// Data
import {
  ChannelMapOption,
  selOptionCountryData,
  ReservationsTh,
  ReservationsTd,
  selectOption,
} from 'pages/Organization/ChannelMapping/data/ChannelMappingData2';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const CellType = styled(TableCell)(({ theme }) => ({
  '& .success': {
    color: '#0077B5',
  },
  '& .fail': {
    color: '#B91C1C',
  },
  '& .update': {
    color: '#0D9488',
  },
}));

// 메인 컨텐츠
function ChannelMapReservations() {
  // Select
  const [openCountry, setOpenCountry] = useState(false);
  const [openChannelMap, setOpeChannelMap] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [openReservation, setOpenReservation] = useState(false);
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Channel Mapping',
    normalMenu: ['Channel Map Reservations'],
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  // Table Body Data
  const sortedTdRows = sortRows([...ReservationsTd], orderBy, order);

  const [openSearch, setOpenSearch] = useState(false);

  // Table view line
  const selOptionData = [
    { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
    { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
    { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
    { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
  ];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container bulk-upload' component='main'>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Channel Map Reservations
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-6'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='chmap-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={selOptionCountryData}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openCountry}
                      setOpen={setOpenCountry}
                    />
                  </Box>

                  {/* Channel Map */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='chmap-ChannelMap'
                      size='large'
                      shrink={true}
                      label='Channel Map'
                      required={false}
                      selectOptions={ChannelMapOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openChannelMap}
                      setOpen={setOpeChannelMap}
                    />
                  </Box>

                  {/* Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='chmap-Type'
                      size='large'
                      shrink={true}
                      label='Type'
                      required={false}
                      selectOptions={ChannelMapOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openType}
                      setOpen={setOpenType}
                    />
                  </Box>

                  {/* Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='chmap-Reservation'
                      size='large'
                      shrink={true}
                      label='Reservation Status'
                      required={false}
                      selectOptions={ChannelMapOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openReservation}
                      setOpen={setOpenReservation}
                    />
                  </Box>

                  <Box component='div' className='col auto'>
                    <DatePicker
                      format='YYYY.MM.DD'
                      size='large'
                      singleDate={false}
                      rangeDate={true}
                      wave={true}
                      sx={{ maxWidth: '210px' }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'></Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box display='flex' gap={1}>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        6
                      </Box>
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SelectBox
                      id='select-01'
                      maxWidth='180px'
                      fullWidth
                      size='medium'
                      selectOptions={selectOption}
                      disabled={false}
                      label=''
                      isFirstSelected={true}
                      open={openSearch}
                      setOpen={setOpenSearch}
                      shrink
                    />

                    <SearchField
                      id='search-1'
                      placeholder='Please enter a search term'
                      label='Ch.no or ch.Name'
                      maxWidth='230px'
                      size='medium'
                      required={false}
                      shrink={false}
                    />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <Button color='tertiary' type='box' size='small' label='New Reservation' isIcon={false} autosize />
                </Box>
                {/* right-area */}
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table auto' aria-label='table'>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {ReservationsTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {ReservationsTh.map((column, colIndex) => (
                            <CellType key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <CheckBox
                                  label={<TextLink href='' text={td[`data${colIndex + 1}`]} />}
                                  value='value'
                                  defaultChecked={false}
                                />
                              ) : colIndex === 8 ? (
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='Edit'
                                  isIcon={false}
                                  disabled={td.data9.disabled}
                                  autosize
                                />
                              ) : (
                                td[`data${colIndex + 1}`]
                              )}
                            </CellType>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>
              <Pagination id='pagination-01' count={5} selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ChannelMapReservations;
