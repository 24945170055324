import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { IconButton, ListItemButton, ListItemText } from '@mui/material';
import GnbItem from './GnbItem';
import PopFavoriteMenu from './PopFavoriteMenu';
import { useNavigate } from 'react-router-dom';

// Icon
import { MenuOpenIcon, DarkMenuOpenIcon } from 'components/component/BasicIcon';

// image
import LeftArrow from 'assets/images/icon/ic_Left_Arrow_32_outline.svg';
import DarkLeftArrow from 'assets/images/icon/ic_Left_Arrow_32_outline_darkmode.svg';

// css
import 'assets/scss/gnb/gnb.scss';
// Data
import { menuItemData, partnerMenuItemData } from './data/GnbItemData';

const drawerWidth = 284;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
}));

const LeftArrowIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${LeftArrow})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50%',
});
const DarkLeftArrowIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${DarkLeftArrow})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50%',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const ListWrapper = styled(List)`
  & .MuiListItemButton-root {
    &.Mui-selected {
      background-color: #a50034;
      color: white;
      & .MuiListItemText-root {
        & .MuiTypography-root {
          color: #fff;
        }
      }
    }
  }
`;

const GnbItemButton = styled(ListItemButton)`
  height: auto;
  padding: 0;
  min-height: auto;
  &.selected {
    background-color: #a50034;
    color: white;
    .dark & {
      background-color: #21000a;
    }
    & .MuiListItemText-root {
      & .MuiTypography-root {
        color: #fff;
      }
    }
  }
`;

export default function Gnb(props) {
  const { darkMode
        , isPartnerPageShow
        , callbackSelectedId
        , selectedIdFromNonLnb
      } = props;

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [displayMenuItemData, setDisplayMenuItemData] = useState([]);
  const [displayFavoriteMenuData, setDisplayFavoriteMenuData] = useState([]);
  const [isGnbAllExpand, setGnbAllExpand] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setGnbAllExpand(true);
  }, []);

  useEffect(() => {
    getLocalStorage();
    // eslint-disable-next-line
  }, [isPartnerPageShow]);

  useEffect(() => {
    const isGnbMenuAnyClose = displayMenuItemData.some(item => item.collapseOpen === false);

    if (isGnbMenuAnyClose) { // GNB 메뉴가 1개라도 닫혀 있는 지 체크
      setGnbAllExpand(false);
    } else {
      setGnbAllExpand(true);
    }
  }, [displayMenuItemData]);

  useEffect(() => {
    if (selectedIdFromNonLnb) {
      handleListSubItemUpdate(selectedIdFromNonLnb);
    }
    // eslint-disable-next-line
  }, [selectedIdFromNonLnb]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleListItemClick = (id) => {
    if (id === 0) {
      setDisplayMenuItemData((prevData) =>
        prevData.map((item) => ({ ...item, collapseOpen: false }))
      );
    } else if (id === 1) {
      setDisplayMenuItemData((prevData) =>
        prevData.map((item) => ({ ...item, collapseOpen: true }))
      );
    } else {
      setDisplayMenuItemData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, collapseOpen: !item.collapseOpen } : item
        )
      );
    }
  };

  const handleListSubItemUpdate = (subId) => {
    const updatedData = displayMenuItemData.map(item => ({
        ...item,
        subItems: item.subItems.map(subItem => ({
          ...subItem,
          selected: subItem.id === subId ? 'selected' : '',
        })),
      }));

    setDisplayMenuItemData(updatedData);

    if (subId !== 10000) {
      callbackSelectedId(subId);
    }
  };

  const handleListSubItemClick = (selectedItem) => {
    navigate(selectedItem.path, { replace: true, state: { move: true, targetComponent: '' } });
    handleListSubItemUpdate(selectedItem.id);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleFavoriteMenuClick = () => {
    setIsOpen(true);
  };

  const handleSelectedFavoriteMenu = (items) => {
    displayMenuItemData.forEach(item => {
      if (item.primaryText === 'Favorite Menu') {
        item.subItems = [...items];
      }
    });

    // save favorite menu to local storage
    localStorage.setItem('favoriteMenu', JSON.stringify(items));
  };

  const getLocalStorage = () => {
    const storedData = localStorage.getItem('favoriteMenu');
    const storedFavoriteMenuData = storedData ? JSON.parse(storedData) : [];

    setDisplayFavoriteMenuData(storedFavoriteMenuData);

    if (isPartnerPageShow) {
      setDisplayMenuItemData(partnerMenuItemData);
    } else {
      menuItemData.forEach(item => {
        if (item.primaryText === 'Favorite Menu') {
          item.subItems = [...storedFavoriteMenuData];
        }
      });

      setDisplayMenuItemData(menuItemData);
    }
  };

  return (
    <>
      <Box className='header-container' component='header'>
        <Box className='navi-container' component='nav'>
          <Drawer variant='permanent' open={drawerOpen} onClose={handleDrawerToggle}>
            {/* Header */}
            <DrawerHeader className='drawer-header'>
              <ListWrapper className='header-wrap' component='div'>
                {/* Gnb 펼치기버튼 */}
                <GnbItemButton
                  component='div'
                  onClick={handleDrawerToggle}
                  sx={{
                    ...(drawerOpen && { display: 'none' }),
                  }}
                >
                  <IconButton color='inherit' aria-label='open drawer' edge='start' className='btn-expand'>
                    {darkMode ? <DarkMenuOpenIcon /> : <MenuOpenIcon />}
                  </IconButton>
                </GnbItemButton>

                {/* Gnb 접기버튼 */}
                <GnbItemButton
                  component='div'
                  onClick={handleDrawerToggle}
                  sx={{
                    ...(!drawerOpen && { display: 'none' }),
                  }}
                >
                  <IconButton className='btn-shrink'>{darkMode ? <DarkLeftArrowIcon /> : <LeftArrowIcon />}</IconButton>
                </GnbItemButton>

                {/*  Menu Expand Button */}
                <GnbItemButton
                  component='div'
                  onClick={() => handleListItemClick(isGnbAllExpand ? 0 : 1)}
                  className='expand-shrink'
                >
                  <ListItemButton className={isGnbAllExpand ? 'btn-menu-shrink selected' : 'btn-menu-expand'}>
                    <IconButton>
                      <span className='icon' />
                    </IconButton>
                    <ListItemText
                      primary={isGnbAllExpand ? 'Close All' : 'Expand All'}
                      sx={{ display: drawerOpen ? 'block' : 'none' }}
                    />
                  </ListItemButton>
                </GnbItemButton>
              </ListWrapper>
            </DrawerHeader>
            {/* Header End */}

            <Divider className='nav-divider' sx={{ background: '#242424' }} />

            <ListWrapper className='nav-container bottom-wrap' sx={{ width: '100%', maxWidth: 360 }} component='nav'>

              {displayMenuItemData.map((item) => (
                <GnbItem
                  key={item.id}
                  item={item}
                  drawerOpen={drawerOpen}
                  handleListItemClick={handleListItemClick}
                  handleListSubItemClick={handleListSubItemClick}
                  handleFavoriteMenuClick={handleFavoriteMenuClick}
                />
              ))}
            </ListWrapper>
          </Drawer>
        </Box>
      </Box>
      <PopFavoriteMenu isOpen={isOpen} darkMode={darkMode} displayFavoriteMenuData={displayFavoriteMenuData} callbackClose={handleClose} callbackSelectedFavoriteMenu={handleSelectedFavoriteMenu}/>
    </>
  );
}
