import * as React from 'react';
import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled
const TextLink = styled(Link)`
  font-weight: 400;
  color: #2196f3;
  font-size: 12px;
  text-decoration: underline;

  &.no-line {
    text-decoration: none;
  }
`;

// TextLink Component
function BasicTextLink({ href, text, noLine, onClick, ...props  }) {
  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault();
      onClick(event);
    }
  };

  return (
    <TextLink href={href} title={text} className={noLine ? 'no-line' : ''} onClick={handleClick} {...props}>
      {text}
    </TextLink>
  );
}

export default BasicTextLink;