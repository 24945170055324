import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import BasicSwitches from 'components/component/BasicSwitch';
import TextField from 'components/component/BasicTextField';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import FileUpload from 'components/component/BasicFileUpload';
import { Box, Typography, Link, Grid, ListItem, List, Divider } from '@mui/material';

// 더미 이미지
import imgDummy from 'assets/images/No-Image_378.png';
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import { useNavigate } from 'react-router-dom';
import BasicSnackbar from 'components/component/BasicSnackbar';
import { updateCreateBannerImage } from '../../api/restApi';

// 메인 컨텐츠
function CategoryDetailBrandingB(props) {
  const { message } = props;
  const { idx, seletedRow, areaId, country, areaName, areaType, selectedStatus, region } = message;
  const navigate = useNavigate();
  const [showSnakBar, setShowSnakBar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [actualSize, setActualSize] = useState({ width: 890, height: 490 });
  const [tempImage, setTempImage] = useState(seletedRow.img);
  const [updateBrandImageB, setUpdateBrandImageB] = useState({
    webPromotionCategoryIdx: seletedRow.resultOrder,
    resultIdx: seletedRow.resultIdx,
    order: seletedRow.resultOrder,
    name: seletedRow.categoryName,
    enable: seletedRow.enable,
    image: seletedRow.img,
    url: "",
    contentType: areaType,
  });

  const isActive =
    updateBrandImageB.categoryName?.trim() !== "" && updateBrandImageB.image;

  const isSizeDifferent = actualSize.width !== 890 || actualSize.height !== 490;

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Web Service',
    normalMenu: ['Category List', 'Category Details'],
  };

  const createBrandingImageInfos = [
    {
      index: 1,
      label: "#Country",
      value: country,
      divide: true,
    },
    {
      index: 2,
      label: "#Selected Area ",
      value: areaName,
      divide: true,
    },
    {
      index: 3,
      label: "#Area Type",
      value: "Branding Image - B type (890px X 490px)",
      divide: false,
    },
  ];

  const params = new URLSearchParams(updateBrandImageB.image);
  const filePath = params.get('file_path');
  const imageName = filePath ? filePath.split('/').pop() : '';

  const clickImg = (imgsrc, pageName) => {
    if (typeof imgsrc === 'string') {
      var imageWin = new Image();
      imageWin = window.open("", pageName, "width=1000px, height=500px");
      imageWin.document.write("<html><body style='margin:0'>");
      imageWin.document.write("<img src='" + imgsrc + "' border=0 />");
      imageWin.document.write("</body><html>");
      imageWin.document.title = pageName;
    } else if (imgsrc instanceof File) {  // File
      const reader = new FileReader();
      reader.onload = (e, pageName = imgsrc.name) => {
        const newWindow = window.open("", pageName, "width=1000px, height=500px");
        newWindow.document.write(`<img src="${e.target.result}" border=0 />`);
        newWindow.document.title = pageName;
      };
      reader.readAsDataURL(imgsrc);
    }
  }

  const onChangeCategoryName = (event) => {
    setUpdateBrandImageB((pewvState) => ({
      ...pewvState,
      name: event.target.value,
    }));
  };

  const onChangeEnable = (checked) => {
    const value = checked ? "ON" : "OFF";
    setUpdateBrandImageB((pevState) => ({
      ...pevState,
      enable: value,
    }));
  };

  const onChangeImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setTempImage(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setUpdateBrandImageB((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const fields = [
    {
      xs: 6,
      label: 'Order',
      value: (
        <Typography component='span' className='text'>
          {updateBrandImageB.order}
        </Typography>
      ),
    },
    {
      xs: 6,
      label: 'Enable',
      bold: true,
      required: true,
      value: (
        <BasicSwitches
          disabled={selectedStatus === 'P'}
          onOffMode={updateBrandImageB.enable === 'ON' ? true : false}
          setSwitchOnOff={onChangeEnable}
          isLabelText={true}
          onText='On'
          offText='off'
          value={updateBrandImageB.enable === 'ON' ? true : false}

        />
      ),
    },
    // Name
    {
      xs: 12,
      label: 'Name',
      required: true,
      alignTop: true,
      value: (
        <>
          <TextField
            type='outlined'
            size='large'
            placeholder=''
            defaultValue={updateBrandImageB.name}
            label=''
            autoComplete='off'
            InputLabelProps={{ shrink: false }}
            onChange={onChangeCategoryName}
            maxLength={200}
            disabled={selectedStatus === 'P'}
          />
        </>
      ),
    },

    // Image
    {
      xs: 12,
      label: 'Image',
      required: true,
      alignTop: true,
      value: (
        <>
          <Box sx={{ marginBottom: '12px' }}>
            <FileUpload
              id='fileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
              buttonLabel='Upload'
              size='large'
              placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
              onFileChange={(file) => onChangeImageUpload(file)}
              accept='.jpg,.png,'
              isSubText
              subText=''
              value={updateBrandImageB.image.name || imageName}
              disabled={selectedStatus === 'P'}
            />
            <Typography component='p' className='upload-info-text'>
              Recommended Size: <strong>890px X 490px</strong>, Actual Image Size: {' '}
              <strong style={{ color: isSizeDifferent ? 'red' : 'inherit' }}>
                {`${actualSize.width}px X ${actualSize.height}px`}
              </strong>
            </Typography>
          </Box>
          {tempImage ? (
            <CustomImage src={tempImage} fit={true} sx={{ width: '100%', height: 202 }} alt='' rounded
              wrapWidth='100%' wrapHeight='202px' imgWidth='100%' imgHeight='202px' />
          ) : (
            <Box style={{ width: '100%', height: '202px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ccc' }}>
              <CustomImage fit={true} src={imgDummy} sx={{ width: '100%', height: 202 }} alt='' rounded />
            </Box>
          )}
          <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
            <Typography className='img-size'>{`${actualSize.width}px X ${actualSize.height}px`}</Typography>
            <Button
              color='tertiary'
              type='outline'
              size='small'
              label='Image URL'
              isIcon={true}
              Icon={BtnTeritaryOutlineRight}
              iconPos='right'
              onClick={() => clickImg(updateBrandImageB.image, imageName)}
            />
          </Box>
        </>
      ),
    },
  ];

  const handleSnackbarClose = () => {
    setShowSnakBar({ open: false, message: "", severity: "" });
  };

  const handelSetShowSnakBar = ({ open, message, severity }) => {
    setShowSnakBar({
      open,
      message,
      severity,
    });
    setUpdateBrandImageB({ ...updateBrandImageB });
  };
  const clickUpdate = async () => {
    const updateCategoryData = { ...updateBrandImageB };
    const res = await updateCreateBannerImage(areaId, idx, updateCategoryData);
    handleClose();
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
  };
  const handleClose = () => {
    // navigate(-1);
    navigate("/WebService", {
      replace: true,
      state: {
        move: true,
        targetComponent: "BrandingImageB",
        message: {
          idx: areaId,
          areaName: areaName,
          areaType: areaType,
          country: country,
          selectedStatus: selectedStatus,
          region: region,
        },
      },
    });
  };

  const onUpdateCategoryCancelBtn = () => {
    handleClose();
  };

  const onUpdateCategorySaveBtn = () => {
    clickUpdate();
  };

  const dialogButtons = useMemo(() => {
    return [
      {
        index: 1,
        minWidth: "90px",
        size: "medium",
        label: "Cancel",
        type: "outline",
        color: "tertiary",
        onClick: onUpdateCategoryCancelBtn,
        disabled: false,
        isIcon: false,
        Icon: null,
      },
      {
        index: 2,
        minWidth: "80px",
        size: "medium",
        label: "Save",
        type: "box",
        color: "primary",
        onClick: onUpdateCategorySaveBtn,
        disabled: !isActive || selectedStatus === "P",
        isIcon: false,
        Icon: null,
      },
    ];
    // eslint-disable-next-line
  }, [isActive, updateBrandImageB]);

  useEffect(() => {
    const img = new Image();
    img.src = seletedRow.img;
    img.onload = () => {
      setActualSize({ width: img.width, height: img.height });
    };
  }, [seletedRow.img]);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-detail-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' to='#'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/WebService', {
                    replace: true, state: {
                      move: true,
                      targetComponent: 'BrandingImageB',
                      message: {
                        idx: areaId,
                        areaName: areaName,
                        areaType: areaType,
                        country: country,
                        selectedStatus: selectedStatus,
                        region: region
                      }
                    }
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                Category Detail
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {createBrandingImageInfos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'>
              <CustomButtonGroup buttons={dialogButtons} />
            </Box>
          </Box>
          <Box className='white-box white-box-wrap single-box'>
            <Box className='box-content'>
              <Box className='detail-list-container type-category'>
                <Grid container columnSpacing={2.5}>
                  {fields.map(({ xs, label, required, value, alignTop, bold }, index) => (
                    <Grid item xs={xs} className='list-wrap' key={index} sx={{ marginBottom: '8px' }}>
                      <div
                        className={`
                          info-value-wrap no-border
                          ${alignTop ? 'align-top' : ''}
                          ${bold ? 'bold' : ''}
                        `}
                      >
                        <Typography component='span' className={`label ${required ? 'required' : ''}`}>
                          <span className='text'>{label}</span>
                        </Typography>
                        <div className='value'>{value}</div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <div>
        <BasicSnackbar onClose={handleSnackbarClose} severity={showSnakBar.severity} message={showSnakBar.message} open={showSnakBar.open} duration={1000} />
      </div>
    </Box>
  );
}

export default CategoryDetailBrandingB;
