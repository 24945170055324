import React, { useState } from 'react';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextLink from 'components/component/BasicTextLink';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import SearchField from 'components/component/BasicSearchField';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { styled } from '@mui/material/styles';

// data
import {
  selectOption,
  AccTh,
  AccTd,
} from 'pages/Configuration/CodeManagement/data/CodeData';

// Button

const crudButtons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Delete',
    isIcon: false,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Add',
    isIcon: false,
    autosize: true,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function AccountPermissions() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedTdRows = sortRows([...AccTd], orderBy, order);

  return (
      <Box className='white-box white-box-wrap'>
        <div className='box-content'>
          <Box className='white-box-content-top-area' alignItems='flex-end'>
            <Box className='left-area column'>
              <Box className='total-count'>
                <Box component='span' className='label'>
                  Total
                </Box>
                <Box component='span' className='value'>
                  6
                </Box>
              </Box>
              <Box display='flex' gap={1}>
                <SelectBox
                    id='select-search'
                    size='medium'
                    selectOptions={selectOption}
                    label=''
                    maxWidth='180px'
                    disabled={false}
                    placeholder=''
                />

                <SearchField placeholder='Please enter a search term' maxWidth='230px' />
              </Box>
            </Box>

            <Box className='right-area'>
              <CustomButtonGroup buttons={crudButtons} justifyContent='flex-end' />
            </Box>
          </Box>
          <Box className='content-inner-wrap'>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table' aria-label='table' stickyHeader>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    {AccTh.map((column) => (
                        <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                          {column.checkbox ? (
                              <>
                                <CheckBox
                                    label=''
                                    value='select-all'
                                    defaultChecked={false}
                                    inputProps={{
                                      'aria-label': 'select all',
                                    }}
                                />

                                {column.sortable ? (
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                ) : (
                                    // 체크박스만 있을 때도 label 표시
                                    <span className='chk-th'>{column.label}</span>
                                )}
                              </>
                          ) : column.sortable ? (
                              <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                          ) : (
                              column.label
                          )}
                        </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {/* table Body */}
                <TableBody className='tbody'>
                  {sortedTdRows.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        {AccTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                  <CheckBox
                                      label={<TextLink href='' text={td[`data0${colIndex + 1}`]} />}
                                      value='value'
                                      defaultChecked={false}
                                  />
                              ) : (
                                  td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                        ))}
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
            {/* // Table */}
          </Box>

          {/* Pagination */}
          <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
        </div>
      </Box>
  );
}

export default AccountPermissions;
