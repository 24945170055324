export const ContentTypeList = [
  {
    id: 1,
    value: "live",
    option: "live",
    type: "text",
    defaultChecked: true,
  },
];

export const ContentTypeVodList = [
  {
    id: "all",
    value: "all",
    option: "All",
    type: "checkbox",
    defaultChecked: false,
  },
  {
    id: 1,
    value: "tvshow",
    option: "tvshow",
    type: "checkbox",
    defaultChecked: false,
  },
  {
    id: 2,
    value: "movie",
    option: "movie",
    type: "checkbox",
    defaultChecked: false,
  },
  {
    id: 3,
    value: "tvshow-series",
    option: "tvshow-series",
    type: "checkbox",
    defaultChecked: false,
  },
];
