import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';

const VodFilter = React.memo(({
  regionOptions,
  countryOptions,
  cpNameOptions,
  typeOptions,
  buttons,
  handleSelectChange
}) => {
  const selectFields = [
    {
      id: 'vod-filter-region',
      name: 'region',
      label: 'Region',
      options: regionOptions
    },
    {
      id: 'vod-filter-country',
      name: 'country',
      label: 'Country',
      options: countryOptions
    },
    {
      id: 'vod-filter-cp-name',
      name: 'cpName',
      label: 'CP Name',
      options: cpNameOptions
    },
    {
      id: 'vod-filter-type',
      name: 'type',
      label: 'Type',
      options: typeOptions
    }
  ];

  const textFields = [
    {placeholder: 'VOD ID', name: 'vodId'},
    {placeholder: 'VOD Title', name: 'vodTitle'}
  ];

  const handleSelectChangeWrapper = (name, value) => {
    try {
      handleSelectChange(name, value);
    } catch (error) {
      console.error(`Error handling select change for ${name}:`, error);
    }
  };

  return (
      <Box component='div' className='white-box filter-box'>
        <Box className='form-wrap-box'>
          <div className='form-area left'>
            <Box component='div' className='component-wrap col-6'>
              {selectFields.map(field => (
                  <Box component='div' className='col' key={field.id}>
                    <SelectBox
                        id={field.id}
                        name={field.name}
                        size='large'
                        shrink={true}
                        label={field.label}
                        selectOptions={field.options}
                        onChange={(e) => handleSelectChangeWrapper(field.name,
                            e)}
                        aria-label={field.label}
                    />
                  </Box>
              ))}
              {textFields.map(field => (
                  <Box component='div' className='col' key={field.name}>
                    <TextField
                        type='outlined'
                        size='large'
                        placeholder={field.placeholder}
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        onChange={(e) => handleSelectChangeWrapper(field.name,
                            e.target.value)}
                        aria-label={field.placeholder}
                    />
                  </Box>
              ))}
            </Box>
          </div>
          <div className='form-area right'>
            <CustomButtonGroup buttons={buttons}/>
          </div>
        </Box>
      </Box>
  );
});

VodFilter.propTypes = {
  regionOptions: PropTypes.array.isRequired,
  countryOptions: PropTypes.array.isRequired,
  cpNameOptions: PropTypes.array.isRequired,
  typeOptions: PropTypes.array.isRequired,
  buttons: PropTypes.array.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
};

VodFilter.defaultProps = {
  regionOptions: [],
  countryOptions: [],
  cpNameOptions: [],
  typeOptions: [],
  buttons: [],
  handleSelectChange: () => {
  },
};

export default VodFilter;