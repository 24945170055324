import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  Paper,
  ListItemText,
  InputLabel,
} from '@mui/material';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import SelectArrowDark from 'assets/images/icon/SelectArrowDarkIcon.svg';

const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
    &.large {
      height: 40px;

      .MuiInputBase-root {
        height: 40px;
      }
      .MuiSelect-select {
        height: 40px;
        box-sizing: border-box;
        padding: 12px 30px 12px 12px;
        overflow: hidden;
        text-overflow: ellipsis;

        .MuiListItemText-root {
          margin: 0;
        }
      }
    }
    &.medium {
      height: 32px;

      .MuiSelect-select {
        height: 32px;
        box-sizing: border-box;
        padding: 10px 12px 4px;

        .MuiListItemText-root {
          margin: 0;
        }
      }
    }
    &.small {
      height: 28px;

      .MuiInputBase-root {
        height: 28px;
      }
      .MuiSelect-select {
        height: 28px;
        box-sizing: border-box;
        padding: 8px 12px;

        .MuiListItemText-root {
          margin: 0;
        }
      }
    }
    // Pagination 왼쪽 출력할 행의 수 선택 Select box
    .print-per-count {
      min-width: 100px;
      padding: 0;

      .MuiSelect-select {
        width: 100%;
        padding: 6px 12px;
        border-color: #a3a3a3;
        font-size: 14px;
        font-weight: 400;
        color: #525252;
        line-height: 20px;
      }
    }
  }
  .MuiFormLabel-root {
    top: 50%;
    transform: translate(14px, -50%);
    font-size: 12px;
    line-height: 20px;

    &.MuiInputLabel-sizeLarge {
      &.MuiFormLabel-filled {
        margin-top: 0;
      }
    }

    &.MuiInputLabel-sizeSmall {
      &.MuiFormLabel-filled {
        margin-top: 0;
      }
    }

    &.Mui-focused {
      font-size: 12px;
      background-color: #fff;
      margin-top: 0;
    }

    &.Mui-disabled {
      opacity: 0.4;
    }
  }

  .MuiInputBase-root {
    padding-top: 6px;
    padding-bottom: 6px;
    height: 32px;
    width: 100%;

    .MuiSelect-select {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;

      .dark & {
        color: #fff;
      }

      .MuiTypography-root {
        font-size: 14px;
        line-height: 20px;
        color: #000;
      }
      .placeholder {
        color: #bbb;
      }
    }
    .MuiSvgIcon-root {
      .dark & {
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        background: url(${SelectArrowDark}) no-repeat 50%;
        color: #ffffff;
      }
    }
    &.MuiInputBase-sizeLarge {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 40px;
    }

    &.MuiInputBase-sizeSmall {
      height: 28px;
    }
  }

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #a3a3a3;

      .dark & {
        border-color: #484848;
      }
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: #2196f3;
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: #b91c1c;
    }

    &.Mui-disabled {
      background-color: #ececec;
      opacity: 0.7;
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }
  }
  legend {
    width: 0;
  }
`;

const CustomPaper = styled(Paper)({
  '&.MuiPaper-root': {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  '.dark &': {
    background: '#121214',
  },
  '& .MuiMenuItem-root': {
    padding: '6px 12px',

    '&.Mui-selected': {
      backgroundColor: '#f7f7f7',

      '&.Mui-focusVisiable': {
        backgroundColor: '#f7f7f7',
      },

      '.dark &': {
        backgroundColor: '#303030',
      },
    },

    '&.placeholder': {
      display: 'none',
    },
  },
  '& .MuiFormControlLabel-root': {
    width: '100%',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '20px',

    '.dark &': {
      color: '#fff',
    },
  },
  '& .MuiRadio-root': {
    padding: 0,
    marginRight: '12px',
  },
});

const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    &.MuiInputLabel-shrink {
      position: absolute;
      left: 0;
      padding: 0 3px;
      background: #fff;
      font-size: 12px;
      line-height: 1;
      z-index: 10;

      .dark & {
        background: #1c1c1c;
        color: #b1b1b1;
      }

      &.MuiInputLabel-sizeLarge,
      &.MuiInputLabel-sizeMedium,
      &.MuiInputLabel-sizeSmall {
        top: 0;
      }
    }
    .required {
      &:after {
        content: '*';
        color: #a50034;
        font-size: 14px;
        margin-left: 3px;
      }
    }
  }
`;

// SelectBox Component
const TabCategoryFilter = ({
  regionList,
  regionPlatformList,
  deviceType,
  handleChangeDeviceType,
  region,
  handleChangeRegion,
  status,
  handleChangeStatus,
  platformVersion,
  handleChangePlatformVersion,
  width,
  maxWidth,
  classes,
}) => {
  
  return (
    <Box className='component-wrap col-5'>
      {/* device type */}
      <Box component='div' className='col' sx={{ position: 'relative' }}>
        <StyledFormControl
          sx={{ width: width, maxWidth: maxWidth }}
          size='large'
          variant='outlined'
          className='large'
        >
          <StyledInputLabel shrink={true}>
            <span>Device Type</span>
          </StyledInputLabel>
          <Select
            value={deviceType}
            displayEmpty
            onChange={handleChangeDeviceType}
            className={classes}
            MenuProps={{
              disableScrollLock: true,
              PaperProps: {
                component: CustomPaper,
              },
            }}
          >
            {regionList.map((row) => (
              <MenuItem
                key={row.deviceType}
                value={row.deviceType}
              >
                <ListItemText primary={row.deviceType} />
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Box>
      {/* region */}
      <Box component='div' className='col' sx={{ position: 'relative' }}>
        <StyledFormControl
          sx={{ width: width, maxWidth: maxWidth }}
          size='large'
          variant='outlined'
          className='large'
        >
          <StyledInputLabel shrink={true}>
            <span>Region</span>
          </StyledInputLabel>
          <Select
            value={region}
            displayEmpty
            onChange={handleChangeRegion}
            className={classes}
            MenuProps={{
              disableScrollLock: true,
              PaperProps: {
                component: CustomPaper,
              },
            }}
          >
            {(regionList.find(n => n.deviceType === deviceType) || { list: [] }).list.map(n2 => (
              <MenuItem key={n2.region} value={n2.region}>
                { n2.region + '(' + n2.countryCode.join(', ') + ')'}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Box>
      {/* platform version */}
      <Box component='div' className='col' sx={{ position: 'relative' }}>
        <StyledFormControl
          sx={{ width: width, maxWidth: maxWidth }}
          size='large'
          variant='outlined'
          className='large'
        >
          <StyledInputLabel shrink={true}>
            <span>Platform Version</span>
          </StyledInputLabel>
          <Select
            value={platformVersion}
            displayEmpty
            onChange={handleChangePlatformVersion}
            className={classes}
            MenuProps={{
              disableScrollLock: true,
              PaperProps: {
                component: CustomPaper,
              },
            }}
          >
            {(regionPlatformList.filter(n => n.deviceType === deviceType && n.region === region)).map(n2 => (
              <MenuItem key={n2.platformVersion} value={n2.platformVersion}>
                {n2.platformVersion}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Box>
      {/* status */}
      <Box component='div' className='col' sx={{ position: 'relative' }}>
        <StyledFormControl
          sx={{ width: width, maxWidth: maxWidth }}
          size='large'
          variant='outlined'
          className='large'
        >
          <StyledInputLabel shrink={true}>
            <span>Status</span>
          </StyledInputLabel>
          <Select
            value={status}
            displayEmpty
            onChange={handleChangeStatus}
            className={classes}
            MenuProps={{
              disableScrollLock: true,
              PaperProps: {
                component: CustomPaper,
              },
            }}
          >
            {['Saved-Testing', 'Saved-Published', 'Testing', 'Published'].map((row) => (
              <MenuItem
                key={row}
                value={row}
              >
                <ListItemText primary={row} />
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Box>
    </Box>
  );
};

export default TabCategoryFilter;
