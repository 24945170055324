import React, { useMemo, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { Typography, List, ListItem, Grid } from '@mui/material';

import SelectCheckBox from 'components/component/BasicSelectCheckBox';
import SelectBox from 'components/component/BasicSelectBox';
import { ContentTypeList, ContentTypeVodList } from 'pages/Organization/WebService/data/PopAddCategoryData';
import { saveWebContentChannelCategory } from '../../api/restApi';

function PopAddCategoryManual(props) {
  const { onClose, handelSetShowSnakBar, idx, country, areaName, areaType, getWebLiveCategoryData } = props;
  const [createWebLiveCategory, setCreateWebLiveCategor] = useState({
    contentType: areaType === "content_vod" ? "" : "live",
    categoryName: "",
  });
  const isActive =
    createWebLiveCategory.contentType &&
    createWebLiveCategory.categoryName?.trim() !== "";

  const infos = [
    {
      index: 1,
      label: '#Country',
      value: country,
      divide: true,
    },
    {
      index: 2,
      label: '#Selected Area ',
      value: areaName,
      divide: true,
    },
    {
      index: 3,
      label: '#Area Type',
      value: areaType === 'content_channel' ? 'Content - Channel' : 'Content - VOD',
      divide: false,
    },
  ];

  const onChangeContentType = (event) => {
    setCreateWebLiveCategor((pewvState) => ({
      ...pewvState,
      contentType: areaType === "content_vod" ? event.join() : event,
    }));
  };

  const onChangeName = (event) => {
    const value = event.target.value;
    setCreateWebLiveCategor((pewvState) => ({
      ...pewvState,
      categoryName: value,
    }));
  };

  const clickSave = async () => {
    const createCategoryData = { ...createWebLiveCategory };
    const res = await saveWebContentChannelCategory(idx, createCategoryData);
    handleClose();
    getWebLiveCategoryData();
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const onCreateCategoryCancelBtn = () => {
    handleClose();
  };

  const onCreateCategorySaveBtn = () => {
    clickSave();
  };

  const dialogButtons = useMemo(() => {
    return [
      {
        width: "150px",
        size: "large",
        label: "Cancel",
        type: "outline",
        color: "tertiary",
        onClick: onCreateCategoryCancelBtn,
        disabled: false,
      },
      {
        width: "150px",
        size: "large",
        label: "Save",
        type: "box",
        color: "primary",
        onClick: onCreateCategorySaveBtn,
        disabled: !isActive,
      },
    ];
    // eslint-disable-next-line
  }, [isActive, createWebLiveCategory]);

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Add Category'
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium'
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <List className='info-list-container'>
              {infos.map((item) => (
                <React.Fragment key={item.index}>
                  <ListItem className='list-item'>
                    <Typography className='label'>{item.label}</Typography>
                    <Typography className='value'>{item.value}</Typography>
                  </ListItem>
                  {item.divide && <Divider className='divider' />}
                </React.Fragment>
              ))}
            </List>
            <Divider sx={{ marginBottom: '16px' }} />
            <Grid container columnSpacing={2.5}>
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  {areaType === 'content_vod' ?
                    <SelectCheckBox
                      id='select-01'
                      fullWidth
                      size='large'
                      width='100%'
                      required={true}
                      selectOptions={ContentTypeVodList}
                      disabled={false}
                      label='Content Type'
                      onChange={onChangeContentType}
                      shrink
                      multiple={true}
                    /> :
                    <SelectBox
                      id='select-01'
                      fullWidth
                      size='large'
                      width='100%'
                      required={true}
                      selectOptions={ContentTypeList}
                      disabled={false}
                      label='Content Type'
                      selected={createWebLiveCategory.contentType}
                      onChange={onChangeContentType}
                      shrink
                    />
                  }
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <TextField
                    required={true}
                    type='outlined'
                    size='large'
                    placeholder='ex) Category Name'
                    defaultValue=''
                    label='Name'
                    autoComplete='off'
                    name={createWebLiveCategory.name}
                    onChange={onChangeName}
                    InputLabelProps={{ shrink: true }}
                    maxLength={200}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopAddCategoryManual;
