export const initialCurrentUser = {
  name: '',
  accessToken: '',
  isAuthenticated: false,
  loginTime: '',
  isSaveId: false,
  sessionTimeout: 1800,        // 30 minutes
  sessionWarningTimeout: 300,  // 5 minutes
  provider: {},
  accessMenuList: [],
  role: '',
  authority: ''
}

export const initialSettings = {
  darkMode: 'off',
  isPartnerPageShow: false,
  preferredCountry: 'US',
}