import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import Pagination from 'components/component/BasicPagination';
import {Box, Dialog, Typography} from '@mui/material';
import ChannelFilter from './component/ChannelFilter';
import ChannelTable from './component/ChannelTable';
import ExtraFilter from './component/ExtraFilter';
import VodFilter from './component/VodFilter';
import VodTable from './component/VodTable';
import {MetaChannelTh, MetaVodTh, selectOption} from './data/MetadataEdit';
import {BASE_URL} from 'constants/index';
import {getHeaders} from '../../../utils/Header';
import {RefreshIcon} from 'components/component/BasicIcon';
import {useNavigate} from "react-router-dom";

const tabs = [{label: 'Channels'}, {label: 'VOD'}];

const MetadataEdit = React.memo((props) => {
  const navigate = useNavigate();
  const {serviceCountry, provider} = props;
  const [countryList, setCountryList] = useState([]);
  const [selectRegionList, setSelectRegionList] = useState([]);
  const [selectCountryList, setSelectCountryList] = useState([]);
  const [selectChannelMapIdList, setSelectChannelMapIdList] = useState([]);
  const [selectProviderList, setSelectProviderList] = useState([]);
  const [selectTypeList, setSelectTypeList] = useState([]);
  const [selectDeviceTypeList, setSelectDeviceTypeList] = useState([]);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [dataList, setDataList] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [tabValue, setTabValue] = useState(0);
  const [isSearchSuccessful, setIsSearchSuccessful] = useState(false);

  const defaultCountryCode = 'US';
  const defaultDeviceType = 'TV';
  const defaultType = 'tvshow';
  const [filters, setFilters] = useState({
    countryCode: defaultCountryCode,
    deviceType: defaultDeviceType,
    channelMapId: '',
    channelNumber: '',
    channelId: '',
    providerId: '',
    channelName: '',
    programId: '',
    type: defaultType,
    vodId: '',
    vodTitle: ''
  });

  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Metadata Edit',
  };

  useEffect(() => {
    if (serviceCountry && provider) {
      const updatedRegionList = ['All', ...Array.from(
          new Set(serviceCountry.map(item => item.ricCode))).sort()];
      const sortedCountryList = serviceCountry.map(
          item => item.countryCode).sort().filter(
          item => item !== defaultCountryCode);
      const updatedCountryList = [defaultCountryCode, ...sortedCountryList];

      setCountryList(updatedCountryList);

      const updatedSelectRegionList = updatedRegionList.map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));

      const updatedSelectCountryList = updatedCountryList.map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));

      let tempChannelMapIdList = serviceCountry
      .filter(item => item.countryCode === defaultCountryCode)
      .map(item => item.channelMapList)
      .reduce((acc, curr) => acc.concat(curr), []);

      const updatedChannelMapIdList = [
        ...tempChannelMapIdList.map(item => ({
          id: item.channelMapId,
          value: item.channelMapId,
          option: item.channelMapId,
          type: 'text'
        }))
      ];

      let tempProviderList = provider
      .filter(item => item.countryCode === defaultCountryCode)
      .map(item => item.providerList)
      .reduce((acc, curr) => acc.concat(curr), []);

      const updatedProviderList = [
        {id: 'All', value: 'All', option: 'All', type: 'text'},
        ...tempProviderList.map(item => ({
          id: item.providerId,
          value: item.providerId,
          option: item.providerName,
          type: 'text'
        }))
      ];

      setSelectRegionList(updatedSelectRegionList);
      setSelectCountryList(updatedSelectCountryList);
      setSelectChannelMapIdList(updatedChannelMapIdList);
      setSelectProviderList(updatedProviderList);
      setSelectDeviceTypeList(['TV', 'auto', 'mobile', 'pc'].map(
          item => ({id: item, value: item, option: item, type: 'text'})));
      setSelectTypeList(['tvshow', 'movie', 'livestream'].map(
          item => ({id: item, value: item, option: item, type: 'text'})));

      if (tabValue === 0) {
        filters.channelMapId = updatedChannelMapIdList[0].value;
      }
      searchMetadata();
    }
    // eslint-disable-next-line
  }, [serviceCountry, provider, tabValue]);

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = dataList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setIsSearchSuccessful(true);
    }
  }, [dataList, page, itemsPerPage]);

  const searchMetadata = useCallback(() => {
    setIsSearchSuccessful(false);
    setPage(1);
    setDataList([]);
    const urlParam = new URLSearchParams();
    for (const [key, value] of Object.entries(filters)) {
      if (value !== '') {
        urlParam.append(key, value);
      }
    }
    let url = `${BASE_URL}/metadata-edit/${tabs[tabValue].label.toLowerCase()}?${urlParam.toString()}`;
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        handleData(body.data);
      } else {
        throw new Error(`[Metadata] ${tabs[tabValue].label} request error`);
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    });
  }, [filters, tabValue]);

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      autosize: true,
    },
    {
      minWidth: '120px',
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      onClick: searchMetadata,
    },
  ];

  const handleTabChange = (event, newValue) => {
    setIsSearchSuccessful(false);
    setTabValue(newValue);
    handleSelectMultiFilter({
      countryCode: defaultCountryCode,
      deviceType: defaultDeviceType,
      channelMapId: '',
      channelNumber: '',
      channelId: '',
      providerId: '',
      channelName: '',
      programId: '',
      type: defaultType,
      vodId: '',
      vodTitle: ''
    });
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...dataList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDataList([...sortedData]);
  };

  const handleSelectRegion = (region) => {
    if (region && region !== 'All') {
      let tempCountryList = serviceCountry
      .filter(item => item.ricCode === region)
      .map(item => item.countryCode).sort();
      setSelectCountryList(tempCountryList.map(
          item => ({id: item, value: item, option: item, type: 'text'})));
      handleSelectCountry(tempCountryList[0]);
    } else {
      setSelectCountryList(countryList.map(
          item => ({id: item, value: item, option: item, type: 'text'})));
      handleSelectCountry(defaultCountryCode);
    }
  };

  const handleSelectCountry = (country) => {
    let tempProviderList = provider.filter(
        item => item.countryCode === country).map(
        item => item.providerList).reduce((acc, curr) => acc.concat(curr), []);
    setSelectProviderList(
        [{id: 'All', value: 'All', option: 'All', type: 'text'},
          ...tempProviderList.map(item => ({
            id: item.providerId,
            value: item.providerId,
            option: item.providerName,
            type: 'text'
          }))]);
    if (tabValue === 0) {
      let tempChannelMapIdList = serviceCountry.filter(
          item => item.countryCode === country).map(
          item => item.channelMapList).reduce((acc, curr) => acc.concat(curr),
          []);
      setSelectChannelMapIdList(tempChannelMapIdList.map(item => ({
        id: item.channelMapId,
        value: item.channelMapId,
        option: item.channelMapId,
        type: 'text'
      })));
      let channelMapId = tempChannelMapIdList.find(
          item => item.channelMapId.indexOf(country) === 0).channelMapId;
      handleSelectMultiFilter(
          {countryCode: country, channelMapId: channelMapId, providerId: ''});
    } else {
      handleSelectMultiFilter({countryCode: country, providerId: ''});
    }
  };

  const handleSelectFilter = (name, value) => {
    if (value !== null && value !== undefined) {
      if (value === 'All') {
        setFilters({
          ...filters,
          [name]: '',
        });
      } else {
        setFilters({
          ...filters,
          [name]: value,
        });
      }
    }
  };

  const handleSelectMultiFilter = (json) => {
    const newFilters = {...filters, ...json};
    setFilters(newFilters);
  };

  const handleData = (data) => {
    setDataList(data);
    setDataCount(Object.keys(data).length);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const handleSelectChange = (name, value) => {
    console.log(`Selected ${name}: ${value}`);
    if (name === 'region') {
      handleSelectRegion(value);
    } else if (name === 'country') {
      handleSelectCountry(value);
    } else if (name === 'device') {
      handleSelectFilter('deviceType', value);
    } else if (name === 'channelMap') {
      handleSelectFilter('channelMapId', value);
    } else if (name === 'cpName') {
      handleSelectFilter('providerId', value);
    } else if (name === 'channelNumber') {
      handleSelectFilter('channelNumber', value);
    } else if (name === 'channelId') {
      handleSelectFilter('channelId', value);
    } else if (name === 'channelName') {
      handleSelectFilter('channelName', value);
    } else if (name === 'vodId') {
      handleSelectFilter('vodId', value);
    } else if (name === 'vodTitle') {
      handleSelectFilter('vodTitle', value);
    } else if (name === 'type') {
      handleSelectFilter('type', value);
    }
  };

  const handleEdit = (contentId) => {
    console.log(`Edit action for tab : ${tabValue}, contentId: ${contentId}`);
    const targetComponent = tabValue === 0 ? 'MetadataChannelEdit'
        : 'MetadataVodEdit';
    navigate('/MetadataEdit',
        {
          replace: true,
          state: {
            move: true,
            targetComponent: targetComponent,
            message: {
              contentId: contentId,
              channelMapId: filters.channelMapId,
            }
          }
        });
  };

  return (
      <Box display='flex' className='wrapper'>
        <Box className='main-container inspect' component='main'>
          <div className='top-title-container'>
            <div className='top-title-box'>
              <div className='title-area'>
                <Typography className='title'>Metadata Edit</Typography>
              </div>
              <div className='bread-area'>
                <BreadCrumb optionMenu={optionMenu}/>
              </div>
            </div>
          </div>

          <Box className='content-wrap' component='div'>
            <div className='tab-container'>
              <CustomTabs tabStyle='type-01' value={tabValue}
                          handleChange={handleTabChange} tabs={tabs}/>

              {tabValue === 0 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <ChannelFilter
                          regionOptions={selectRegionList}
                          countryOptions={selectCountryList}
                          deviceOptions={selectDeviceTypeList}
                          channelMapOptions={selectChannelMapIdList}
                          cpNameOptions={selectProviderList}
                          buttons={buttons}
                          handleSelectChange={handleSelectChange}
                      />
                      {isSearchSuccessful && (
                          <Box className='white-box white-box-wrap'>
                            <div className='box-content'>
                              <ExtraFilter total={dataCount}
                                           selectOptions={selectOption}
                                           searchPlaceholder='Please enter a search term'/>
                              <Box className='content-inner-wrap'>
                                <ChannelTable
                                    columns={MetaChannelTh}
                                    rows={paginatedData}
                                    order={order}
                                    orderBy={orderBy}
                                    handleRequestSort={handleRequestSort}
                                    handleEdit={handleEdit}
                                />
                              </Box>
                              <Pagination
                                  id='pagination-01'
                                  count={Math.ceil(
                                      dataList.length / itemsPerPage)}
                                  selected={itemsPerPage}
                                  onChangeHandle={handlePagination}
                                  page={page}
                              />
                            </div>
                          </Box>
                      )}
                    </div>
                  </div>
              )}

              {tabValue === 1 && (
                  <div className='tab-panel'>
                    <div className='panel-wrap'>
                      <VodFilter
                          regionOptions={selectRegionList}
                          countryOptions={selectCountryList}
                          cpNameOptions={selectProviderList}
                          typeOptions={selectTypeList}
                          buttons={buttons}
                          handleSelectChange={handleSelectChange}
                      />
                      {isSearchSuccessful && (
                          <Box className='white-box white-box-wrap'>
                            <div className='box-content'>
                              <ExtraFilter total={dataCount}
                                           selectOptions={selectOption}
                                           searchPlaceholder='Please enter a search term'/>
                              <Box className='content-inner-wrap'>
                                <VodTable
                                    columns={MetaVodTh}
                                    rows={paginatedData}
                                    order={order}
                                    orderBy={orderBy}
                                    handleRequestSort={handleRequestSort}
                                    handleEdit={handleEdit}
                                />
                              </Box>
                              <Pagination
                                  id='pagination-01'
                                  count={Math.ceil(
                                      dataList.length / itemsPerPage)}
                                  selected={itemsPerPage}
                                  onChangeHandle={handlePagination}
                                  page={page}
                              />
                            </div>
                          </Box>
                      )}
                    </div>
                  </div>
              )}
            </div>
          </Box>
        </Box>
        <Dialog
            open={isAlertOpen}
            handleClose={handleAlertClose}
            closeX={false}
            size='small'
            title={alertTitle}
            contentTitle={false}
            bottomDivider={false}
            contentText={alertMessage}
            buttons={alertDialogButtons}
        />
      </Box>
  );
});

MetadataEdit.propTypes = {
  serviceCountry: PropTypes.array.isRequired,
  provider: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  serviceCountry: state.common.country.serviceCountry,
  provider: state.common.provider.country,
});

export default connect(mapStateToProps)(MetadataEdit);