import { initialCurrentUser } from 'pages/Main/Login/data/User';

export const getHeaders = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser')) || initialCurrentUser;
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  if (currentUser.accessToken) {
    headers.append('Authorization', 'Bearer ' + currentUser.accessToken);
  }
  return headers;
};

export const getHeadersFormData = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser')) || initialCurrentUser;
  const headers = new Headers({});
  if (currentUser.accessToken) {
    headers.append('Authorization', 'Bearer ' + currentUser.accessToken);
  }
  return headers;
};
