import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import TextField from 'components/component/BasicTextField';

import PropTypes from 'prop-types';

import {
  Box,
  Link,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

import {
  AntestDeviceTypeOption,
  StatusOption,
  AnTestTh,
  AnTestTd,
  PlatformVersionOption,
} from 'pages/Organization/ANTest/data/ANTestdata';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';
import { connect } from 'react-redux';
import PopAddANTest from './PopAddANTest';
import PopEditANTest from './PopEditANTest';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function ANTest(props) {
  const { regionCountryList } = props;
  const navigate = useNavigate();
  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [selectedDeviceType, setSelectedDeviceType] = useState('all');
  const [selectedTab, setSelectedTab] = useState('all');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedPlatformVersion, setSelectedPlatformVersion] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [title, setTitle] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs());
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'AN Test',
  };

  const onClickReset = () => {
    setSelectedCountry('US');
    setSelectedDeviceType('all');
    setSelectedTab('all');
    setSelectedCategory('all');
    setSelectedPlatformVersion('all');
    setSelectedStatus('all');
    setTitle('');
    setSelectedStartDate(dayjs());
    setSelectedEndDate(dayjs());
  }

  const onClickSearch = () => {
    console.log("Search");
    // TODO search data by selected filter data
  }

  const onClickDelete = () => {
    console.log("Delete");
    // TODO add delete logic (add delete confirm popup)
  }

  const onClickAdd = () => {
    setIsAddOpen(true);
    // TODO add Add logic
  }

  const handelTitleClick = (row) => {
    console.log(row);
    // TODO add Edit antest logic
    setIsEditOpen(true);
  }

  const onEndClick = () => {
    console.log("End");
    // TODO add end logic (add end confirm popup)
  }

  const onSegmentClick = () => {
    navigate('/ANTest', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestSegment',
        message: null, // TODO add clicked segment info + alpha
      }
    });
  }

  // Button Data
  const buttons = [ // refresh filter & search an test data
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      width: 107,
      onClick: onClickReset
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      onClick: onClickSearch
    },
  ];

  const BoxButtons = [  // delete or add an test data
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Delete',
      isIcon: false,
      autosize: true,
      onClick: onClickDelete
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Add',
      autosize: true,
      onClick: onClickAdd
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const sortedTdRows = sortRows([...AnTestTd], orderBy, order);

  const onChangeCountry = (value) => {
    setSelectedCountry(value);
  }

  const onChangeDeviceType = (value) => {
    setSelectedDeviceType(value);
  }

  const onChangeTab = (value) => {
    setSelectedTab(value);
  }

  const countrySelectOptions = useMemo(() => {
    const countryCdSet = new Set();
    regionCountryList.forEach(item => countryCdSet.add(item.countryCode));

    return Array.from(countryCdSet, countryCd => ({
      id: countryCd,
      value: countryCd,
      option: countryCd,
      type: 'text'
    })).sort((a, b) => a.id.localeCompare(b.id));
  }, [regionCountryList]);

  const tabSelectOptions = useMemo(() => {
    // TODO: get tab list by selectedCountry
    return [{ id: 'all', value: 'all', option: 'All', type: 'text' }];

    // TODO: delete the line below (use 'selectedCountry' in this useMemo)
    // eslint-disable-next-line
  }, [selectedCountry]);

  const categorySelectOptions = useMemo(() => {
    // TODO: get category list by selectedTab
    return [{ id: 'all', value: 'all', option: 'All', type: 'text' }];

    // TODO: delete the line below (use 'selectedTab' in this useMemo)
    // eslint-disable-next-line
  }, [selectedTab]);

  const platformVersionSelectOptions = useMemo(() => {
    if (selectedDeviceType in PlatformVersionOption) {
      return PlatformVersionOption[selectedDeviceType];
    } else {
      return [];
    }
  }, [selectedDeviceType]);

  useEffect(() => {
    if (platformVersionSelectOptions.length > 0) {
      setSelectedPlatformVersion(platformVersionSelectOptions[0].value);
    } else {
      setSelectedPlatformVersion('');
    }
  }, [platformVersionSelectOptions]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                AN Test
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5, col-6  */}
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Country'
                      size='large'
                      shrink={true}
                      label='Country'
                      selectOptions={countrySelectOptions}
                      disabled={false}
                      placeholder=''
                      selected={selectedCountry}
                      onChange={onChangeCountry}
                    />
                  </Box>

                  {/* Device Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Device'
                      size='large'
                      shrink={true}
                      label='Device Type'
                      selectOptions={AntestDeviceTypeOption}
                      disabled={false}
                      placeholder=''
                      selected={selectedDeviceType}
                      onChange={onChangeDeviceType}
                    />
                  </Box>

                  {/* Tab */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Tab'
                      size='large'
                      shrink={true}
                      label='Tab'
                      selectOptions={tabSelectOptions}
                      disabled={false}
                      placeholder=''
                      selected={selectedTab}
                      onChange={onChangeTab}
                    />
                  </Box>

                  {/* Category */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Category'
                      size='large'
                      shrink={true}
                      label='Category'
                      selectOptions={categorySelectOptions}
                      disabled={false}
                      placeholder=''
                      selected={selectedCategory}
                      onChange={(value) => setSelectedCategory(value)}
                    />
                  </Box>

                  {/* Platform Version */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-PlatformV'
                      size='large'
                      shrink={true}
                      label='Platform Version'
                      selectOptions={platformVersionSelectOptions}
                      disabled={false}
                      placeholder=''
                      selected={selectedPlatformVersion}
                      onChange={(value) => setSelectedPlatformVersion(value)}
                    />
                  </Box>
                </Box>

                {/* 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
                <Box className='component-wrap col-5'>
                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='antest-Status'
                      size='large'
                      shrink={true}
                      label='Status'
                      selectOptions={StatusOption}
                      disabled={false}
                      placeholder=''
                      selected={selectedStatus}
                      onChange={(value) => setSelectedStatus(value)}
                    />
                  </Box>

                  {/* Title */}
                  <Box component='div' className='col'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Title'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      required={true}
                      InputLabelProps={{ shrink: false }}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Box>

                  {/* Datepiceker */}
                  <Box component='div' className='col auto'>
                    <DatePicker
                      className='input-date-picker'
                      format='MM/DD/YYYY'
                      size='large'
                      maxWidth='210px'
                      singleDate={false}
                      rangeDate={true}
                      wave={true}
                      startDate={selectedStartDate}
                      endDate={selectedEndDate}
                      onChangeStartDate={(value) => setSelectedStartDate(value)}
                      onChangeEndDate={(value) => setSelectedEndDate(value)}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons} />
              </div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap single-box'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <Box className='box-title-area' display='flex' alignItems='center'>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        445
                      </Box>
                    </Box>
                    <Typography className='sm-sub-text' ml={3}>
                      UTC Based
                    </Typography>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup buttons={BoxButtons} />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {AnTestTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {AnTestTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <CheckBox
                                  label={
                                    <span onClick={() => handelTitleClick(td)} style={{ color: '#2196f3', cursor: 'pointer' }} >
                                      {td.data01}
                                    </span>
                                  }
                                  value='value'
                                  defaultChecked={false}
                                />
                              ) : colIndex === 8 ? (
                                <span className={td.data09 === 'ready' ? 'ready' : td.data09 === 'done' ? 'done' : ''}>
                                  {td.data09 === 'ready' ? 'Ready' : td.data09 === 'done' ? 'Done' : ''}
                                </span>
                              ) : colIndex === 9 ? (
                                <Box display='flex' gap={1}>
                                  {td.data10.map((buttonData, index) => (
                                    <Button
                                      key={index}
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label={buttonData.btnText}
                                      disabled={buttonData.disabled}
                                      isIcon={false}
                                      autosize
                                      onClick={() => {
                                        if (buttonData.btnText === 'End')  onEndClick();
                                        else if (buttonData.btnText === 'Segment')  onSegmentClick();
                                      }}
                                    />
                                  ))}
                                </Box>
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
      <PopAddANTest isOpen={isAddOpen} setIsOpen={setIsAddOpen} tabSelectOptions={tabSelectOptions?.map(item => item.value !== 'all')} />
      <PopEditANTest isOpen={isEditOpen} setIsOpen={setIsEditOpen} />
    </Box>
  );
}

ANTest.propTypes = {
  regionCountryList: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  regionCountryList: state.common.country.regionCountry? state.common.country.regionCountry:[],
});

export default connect(mapStateToProps)(ANTest);