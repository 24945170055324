import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';

import './App.css';
import 'assets/scss/common/reset.scss';
import 'assets/scss/common/common.scss';
import 'assets/scss/common/theme.scss';

// [Common]
import Entry from 'pages/Main/Launch/Entry';
import TopBar from 'components/layout/TopBar';
import Gnb from 'components/layout/Gnb';
import { initialCurrentUser, initialSettings } from 'pages/Main/Login/data/User';
import MyProfile from 'pages/Main/MyProfile/MyProfile';
import Notification from 'pages/Main/Support/Notification';

import { getCountry } from 'actions/common/countryActions';
import { getProvider } from 'actions/common/providerActions';

import { menuItemData, partnerMenuItemData } from 'components/layout/data/GnbItemData';

// [Inspection]
// Channel Status
import ChannelStatus from 'pages/Inspect/ChannelStatus/ChannelStatus';

// Metadata
import Metadata from 'pages/Inspect/Metadata/Metadata';
import ChannelDetail from 'pages/Inspect/Metadata/ChannelDetail';
import ProgramDetail from 'pages/Inspect/Metadata/ProgramDetail';
import VodDetailTVShow from 'pages/Inspect/Metadata/VodDetailTVShow';
import VodDetailMovie from 'pages/Inspect/Metadata/VodDetailMovie';
import VodDetailLiveStream from 'pages/Inspect/Metadata/VodDetailLiveStream';
import SeriesDetail from 'pages/Inspect/Metadata/SeriesDetail';

// [Organization]
// Channel Mapping
import ChannelMapping from 'pages/Organization/ChannelMapping/ChannelMapping';
import BulkUpload from 'pages/Organization/ChannelMapping/BulkUploadSaved';
import ChannelMapReservations from 'pages/Organization/ChannelMapping/ChannelMapReservations';

// Tab/Category
import TabCategoryList from 'pages/Organization/TabCategory/TabCategoryList';
import CategoryList from 'pages/Organization/TabCategory/CategoryList';
import CommonManual from 'pages/Organization/TabCategory/CommonManual';
import CommonAuto from 'pages/Organization/TabCategory/CommonAuto';
import OrganizeSlider from 'pages/Organization/TabCategory/OrganizeSlider';
import WebService from 'pages/Organization/WebService/WebService';
import ListBanner from 'pages/Organization/WebService/PRPage/Banner/ListBanner';
import WebLiveManual from 'pages/Organization/WebService/PRPage/ContentChannelVod/WebLiveManual';
import CategoryOrganize from 'pages/Organization/WebService/PRPage/ContentChannelVod/CategoryOrganize';
import BrandingImageA from 'pages/Organization/WebService/PRPage/BrandingImage/BrandingImageA';
import CategoryDetailBrandingA from 'pages/Organization/WebService/PRPage/BrandingImage/CategoryDetailBrandingA';
import CategoryDetailBanner from 'pages/Organization/WebService/PRPage/Banner/CategoryDetailBanner';
import BrandingImageB from 'pages/Organization/WebService/PRPage/BrandingImage/BrandingImageB';
import CategoryDetailBrandingB from 'pages/Organization/WebService/PRPage/BrandingImage/CategoryDetailBrandingB';
import BannerOrder from 'pages/Organization/WebService/PRPage/Banner/BannerOrder';

// AN Test
import ANTest from 'pages/Organization/ANTest/ANTest';
import ANTestSegment from 'pages/Organization/ANTest/ANTestSegment';
import ANTestSegmentOrganize from 'pages/Organization/ANTest/ANTestSegmentOrganize';
import ANTestSegmentOrganizeAddGroup from 'pages/Organization/ANTest/ANTestSegmentOrganizeAddGroup';
import ANTestResult from 'pages/Organization/ANTest/ANTestResult';
import ANTestSegmentOrganizeOrganize from 'pages/Organization/ANTest/ANTestSegmentOrganizeOrganize';

// AN Service
import ANService from 'pages/Organization/ANService/ANService';
import ANServiceSegment from 'pages/Organization/ANService/ANServiceSegment';
import ANServiceSegmentOrganize from 'pages/Organization/ANService/ANServiceSegmentOrganize';
import ANServiceSegmentOrganizeAddGroup from 'pages/Organization/ANService/ANServiceSegmentOrganizeAddGroup';
import ANServiceSegmentOrganizeOrganize from 'pages/Organization/ANService/ANServiceSegmentOrganizeOrganize';
import ANServiceResult from 'pages/Organization/ANService/ANServiceResult';

// Device Notification
import DeviceNotification from 'pages/Organization/DeviceNotification/DeviceNotification';

// [Promotion]
// Free On LG Channels Shelf
import FreeOnLGChannelsShelf from 'pages/Promotion/FreeOnLGChannelsShelf/FreeOnLGChannelsShelf';
import FreeOnLGChannelsShelfHistory from 'pages/Promotion/FreeOnLGChannelsShelf/FreeOnLGChannelsShelfHistory';
import FreeOnLGChannelsShelfHistoryDetail from 'pages/Promotion/FreeOnLGChannelsShelf/FreeOnLGChannelsShelfHistoryDetail';

// Watch On LG Channels Shelf
import WatchOnLGChannelsShelf from 'pages/Promotion/WatchOnLGChannelsShelf/WatchOnLGChannelsShelf';

// LG Channels Shelf
import LGChannelsShelf from 'pages/Promotion/LGChannelsShelf/LGChannelsShelf';

// Interactive Dock - Recommendation
import InteractiveDock from 'pages/Promotion/InteractiveDock/InteractiveDock';
import CategoryTitle from 'pages/Promotion/InteractiveDock/CategoryTitle';
import InteractiveDockOrganize from 'pages/Promotion/InteractiveDock/InteractiveDockOrganize';

// [Configuration]
// Account
import Account from 'pages/Configuration/Account/Account';

// Code Management
import CodeManagement from './pages/Configuration/CodeManagement/CodeManagement';

// Metadata Edit
import MetadataEdit from './pages/Configuration/MetadataEdit/MetadataEdit';
import MetadataChannelEdit from './pages/Configuration/MetadataEdit/MetadataChannelEdit';
import MetadataVodEdit from './pages/Configuration/MetadataEdit/MetadataVodEdit';

// Terms Of Use
import TermsOfUse from 'pages/Configuration/TermsOfUse/TermsOfUse';

// Privacy Policy
import PrivacyPolicy from 'pages/Configuration/PrivacyPolicy/PrivacyPolicy';

// Support
import Support from 'pages/Configuration/Support/Support';

// [Monitoring]
// Emulate
import Emulate from 'pages/Monitoring/Emulate/Emulate';

// API Call Logs
import APICallLogs from 'partner_pages/Monitoring/APICallLogs/APICallLogs';

// [Statistics]
// Channel
import Channel from 'partner_pages/Statistics/Channel';

// Program
import Program from 'partner_pages/Statistics/Program';

// Vod
import Vod from 'partner_pages/Statistics/Vod';
import VODGenreDetail
  from "./pages/Configuration/CodeManagement/VODGenreDetail";

function App({ getCountry, getProvider }) {
  const [currentUser, setCurrentUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [message, setMessage] = useState('');
  const [isDisplayApp, setDisplayApp] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [targetComponent, setTargetComponent] = useState('');
  const [isPartnerPageShow, setPartnerPageShow] = useState(false);
  const [settings, setSettings] = useState(null);
  const [recentlyUsedMenu, setRecentlyUsedMenu] = useState({ adminMenu: [], partnerMenu: [] });

  // Lnb menu 가 아닌 다른 곳에서 선택한 경우를 처리하기 위한 용도, 값이 10000이면 Lnb 메뉴 선택 초기화
  const [selectedIdFromNonLnb, setSelectedIdFromNonLnb] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setMessage('');
    setTargetComponent('');
    location.state = null;

    const savedCurrentUser = JSON.parse(localStorage.getItem('currentUser')) || initialCurrentUser;
    setCurrentUser(savedCurrentUser);

    if (savedCurrentUser && savedCurrentUser.isAuthenticated && !isSessionExpired(savedCurrentUser)) {
      setIsAuthenticated(true);
      updateSessionTime(savedCurrentUser);
    } else {
      setIsAuthenticated(false);
    }

    const darkMode = localStorage.getItem('darkMode') || 'off';
    if (darkMode === 'on') {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }

    const tempSavedSettings = JSON.parse(localStorage.getItem('settings'));
    if (tempSavedSettings) {
      setSettings(tempSavedSettings);
    } else {
      setSettings(initialSettings);
    }

    const SavedRecentlyUsedMenu = JSON.parse(localStorage.getItem('recentlyUsedMenu'));

    if (SavedRecentlyUsedMenu) {
      setRecentlyUsedMenu(SavedRecentlyUsedMenu);
    } else {
      setRecentlyUsedMenu({ adminMenu: [], partnerMenu: [] });
    }

    setDisplayApp(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Admin 실행시에 로그인이 완료된 이후에 서버에서 국가 정보와 CP 정보를 가져옴
    if (isAuthenticated) {
      getCountry();
      getProvider();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (location.state) {
      if (location.state.auth) {
        const savedCurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        setCurrentUser(savedCurrentUser);

        if (location.state.auth === 'true') {
          setIsAuthenticated(true);
        } else if (location.state.auth === 'false') {
          setIsAuthenticated(false);
        }

        if (savedCurrentUser.role === 'ROLE_CP' || savedCurrentUser.role === 'ROLE_STUDIO') {
          setPartnerPageShow(true);
        } else {
          setPartnerPageShow(false);
        }

        setMessage(location.state.message);
        setTargetComponent('');
      } else if (location.state.move) {
        if (location.state.targetComponent) {
          setMessage(location.state.message);
          setTargetComponent(location.state.targetComponent);

          if (location.state.targetComponent === 'PartnerMain') {
            setPartnerPageShow(true);
          } else if (location.state.targetComponent === 'Main') {
            setPartnerPageShow(false);
          }
        } else {
          setMessage('');
          setTargetComponent('');
        }

        if (location.state.selectedId) {
          setSelectedIdFromNonLnb(location.state.selectedId);
        } else {
          setSelectedIdFromNonLnb(null);
        }
      }

      location.state = null;
    } else {
      setMessage('');
      setTargetComponent('');
    }
  }, [location]);

  useEffect(() => {
    if (settings) {
      localStorage.setItem('settings', JSON.stringify(settings));
    }
  }, [settings]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }

    updateSettings({ darkMode: darkMode ? 'on' : 'off' });
  }, [darkMode]);

  useEffect(() => {
    updateSettings({ isPartnerPageShow: isPartnerPageShow });
  }, [isPartnerPageShow]);

  useEffect(() => {
    if (recentlyUsedMenu) {
      localStorage.setItem('recentlyUsedMenu', JSON.stringify(recentlyUsedMenu));
    }
  }, [recentlyUsedMenu]);

  const updateSessionTime = (currentUser) => {
    const updateCurrentUser = {
      ...currentUser,
      loginTime: new Date().toISOString(),
    };

    localStorage.setItem('currentUser', JSON.stringify(updateCurrentUser));
  };

  const isSessionExpired = (currentUser) => {
    if (!currentUser.loginTime) return true;

    const loginTime = new Date(currentUser.loginTime);
    const currentTime = new Date();
    const timeDifference = currentTime - loginTime;
    const minutesDifference = timeDifference / 1000;

    return minutesDifference > currentUser.sessionTimeout; // 30 minutes
  };

  const handleDarkMode = (value) => {
    setDarkMode(value);
  };

  const updateSettings = (newSettings) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...newSettings,
    }));
  };

  const handleSelectedId = (selectedId) => {
    const currentMenuItemData = isPartnerPageShow ? partnerMenuItemData : menuItemData;

    let selectedItem;
    currentMenuItemData.some((item) => {
      const subItem = item.subItems.find((sub) => sub.id === selectedId);
      if (subItem) {
        selectedItem = {
          id: 1,
          title: subItem.text,
          breadCrumb: `Home > ${item.primaryText} > ${subItem.text}`,
          path: subItem.path,
          menuId: subItem.id,
        };
        return true;
      }
      return false;
    });

    let currentRecentlyUsedMenu = isPartnerPageShow ? recentlyUsedMenu.partnerMenu : recentlyUsedMenu.adminMenu;

    const existingItemIndex = currentRecentlyUsedMenu.findIndex((item) => item.title === selectedItem.title);
    if (existingItemIndex !== -1) {
      currentRecentlyUsedMenu.splice(existingItemIndex, 1);
    }

    currentRecentlyUsedMenu = currentRecentlyUsedMenu.map((item) => ({ ...item, id: item.id + 1 }));
    currentRecentlyUsedMenu.unshift(selectedItem);

    currentRecentlyUsedMenu.forEach((item, index) => {
      item.id = index + 1;
    });

    const updatedRecentlyUsedMenu = {
      ...recentlyUsedMenu,
      [isPartnerPageShow ? 'partnerMenu' : 'adminMenu']: currentRecentlyUsedMenu,
    };

    setRecentlyUsedMenu(updatedRecentlyUsedMenu);
  };

  return (
    isDisplayApp && (
      <>
        <CssBaseline />
        <Box display='flex' className='wrapper'>
          {isAuthenticated && <Gnb darkMode={darkMode} isPartnerPageShow={isPartnerPageShow} callbackSelectedId={handleSelectedId} selectedIdFromNonLnb={selectedIdFromNonLnb} />}
          <Box className='main-container' sx={{ flexGrow: 1 }}>
            {isAuthenticated && <TopBar currentUser={currentUser} darkMode={darkMode} />}
            <Routes>
              {/* Main */}
              <Route
                path='/'
                element={
                  targetComponent === 'MyProfile' ? (
                    <MyProfile currentUser={currentUser} darkMode={darkMode} callBackDarkMode={handleDarkMode} />
                  ) : targetComponent === 'Notification' ? (
                    <Notification currentUser={currentUser} darkMode={darkMode} />
                  ) : (
                    <Entry
                      isAuthenticated={isAuthenticated}
                      message={message}
                      currentUser={currentUser}
                      darkMode={darkMode}
                      isPartnerPageShow={isPartnerPageShow}
                      recentlyUsedMenu={recentlyUsedMenu}
                    />
                  )
                }
              />

              {/* Inspection */}
              {/*<Route path='/ChannelStatus' element={isAuthenticated ? <ChannelStatus darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route
                path='/Metadata'
                element={
                  isAuthenticated ? (
                    targetComponent === 'ChannelDetail' ? (
                      <ChannelDetail darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ProgramDetail' ? (
                      <ProgramDetail darkMode={darkMode} message={message} />
                    ) : targetComponent === 'VodDetailTVShow' ? (
                      <VodDetailTVShow darkMode={darkMode} message={message} />
                    ) : targetComponent === 'VodDetailMovie' ? (
                      <VodDetailMovie darkMode={darkMode} message={message} />
                    ) : targetComponent === 'VodDetailLiveStream' ? (
                      <VodDetailLiveStream darkMode={darkMode} message={message} />
                    ) : targetComponent === 'SeriesDetail' ? (
                      <SeriesDetail darkMode={darkMode} message={message} />
                    ) : (
                      <Metadata darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />*/}
              {/* Organization */}
             {/* <Route
                path='/ChannelMapping'
                element={
                  isAuthenticated ? (
                    targetComponent === 'BulkUpload' ? (
                      <BulkUpload darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ChannelMapReservations' ? (
                      <ChannelMapReservations darkMode={darkMode} message={message} />
                    ) : (
                      <ChannelMapping darkMode={darkMode} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />*/}
             {/* <Route
                path='/TabCategoryList'
                element={
                  isAuthenticated ? (
                    targetComponent === 'CategoryList' ? (
                      <CategoryList darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ManualOrganize' ? (
                      <CommonManual darkMode={darkMode} message={message} />
                    ) : targetComponent === 'AutoOrganize' ? (
                      <CommonAuto darkMode={darkMode} message={message} />
                    ) : targetComponent === 'SliderOrganize' ? (
                      <OrganizeSlider darkMode={darkMode} message={message} />
                    ) : (
                      <TabCategoryList darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />*/}
              <Route
                path='/WebService'
                element={
                  isAuthenticated ? (
                    targetComponent === 'ListBanner' ? (
                      <ListBanner darkMode={darkMode} message={message} />
                    ) : targetComponent === 'WebLiveManual' ? (
                      <WebLiveManual darkMode={darkMode} message={message} />
                    ) : targetComponent === 'WebVodManual' ? (
                      <WebLiveManual darkMode={darkMode} message={message} />
                    ) : targetComponent === 'BrandingImageA' ? (
                      <BrandingImageA darkMode={darkMode} message={message} />
                    ) : targetComponent === 'BrandingImageB' ? (
                      <BrandingImageB darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CategoryDetailBanner' ? (
                      <CategoryDetailBanner darkMode={darkMode} message={message} />
                    ) : targetComponent === 'BannerOrder' ? (
                      <BannerOrder darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CategoryDetailBrandingA' ? (
                      <CategoryDetailBrandingA darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CategoryDetailBrandingB' ? (
                      <CategoryDetailBrandingB darkMode={darkMode} message={message} />
                    ) : targetComponent === 'CategoryOrganize' ? (
                      <CategoryOrganize darkMode={darkMode} message={message} />
                    ) : (
                      <WebService darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
            {/*  <Route
                path='/ANTest'
                element={
                  isAuthenticated ? (
                    targetComponent === 'ANTestSegment' ? (
                      <ANTestSegment darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestSegmentOrganize' ? (
                      <ANTestSegmentOrganize darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestSegmentOrganizeAddGroup' ? (
                      <ANTestSegmentOrganizeAddGroup darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestSegmentOrganizeOrganize' ? (
                      <ANTestSegmentOrganizeOrganize darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANTestResult' ? (
                      <ANTestResult darkMode={darkMode} message={message} />
                    ) : (
                      <ANTest darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />*/}
             {/* <Route
                path='/ANService'
                element={
                  isAuthenticated ? (
                    targetComponent === 'ANServiceSegment' ? (
                      <ANServiceSegment darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANServiceSegmentOrganize' ? (
                      <ANServiceSegmentOrganize darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANServiceSegmentOrganizeAddGroup' ? (
                      <ANServiceSegmentOrganizeAddGroup darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANServiceSegmentOrganizeOrganize' ? (
                      <ANServiceSegmentOrganizeOrganize darkMode={darkMode} message={message} />
                    ) : targetComponent === 'ANServiceResult' ? (
                      <ANServiceResult darkMode={darkMode} message={message} />
                    ) : (
                      <ANService darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route path='/DeviceNotification' element={isAuthenticated ? <DeviceNotification darkMode={darkMode} /> : <Navigate to='/' replace />} />*/}

              {/* Promotion */}
             {/* <Route
                path='/FreeOnLGChannelsShelf'
                element={
                  isAuthenticated ? (
                    targetComponent === 'History' ? (
                      <FreeOnLGChannelsShelfHistory currentUser={currentUser} darkMode={darkMode} message={message} />
                    ) : targetComponent === 'HistoryDetail' ? (
                      <FreeOnLGChannelsShelfHistoryDetail currentUser={currentUser} darkMode={darkMode} message={message} />
                    ) : (
                      <FreeOnLGChannelsShelf currentUser={currentUser} darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />
              <Route path='/WatchOnLGChannelsShelf' element={isAuthenticated ? <WatchOnLGChannelsShelf darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/LGChannelsShelf' element={isAuthenticated ? <LGChannelsShelf darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route
                path='/InteractiveDock'
                element={
                  isAuthenticated ? (
                    targetComponent === 'CategoryTitle' ? (
                      <CategoryTitle currentUser={currentUser} darkMode={darkMode} message={message} />
                    ) : targetComponent === 'InteractiveDockOrganize' ? (
                      <InteractiveDockOrganize currentUser={currentUser} darkMode={darkMode} message={message} />
                    ) : (
                      <InteractiveDock currentUser={currentUser} darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )
                }
              />*/}

              {/* Configuration */}
             {/* <Route path='/Account' element={isAuthenticated ? <Account darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route
                  path='/CodeManagement'
                  element={isAuthenticated ? (
                    targetComponent === 'VODGenreDetail' ? (
                        <VODGenreDetail darkMode={darkMode} message={message} />
                    ) : (
                        <CodeManagement darkMode={darkMode} message={message} />
                    )
                  ) : (
                    <Navigate to='/' replace />
                  )} />
              <Route path='/MetadataEdit' element={isAuthenticated ?
                  targetComponent === 'MetadataChannelEdit' ? (
                      <MetadataChannelEdit darkMode={darkMode} message={message}/>
                  ) : targetComponent === 'MetadataVodEdit' ? (
                      <MetadataVodEdit darkMode={darkMode} message={message}/>
                  ) : <MetadataEdit darkMode={darkMode} message={message}/> :
                  <Navigate to='/' replace/>}/>
              <Route path='/TermsOfUse' element={isAuthenticated ? <TermsOfUse darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/PrivacyPolicy' element={isAuthenticated ? <PrivacyPolicy darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/Support' element={isAuthenticated ? <Support darkMode={darkMode} /> : <Navigate to='/' replace />} />*/}

              {/* Monitoring */}
           {/*   <Route path='/Emulate' element={isAuthenticated ? <Emulate darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/APICallLogs' element={isAuthenticated ? <APICallLogs darkMode={darkMode} /> : <Navigate to='/' replace />} />*/}

              {/* Statistics */}
          {/*    <Route path='/Channel' element={isAuthenticated ? <Channel darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/Program' element={isAuthenticated ? <Program darkMode={darkMode} /> : <Navigate to='/' replace />} />
              <Route path='/Vod' element={isAuthenticated ? <Vod darkMode={darkMode} /> : <Navigate to='/' replace />} />*/}
            </Routes>
          </Box>
        </Box>
      </>
    )
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getCountry: () => dispatch(getCountry()),
  getProvider: () => dispatch(getProvider()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
