import React, { useMemo, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';
import BasicSwitches from 'components/component/BasicSwitch';

import { Grid, Typography, List, ListItem, Divider } from '@mui/material';

import { Box } from '@mui/system';
import { ContentTypeList, ContentTypeVodList } from 'pages/Organization/WebService/data/PopAddCategoryData';
import SelectBox from 'components/component/BasicSelectBox';
import SelectCheckBox from 'components/component/BasicSelectCheckBox';
import { updateWebContentChannelCategory } from '../../api/restApi';

// 메인 컨텐츠
function PopEditCategory(props) {
  const { onClose, handelSetShowSnakBar, editWebContentCategory, idx, country, areaName, areaType, selectedStatus, getWebLiveCategoryData } = props;

  const [updateWebLiveCategory, setUpdateWebLiveCategor] = useState({
    idx: editWebContentCategory.idx,
    contentType: editWebContentCategory.contentType,
    categoryName: editWebContentCategory.categoryName,
    enable: editWebContentCategory.enable,
  });
  const isActive =
    updateWebLiveCategory.contentType &&
    updateWebLiveCategory.categoryName?.trim() !== "";

  // Area 정보 데이터
  const infos = [
    {
      index: 1,
      label: '#Country',
      value: country,
      divide: true,
    },
    {
      index: 2,
      label: '#Selected Area ',
      value: areaName,
      divide: true,
    },
    {
      index: 3,
      label: '#Area Type',
      value: areaType === 'content_channel' ? 'Content - Channel' : 'Content - VOD',
      divide: false,
    },
  ];

  const contentTypeVodCheckedList = useMemo(() => {
    const editContentTypeList = editWebContentCategory.contentType.split(',');
    const isAllChecked = editContentTypeList.length === (ContentTypeVodList.length - 1);

    return ContentTypeVodList.map(item => {
      const newItem = { ...item };
      if (newItem.id === 'all') {
        newItem.defaultChecked = isAllChecked;
      } else {
        if (!isAllChecked) {
          newItem.defaultChecked = editContentTypeList.includes(newItem.value);
        } else {
          newItem.defaultChecked = false;
        }
      }
      return newItem;
    });
    // eslint-disable-next-line
  }, []);

  const onChangeContentType = (event) => {
    setUpdateWebLiveCategor((pewvState) => ({
      ...pewvState,
      contentType: areaType === "content_vod" ? event.join() : event,
    }));
  };

  const onChangeName = (event) => {
    const value = event.target.value;
    setUpdateWebLiveCategor((pewvState) => ({
      ...pewvState,
      categoryName: value,
    }));
  };

  const onChangeEnable = (checked) => {
    const value = checked ? "ON" : "OFF";
    setUpdateWebLiveCategor((pevState) => ({
      ...pevState,
      enable: value,
    }));
  };

  const clickUpdate = async () => {
    const updateCategoryData = { ...updateWebLiveCategory };
    const res = await updateWebContentChannelCategory(idx, updateCategoryData);
    handleClose();
    getWebLiveCategoryData();
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
  };
  const handleClose = () => {
    onClose();
  };

  const onUpdateCategoryCancelBtn = () => {
    handleClose();
  };

  const onUpdateCategorySaveBtn = () => {
    clickUpdate();
  };

  const dialogButtons = useMemo(() => {
    return [
      {
        width: "150px",
        size: "large",
        label: "Cancel",
        type: "outline",
        color: "tertiary",
        onClick: onUpdateCategoryCancelBtn,
        disabled: false,
      },
      {
        width: "150px",
        size: "large",
        label: "Save",
        type: "box",
        color: "primary",
        onClick: onUpdateCategorySaveBtn,
        disabled: !isActive || selectedStatus === "P",
      },
    ];
    // eslint-disable-next-line
  }, [isActive, updateWebLiveCategory]);

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Edit Category'
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium'
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <List className='info-list-container'>
              {infos.map((item) => (
                <React.Fragment key={item.index}>
                  <ListItem className='list-item'>
                    <Typography className='label'>{item.label}</Typography>
                    <Typography className='value'>{item.value}</Typography>
                  </ListItem>
                  {item.divide && <Divider className='divider' />}
                </React.Fragment>
              ))}
            </List>
            <Divider sx={{ marginBottom: '16px' }} />
            <Grid container columnSpacing={2.5}>
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='switch-with-label-box field-box'>
                  <Typography component='span' className='field-label bold'>
                    Enable
                  </Typography>
                  <BasicSwitches
                    disabled={false}
                    onOffMode={updateWebLiveCategory.enable === 'ON' ? true : false}
                    setSwitchOnOff={onChangeEnable}
                    isLabelText={true}
                    onText='On'
                    offText='Off'
                    value={updateWebLiveCategory.enable === 'ON' ? true : false}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  {areaType === 'content_vod' ?
                    <SelectCheckBox
                      id='select-01'
                      fullWidth
                      size='large'
                      width='100%'
                      required={true}
                      selectOptions={contentTypeVodCheckedList}
                      disabled={false}
                      label='Content Type'
                      onChange={onChangeContentType}
                      shrink
                      multiple={true}
                    /> :
                    <SelectBox
                      id='select-01'
                      fullWidth
                      size='large'
                      width='100%'
                      required={true}
                      selectOptions={ContentTypeList}
                      disabled={false}
                      label='Content Type'
                      selected={updateWebLiveCategory.contentType}
                      onChange={onChangeContentType}
                      shrink
                    />
                  }
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box component='div' className='field-box'>
                  <TextField
                    type='outlined'
                    size='large'
                    required={true}
                    placeholder='ex) Category'
                    defaultValue={updateWebLiveCategory.categoryName}
                    label='Name'
                    autoComplete='off'
                    onChange={onChangeName}
                    InputLabelProps={{ shrink: true }}
                    maxLength={200}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopEditCategory;
