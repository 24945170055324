import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRequest } from 'utils/Request';
import { setParamAction, getTabCategoryAction, getRegionAction, getRegionPlatformAction } from 'actions/homeAppActions';
import TabCategoryFilter from 'pages/Organization/TabCategory/TabCategoryFilter';
import PopCreateTab from 'pages/Organization/TabCategory/PopCreateTab';
import PopEditTab from 'pages/Organization/TabCategory/PopEditTab';
import BasicDialog from 'components/component/BasicDialog';
import BasicSnackbar from 'components/component/BasicSnackbar';

import SearchField from 'components/component/BasicSearchField';
import Button from 'components/component/BasicButton';
import Tooltip from 'components/component/BasicTooltip';

import CheckBox from 'components/component/BasicCheckBox';
import { Box, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { ToggleIcon } from 'components/component/BasicIcon';

import { styled } from '@mui/material/styles';

// data for Table
import {
  TabCategoryListTh,
  selectOption,
} from 'pages/Organization/WebService/data/CategoryListData';

const homeAppStatus =[
  {
    name: 'Saved',
    value: 'S',
  },
  {
    name: 'Saved-Testing',
    value: 'S-T',
  },
  {
    name: 'Saved-Published',
    value: 'S-P',
  },
  {
    name: 'Testing',
    value: 'T',
  },
  {
    name: 'Published',
    value: 'P',
  },
]

const Buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Delete',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'History',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Create Tab',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Save',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'box',
    size: 'medium',
    label: 'Testing',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
];

const tabSkeletonTypeOptions = [
  {id: 'carousel', value: 'carousel', option: 'carousel', type: 'text'},
  {id: 'live', value: 'live', option: 'live', type: 'text'},
  {id: 'default', value: 'default', option: 'default', type: 'text'},
  {id: 'slider', value: 'slider', option: 'slider', type: 'text'},
  {id: 'my', value: 'my', option: 'my', type: 'text'},
  {id: 'search', value: 'search', option: 'search', type: 'text'},
];
const tabLocationTypeOptions = [
  {id: 'scroll', value: 'scroll', option: 'scroll', type: 'text'},
  {id: 'link', value: 'link', option: 'link', type: 'text'},
  {id: 'bottom', value: 'bottom', option: 'bottom', type: 'text'},
  {id: 'top', value: 'top', option: 'top', type: 'text'},
  ];
const tabTypeOptions = [
  {id: 'tab', value: 'tab', option: 'tab', type: 'text'},
  {id: 'link', value: 'link', option: 'link', type: 'text'},
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function TabCategoryList({
  param,// 메인 필터값(device Type, Region, PlatformVersion, status) 현재 값을 저장
  setParamAction,// 사용자가 필터 값 변경 시 변경된 값을 반영
  tabCategory,// 필터 값에 맞는 전체 홈앱 탭/카테고리 데이터
  getTabCategoryAction,// 필터 값에 맞는 전체 탭/카테고리 데이터를 호출
  getRegionAction,// deviceType, region 필터 값을 호출
  getRegionPlatformAction,// region, platformtype 필터 값을 호출
  regionList,// deviceType, region 필터 데이터
  regionPlatformList,// region, platformtype 필터 데이터
  }) {

  // 네비게이션 메뉴명
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
  };

  const [openSelectResult, setOpenSelectResult] = useState(false);

  // 선택(클릭) Tab 정보
  const [tabInfo, setTabInfo] = useState({});
  // 체크박스 체크된 Tab의 idx 데이터 저장
  const [checkedTab, setCheckedTab] = useState([]);
  // 선택(클릭) Tab 정보
  const [multiLangOptions, setMultiLangOptions] = useState([]);

  // PopUp창 노출(CreateTab, EditTab, Delete, Published)
  const [actionDialog, setActionDialog] = useState("");
  // PopUp창 이벤트 액션 이후에 서버에서 받은 메세지를 출력하는 알림창 기본값
  const [showSnakBar, setShowSnakBar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [params, setParams] = useState({
    order_type: "asc",
    order: "areaOrder",
    region: "",
    country: "",
    status: "",
    page: 1,
    size: 10,
  });

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const navigate = useNavigate();

  // 메뉴 최초 진입 및 새로고침 시 필터 값을 새로 받아온다
  useEffect(() => {
    getRegionAction();
    getRegionPlatformAction();
    getMultiLanguageList();
    // eslint-disable-next-line
  }, []);

  // 필터값이 최초 정의될때와 필터 값이 변경 되었을 때 필터에 맞는 탭/카테고리 전체 정보를 호출
  useEffect(() => {
    getTabCategoryAction(param.deviceType, param.region, param.platformVersion, mappingStatus(param.status));
    // eslint-disable-next-line
  }, [param]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // DeviceType 필터 값이 변경 되었을 때 해당 DeviceType에 포함된(존재하는) Region으로 셋팅/노출
  const getRegion = value => {
    gePtlatformVersion(value, regionList.find(n => n.deviceType === value).list[0].region);
    return regionList.find(n => n.deviceType === value).list[0].region;
  };

  // Region 필터 값이 변경 되었을 때 해당 Region에 포함된(존재하는) PlatformType으로 셋팅/노출
  const gePtlatformVersion = (deviceType, region) => {
    if (regionPlatformList.find(n => n.deviceType === deviceType && n.region === region)) {
      return regionPlatformList.find(n => n.deviceType === deviceType && n.region === region).platformVersion;
    }
    return '';
  };

  // Admin UI에서 상태값을 DB 쿼리 수행 시 조건 값으로 변환
  // Save-Testing > S-T
  // Testing > T
  const mappingStatus = name => {
    const status = homeAppStatus.find(n => n.name === name).value;
    return status;
  }

  // 홈앱 지원 전체 Multi Language 데이터 호출
  const getMultiLanguageList = async () => {
    const body = await getRequest(`/api/lang-list`, null);
    if (body && body.result === 'SUCCESS') {
      setMultiLangOptions(body.data);
    }
  };

  // 탭명 링크를 클릭 시 탭정보 수정 팝업을 open하고 선택한 탭의 정보를 매개객체로 전달
  const handleTabClick = (row) => {
    // 여러개의 탭중에 선택한 탭의 정보를 전체정보에서 필터링하여 seleteRow에 저장
    const seletedRow = tabCategory.find(n => n.idx === row.idx);
      // editTab 객체는 선택된 탭의 데이터를 저장
      setTabInfo(seletedRow);
      if (seletedRow) {
        // edit tab 팝업 open
        setActionDialog('Edit Tab');
      }
  };

  // category button 클릭 시 카테고리 목록 화면으로 전환
  // Main화면 Data를 message에 담아서 전환 화면으로 전달
  const handleCategoryButtonClick = (row) => {
    navigate('/TabCategoryList', {
      replace: true, state: {
        move: true,
        targetComponent: 'CategoryList',
        message: {
          tabIdx: row.idx,
          tab: row,
          categorys: row.categorys,
          region: param.region,
          deviceType: param.deviceType,
          platformVersion: param.platformVersion,
          status: param.status,
        }
      }
    });
  };

  // 컴포넌트 외부에 정의된 Button을 useMemo로 감지
  // Delete 버튼은 check box 체크된 탭이 있을 경우 활성화
  const customButtons = useMemo(() => {
    return Buttons.map((button) => {
      button.onClick = () => handleButtonClick(button.label);
      if (button.label === "Delete") {
        button.disabled = checkedTab.length !== 0 ? false : true;
      }
      return button;
    });
  }, [checkedTab]);

  // 팝업창 관리(label 매개값을 전달 받으면 해당 값으로 정의된 팝업창을 open)
  const handleButtonClick = (label) => {
    setActionDialog(label);
  };

  // open된 팝업창에서 Cancel 버튼을 클릭하면 해당 팝업을 닫음
  const onActionCancelBtn = () => {
    setActionDialog("");
  };

  // 팝업창 안에서 save 이벤트를 수행 후 서버에서 받은 메세지 값들을 알림창으로 띄워서 전달
  const handelSetShowSnakBar = ({ open, message, severity }) => {
    setShowSnakBar({
      open,
      message,
      severity,
    });
    setParams({ ...params });
  };

  // 서버 메세지를 띄운 알림창을 close 버튼 클릭 했을 때
  const handleSnackbarClose = () => {
    setShowSnakBar({ open: false, message: "", severity: "" });
  };

  // 탭 목록 그리드 화면에서 check box 이벤트를 컨트롤(선택된 탭의 idx 값을 저장)
  const handleCheckBox = (event) => {
    let idxs = [];
    // 최상단 전체 선택 check box 클릭 시
    if (event.target.checked && event.target.name === "select-all") {
      idxs = tabCategory.map((row) => row.idx);
    // 최상단 전체 선택 해제 check box 클릭 시
    } else if (!event.target.checked && event.target.name === "select-all") {
      idxs = [];
    // 개별 탭 check box 클릭 시
    } else if (event.target.checked) {
      idxs = [...checkedTab, parseInt(event.target.name)];
    // 개별 탭 check box 해제 시
    } else if (!event.target.checked) {
      idxs = checkedTab.filter(
        (row) => row !== parseInt(event.target.name)
      );
    }
    setCheckedTab(idxs);
  };

  // Delete Button 클릭 시 check box 선탠된 탭을 삭제
  const onDeleteOKBtn = async () => {
    console.log('delete tabIdx ' + checkedTab);
  };

  // Save-Testing 상태에 배포 버튼 클릭 시 전체 탭/카테고리 데이터를 Testing 으로 반영
  // Save-Published 상태에 배포 버튼 클릭 시 전체 탭/카테고리 데이터를 Published 로 반영
  const onPublishOKBtn = async () => {
  };

  let sortedPrTdRows = sortRows(
    [...tabCategory],
    params.order,
    params.order_type
  );

  // Delete 버튼 클릭 시 해당 팝업의 버튼을 정의
  const deleteActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onDeleteOKBtn,
    },
  ];

  // 배포 버튼 클릭 시 해당 팝업의 버튼을 정의
  const publishActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      minWidth: 103,
      disabled: false,
      onClick: onPublishOKBtn,
    },
  ];

  // TableBody Row(전체 탭/카테고리의 데이터를 tableBody 값으로 전달)
  const sortedBnrTdRows = sortRows([...tabCategory], orderBy, order);

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Tab / Category
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                  <TabCategoryFilter
                    regionList={regionList}
                    regionPlatformList={regionPlatformList}
                    deviceType={param.deviceType}
                    handleChangeDeviceType={e => {
                      setParamAction(e.target.value, getRegion(e.target.value), param.status, gePtlatformVersion(e.target.value, getRegion(e.target.value)));
                    }}
                    region={param.region}
                    handleChangeRegion={e => {
                      setParamAction(param.deviceType, e.target.value, param.status, gePtlatformVersion(param.deviceType, e.target.value));
                    }}
                    platformVersion={param.platformVersion}
                    handleChangePlatformVersion={e => {
                      setParamAction(param.deviceType, param.region, param.status, e.target.value);
                    }}
                    status={param.status}
                    handleChangeStatus={e => {
                      setParamAction(param.deviceType, param.region, e.target.value, param.platformVersion);
                    }}
                  />
              </Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      {tabCategory.length}
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SelectBox
                      id='select-search'
                      size='medium'
                      selectOptions={selectOption}
                      label=''
                      maxWidth='180px'
                      disabled={false}
                      isFirstSelected={true}
                      open={openSelectResult}
                      setOpen={setOpenSelectResult}
                      placeholder=''
                    />

                    <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <CustomButtonGroup
                      buttons={customButtons}
                      justifyContent='flex-end'
                      onClick={(button) => handleButtonClick(button)}
                  />
                  <Tooltip title='Copy Home-App Data' placement='top' arrow className='btn-tooltip'>
                    <Box sx={{ marginLeft: '8px' }}>
                      <Button
                        color=''
                        type='only-icon'
                        size='medium'
                        label=''
                        isIcon={true}
                        Icon={ToggleIcon}
                        autosize
                      />
                    </Box>
                  </Tooltip>
                </Box>
                {/* right-area */}
              </Box>

              {/* 241011 no-page-number 클래스 추가하였습니다. */}
              <Box className='content-inner-wrap  no-page-number'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {TabCategoryListTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  checked={tabCategory.length !== 0 && sortedPrTdRows.length === checkedTab.length}
                                  onChange={handleCheckBox}
                                  indeterminate={tabCategory.length !== 0 && sortedPrTdRows.length !== checkedTab.length}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedBnrTdRows.map((row, index) => (
                        <TableRow className='tr' key={row.id || index}>
                          {TabCategoryListTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <CheckBox
                                   label={row.tabOrder}
                                   value={row.idx}
                                   onChange={handleCheckBox}
                                   checked={checkedTab.indexOf(row.idx) > -1}
                                />
                              ) : colIndex === 1 ? (
                                row.tabCode
                              ) : colIndex === 2 ? (
                                <>
                                  <span onClick={() => handleTabClick(row)} style={{ color: '#2196f3', cursor: 'pointer' }} >
                                    {row.tabName}
                                  </span>
                                </>
                              ) : colIndex === 3 ? (
                                row.locationType
                              ) : colIndex === 4 ? (
                                row.skeletonType
                              ) : colIndex === 5 ? (
                                row.themeApply === 'Y' ? 'on' : 'off'
                              ) : colIndex === 6 ? (
                                (row.categoryFlag === 'Y' && row.randomOrderFlag) === 'Y' ? 'on' : 'off'
                              ) : colIndex === 7 ? (
                                row.useFlag === 'Y' ? 'on' : 'off'
                              ) : colIndex === 8 ? (
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='Category'
                                  isIcon={false}
                                  Icon=''
                                  iconPos={undefined}
                                  onClick={() => handleCategoryButtonClick(row)}
                                  autosize
                                />
                              ) : (
                                row[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
                <div>
                  {actionDialog === 'Edit Tab' && (
                    <PopEditTab
                      tabInfo={tabInfo}
                      tabSkeletonTypeOptions={tabSkeletonTypeOptions}
                      tabLocationTypeOptions={tabLocationTypeOptions}
                      tabTypeOptions={tabTypeOptions}
                      multiLangOptions={multiLangOptions}
                      onClose={() => setActionDialog('')}
                      handelSetShowSnakBar={handelSetShowSnakBar}
                    />
                  )}
                </div>
                <div>
                  {actionDialog === 'Create Tab' && (
                    <PopCreateTab
                      onClose={() => setActionDialog('')}
                      handelSetShowSnakBar={handelSetShowSnakBar}
                    />
                  )}
                </div>
                <div>
                  {(actionDialog === 'Delete' || actionDialog === 'Testing') &&
                  <BasicDialog
                    title={actionDialog === 'Delete' ? 'Delete Area' : actionDialog === 'Testing' ? 'Publish Area' : ''}
                    contentTitle={actionDialog === 'Delete' ? ' Are you sure to delete?' : actionDialog === 'Testing' ? ' Are you sure to publish?' : ''}
                    size={'small'}
                    closeX={true}
                    open={true}
                    handleClose={() => setActionDialog('')}
                    buttons={actionDialog === 'Delete' ? deleteActionButtons : actionDialog === 'Testing' ? publishActionButtons : []}
                  />}
                </div>
                <div>
                  <BasicSnackbar onClose={handleSnackbarClose} severity={showSnakBar.severity} message={showSnakBar.message} open={showSnakBar.open} duration={1000} />
                </div>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

TabCategoryList.propTypes = {
  param: PropTypes.object.isRequired,
  setParamAction: PropTypes.func.isRequired,
  tabCategory: PropTypes.array.isRequired,
  getTabCategoryAction: PropTypes.func.isRequired,
  regionList: PropTypes.array.isRequired,
  regionPlatformList: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  param: state.homeapp.param,
  tabCategory: state.homeapp.tabCategory,
  regionList: state.homeapp.regionList,
  regionPlatformList: state.homeapp.regionPlatformList,
});

const mapDispatchToProps = dispatch => ({
  setParamAction(deviceType, region, status, platformVersion) {
    dispatch(setParamAction(deviceType, region, status, platformVersion));
  },

  getTabCategoryAction(deviceType, region, platformVersion, status) {
    dispatch(getTabCategoryAction(deviceType, region, platformVersion, status));
  },

  getRegionAction() {
    dispatch(getRegionAction());
  },

  getRegionPlatformAction() {
    dispatch(getRegionPlatformAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TabCategoryList);
