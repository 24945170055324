import React, { useState } from 'react';
import BasicSwitches from 'components/component/BasicSwitch';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import Dialog from 'components/component/BasicDialog';
import FileUpload from 'components/component/BasicFileUpload';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { AddPlus, CloseIcon24Black } from 'components/component/BasicIcon';

import { Grid, IconButton, Typography } from '@mui/material';
import { RegionSelectData, CountrySelectData } from 'pages/Organization/WebService/data/OrganizationData';
import { Box } from '@mui/system';

const uploadedFiles = [
  {
    name: 'Logo_1.png',
    path: '',
  },
];
const themeUploadedFiles = [
  {
    name: 'CP A_Bulk.png',
    path: '',
  },
];

// 메인 컨텐츠
function PopCreateTab(props) {
  const { onClose } = props;

  const [openSelect1, setOpenSelect1] = useState(false);
  const [openSelect2, setOpenSelect2] = useState(false);
  const [openSelect3, setOpenSelect3] = useState(false);

  // States for Tab Name
  const [tabNameRegion1, setTabNameRegion1] = useState(false);
  const [tabNameRegion2, setTabNameRegion2] = useState(false);
  const [tabNameRegion3, setTabNameRegion3] = useState(false);
  const [tabNameType, setTabNameType] = useState(false);
  const [isRandomSwitchOn, setIsRandomSwitchOn] = useState(false);
  const [isCategoryFlagSwitchOn, setIsCategoryFlagSwitchOn] = useState(true);

  // States for Theme
  const [isThemeApplySwitchOn, setIsThemeApplySwitchOn] = useState(false);

  const handleClose = () => {
    onClose();
  };
  const onCancelBtn = () => {
    handleClose();
  };

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: onCancelBtn,
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Create Tab' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={6} mb={1}>
                {/* Device Type */}
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-02'
                    fullWidth
                    size='large'
                    width='100%'
                    selectOptions={CountrySelectData}
                    disabled={false}
                    required={true}
                    label='Device Type'
                    isFirstSelected={true}
                    open={openSelect2}
                    setOpen={setOpenSelect2}
                    placeholder='Select'
                    shrink
                  />
                </Box>
              </Grid>
              {/* Region */}
              <Grid item xs={6} mb={1}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-01'
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    selectOptions={RegionSelectData}
                    disabled={false}
                    label='Region'
                    isFirstSelected={openSelect1}
                    open={openSelect1}
                    setOpen={setOpenSelect1}
                    placeholder='Select'
                    shrink
                  />
                </Box>
              </Grid>
              {/* Platform Version */}
              <Grid item xs={12} mb={1}>
                <Box component='div' className='field-box'>
                  <TextField
                    type='outlined'
                    size='large'
                    required={false}
                    placeholder='ex)4.3.0'
                    defaultValue={null}
                    label='Platform Version'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              {/* Tab Code */}
              <Grid item xs={12} mb={1}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-0e'
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    selectOptions={CountrySelectData}
                    disabled={false}
                    label='Tab Code'
                    isFirstSelected={true}
                    open={openSelect3}
                    setOpen={setOpenSelect3}
                    placeholder='Select'
                    shrink
                  />
                  <Typography component='p' className='input-sub-text' sx={{ paddingLeft: '14px' }}>
                    When there are no selectable tab codes, please contact us via Harmony.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/* Tab Name */}
            <Box component='section' className='section-container'>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12}>
                  <Typography className='field-label bold required'>Tab Name</Typography>
                </Grid>
                <Grid item xs={12}>
                  {/* 1st Tab Name */}
                  <Box component='div' className='field-box flex-start' mb={1}>
                    <SelectBox
                      id='select-tab-name-region-1'
                      size='large'
                      maxWidth='170px'
                      required={true}
                      selectOptions={CountrySelectData}
                      disabled={true}
                      label=''
                      isFirstSelected={true}
                      open={tabNameRegion1}
                      setOpen={setTabNameRegion1}
                      shrink
                    />

                    <TextField
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Input Tab Name'
                      defaultValue='Watch Now'
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />

                    <Button
                      color='tertiary'
                      type='outline icon'
                      size='medium'
                      label=''
                      isIcon={true}
                      Icon={AddPlus}
                      iconPos='center'
                      autosize
                    />
                  </Box>
                  {/* 2nd Tab Name */}
                  <Box component='div' className='field-box flex-start' mb={1}>
                    <SelectBox
                      id='select-tab-name-region-2'
                      fullWidth
                      size='large'
                      maxWidth='170px'
                      required={true}
                      selectOptions={CountrySelectData}
                      disabled={false}
                      label=''
                      isFirstSelected={true}
                      open={tabNameRegion2}
                      setOpen={setTabNameRegion2}
                      shrink
                    />

                    <TextField
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Input Tab Name'
                      defaultValue='Se nu'
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />

                    <Button
                      color='tertiary'
                      type='outline icon'
                      size='medium'
                      label=''
                      isIcon={true}
                      Icon={AddPlus}
                      iconPos='center'
                      autosize
                    />
                  </Box>
                  {/* 3rd Tab Name */}
                  <Box component='div' className='field-box flex-start' mb={1}>
                    <SelectBox
                      id='select-tab-name-region-3'
                      fullWidth
                      size='large'
                      maxWidth='170px'
                      required={true}
                      selectOptions={CountrySelectData}
                      disabled={false}
                      label=''
                      isFirstSelected={true}
                      open={tabNameRegion3}
                      setOpen={setTabNameRegion3}
                      shrink
                    />

                    <TextField
                      type='outlined'
                      size='large'
                      required={false}
                      placeholder='Input Tab Name'
                      defaultValue='a'
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />

                    <Button
                      color='tertiary'
                      type='outline icon'
                      size='medium'
                      label=''
                      isIcon={true}
                      Icon={AddPlus}
                      iconPos='center'
                      autosize
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box flex-start'>
                    <SelectBox
                      id='select-04'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={CountrySelectData}
                      disabled={false}
                      required={true}
                      label='Type'
                      isFirstSelected={true}
                      open={tabNameType}
                      setOpen={setTabNameType}
                      shrink
                    />
                  </Box>
                </Grid>

                <Grid item xs={6} mb={1}>
                  {/* Location Type */}
                  <Box component='div' className='field-box'>
                    <SelectBox
                      id='select-02'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={CountrySelectData}
                      disabled={false}
                      required={true}
                      label='Location Type'
                      isFirstSelected={true}
                      open={openSelect2}
                      setOpen={setOpenSelect2}
                      shrink
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' sx={{ width: '90px' }}>
                      Enable
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={isRandomSwitchOn}
                      setSwitchOnOff={setIsRandomSwitchOn}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                  {/* Skeleton Type */}
                  <Box component='div' className='field-box'>
                    <SelectBox
                      id='select-02'
                      fullWidth
                      size='large'
                      width='100%'
                      selectOptions={CountrySelectData}
                      disabled={false}
                      required={true}
                      label='Skeleton Type'
                      isFirstSelected={true}
                      open={openSelect2}
                      setOpen={setOpenSelect2}
                      shrink
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                  {/* Category Flag */}
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' style={{ width: '90px' }}>
                      Category Flag
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={isCategoryFlagSwitchOn}
                      setSwitchOnOff={setIsCategoryFlagSwitchOn}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} mb={1}>
                  <Box className='field-box'>
                    <FileUpload
                      id='tabImg'
                      buttonLabel='Upload'
                      size='large'
                      label='Tab Icon'
                      placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                      onFileChange={(file) => console.log('Selected file:', file)}
                      accept='.jpg,.png,'
                      isSubText
                      subText=''
                      required={true}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Typography component='p' className='upload-info-text'>
                      Recommended Size : <strong>78 x 78</strong> , Actual Image Size: <strong>78 x 78</strong>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  {/* 업로드 정보 */}
                  <Box className='upload-box'>
                    <div className='upload-total-count'>Upload File</div>
                    {uploadedFiles.map((file, i) => (
                      <Box component='div' className='upload-file-list' key={`uplload-field-${i}`}>
                        <Box className='box-content' display='flex'>
                          <Box className='left-area'>
                            <CustomImage
                              src={file.path}
                              fit={false}
                              wrapWidth='120px'
                              wrapHeight='64px'
                              alt=''
                              rounded
                            />
                          </Box>
                          <Box className='right-area'>
                            <Box component='div' className='upload-info-wrap'>
                              <Typography component='p' className='file-name'>
                                {file.name}
                              </Typography>
                              <Box component='ul' className='file-list-area'>
                                <Box component='li' className='item'>
                                  Recommended Size : 78 x 78{' '}
                                </Box>
                                <Box component='li' className='item'>
                                  Actual Image Size: 78 x 78
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <IconButton className='btn-close'>
                          <CloseIcon24Black />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* Theme */}
            <Box component='section' className='section-container'>
              <Grid container columnSpacing={2.5}>
                <Grid item xs={12}>
                  <h3 className='sub-title'>Theme</h3>
                </Grid>
                <Grid item xs={12} mb={1}>
                  {/* Theme Apply */}
                  <Box component='div' className='switch-with-label-box field-box'>
                    <Typography component='span' className='field-label bold' style={{ width: '90px' }}>
                      Theme Apply
                    </Typography>
                    <BasicSwitches
                      disabled={false}
                      onOffMode={isThemeApplySwitchOn}
                      setSwitchOnOff={setIsThemeApplySwitchOn}
                      isLabelText={true}
                      onText='On'
                      offText='Off'
                    />
                  </Box>
                </Grid>
                {/* Title */}
                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Input Title'
                      defaultValue='Summer Vacation'
                      label='Title'
                      autoComplete='off'
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>
                {/* Description */}
                <Grid item xs={12} mb={1}>
                  <Box component='div' className='field-box'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Input Description'
                      defaultValue='Summer Vacation'
                      label='Description'
                      autoComplete='off'
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12} mb={1}>
                <Box className='field-box'>
                  <FileUpload
                    id='bgImg'
                    buttonLabel='Upload'
                    size='large'
                    label='Background Image'
                    placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                    onFileChange={(file) => console.log('Selected file:', file)}
                    accept='.jpg,.png,'
                    isSubText
                    subText=''
                    InputLabelProps={{ shrink: true }}
                  />
                  <Typography component='p' className='upload-info-text'>
                    Recommended Size : <strong>78 x 78</strong> , Actual Image Size: <strong>78 x 78</strong>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                {/* 업로드 정보 */}
                <Box className='upload-box'>
                  <div className='upload-total-count'>Upload File</div>
                  {themeUploadedFiles.map((file, i) => (
                    <Box component='div' className='upload-file-list' key={`uploaded-background-${i}`}>
                      <Box className='box-content' display='flex'>
                        <Box className='left-area'>
                          <CustomImage src={file.path} fit={false} wrapWidth='280px' wrapHeight='64px' alt='' rounded />
                        </Box>
                        <Box className='right-area'>
                          <Box component='div' className='upload-info-wrap'>
                            <Typography component='p' className='file-name'>
                              {file.name}
                            </Typography>
                            <Box component='ul' className='file-list-area'>
                              <Box component='li' className='item'>
                                Recommended Size : 78 x 78{' '}
                              </Box>
                              <Box component='li' className='item'>
                                Actual Image Size: 78 x 78
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <IconButton className='btn-close'>
                        <CloseIcon24Black />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Box>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopCreateTab;
