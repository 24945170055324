export const ServiceOption = [
  { id: 1, value: 'All', option: 'All', type: 'text' },
  { id: 2, value: 'on', option: 'on', type: 'text' },
  { id: 3, value: 'off', option: 'off', type: 'text' },
];

export const MappingRuleOption = [
  { id: 1, value: 'All', option: 'All', type: 'text' },
  { id: 2, value: 'manual', option: 'manual', type: 'text' },
  { id: 3, value: 'auto', option: 'auto', type: 'text' },
];

export const PublishingFlagOption = [
  { id: 1, value: 'Saved for Testing', option: 'Saved for Testing', type: 'text' },
  { id: 2, value: 'Saved for Published', option: 'Saved for Published', type: 'text' },
];

export const ManualAutoOption = [
  { id: 'manual', value: 'manual', option: 'Manual', type: 'text' },
  { id: 'auto', value: 'auto', option: 'Auto', type: 'text' },
];

export const ManualAutoOptionWithCpAndPluto = [
  { id: 'manual', value: 'manual', option: 'Manual', type: 'text' },
  { id: 'auto', value: 'auto', option: 'Auto', type: 'text' },
  { id: 'cp', value: 'cp', option: 'CP', type: 'text' },
  { id: 'block', value: 'block', option: 'block', type: 'text' },
];

export const InteractiveTitle = [
  {
    id: 'Service',
    align: 'left',
    label: 'Service',
    width: '7%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'relatedRule',
    align: 'left',
    label: 'Manual/Auto',
    width: '14%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'logoUrl',
    align: 'left',
    label: 'Logo',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'channelNumber',
    align: 'left',
    label: 'Ch.No',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelName',
    align: 'left',
    label: 'Ch.Name',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelId',
    align: 'left',
    label: 'Ch.ID',
    width: '15%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerName',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'genreName',
    align: 'left',
    label: 'Genre',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Action',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const CategoryTitleHeader = [
  {
    id: 'Classification',
    align: 'left',
    label: 'Classification',
    width: '16%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'Priority',
    align: 'left',
    label: 'Priority',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Description',
    align: 'left',
    label: 'Description',
    width: '35%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'DefaultCategory',
    align: 'left',
    label: 'Default Category Name',
    width: '25%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'Action',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];

export const OrganizeTitle = [
  {
    id: 'Checkbox',
    align: 'left',
    label: '',
    width: '5%',
    checkbox: true,
    sortable: false,
  },
  {
    id: 'providerName',
    align: 'left',
    label: 'CP Name',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentId',
    align: 'left',
    label: 'Ch.ID',
    width: '26%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentName',
    align: 'left',
    label: 'Ch.Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'imageUrl',
    align: 'left',
    label: 'Image',
    width: '18%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'genre',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const OrganizeScheduleTitle = [
  {
    id: 'CheckBox',
    align: 'left',
    label: 'Order',
    width: '8%',
    checkbox: true,
    sortable: true,
  },
  {
    id: 'contentOrder',
    align: 'left',
    label: 'Order',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerName',
    align: 'left',
    label: 'CP Name',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contendId',
    align: 'left',
    label: 'Ch.ID',
    width: '20%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'contentName',
    align: 'left',
    label: 'Ch. name',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'imageUrl',
    align: 'left',
    label: 'Image',
    width: '12%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'genre',
    align: 'left',
    label: 'Genre',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const TypeOption = [
  { id: 'live', value: 'live', option: 'live', type: 'text' },
  { id: 'movie', value: 'movie', option: 'movie', type: 'text' },
];

export const TableSearchOption = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'channelNumber', value: 'channelNumber', option: 'channelNumber', type: 'text' },
  { id: 'channelName', value: 'channelName', option: 'channelName', type: 'text' },
  { id: 'channelId', value: 'channelId', option: 'channelId', type: 'text' },
  { id: 'providerName', value: 'providerName', option: 'providerName', type: 'text' },
];
