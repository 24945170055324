import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import BasicSwitches from 'components/component/BasicSwitch';
import TextField from 'components/component/BasicTextField';
import Button from 'components/component/BasicButton';
import CustomImage from 'components/component/BasicImage';
import DatePicker from 'components/component/BasicDatePicker';
import dayjs from 'dayjs';
import { Box, Typography, Link, Grid, ListItem, List, Divider } from '@mui/material';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import FileUpload from 'components/component/BasicFileUpload';

// 더미 이미지
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import { useNavigate } from 'react-router-dom';
import BasicSnackbar from 'components/component/BasicSnackbar';
import { updateBannerImage } from '../../api/restApi';

function CategoryDetailBanner(props) {
  const { message } = props;
  const { idx, areaId, country, areaName, areaType, seletedRow, selectedStatus, sortedPrTdRows, region } = message;

  const [showSnakBar, setShowSnakBar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [actualSize, setActualSize] = useState({ width: 1920, height: 305 });
  const [tempImage, setTempImage] = useState(null);
  const today = dayjs(new Date());

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Web Service',
    normalMenu: ['Category List'],
  };

  const currentSelectedRow = {
    webPromotionCategoryIdx: seletedRow.resultOrder,
    resultIdx: seletedRow.resultIdx,
    order: seletedRow.resultOrder,
    name: seletedRow.categoryName,
    enable: seletedRow.enable,
    image: seletedRow.img,
    url: seletedRow.url,
    startDate: seletedRow.startDate,
    endDate: seletedRow.endDate,
    contentType: areaType,
  };
  const [updateBrandImageA, setUpdateBrandImageA] = useState({
    webPromotionCategoryIdx: seletedRow.resultOrder,
    resultIdx: seletedRow.resultIdx,
    order: seletedRow.resultOrder,
    name: seletedRow.categoryName,
    enable: seletedRow.enable,
    image: seletedRow.img,
    url: seletedRow.url,
    startDate: seletedRow.startDate,
    endDate: seletedRow.endDate,
    contentType: areaType,
  });

  const clickImg = (imgsrc, pageName) => {
    if (typeof imgsrc === 'string') {
      var imageWin = new Image();
      imageWin = window.open("", pageName, "width=1000px, height=500px");
      imageWin.document.write("<html><body style='margin:0'>");
      imageWin.document.write("<img src='" + imgsrc + "' border=0 />");
      imageWin.document.write("</body><html>");
      imageWin.document.title = pageName;
    } else if (imgsrc instanceof File) {  // File
      const reader = new FileReader();
      reader.onload = (e, pageName = imgsrc.name) => {
        const newWindow = window.open("", pageName, "width=1000px, height=500px");
        newWindow.document.write(`<img src="${e.target.result}" border=0 />`);
        newWindow.document.title = pageName;
      };
      reader.readAsDataURL(imgsrc);
    }
  }

  const onChangeEnable = (checked) => {
    const value = checked ? "ON" : "OFF";
    setUpdateBrandImageA((pevState) => ({
      ...pevState,
      enable: value,
    }));
  };

  const onChangeCategoryName = (event) => {
    setUpdateBrandImageA((pewvState) => ({
      ...pewvState,
      name: event.target.value,
    }));
  };

  const onChangeStartDate = (newDate) => {
    if (newDate && newDate.$d) {
      const formattedDate = dayjs(newDate.$d).format("MM/DD/YYYY");
      setUpdateBrandImageA((prevState) => ({
        ...prevState,
        startDate: formattedDate,
      }));
    } else {
      setUpdateBrandImageA((prevState) => ({
        ...prevState,
        startDate: null,
      }));
    }
  };

  const onChangeEndDate = (newDate) => {
    if (newDate && newDate.$d) {
      const formattedDate = dayjs(newDate.$d).format("MM/DD/YYYY");
      setUpdateBrandImageA((prevState) => ({
        ...prevState,
        endDate: formattedDate,
      }));
    } else {
      setUpdateBrandImageA((prevState) => ({
        ...prevState,
        endDate: null,
      }));
    }
  };

  const onChangeURL = (event) => {
    setUpdateBrandImageA((pewvState) => ({
      ...pewvState,
      url: event.target.value,
    }));
  };

  const isSizeDifferent =
    actualSize.width !== 1920 || actualSize.height !== 305;

  const navigate = useNavigate();
  const params = new URLSearchParams(updateBrandImageA.image);
  const filePath = params.get('file_path');
  const imageName = filePath ? filePath.split('/').pop() : '';
  const fields = [
    {
      xs: 6,
      label: 'Order',
      value: (
        <Typography component='span' className='text'>
          {updateBrandImageA.order}
        </Typography>
      ),
    },
    {
      xs: 6,
      label: 'Enable',
      bold: true,
      required: true,
      value: (
        <BasicSwitches
          disabled={selectedStatus === "P"}
          onOffMode={updateBrandImageA.enable === 'ON' ? true : false}
          setSwitchOnOff={onChangeEnable}
          isLabelText={true}
          onText='On'
          offText='off'
          value={updateBrandImageA.enable === 'ON' ? true : false}
        />
      ),
    },

    // Name
    {
      xs: 12,
      label: 'Name',
      required: true,
      alignTop: true,
      value: (
        <>
          <TextField
            type='outlined'
            size='large'
            placeholder=''
            label=''
            autoComplete='off'
            InputLabelProps={{ shrink: false }}
            onChange={onChangeCategoryName}
            defaultValue={updateBrandImageA.name}
            disabled={selectedStatus === "P"}
          />
        </>
      ),
    },

    // Image
    {
      xs: 12,
      label: 'Image',
      required: true,
      alignTop: true,
      value: (
        <>
          <Box sx={{ marginBottom: '12px' }}>
            <FileUpload
              id='fileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
              buttonLabel='Upload'
              size='large'
              placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
              onFileChange={(file) => onChangeImageUpload(file)}
              accept='.jpg,.png,'
              isSubText
              subText=''
              value={updateBrandImageA.image.name || imageName}
              disabled={selectedStatus === "P"}
            />
            <Typography component='p' className='upload-info-text'>
              Recommended Size : <strong>1920px X 305px</strong> , Actual Image Size: {' '}
              <strong style={{ color: isSizeDifferent ? 'red' : 'inherit' }}>
                {`${actualSize.width}px X ${actualSize.height}px`}
              </strong>
            </Typography>
          </Box>
          <CustomImage src={tempImage ? tempImage : updateBrandImageA.image} fit={true} alt='' sx={{ width: '100%', height: 202 }} rounded
            wrapWidth='100%' wrapHeight='202px' imgWidth='100%' imgHeight='202px' />
          <Box className='img-url-wrap' display='flex' justifyContent='space-between'>
            <Typography className='img-size'>{`${actualSize.width}px X ${actualSize.height}px`}</Typography>
            <Button
              color='tertiary'
              type='outline'
              size='small'
              label='Image URL'
              isIcon={true}
              Icon={BtnTeritaryOutlineRight}
              iconPos='right'
              onClick={() => clickImg(updateBrandImageA.image, imageName)}
            />
          </Box>
        </>
      ),
    },

    // DataPicker
    {
      xs: 6,
      label: 'Start Date',
      required: true,
      value: <DatePicker size='large' singleDate={true} minDate={today} maxDate={dayjs(updateBrandImageA.endDate).subtract(1, 'day')}
        onChange={onChangeStartDate}
        value={dayjs(updateBrandImageA.startDate)}
        disabled={selectedStatus === "P"}
      />,
    },
    {
      xs: 6,
      label: 'End Date',
      required: true,
      value: <DatePicker size='large' singleDate={true} minDate={dayjs(updateBrandImageA.startDate).add(1, 'day')}
        onChange={onChangeEndDate}
        value={updateBrandImageA.endDate ? dayjs(updateBrandImageA.endDate) : dayjs(null)}
        disabled={selectedStatus === "P"}
      />,
    },

    // URL
    {
      xs: 12,
      label: 'URL',
      value: (
        <TextField
          type='outlined'
          size='large'
          placeholder=''
          defaultValue={updateBrandImageA.url}
          label=''
          autoComplete='off'
          InputLabelProps={{ shrink: false }}
          onChange={onChangeURL}
          disabled={selectedStatus === "P"}
        />
      ),
    },
  ];

  useEffect(() => {
    const img = new Image();
    img.src = seletedRow.img;
    img.onload = () => {
      setActualSize({ width: img.width, height: img.height });
    };
  }, [seletedRow.img]);

  const bannerCategoryInfos = [
    {
      index: 1,
      label: "#Country",
      value: country,
      divide: true,
    },
    {
      index: 2,
      label: "#Selected Area ",
      value: areaName,
      divide: true,
    },
    {
      index: 3,
      label: "#Area Type",
      value: "Banner (1920px X 305px)",
      divide: false,
    },
  ];

  const onChangeImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setTempImage(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setUpdateBrandImageA((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const handleSnackbarClose = () => {
    setShowSnakBar({ open: false, message: "", severity: "" });
  };

  const handelSetShowSnakBar = ({ open, message, severity }) => {
    setShowSnakBar({
      open,
      message,
      severity,
    });
  };

  const clickUpdate = async () => {
    const updateCategoryData = { ...updateBrandImageA };
    const enabledCat = sortedPrTdRows.filter(
      (category) => category.enable === "ON"
    );
    const enabledCount = enabledCat.length;
    const isMatch = enabledCat.some(
      (item) => item.resultIdx === updateCategoryData.resultIdx
    );

    if (!isMatch && enabledCount >= 5 && updateCategoryData.enable === "ON") {
      handelSetShowSnakBar({
        open: true,
        message: "A maximum of 5 items can be enabled.",
        severity: "error",
      });
    } else {
      const res = await updateBannerImage(areaId, idx, updateCategoryData);
      handleClose();
      handelSetShowSnakBar({
        open: true,
        message: res.message,
        severity: res.severity,
      });
      // navigate(-1);
      navigate("/WebService", {
        replace: true,
        state: {
          move: true,
          targetComponent: "ListBanner",
          message: {
            idx: areaId,
            areaName: areaName,
            areaType: areaType,
            country: country,
            selectedStatus: selectedStatus,
            region: region,
          },
        },
      });
    }
  };
  const handleClose = () => {
    handleSnackbarClose();
  };

  const onUpdateCategoryCancelBtn = () => {
    handleClose();
    navigate("/WebService", {
      replace: true,
      state: {
        move: true,
        targetComponent: "ListBanner",
        message: {
          idx: areaId,
          areaName: areaName,
          areaType: areaType,
          country: country,
          selectedStatus: selectedStatus,
          region: region,
        },
      },
    });
  };

  const onUpdateCategorySaveBtn = () => {
    clickUpdate();
  };

  const normalizeDate = (dateString) => {
    let year, month, day;
    if (dateString.includes("-")) {
      // 'YYYY-MM-DD' 형식
      [year, month, day] = dateString.split("-");
    } else if (dateString.includes("/")) {
      // 'MM/DD/YYYY' 형식
      [month, day, year] = dateString.split("/");
    } else {
      throw new Error("Invalid date format");
    }
    return `${year}${month}${day}`;
  };

  const updateJsonWithFormattedDate = (json) => {
    const newJson = { ...json };
    newJson.startDate = newJson.startDate
      ? normalizeDate(newJson.startDate)
      : newJson.startDate;
    newJson.endDate = newJson.endDate
      ? normalizeDate(newJson.endDate)
      : newJson.endDate;
    return newJson;
  };

  const isActive =
    updateBrandImageA.categoryName?.trim() !== "" &&
    updateBrandImageA.image &&
    updateBrandImageA.enable &&
    updateBrandImageA.startDate &&
    updateBrandImageA.endDate &&
    JSON.stringify(updateJsonWithFormattedDate(currentSelectedRow)) !==
      JSON.stringify(updateJsonWithFormattedDate(updateBrandImageA));

  const dialogButtons = useMemo(() => {
    return [
      {
        width: "150px",
        size: "large",
        label: "Cancel",
        type: "outline",
        color: "tertiary",
        onClick: onUpdateCategoryCancelBtn,
        disabled: false,
      },
      {
        width: "150px",
        size: "large",
        label: "Save",
        type: "box",
        color: "primary",
        onClick: onUpdateCategorySaveBtn,
        disabled: !isActive || selectedStatus === "P",
      },
    ];
    // eslint-disable-next-line
  }, [isActive, updateBrandImageA]);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-detail-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' to='#'
                onClick={(e) => {
                  e.preventDefault();
                  // navigate(-1);
                  navigate('/WebService', {
                    replace: true, state: {
                      move: true,
                      targetComponent: 'ListBanner',
                      message: {
                        idx: areaId,
                        areaName: areaName,
                        areaType: areaType,
                        country: country,
                        selectedStatus: message.selectedStatus,
                        region: region
                      }
                    }
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                Category Detail
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {bannerCategoryInfos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'>
              <CustomButtonGroup buttons={dialogButtons} />
            </Box>
          </Box>
          <Box className='white-box white-box-wrap'>
            <Box className='box-content'>
              <Box className='detail-list-container type-category'>
                <Grid container columnSpacing={2.5}>
                  {fields.map(({ xs, label, required, value, alignTop, bold }, index) => (
                    <Grid item xs={xs} className='list-wrap' key={index}>
                      <div
                        className={`
                          info-value-wrap no-border
                          ${bold ? 'bold' : ''}
                        `}
                      >
                        <Typography component='span' className={`label ${required ? 'required' : ''}`}>
                          <span className='text'>{label}</span>
                        </Typography>
                        <div className='value'>{value}</div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <div>
        <BasicSnackbar onClose={handleSnackbarClose} severity={showSnakBar.severity} message={showSnakBar.message} open={showSnakBar.open} duration={1000} />
      </div>
    </Box>
  );
}

export default CategoryDetailBanner;
