import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

import noImageText from 'assets/images/No_Image.png';

// Styled
const ImageWrap = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: '#525252',
  backgroundImage: `url(${noImageText})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50%',
  borderRadius: '4px',
  '.dark &': {
    background: '#000',
  },
  '&.fit': {
    '& img': {
      width: 'fit-content',
      height: 'fit-content',
    },
  },
  '&.bg-del': {
    backgroundColor: 'initial',
  },
  '& img': {
    width: '100%',
    height: '100%',
    borderRadius: '4px',
  },
});

// Image Component
function BasicImage({ src, alt, fit, imgWidth, imgHeight, bgDel, wrapWidth, wrapHeight, ...props }) {
  const defaultSrc = src;
  return (
    <ImageWrap
      {...props}
      className={`custom-image ${fit ? 'fit' : ''} ${bgDel ? 'bg-del' : ''}`}
      style={{ width: wrapWidth, height: wrapHeight }}
    >
      <img src={defaultSrc} alt={alt} style={{ width: imgWidth, height: imgHeight }} />
    </ImageWrap>
  );
}

BasicImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export default BasicImage;
