import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import TextField from 'components/component/BasicTextField';
import Button from 'components/component/BasicButton';
import SelectBox from 'components/component/BasicSelectBox';
import Dialog from 'components/component/BasicDialog';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { CategoryTitleHeader } from './data/InteractiveDockData';
import { AddPlus, DelMinus } from 'components/component/BasicIcon';
import Snackbar from 'components/component/BasicSnackbar';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

function CategoryTitle(props) {
  const { darkMode, message } = props;
  const { filter } = message;

  const navigate = useNavigate();

  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Interactive Dock - Recommendation',
    normalMenu: ['Category Title'],
  };

  // 인터렉티브 독에서 선택한 국가
  const [selectedCountry, setSelectedCountry] = useState('');

  // 왼쪽 Category 에서 edit 선택 유무
  const [isCategoryTypeSelect, setCategoryTypeSelect] = useState(false);

  // 왼쪽 화면에서 선택한 category type
  const [selectedCategoryType, setSelectedCategoryType] = useState('');

  // 서버에서 받아온 language list
  const [languageList, setLanguageList] = useState([]);

  // 화면에 보이는 language option list
  const [languageOptionList, setLanguageOptionList] = useState([]);

  // Related Content Category list
  const [relatedContentCategory, setRelatedContentCategory] = useState([]);

  // 오른쪽 화면에 보이는 default name 과 lang list 관리
  const [defaultCategoryName, setDefaultCategoryName] = useState('');
  const [editLangList, setEditLangList] = useState([{ langCode: '', name: '' }]);
  const [multiLang, setMultiLang] = useState([]);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    getLanguageList();
  }, []);

  useEffect(() => {
    if (filter && filter.selectedCountry) {
      setSelectedCountry(filter.selectedCountry);
    }
  }, [filter]);

  useEffect(() => {
    if (selectedCountry) {
      getRelatedContentCategory();
    }
    // eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    if (isCategoryTypeSelect) {
      let initLangList = [{ langCode: '', name: '' }];

      if (multiLang && multiLang.length > 0) {
        initLangList = multiLang.map(item => ({
            langCode: item.langCode,
            name: item.relatedContentCategoryName
        }));
      }

      setEditLangList([...initLangList]);
    }
  }, [isCategoryTypeSelect, multiLang]);

  const getLanguageList = () => {
    fetch(`${BASE_URL}/interactivedock/language-list`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        const tempLangList = body.data.langList.map(item => ({
          id: item, value: item, option: item, type: 'text'
        }));
        setLanguageOptionList(tempLangList);
        setLanguageList(body.data.langList);
      } else {
        throw new Error(`[Interactive] search-filter request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    }).finally(() => {});
  };

  const getRelatedContentCategory = () => {
    const param = `country=${selectedCountry}`;

    fetch(`${BASE_URL}/interactivedock/related-content-category?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setRelatedContentCategory(body.data.relatedContentCategory);
      } else {
        throw new Error(`[Interactive] search-filter request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    }).finally(() => {});
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const getRelatedContentCategoryMultiLang = (categoryType, categoryName) => {
    setDefaultCategoryName(categoryName);
    setSelectedCategoryType(categoryType);

    const param = `country=${selectedCountry}&categoryType=${categoryType}`;

    fetch(`${BASE_URL}/interactivedock/related-content-category-multi-lang?${param}`, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setMultiLang(body.data.multiLang);
        setCategoryTypeSelect(true);
      } else {
        throw new Error(`[Interactive] getRelatedContentCategoryMultiLang request fail`);
      }
    })
    .catch(error => {
      setAlertTitle(`Warning`);
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    }).finally(() => {});
  };

  const handleMultiLang = (index, type, value) => {
    if (type === 'langCode') {
      const isLangCodeDuplicated = editLangList.find(item => item.langCode === value);

      if (isLangCodeDuplicated) {
        setAlertTitle(`Warning`);
        setAlertMessage(`${value} is already selected, so select other language`);
        setAlertOpen(true);
        return;
      }
    }

    let newData = [...editLangList];
    newData[index][type] = value;
    setEditLangList(newData);
  };

  const handleBack = (event) => {
    event.preventDefault();
    navigate('/InteractiveDock', { replace: true, state:
      { move: true,
        targetComponent: 'InteractiveDock',
        message: { filter }
      }
    });
  };

  const handleSave = () => {
    if (!defaultCategoryName) {
      setAlertTitle(`Warning`);
      setAlertMessage(`Default name is empty, please input default name`);
      setAlertOpen(true);
      return;
    }

    const updateLangList = editLangList.filter(item => item.langCode && item.name).map(item => ({
      langCode: item.langCode,
      relatedContentCategoryName: item.name,
    }));

    const requestData = {
      country: filter.selectedCountry,
      relatedContentCategoryType: selectedCategoryType,
      relatedContentDefaultCategoryName: defaultCategoryName,
      relatedContentCategoryMultiLangList: updateLangList
    };

    fetch(`${BASE_URL}/interactivedock/save-related-content-category`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(requestData)
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setSnackbarMessage('Successfully saved !');
        setSnackbarOpen(true);
        getRelatedContentCategoryMultiLang(selectedCategoryType, defaultCategoryName);
      } else {
        throw new Error(`[Interactive] save-related-content-category request fail`);
      }
    })
    .catch(error => {
      setSnackbarMessage(error.message.replace('Error: ', ''));
      setSnackbarOpen(true);
    }).finally(() => {});
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }} className='wrapper'>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area' sx={{ cursor: 'pointer' }}>
              <Link
                className='title link'
                to='#'
                onClick={handleBack}
              >
                Category Title
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list ' component='div'>
          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {/* Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Category</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {relatedContentCategory.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {CategoryTitleHeader.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {relatedContentCategory.map((td, index) => (
                            <TableRow className='tr' key={td.id || index}>
                              {CategoryTitleHeader.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  { colIndex === 0 ? (
                                      td.relatedContentCategoryType
                                    ) : colIndex === 1 ? (
                                      td.priority
                                    ) : colIndex === 2 ? (
                                      td.description
                                    ) : colIndex === 3 ? (
                                      td.relatedContentCategoryName
                                    ) : colIndex === 4 ? (
                                      <Button
                                        color='tertiary'
                                        type='outline'
                                        size='small'
                                        label='EDIT'
                                        isIcon={false}
                                        Icon=''
                                        iconPos={undefined}
                                        autosize
                                        onClick={() => getRelatedContentCategoryMultiLang(td.relatedContentCategoryType, td.relatedContentCategoryName)}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Title</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Button color='primary' type='box' size='small' label='Save' isIcon={false} autosize onClick={handleSave}/>
                  </Box>
                </Box>

                <Box className='box-content'>
                  {/* Title */}
                  <Box className='content-inner-wrap'>
                    <Box className='title-selected-container'>
                      {isCategoryTypeSelect === false ? (
                        <Box className='content-empty-container'>
                          <Typography component='p' className='empty-message'>
                            Please select a category.
                          </Typography>
                        </Box>
                      ) : (
                        <Box className='content-container'>
                          <Grid container>
                            <Grid item xs={12} sx={{ margin: '12px 0' }}>
                              <TextField
                                type='outlined'
                                size='large'
                                placeholder=''
                                defaultValue=''
                                label='Name'
                                autoComplete='off'
                                required={false}
                                InputLabelProps={{ shrink: true }}
                                value={defaultCategoryName}
                                onChange={e => {
                                  setDefaultCategoryName(e.target.value);
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sx={{ margin: '12px 0' }}>
                              <div className='title-area'>
                                <Typography className='field-label bold required'>Multilingual Name</Typography>
                              </div>
                              {editLangList.map((item, index) => (
                                <Box component='div' className='field-box flex-start'>
                                  <SelectBox
                                    id='Criteria-02'
                                    fullWidth
                                    required={false}
                                    size='large'
                                    maxWidth='170px'
                                    selectOptions={languageOptionList}
                                    disabled={false}
                                    label=''
                                    selected={item.langCode}
                                    isEmpty={true}
                                    onChange={value => {
                                      handleMultiLang(index, 'langCode', value);
                                    }}
                                  />
                                  <TextField
                                    type='outlined'
                                    size='large'
                                    placeholder=''
                                    defaultValue=''
                                    maxWidth='100%'
                                    label=''
                                    autoComplete='off'
                                    required={false}
                                    InputLabelProps={{ shrink: false }}
                                    disabled={false}
                                    value={item.name}
                                    onChange={e => {
                                      handleMultiLang(index, 'name', e.target.value);
                                    }}
                                  />
                                  <Button
                                    color='tertiary'
                                    type='outline icon'
                                    size='medium'
                                    label=''
                                    isIcon={true}
                                    Icon={AddPlus}
                                    autosize
                                    disabled={editLangList.length >= languageList.length}
                                    onClick={() => {
                                      setEditLangList(editLangList.concat({ langCode: '', name: '' }));
                                    }}
                                  />
                                  <Button
                                    color='tertiary'
                                    type='outline icon'
                                    size='medium'
                                    label=''
                                    isIcon={true}
                                    Icon={DelMinus}
                                    autosize
                                    disabled={editLangList.length <= 1}
                                    onClick={() => {
                                      setEditLangList(editLangList.filter((n2, i2) => i2 !== index));
                                    }}
                                  />
                                </Box>
                              ))}
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog
        open={isAlertOpen} // boolean 값으로 설정
        handleClose={handleAlertClose}
        closeX={false}
        size='small' // large, medium, small
        title={alertTitle} // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
      <Snackbar
        severity='warning'
        message={snackbarMessage}
        open={isSnackbarOpen}
        duration={1000}
        bottom={'8%'}
        darkMode={darkMode}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
}
export default CategoryTitle;
