import { BASE_URL } from '../constants';
import { getHeaders, getHeadersFormData } from 'utils/Header';
import { isEmpty } from 'utils/Valid';

async function request(requestMethod, requestPath, isFile, requestBody, finallyFunction) {
  let requestInfo = {
    method: requestMethod,
    headers: isFile ? getHeadersFormData() : getHeaders(),
  };
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(requestMethod)) {
    requestInfo.body = isFile ? requestBody : isEmpty(requestBody) ? {} : JSON.stringify(requestBody);
  }
  try {
    const response = await fetch(BASE_URL + requestPath, requestInfo);
    const result = await response.json();
    return result;
  } catch (err) {
    console.error('Error message:', err);
    throw err;
  } finally {
    if (finallyFunction && typeof finallyFunction === 'function') {
      finallyFunction();
    }
  }
}

export async function getRequest(requestPath, finallyFunction) {
  return await request('GET', requestPath, false, null, finallyFunction);
}

export async function postRequest(requestPath, requestBody, finallyFunction) {
  return await request('POST', requestPath, false, requestBody, finallyFunction);
}

export async function putRequest(requestPath, requestBody, finallyFunction) {
  return await request('PUT', requestPath, false, requestBody, finallyFunction);
}

export async function patchRequest(requestPath, requestBody, finallyFunction) {
  return await request('PATCH', requestPath, false, requestBody, finallyFunction);
}

export async function deleteRequest(requestPath, requestBody, finallyFunction) {
  return await request('DELETE', requestPath, false, requestBody, finallyFunction);
}

export async function multiRequest(requestMethod, requestPath, requestBody, finallyFunction) {
  if (['POST', 'PUT', 'PATCH'].includes(requestMethod)) {
    return await request(requestMethod, requestPath, true, requestBody, finallyFunction);
  }
  throw new Error('Invalid request method for multiRequest');
}
