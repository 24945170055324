import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tag from 'components/component/BasicTag';
import CustomTabs from 'components/component/BasicTabs';
import Snackbar from 'components/component/BasicSnackbar';
import SelectBox from 'components/component/BasicSelectBox';
import { Button, Box, Grid, Link, List, ListItem, ListItemText, Typography } from '@mui/material';

import { styled } from '@mui/system';

// Icon
import { ArrowRight24, FileIcon } from 'components/component/BasicIcon';
// css
import 'assets/scss/page/main.scss';

// image

// data
import { tabs, NotiDatas, NotiDatas2, NotiDatas3, NotiDatas4 } from './data/PartnerMainData';

const CardContainer = styled('div')({
  display: 'block',
});
const BoxContainer = styled('div')({
  paddingRight: '.3rem',
});
const StyledLink = styled('a')`
  width: 2.4rem;
  height: 2.4rem;
  display: inline-block;
`;

const LogWrap = ({ name, api, file, message, startTime, endTime }) => (
  <Box className='log-wrap'>
    <Box className='name-area'>
      <Typography className='log-name'>{name}</Typography>
    </Box>
    <Box className='log-area'>
      <Box mb={1.5}>
        <Typography component={'p'} className='log-item'>
          <span className='label-text'>API : </span>
          <span className='value-text'>{api}</span>
        </Typography>

        <Typography component={'p'} className='log-item'>
          <span className='label-text'>File : </span>
          <span className='value-text'>{file}</span>
        </Typography>
      </Box>
      {message && (
        <Box mb={1.5}>
          <Typography component={'p'} className='log-item'>
            <span className='label-text'>Message : </span>
            <span className='value-text'>{message}</span>
          </Typography>
        </Box>
      )}
      <Box>
        <Typography component={'p'} className='log-item'>
          <span className='value-text'>Start time : </span>
          <span className='value-text'>{startTime}</span>
        </Typography>
        <Typography component={'p'} className='log-item'>
          <span className='value-text'>End time : </span>
          <span className='value-text'>{endTime}</span>
        </Typography>
      </Box>
    </Box>
  </Box>
);

const logData = [
  {
    name: 'Xumo',
    api: '/programschedules',
    file: '/programschedules',
    message: '["id":"dazntv_dzntv_CA","code":"CDP_SVC_4045","message":"Channel not found"]',
    startTime: '/programschedules',
    endTime: '/programschedules',
  },
  {
    name: 'Amagi',
    api: '/channels',
    file: '/on_xumo_Channels_LG_CA_staging_20241022.json',
    message: '["id":"dazntv_dzntv_CA","code":"CDP_SVC_4045","message":"Channel not found"]',
    startTime: '/programschedules',
    endTime: '/programschedules',
  },
  {
    name: 'Amagi Now',
    api: '/vods',
    file: '/programschedules',
    message: '["id":"dazntv_dzntv_CA","code":"CDP_SVC_4045","message":"Channel not found"]',
    startTime: '/programschedules',
    endTime: '/programschedules',
  },
  {
    name: 'Amagi Now',
    api: '/programschedules',
    file: '/programschedules',
    message: '["id":"dazntv_dzntv_CA","code":"CDP_SVC_4045","message":"Channel not found"]',
    startTime: '/programschedules',
    endTime: '/programschedules',
  },
];

// Select Data
const CPNameData = [
  { id: 'all', value: 'All', option: 'All', type: 'text' },
  { id: 'option', value: 'option', option: 'option', type: 'text' },
];

// ************************** 메인 컨텐츠 ***********************************
function PartnerMain(props) {
  const { darkMode, recentlyUsedMenu } = props;
  const navigate = useNavigate();

  const [partnerUsedMenu, setParterUsedMenu] = useState([]);

  useEffect(() => {
    if (recentlyUsedMenu && recentlyUsedMenu.partnerMenu) {
      setParterUsedMenu(recentlyUsedMenu.partnerMenu);
    }
  }, [recentlyUsedMenu]);

  // support
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMoveToOtherPage = (event, moveToPage, targetComponent, selectedId) => {
    event.preventDefault();
    navigate(moveToPage, { replace: true, state: { move: true, targetComponent: targetComponent, selectedId: selectedId } });
  };

  return (
    <Box display='flex' className='wrapper'>
      {/* 파트너 화면 partner-main  클래스 */}
      <Box className='main-container partner-main' component='main' sx={{ flexGrow: 1 }}>
        <Box className='main-wrap' component='div'>
          <Grid container spacing={2.5} columnSpacing={{ md: 2.5 }} className='activity-box left-container'>
            {/* Statistics */}
            <Grid item sm={12}>
              <Box className='white-box white-box-wrap statistic'>
                {/* Box Title */}
                <Box className='title-wrap'>
                  <Box className='box-title-area'>
                    <Typography variant='h2' className='bot-title-text'>
                      Statistics
                    </Typography>
                  </Box>

                  <Link
                    href='#'
                    underline='none'
                    className='title-link'
                    onClick={(event) => handleMoveToOtherPage(event, '/Channel', '', 201)}  // Channel id
                  >
                    <ArrowRight24 />
                  </Link>
                </Box>
                <CardContainer className='card-container'>
                  <BoxContainer className='box-container'>
                    <Box className='statistic-container'>
                      <Box className='statistic-area'>
                        <Box className='area-top'>
                          <Box className='top-right'>
                            <Box className='field-area'>
                              <Box className='field-item'>
                                <SelectBox
                                  id='Cp014'
                                  label='CP Name'
                                  fullWidth
                                  size='medium'
                                  width='150px'
                                  require
                                  selectOptions={CPNameData}
                                  disabled={false}
                                  required={false}
                                  placeholder='Select'
                                  shrink
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className='area-middle'>
                          <Box className='data-container'>
                            <Box className='data-box'>
                              <Typography className='label'>Channels</Typography>
                              <Typography className='value'>671</Typography>
                            </Box>
                            <Box className='data-box'>
                              <Typography className='label'>VODs</Typography>
                              <Typography className='value'>9</Typography>
                            </Box>
                            <Box className='data-box'>
                              <Typography className='label'>Launch countries</Typography>
                              <Typography className='value'>88</Typography>
                            </Box>
                          </Box>

                          <Box className='data-container merge'>
                            <Box className='box-top'>
                              <Typography className='date'>Date : 2024.10.22 ~ 2024.10.29</Typography>
                            </Box>
                            <Box className='box-wrap'>
                              <Box className='data-box'>
                                <Box className='box-inner'>
                                  <Typography className='label'>Unique Device</Typography>
                                  <Typography className='value'>671</Typography>
                                  <Box className='number-box down'>
                                    <span className='text'>92%</span>
                                  </Box>
                                </Box>
                              </Box>
                              <Box className='data-box'>
                                <Box className='box-inner'>
                                  <Typography className='label'>Total Duration</Typography>
                                  <Typography className='value'>9</Typography>
                                  <Box className='number-box up'>
                                    <span className='text'>3.14%</span>
                                  </Box>
                                </Box>
                              </Box>
                              <Box className='data-box'>
                                <Box className='box-inner'>
                                  <Typography className='label'>Play</Typography>
                                  <Typography className='value'>18,333</Typography>
                                  <Box className='number-box up'>
                                    <span className='text'>7.11%</span>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </BoxContainer>
                </CardContainer>
              </Box>
            </Grid>

            {/* Recently Used Menu */}
            <Grid item sm={12}>
              {/* 하얀박스 : white-box white-box-wrap */}
              <Box className='white-box white-box-wrap recently'>
                {/* Box Title */}
                <Box className='title-wrap'>
                  <Box className='box-title-area'>
                    <Typography variant='h2' className='box-title'>
                      Recently Used Menu
                    </Typography>
                  </Box>
                </Box>
                <CardContainer className='card-container'>
                  <BoxContainer className='box-container'>
                    <List className='recently-used'>
                      {partnerUsedMenu && partnerUsedMenu.map((recentData) => (
                        <ListItem key={recentData.id} className='list-item'>
                          <Box className='item-wrap'>
                            <Box component='div' className='text-left-wrap'>
                              <Typography className='channelTitle' component='div' variant='body1'>
                                {recentData.title}
                              </Typography>
                              <Typography className='channelBreadCrumb' component='div' variant='body1'>
                                {recentData.breadCrumb}
                              </Typography>
                            </Box>
                            <Button
                              href='#'
                              variant=''
                              className='link-arrow'
                              onClick={(event) => handleMoveToOtherPage(event, recentData.path, '', recentData.menuId)}
                            >
                              <ArrowRight24 />
                            </Button>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </BoxContainer>
                </CardContainer>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2.5} direction='row' className='right-container'>
            {/* API Call Fail Log */}
            <Grid item sm={12}>
              {/* 하얀박스 : white-box white-box-wrap */}
              <Box className='white-box white-box-wrap fail-log'>
                <Box className='title-wrap'>
                  {/* Box Title */}
                  <Box className='box-title-area'>
                    <Typography variant='h2' className='box-title'>
                      API Call Fail Log
                    </Typography>
                    <Link
                      href='#'
                      underline='none'
                      className='title-link'
                      onClick={(event) => handleMoveToOtherPage(event, '/APICallLogs', '', 301)} // Api Call Logs Id
                    >
                      <ArrowRight24 />
                    </Link>
                  </Box>
                </Box>
                <CardContainer className='card-container'>
                  <BoxContainer className='box-container'>
                    <Box className='fail-log-container'>
                      {logData.map((log, index) => (
                        <LogWrap
                          key={index}
                          name={log.name}
                          api={log.api}
                          file={log.file}
                          message={log.message}
                          startTime={log.startTime}
                          endTime={log.endTime}
                        />
                      ))}
                    </Box>
                  </BoxContainer>
                </CardContainer>
              </Box>
            </Grid>

            {/* Support */}
            <Grid item sm={12}>
              {/* 하얀박스 : white-box white-box-wrap */}
              <Box className='white-box white-box-wrap support'>
                {/* Box Title */}
                <Box className='title-wrap'>
                  <Box className='box-title-area'>
                    <Typography variant='h2' className='bot-title-text'>
                      Support
                    </Typography>
                  </Box>

                  <Link
                    href='#'
                    underline='none'
                    className='title-link'
                    onClick={(event) => handleMoveToOtherPage(event, '/', 'Notification', 10000)} // Lnb 선택할 필요가 없어서 10000 으로 설정
                  >
                    <ArrowRight24 />
                  </Link>
                </Box>
                <Box className='support-list-container'>
                  <CustomTabs value={value} handleChange={handleChange} tabs={tabs} />

                  {[NotiDatas, NotiDatas2, NotiDatas3, NotiDatas4].map(
                    (dataList, tabIndex) =>
                      value === tabIndex && (
                        <Box className='tab-panel' key={tabIndex}>
                          <Box className='panel-wrap'>
                            <List>
                              {dataList.map((data) => (
                                <ListItem key={data.id}>
                                  <ListItemText
                                    primary={
                                      <Box className={`row ${data.new}`}>
                                        <Box className='col col-01'>
                                          <Typography component='span' variant='body1' sx={{ fontSize: '1.4rem' }}>
                                            {data.id}
                                          </Typography>
                                        </Box>
                                        <Box className='col col-02'>
                                          {data.new && <Tag type='new' label='new' badgeType='new' className='chip' />}
                                        </Box>
                                        <Box className='col col-03'>
                                          <Typography component='p' variant='body1' sx={{ fontSize: '1.4rem' }}>
                                            <Link href='#' underline='hover'>
                                              {data.type}
                                              {data.content}
                                            </Link>
                                          </Typography>
                                        </Box>
                                        <Box className='col col-04'>
                                          {data.addFile && (
                                            <StyledLink href='' className='file-icon'>
                                              <FileIcon />
                                            </StyledLink>
                                          )}
                                          <Typography component='span' variant='body1' className='notifi-date'>
                                            {data.date}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        </Box>
                      )
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/*
      Toast Pop-Up
      severity: default, error, warning, info, success
      */}
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode} />
    </Box>
  );
}

export default PartnerMain;
