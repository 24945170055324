import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import Snackbar from 'components/component/BasicSnackbar';
import Tooltip from 'components/component/BasicTooltip';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { Box, Typography, Link, Grid } from '@mui/material';
import DetailItem from './component/DetailItem';
import DetailImageItem from './component/DetailImageItem';

import { GenreCodeOption } from 'pages/Configuration/MetadataEdit/data/MetadataEdit';
import {getRequest} from "../../../utils/Request";

const InfoTooltip = React.forwardRef((props, ref) => (
    <div className='info-list-box'>
      {props.list.map((infoField, i) => (
          <div className='info' key={i}>
            <Typography className='label'>{`${infoField.label} : `}</Typography>
            <span className='info-field'>{infoField.info}</span>
          </div>
      ))}
    </div>
));

InfoTooltip.propTypes = {
  list: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        info: PropTypes.string.isRequired,
      })
  ).isRequired,
};

const MetadataChannelEdit = React.memo((props) => {
  const { darkMode, message} = props;
  const { contentId, channelMapId } = message;
  const [channelData, setChannelData] = useState({});
  const [isSearchSuccessful, setIsSearchSuccessful] = useState(false);
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Metadata Edit',
    normalMenu: ['Channel Detail Edit'],
  };

  useEffect(() => {
    if (message) {
      searchChannelDetail().then(r => console.log(r));
    }
    // eslint-disable-next-line
  }, [message]);

  const searchChannelDetail = async () => {
    setIsSearchSuccessful(false);
    const body = await getRequest(`/metadata-edit/channel/${contentId}?channelMapId=${channelMapId}`, null);
    if (body && body.result === 'SUCCESS') {
      setChannelData(body.data);
      setIsSearchSuccessful(true);
    }
  };

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Restore',
      autosize: true,
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Save',
      autosize: true,
    },
  ];

  return (
      <Box display='flex' className='wrapper'>
        <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
          <div className='top-title-container'>
            <div className='top-title-box'>
              <div className='title-area'>
                <Link className='title link' href=''>
                  Channel Detail Edit
                </Link>
              </div>
              <div className='bread-area'>
                <BreadCrumb optionMenu={optionMenu} />
              </div>
            </div>
          </div>

          <Box className='content-wrap channel-detail' component='div'>
            {isSearchSuccessful && (
                <Box className='white-box white-box-wrap single-box'>
                  <Box className='title-wrap space-between'>
                    <Box className='left-area'>
                      <Box className='box-title-area' display='flex' alignItems='center'>
                        <Typography variant='h2'>Basic Information</Typography>
                      </Box>
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={buttons} justifyContent='flex-end'/>
                    </Box>
                  </Box>
                  <div className='box-content'>
                    <Box className='detail-desc-container'>*Items modified in CMP are highlighted in red.</Box>

                    <Box className='content-inner-wrap' mt={2}>
                      <Box className='detail-list-container'>
                        <Grid className='section' container columnSpacing={2.5}>
                          <Grid item xs={6}>
                            <DetailItem label='Channel ID' value={channelData.channelId}/>
                            <DetailItem label='Channel Type' value={channelData.channelType}/>

                            <Grid item xs={12}>
                              <Box className='content-tab-container'>
                                <Box className='info-value-wrap align-top'>
                                  <Tooltip title={`OriginData : ${channelData.replacedChannelName || channelData.channelName}`} placement='top-start'>
                                    <Typography component='span' className='label' >
                                      Channel Name
                                    </Typography>
                                  </Tooltip>
                                  <Box className='value no-border'>
                                    <Box className='field-box'>
                                      <TextField
                                          id='channel-name-textfield'
                                          type='outlined'
                                          size='large'
                                          placeholder=''
                                          defaultValue={channelData.channelName}
                                          label=''
                                          autoComplete='off'
                                          required={false}
                                          InputLabelProps={{shrink: false}}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>

                            <DetailItem label='Channel Number' value={channelData.channelNumber}/>
                            <DetailItem label='CP Name' value={channelData.cpName}/>
                          </Grid>
                        </Grid>

                        <Grid container columnSpacing={2.5}>
                          <DetailItem
                              label={<>Active Time <br/> Period Start</>}
                              value={channelData.activeTimePeriodStart}
                              xs={6}
                          />
                          <DetailItem
                              label={<>Active Time <br/> Period End</>}
                              value={channelData.activeTimePeriodEnd}
                              xs={6}
                          />

                          <Box display="flex" justifyContent="space-between">
                            <DetailImageItem xs={8} label='Representative Image'
                                             src={channelData.representativeImageList && channelData.representativeImageList.length > 0 ? channelData.representativeImageList[0].originUrl : ''}
                                             uiWidth='768px'
                                             uiHeight='432px'
                                             actualWidth={channelData.representativeImageList && channelData.representativeImageList.length > 0 ? channelData.representativeImageList[0].width : 0}
                                             actualHeight={channelData.representativeImageList && channelData.representativeImageList.length > 0 ? channelData.representativeImageList[0].height : 0}/>
                            <DetailImageItem xs={8} label=''
                                             src={channelData.representativeImageList && channelData.representativeImageList.length > 1 ? channelData.representativeImageList[1].originUrl : ''}
                                             uiWidth='768px'
                                             uiHeight='432px'
                                             actualWidth={channelData.representativeImageList && channelData.representativeImageList.length > 1 ? channelData.representativeImageList[1].width : 0}
                                             actualHeight={channelData.representativeImageList && channelData.representativeImageList.length > 1 ? channelData.representativeImageList[1].height : 0}/>
                          </Box>
                          <DetailItem xs={12} label='Media URL' value={channelData.mediaUrl}/>

                          <DetailImageItem xs={8} label='Logo'
                                           src={channelData.logoImage.originUrl}
                                           uiWidth='600px'
                                           uiHeight='200px'
                                           actualWidth={channelData.logoImage.width}
                                           actualHeight={channelData.logoImage.height}/>

                          <DetailItem
                              xs={6}
                              label='Channel Genre Code'
                              value={channelData.ChannelGenreCode}
                              content={
                                <SelectBox
                                    id='admin-Parents'
                                    size='large'
                                    shrink={false}
                                    label=''
                                    required={false}
                                    selectOptions={GenreCodeOption}
                                    disabled={false}
                                    placeholder='Select'
                                />
                              }
                          />
                          <DetailItem xs={6} label='Keywords' value={channelData.keywords}/>
                          <DetailItem xs={6} label='Adult Flag' value={channelData.adultFlag ? 'Yes' : 'No'}/>
                          <DetailItem xs={6} label='Paid Flag' value={channelData.paidFlag ? 'Yes' : 'No'}/>
                          <DetailItem xs={6} label='AD Flag' value={channelData.adFlag ? 'Yes' : 'No'}/>
                          <DetailItem xs={6} label='Promotion Flag' value={channelData.promotionFlag ? 'Yes' : 'No'}/>
                          <DetailItem xs={6} label='Visible' value={channelData.visible ? 'Yes': 'No'}/>
                          <DetailItem xs={6} label='Last Update' value={channelData.lastUpdateDate}/>
                        </Grid>

                        <Grid container component='section'
                              className='section-container' columnSpacing={2.5}
                              sx={{mt: 5}}>
                          <Grid item xs={12}>
                            <Typography className='sub-title'>Device List</Typography>
                          </Grid>

                          <DetailItem label='Device Name / Version' className='bold' value={channelData.deviceName} xs={6}/>

                        </Grid>
                      </Box>
                    </Box>
                  </div>
                </Box>
            )}
          </Box>
        </Box>

        <Snackbar severity='success' message='success' open={false} duration={1000} bottom={50} darkMode={darkMode}/>
      </Box>
  );
});

MetadataChannelEdit.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    contentId: PropTypes.string.isRequired,
    channelMapId: PropTypes.string,
  }).isRequired,
};

export default MetadataChannelEdit;