import React, { useState } from 'react';
import Button from 'components/component/BasicButton';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { AddPlus, DelMinus } from 'components/component/BasicIcon';
import { Grid, Box } from '@mui/material';

// Data
import { CountrySelectData } from 'pages/Organization/WebService/data/OrganizationData';

// 메인 컨텐츠
function PopChannelAdd() {
  const [isOpen, setIsOpen] = useState(true);

  // States for Tab Name
  const [openSelects, setOpenSelects] = useState([]);
  const changeSetSelects = (index) => {
    const newOpenSelects = [...openSelects];
    newOpenSelects[index] = !newOpenSelects[index];
    setOpenSelects(newOpenSelects);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={isOpen}
        closeX={true}
        title='Channel Add' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='large' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-addChannel'
        contentTextProps={
          <Grid container sx={{ marginLeft: 0 }}>
            <Grid container xs={12} gap={1} alignItems='center'>
              {/* 1st Tab Name */}
              <Grid item xs={2.5}>
                <Box component='div' className='field-box flex-start'>
                  <TextField
                    type='outlined'
                    size='large'
                    placeholder='Ch.No'
                    defaultValue=''
                    label='Ch.No'
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.5}>
                <Box component='div' className='field-box flex-start'>
                  <TextField
                    type='outlined'
                    size='large'
                    placeholder='Ch.ID'
                    defaultValue=''
                    label='Ch.ID'
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.5}>
                <Box component='div' className='field-box flex-start'>
                  <TextField
                    type='outlined'
                    size='large'
                    placeholder='Ch.Name'
                    defaultValue=''
                    label='Ch.Name'
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.5}>
                <Box component='div' className='field-box flex-start'>
                  <SelectBox
                    id='select-tab-name-region-1'
                    size='large'
                    maxWidth='201px'
                    required={false}
                    selectOptions={CountrySelectData}
                    disabled={false}
                    label='DMA Group Name'
                    isFirstSelected={true}
                    open={openSelects[1]}
                    setOpen={() => changeSetSelects(1)}
                    shrink
                  />
                </Box>
              </Grid>
              <Grid container xs={1.2} sx={{ marginLeft: '16px' }}>
                <Grid item>
                  <Button
                    color='tertiary'
                    type='outline icon'
                    size='medium'
                    label=''
                    isIcon={true}
                    Icon={AddPlus}
                    iconPos='center'
                    autosize
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} gap={1} alignItems='center'>
              <Grid item xs={2.5}>
                <Box component='div' className='field-box flex-start'>
                  <TextField
                    type='outlined'
                    size='large'
                    placeholder='Ch.No'
                    defaultValue=''
                    label=''
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.5}>
                <Box component='div' className='field-box flex-start'>
                  <TextField
                    type='outlined'
                    size='large'
                    placeholder='Ch.ID'
                    defaultValue=''
                    label=''
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.5}>
                <Box component='div' className='field-box flex-start'>
                  <TextField
                    type='outlined'
                    size='large'
                    placeholder='Ch.Name'
                    defaultValue=''
                    label=''
                    autoComplete='off'
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2.5}>
                <Box component='div' className='field-box flex-start'>
                  <SelectBox
                    id='select-tab-name-region-2'
                    size='large'
                    maxWidth='201px'
                    required={false}
                    selectOptions={CountrySelectData}
                    disabled={false}
                    label='' //DMA Group Name
                    isFirstSelected={true}
                    open={openSelects[2]}
                    setOpen={() => changeSetSelects(2)}
                    shrink
                  />
                </Box>
              </Grid>
              <Grid container xs={1.2} gap={1} sx={{ marginLeft: '16px' }}>
                <Grid item>
                  <Button
                    color='tertiary'
                    type='outline icon'
                    size='medium'
                    label=''
                    isIcon={true}
                    Icon={AddPlus}
                    iconPos='center'
                    autosize
                  />
                </Grid>
                <Grid item>
                  <Button
                    color='tertiary'
                    type='outline icon'
                    size='medium'
                    label=''
                    isIcon={true}
                    Icon={DelMinus}
                    iconPos='center'
                    autosize
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* 2nd Tab Name */}
          </Grid>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopChannelAdd;
