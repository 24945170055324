import React, { useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';
import { Grid, Box } from '@mui/material';

import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';

// 메인 컨텐츠
function PopChangePassword(props) {
  const { currentUser, isOpen, callbackClose } = props;

  const [password, setPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');

  const handleClose = () => {
    callbackClose(false);
  };

  const handleSave = () => {
    if (password.length < 6) {
      setAlertTitle('Warning');
      setAlertMessage('Please check password length !');
      setAlertOpen(true);
    } else if (password !== reEnterPassword) {
      setAlertTitle('Warning');
      setAlertMessage('Please re-enter exact password !');
      setAlertOpen(true);
    } else {
      setConfirmMessage('Are you sure to change password?');
      setConfirmOpen(true);
    }
  };

  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: handleSave
    },
  ];

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  };

  const handleReEnterPasswordChange = (event) => {
    setReEnterPassword(event.target.value)
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmSave = () => {
    passwordChangeRequest();
    setConfirmOpen(false);
    handleClose();
  };

  const passwordChangeRequest = () => {
    const userInfo = {
      usernameOrEmail: currentUser.name,
      password: password,
    };

    fetch(`${BASE_URL}/api/auth/password`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(userInfo),
    })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Password change error: An error occurred while processing the request`);
      }
      return res.json();
    })
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info');
        setAlertMessage('Password change OK!');
        setAlertOpen(true);
      } else {
        throw new Error(`Password change error: An error occurred while processing the request`)
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    }).finally(() => {});
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: handleConfirmSave
    },
  ];

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div className='component-view'>
      <Dialog
        open={isOpen}
        closeX={true}
        title='Change Password' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={12}>
                <Box component='div' className='field-box'>
                  <TextField
                    id='pwd'
                    type='password'
                    size='large'
                    placeholder='Password'
                    defaultValue=''
                    label='Password'
                    autoComplete='off'
                    required={true}
                    helperText='At least 6 characters'
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyDown}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Box component='div' className='field-box'>
                  <TextField
                    id='pwd-re'
                    type='password'
                    size='large'
                    placeholder='Re-enter Password'
                    defaultValue=''
                    label='Re-enter Password'
                    autoComplete='off'
                    required={true}
                    onChange={handleReEnterPasswordChange}
                    onKeyDown={handleKeyDown}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
      <Dialog
        open={isAlertOpen} // boolean 값으로 설정
        handleClose={handleAlertClose}
        closeX={false}
        size='small' // large, medium, small
        title={alertTitle} // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
      <Dialog
        open={isConfirmOpen} // boolean 값으로 설정
        handleClose={handleConfirmClose}
        closeX={false}
        size='small' // large, medium, small
        title='Confirm' // Popup Title
        contentTitle={false} // Content Title
        bottomDivider={false}
        contentText={confirmMessage}
        buttons={confirmDialogButtons}
      />
    </div>
  );
}

export default PopChangePassword;
