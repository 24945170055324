import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import SearchField from 'components/component/BasicSearchField';
import Pagination from 'components/component/BasicPagination';
import CustomImage from 'components/component/BasicImage';
import SelectBox from 'components/component/BasicSelectBox';
import CheckBox from 'components/component/BasicCheckBox';
import TextField from 'components/component/BasicTextField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CustomBasicMenu from 'components/component/BasicMenu';
import TextLink from 'components/component/BasicTextLink';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { styled } from '@mui/material/styles';

import {
  Box,
  Typography,
  Link,
  Grid,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// ICON
import { BtnArrowRight } from 'components/component/BasicIcon';

// Data
import {
  segOrgTypeOption,
  segOrgCpNameOption,
  segOrgGenreOption,
  SegOrgResultTh,
  SegOrgResultTd,
  SegOrgScheduledTh,
  SegOrgScheduledTd,
} from 'pages/Organization/ANTest/data/SegmentOrganizeData';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import { RefreshIcon } from 'components/component/BasicIcon';
import { useNavigate } from 'react-router-dom';

const Buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Top',
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Bottom',
    autosize: true,
  },
  {
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Delete',
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Save',
    autosize: true,
  },
];

const FilterButtons = [
  {
    color: 'tertiary',
    type: 'outline icon',
    size: 'medium',
    label: 'Reset',
    isIcon: true,
    Icon: RefreshIcon,
    iconPos: 'left',
    autosize: false,
    minWidth: '107px',
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Search',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: false,
    minWidth: '120px',
  },
];

const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function ANTestSegmentOrganizeOrganize() {  // TODO add props
  const navigate = useNavigate();
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'AN Test',
    normalMenu: ['Segment', 'Segment Organize', 'Organize'],
  };

  const infos = [
    {
      index: 1,
      label: '#Recommendation Content',
      value: 'News_Channel',
      divide: true,
    },
    {
      index: 2,
      label: '#Rate',
      value: '50',
      divide: true,
    },
  ];

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  // Table Body Data
  const [rows, setRows] = useState([...SegOrgScheduledTd]);

  const sortedTdRows = sortRows([...SegOrgResultTd], orderBy, order);

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRows(items);
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='#'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/ANTest', {
                    replace: true, state: {
                      move: true,
                      targetComponent: 'ANTestSegmentOrganizeAddGroup',
                      message: null // TODO add info
                    }
                  });
                }}
              >
                Organize
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          <Box className='group-title-area'>
            <Typography className='group-title'>B Group</Typography>
          </Box>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='orgSeg-Type'
                      size='large'
                      shrink={true}
                      label='Type'
                      require={false}
                      selectOptions={segOrgTypeOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* CP Name */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='orgSeg-cp-name'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      require={false}
                      selectOptions={segOrgCpNameOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* Genre */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='orgSeg-Genre'
                      size='large'
                      shrink={true}
                      label='Genre'
                      require={false}
                      selectOptions={segOrgGenreOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>
                  {/* ID */}
                  <Box component='div' className='col'>
                    <TextField
                      id='orgSeg-id-1'
                      type='outlined'
                      size='large'
                      required
                      placeholder='ID'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                  {/* Name */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      id='orgSeg-name-1'
                      type='outlined'
                      size='large'
                      required
                      placeholder='Name'
                      defaultValue={null}
                      label=''
                      autoComplete='off'
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='form-area right'>
                <CustomButtonGroup buttons={FilterButtons} />
              </Box>
            </Box>
          </Box>

          <Grid container columnSpacing={2.5} className='content-pack-area'>
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Result for Content</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label='Ch.no or ch.Name'
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <Button
                        color='tertiary'
                        type='box'
                        size='small'
                        label='Select Ch Input'
                        isIcon={true}
                        autosize
                        Icon={BtnArrowRight}
                        iconPos='right'
                      />
                    </Box>
                  </Box>

                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader sx={{ width: 'auto' }}>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {SegOrgResultTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '24px' }}
                                      />
                                      {index === 0 && (
                                        <CustomBasicMenu
                                          type='icon'
                                          title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                          options={selPageData}
                                        />
                                      )}
                                    </Box>
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {sortedTdRows.map((td, index) => (
                            <TableRow className={`tr ${td.unsaved ? 'unsaved' : ''}`} key={td.id || index}>
                              {SegOrgResultTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align} sx={{ height: '88px' }}>
                                  {colIndex === 0 ? (
                                    <CheckBox label={td[`data0${colIndex + 1}`]} value='value' defaultChecked={false} />
                                  ) : colIndex === 1 ? (
                                    <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                  ) : colIndex === 3 ? (
                                    <CustomImage
                                      src={td[`data0${colIndex + 1}`]}
                                      fit={false}
                                      alt=''
                                      wrapWidth='90px'
                                      wrapHeight='64px'
                                      rounded
                                    />
                                  ) : (
                                    td[`data0${colIndex + 1}`]
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  {/* Pagination */}
                  <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Scheduled Content List</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label='Ch.no or ch.Name'
                        size='medium'
                        maxWidth='230px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={Buttons} justifyContent='center' />
                    </Box>
                  </Box>

                  {/* Selected Content */}

                  <Box className='content-inner-wrap '>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table'>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {SegOrgScheduledTh.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box display='flex' alignContent='center'>
                                          <IconDraggable className='icon-draggable' sx={{ flexShrink: 0 }} />
                                          <CheckBox
                                            label=''
                                            value='select-all'
                                            defaultChecked={false}
                                            inputProps={{
                                              'aria-label': 'select all',
                                            }}
                                            sx={{ marginRight: '4px' }}
                                          />
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'desc'}
                                              onClick={() => handleRequestSort(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}
                              <TableBody className='tbody'>
                                {rows.map((td, index) => (
                                  <Draggable key={td.id || index} draggableId={td.id || index.toString()} index={index}>
                                    {(provided) => (
                                      <TableRow
                                        className='tr'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {SegOrgScheduledTh.map((column, colIndex) => (
                                          <TableCell
                                            key={column.id}
                                            className='td'
                                            align={column.align}
                                            sx={{ height: '88px' }}
                                          >
                                            {colIndex === 0 ? (
                                              <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                <IconDraggable className='icon-draggable' />

                                                <CheckBox
                                                  label={td[`data0${colIndex + 1}`]}
                                                  value='value'
                                                  defaultChecked={false}
                                                />
                                              </Box>
                                            ) : colIndex === 3 ? (
                                              <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                            ) : colIndex === 5 ? (
                                              <CustomImage
                                                src={td[`data0${colIndex + 1}`]}
                                                fit={false}
                                                alt=''
                                                wrapWidth='69px'
                                                wrapHeight='64px'
                                                rounded
                                              />
                                            ) : (
                                              td[`data0${colIndex + 1}`]
                                            )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>

                    {/* Pagination */}
                    <Pagination count={5} id='pagination-02' selOptionData={selOptionData} />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default ANTestSegmentOrganizeOrganize;
