import React, { useEffect, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import SearchField from 'components/component/BasicSearchField';
import SortLabel from 'components/component/BasicTableSortLabel';
import { styled } from '@mui/material/styles';
import { Box, Table, Typography, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { isEmpty } from 'utils/Valid';

const BoxContainer = styled(Box)`
  display: flex;
  gap: 20px;
`;
// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '426px',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function PopDMAGroupNameOutput({ isDialogOpen, closeHandler, dmaList }) {
  const [isOpen, setIsOpen] = useState(true);
  const [sortedDmaChannelRows, setSortedDmaChannelRows] = useState([]);
  const [dmaChannelRows, setDmaChannelRows] = useState([]);
  const [dmaSearchField, setDmaSearchField] = useState('');
  useEffect(() => {
    setSortedDmaChannelRows(dmaList);
    setDmaChannelRows(dmaList);
    // eslint-disable-next-line
  }, [dmaList]);

  const onClose = () => {
    if (closeHandler) {
      setIsOpen(false);
      closeHandler();
    }
  };

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      onClick: onClose,
    },
  ];

  const dmaChannelSort = (col, desc) => {
    setSortedDmaChannelRows(sortRows([...sortedDmaChannelRows], col, desc));
  };
  // States for Tab Name
  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const dmaSearchFieldHandler = (searchText) => {
    setDmaSearchField(searchText);
    let rows = [];
    if (!isEmpty(dmaChannelRows)) {
      rows = [...dmaChannelRows];
      if (!isEmpty(searchText)) {
        rows = rows.filter((search) => isIncludeSearchText(search, searchText));
      }
    }
    if (isEmpty(rows)) {
      setSortedDmaChannelRows([]);
    } else {
      setSortedDmaChannelRows(sortRows(rows, orderBy, order));
    }
  };

  const isIncludeSearchText = (row, searchText) => {
    let dmaChannelNumber = '';
    let dmaChannelName = '';
    let dmaChannelId = '';
    let dmaGroupName = '';
    if (!isEmpty(searchText)) {
      searchText = searchText.toString().toLowerCase();
    }
    if (!isEmpty(row.channelNumber)) {
      dmaChannelNumber = row.channelNumber.toString().toLowerCase();
    }
    if (!isEmpty(row.channelName)) {
      dmaChannelName = row.channelName.toString().toLowerCase();
    }
    if (!isEmpty(row.channelId)) {
      dmaChannelId = row.channelId.toString().toLowerCase();
    }
    if (!isEmpty(row.dmaGroupName)) {
      dmaGroupName = row.dmaGroupName.toString().toLowerCase();
    }
    return dmaChannelNumber.indexOf(searchText) > -1 || dmaChannelName.indexOf(searchText) > -1 || dmaChannelId.indexOf(searchText) > -1 || dmaGroupName.indexOf(searchText) > -1;
  };

  // TableBody Row
  //const sortedChannelCountTdRows = sortRows([...DMAgroupOutputTd], orderBy, order);
  useEffect(() => {
    setIsOpen(isDialogOpen);
    // eslint-disable-next-line
  }, [isDialogOpen]);

  return (
    <div className='component-view'>
      <Dialog
        open={isOpen}
        closeX={true}
        title='DMA Group Name' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={onClose}
        size='large' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-dma-group-name'
        contentTextProps={
          <BoxContainer>
            <Box className='dma-group-name-container'>
              <Box className='top-area' sx={{ display: 'flex', paddingBottom: 1 }}>
                <Box sx={{ width: 230, marginLeft: 'auto' }}>
                  {/* <SearchField placeholder='Ch.ID or Ch.Name' label='' size='medium' required={false} shrink /> */}
                  <SearchField id='search-1' placeholder='Ch.ID or Ch.Name' label='' size='medium' required={false} shrink={false} value={dmaSearchField} setSearchText={dmaSearchFieldHandler} />
                </Box>
              </Box>

              {/* Table */}
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  {/* table Head */}
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      <TableCell key={'dma-channelNumber'} className='th' align={'left'} sx={{ width: '10%' }}>
                        <SortLabel
                          labelText={'Ch.No'}
                          labelId={'channelNumber'}
                          sortable={false}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          order={order}
                          setOrder={setOrder}
                          sorting={dmaChannelSort}
                        />
                      </TableCell>
                      <TableCell key={'dma-channelName'} className='th' align={'left'} sx={{ width: '25%' }}>
                        <SortLabel labelText={'Ch.Name'} labelId={'channelName'} sortable={true} orderBy={orderBy} setOrderBy={setOrderBy} order={order} setOrder={setOrder} sorting={dmaChannelSort} />
                      </TableCell>
                      <TableCell key={'dma-channelId'} className='th' align={'left'} sx={{ width: '30%' }}>
                        <SortLabel labelText={'Ch.ID'} labelId={'channelId'} sortable={true} orderBy={orderBy} setOrderBy={setOrderBy} order={order} setOrder={setOrder} sorting={dmaChannelSort} />
                      </TableCell>
                      <TableCell key={'dma-GroupName'} className='th' align={'left'} sx={{ width: '30%' }}>
                        <SortLabel
                          labelText={'DMA Group Name'}
                          labelId={'dmaGroupName'}
                          sortable={true}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          order={order}
                          setOrder={setOrder}
                          sorting={dmaChannelSort}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* table Body */}
                  {isEmpty(sortedDmaChannelRows) ? (
                    <TableBody className='tbody'>
                      <TableRow className='tr'>
                        <TableCell colSpan={4} className={'td noData'} align='center'>
                          <Box className='no-data-message' sx={{ height: '400px', padding: '50px 0' }}>
                            <Typography>noData</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody className='tbody'>
                      {sortedDmaChannelRows.map((row) => (
                        <TableRow className='tr' key={`dma-row-${row.channelIdx}`}>
                          {/* {DMAgroupNamePopTh.map((column, colIndex) => ( */}
                          <TableCell key={`dma-channel-no-${row.channelIdx}`} className='td' align={'left'}>
                            {row.channelNumber}
                          </TableCell>
                          <TableCell key={`dma-channel-name-${row.channelIdx}`} className='td' align={'left'}>
                            {row.channelName}
                          </TableCell>
                          <TableCell key={`dma-channel-id-${row.channelIdx}`} className='td' align={'left'}>
                            {row.channelId}
                          </TableCell>
                          <TableCell key={`dma-group-name-${row.channelIdx}`} className='td' align={'left'}>
                            {row.dmaGroupName}
                          </TableCell>
                          {/* ))} */}
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </CustomTableContainer>
            </Box>
          </BoxContainer>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopDMAGroupNameOutput;
