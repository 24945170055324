import React, { useEffect, useMemo, useRef, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import BasicSwitches from 'components/component/BasicSwitch';

import { Grid, Typography } from '@mui/material';

import { Box } from '@mui/system';
import { getAreaCodesByCountry, getAreaTypesByCountry, updateEditArea } from '../api/restApi';
import { areaTypeOption } from '../data/OrganizationData';

function PopEditArea(props) {
  const { onClose, editWebProArea, selectedCountry, regionOption, countryOption,
    selectedRegion, areaCodeList, handelSetShowSnakBar, getTData, selectedStatus } = props;

  const [createPRArea, setcreatePRArea] = useState({
    idx: editWebProArea.idx,
    region: selectedRegion,
    country: selectedCountry,
    areaCode: editWebProArea.areaCode,
    areaName: editWebProArea.areaName,
    enable: editWebProArea.enable,
    areaType: editWebProArea.areaType,
    selectedStatus: selectedStatus,
  });
  const [countryAreaCodes, setCountryAreaCodes] = useState([]);
  const [countryAreaTypes, setCountryAreaTypes] = useState([]);
  const initialCountryRef = useRef(null);
  const initialAreaTypeRef = useRef(null);

  const onChangeRegion = (eValue) => {
    const value = eValue;
    let defaultCountry = countryOption[value][0].value;
    if (value === "All") {
      defaultCountry =
        countryOption[eValue][countryOption[eValue].length - 1].value;
    }
    setcreatePRArea((pewvState) => ({
      ...pewvState,
      region: value,
      country: defaultCountry,
    }));
  };

  const onChangeCountry = (value) => {
    setcreatePRArea((pewvState) => ({
      ...pewvState,
      country: value,
    }));
  };

  const onChangeAreaCode = (value) => {
    setcreatePRArea((pevState) => ({
      ...pevState,
      areaCode: value,
    }));
  };

  const onChangeAreaName = (event) => {
    const value = event.target.value;
    setcreatePRArea((pevState) => ({
      ...pevState,
      areaName: value,
    }));
  };

  const onChangeAreaType = (value) => {
    setcreatePRArea((pevState) => ({
      ...pevState,
      areaType: value,
    }));
  };

  const onChangeEnable = (checked) => {
    const value = checked ? "ON" : "OFF";
    setcreatePRArea((pevState) => ({
      ...pevState,
      enable: value,
    }));
  };

  const handleClose = () => {
    onClose();
  };

  const onCreateAreaCancelBtn = () => {
    handleClose();
  };

  const onCreateAreaSaveBtn = () => {
    clickSave();
  };

  const getAreaCodesByCountryData = async (country) => {
    let areaCodes = [];
    const res = await getAreaCodesByCountry(country);

    areaCodeList.forEach((c) => {
      const isSelected =
        c.value === createPRArea.areaCode &&
        initialCountryRef.current === country;
      const isDisabled = res.data.indexOf(c.value) > -1 && !isSelected;
      areaCodes.push({
        ...c,
        disabled: isDisabled,
      });
    });
    setCountryAreaCodes(areaCodes);
  };

  const getAreaTypesByCountryData = async (country) => {
    const resAreaTypes = await getAreaTypesByCountry(country);
    setCountryAreaTypes(resAreaTypes);
  };

  const clickSave = async () => {
    const createAreaData = { ...createPRArea };
    const isValidate = await validateSave();
    if (!isValidate) {
      return;
    } else {
      const res = await updateEditArea(createAreaData);
      handleClose();
      getTData();
      handelSetShowSnakBar({
        open: true,
        message: res.message,
        severity: res.severity,
      });
    }
  };

  const validateSave = async () => {
    if (initialCountryRef.current !== createPRArea.country) {
      if (countryAreaTypes.data.includes(createPRArea.areaType)) {
        handelSetShowSnakBar({
          open: true,
          message: "Same Area Type already exists",
          severity: "error",
        });
        return false;
      }
      return true;
    }
    const isSelected =
      initialAreaTypeRef.current === createPRArea.areaType &&
      initialCountryRef.current === selectedCountry;
    if (isSelected) {
      return true;
    } else {
      if (countryAreaTypes.data.includes(createPRArea.areaType)) {
        handelSetShowSnakBar({
          open: true,
          message: "Same Area Type already exists",
          severity: "error",
        });
        return false;
      }
      return true;
    }
  };

  const isActive =
    createPRArea.region &&
    createPRArea.country &&
    createPRArea.areaCode &&
    createPRArea.areaType &&
    createPRArea.areaName?.trim() !== "";

  const dialogButtons = useMemo(() => {
    return [
      {
        width: "150px",
        size: "large",
        label: "Cancel",
        type: "outline",
        color: "tertiary",
        onClick: onCreateAreaCancelBtn,
        disabled: false,
      },
      {
        width: "150px",
        size: "large",
        label: "Save",
        type: "box",
        color: "primary",
        onClick: onCreateAreaSaveBtn,
        disabled: !isActive || selectedStatus === "P",
      },
    ];
    // eslint-disable-next-line
  }, [isActive, createPRArea]);

  useEffect(() => {
    getAreaCodesByCountryData(createPRArea.country);
    getAreaTypesByCountryData(createPRArea.country);
    // eslint-disable-next-line
  }, [createPRArea.country]);

  useEffect(() => {
    if (!initialCountryRef.current) {
      initialCountryRef.current = createPRArea.country;
    }
    if (!initialAreaTypeRef.current) {
      initialAreaTypeRef.current = createPRArea.areaType;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Edit Area'
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium'
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='switch-with-label-box field-box'>
                  <Typography component='span' className='field-label bold'>
                    Enable
                  </Typography>
                  <BasicSwitches
                    disabled={false}
                    onOffMode={createPRArea.enable === 'ON' ? true : false}
                    setSwitchOnOff={onChangeEnable}
                    isLabelText={true}
                    onText='On'
                    offText='Off'
                    value={createPRArea.enable === 'ON' ? true : false}
                  />
                </Box>
              </Grid>

              <Grid item xs={6} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-01'
                    fullWidth
                    size='large'
                    width='100%'
                    selectOptions={regionOption}
                    disabled={false}
                    label='Region'
                    selected={createPRArea.region}
                    onChange={onChangeRegion}
                    shrink
                  />
                </Box>
              </Grid>

              <Grid item xs={6} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-01'
                    label='Country'
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    disabled={false}
                    selectOptions={countryOption[createPRArea.region]}
                    selected={createPRArea.country}
                    onChange={onChangeCountry}
                    shrink
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-01'
                    label='Area Code'
                    selected={createPRArea.areaCode}
                    onChange={onChangeAreaCode}
                    fullWidth
                    size='large'
                    width='100%'
                    required={false}
                    selectOptions={countryAreaCodes}
                    disabled={false}
                    shrink
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <TextField
                    type='outlined'
                    label='Area Name*'
                    defaultValue={createPRArea.areaName}
                    onChange={onChangeAreaName}
                    size='large'
                    required={true}
                    placeholder='ex) Area Name'
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-01'
                    label='Area Type'
                    selected={createPRArea.areaType}
                    onChange={onChangeAreaType}
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    selectOptions={areaTypeOption}
                    disabled={false}
                    shrink
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopEditArea;