import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import Pagination from 'components/component/BasicPagination';

import {
  Box,
  Link,
  ListItem,
  List,
  Divider,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { styled } from '@mui/material/styles';
// Icon

import { SegmentTh, SegmentTd } from 'pages/Organization/ANTest/data/ANTestdata';
import { useNavigate } from 'react-router-dom';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function ANTestSegment() {  // TODO add props
  const navigate = useNavigate();
  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'AN Test',
    normalMenu: ['Segment'],
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const sortedTdRows = sortRows([...SegmentTd], orderBy, order);

  const infos = [
    {
      index: 1,
      label: '#Device Type',
      value: 'tv',
      divide: true,
    },
    {
      index: 2,
      label: '#Country',
      value: 'US',
      divide: true,
    },
    {
      index: 3,
      label: '#Tab',
      value: 'T-WATCHNOW Home',
      divide: true,
    },
    {
      index: 4,
      label: '#Category',
      value: 'Featured',
      divide: true,
    },
    {
      index: 5,
      label: '#Date',
      value: '2024.09.28 01:00 ~ 2024.09.30 02:00',
      divide: true,
    },
    {
      index: 6,
      label: '#Status',
      value: 'Ready',
      divide: false,
    },
  ];

  const onSegmentClick = () => {
    navigate('/ANTest', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestSegmentOrganize',
        message: null, // TODO add clicked segment info + alpha
      }
    });
  }

  const onOrganizeClick = () => {
    navigate('/ANTest', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestSegmentOrganizeAddGroup',
        message: null, // TODO add clicked segment info + alpha
      }
    });
  }

  const onDeleteClick = () => {
    console.log('delete');
    // TODO add delete logic, popup open
  }

  const onResultClick = () => {
    navigate('/ANTest', {
      replace: true, state: {
        move: true,
        targetComponent: 'ANTestResult',
        message: null, // TODO add clicked segment info + alpha
      }
    });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container category-tab-list' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='#'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/ANTest', {
                    replace: true, state: {
                      move: true,
                      targetComponent: 'ANTest',
                      message: null // TODO add info
                    }
                  });
                }}
              >
                Segment
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          <Box className='white-box white-box-wrap single-box'>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <Box className='box-title-area' display='flex' alignItems='center'>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        445
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <Button color='tertiary' type='box' size='small' label='Segment' isIcon={false} autosize onClick={onSegmentClick}/>
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {SegmentTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {SegmentTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 1 ? (
                                <div>
                                  {td.data02 === 'y' ? ( // result
                                    <span className='greenCircle'></span>
                                  ) : td.data02 === 'default' ? ( // default
                                    '-'
                                  ) : td.data02 === 's' ? ( // if update result
                                    <span className='greenCircle edit'></span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              ) : colIndex === 6 ? (
                                <Box display='flex' gap={1} flexWrap={'wrap'}>
                                  {td.data07.map((buttonData, index) => (
                                    <Button
                                      key={index}
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label={buttonData.btnText}
                                      disabled={buttonData.disabled}
                                      isIcon={false}
                                      autosize
                                      onClick={() => {
                                        if (buttonData.btnText === 'ORGANIZE')  onOrganizeClick();
                                        else if (buttonData.btnText === 'DELETE') onDeleteClick();
                                        else if (buttonData.btnText === 'RESULT') onResultClick();
                                      }}
                                    />
                                  ))}
                                </Box>
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ANTestSegment;
