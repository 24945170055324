// src/data.js
export const GenreCodeOption = [
  { id: 'sport', value: 'sport', option: 'sport', type: 'text' },
  { id: 'Genre-2', value: 'Genre-2', option: 'Genre-2', type: 'text' },
  { id: 'Genre-3', value: 'Genre-3', option: 'Genre-3', type: 'text' },
];

export const selectOption = [
  {id: 'all', value: 'All', option: 'All', type: 'text'},
  {
    id: 'channelNumber',
    value: 'channelNumber',
    option: 'Channel Number',
    type: 'text'
  },
  {id: 'channelId', value: 'channelId', option: 'Channel Id', type: 'text'},
  {
    id: 'channelName',
    value: 'channelName',
    option: 'Channel Name',
    type: 'text'
  },
];

export const MetaChannelTh = [
  {
    id: 'logoUrl',
    label: 'Logo',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: false,
  },
  {
    id: 'channelId',
    label: 'Channel ID',
    align: 'left',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelNumber',
    label: 'Channel No.',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelName',
    label: 'Channel Name',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'cpName',
    label: 'CP Name',
    align: 'left',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'lastUpdateDate',
    label: 'LastUpdated',
    align: 'left',
    width: '16%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'ch-74156',
    label: 'Action',
    align: 'left',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];

export const MetaVodTh = [
  {
    id: 'cpName',
    label: 'CP Name',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodId',
    label: 'VOD ID',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTitle',
    label: 'VOD Title',
    align: 'left',
    width: '24%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'duration',
    label: 'Duration(sec)',
    align: 'left',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'episodeNumber',
    label: 'Episode No.',
    align: 'left',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'releaseDate',
    label: 'Release Date',
    align: 'left',
    width: '12%',
    sortable: true,
  },
  {
    id: 'vod-Action',
    label: 'Action',
    align: 'left',
    width: 'auto',
    sortable: true,
  },
];
