export const selectTypeOption = [
  { id: "live", value: "live", option: "live", type: "text" },
];

export const ContentTypeVodList = [
  {
    id: "tvshow",
    value: "tvshow",
    option: "tvshow",
    type: "text",
  },
  {
    id: "movie",
    value: "movie",
    option: "movie",
    type: "text",
  },
  {
    id: "tvshow-series",
    value: "tvshow-series",
    option: "tvshow-series",
    type: "text",
  },
];

export const ContentListTh = [
  {
    id: "type",
    align: "left",
    label: "Type",
    width: "13%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "cp",
    align: "left",
    label: "CP",
    width: "12%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "id",
    align: "left",
    label: "ID",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "name",
    align: "left",
    label: "Name",
    width: "25%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "image",
    align: "left",
    label: "Image",
    width: "18%",
    checkbox: false,
    sortable: true,
    searchable: false,
  },
  {
    id: "genre",
    align: "left",
    label: "Genre",
    width: "11%",
    checkbox: false,
    sortable: true,
  },
];

export const SelectedContentTh = [
  {
    id: "resultOrder",
    align: "left",
    label: "Order",
    width: "20%",
    checkbox: true,
    sortable: true,
  },
  {
    id: "type",
    align: "left",
    label: "Type",
    width: "12%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "cp",
    align: "left",
    label: "CP",
    width: "10%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "id",
    align: "left",
    label: "ID",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "name",
    align: "left",
    label: "Name",
    width: "25%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "genre",
    align: "left",
    label: "genre",
    width: "12%",
    checkbox: false,
    sortable: true,
  },
];
