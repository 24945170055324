import * as actions from 'actions/actionTypes';

const initialState = { deviceType:'tv', region: 'US', status: 'Saved-Testing' , platformVersion: '3.4.0' };

export function param(state = initialState, action) {
  switch (action.type) {
    case actions.SET_HOMEAPP_PARAM:
      if (action.payload) {
        return action.payload;
      } else {
        return state;
      }
    default:
      return state;
  }
}