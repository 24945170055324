import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import FileUpload from 'components/component/BasicFileUpload';
import SelectBox from 'components/component/BasicSelectBox';
import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import Pagination from 'components/component/BasicPagination';
import Tooltip from 'components/component/BasicTooltip';
import CheckBox from 'components/component/BasicCheckBox';
import SearchField from 'components/component/BasicSearchField';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import { Box, Typography, Link, Divider, List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Menu, MenuItem } from '@mui/material';
import { multiRequest } from 'utils/Request';
// Data
import { PrimaryInfoIcon } from 'components/component/BasicIcon';
import { BulkUploadTh, BulkUploadTd, selectOption, chCountListData } from 'pages/Organization/ChannelMapping/data/ChannelMappingData';
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

const CellType = styled(TableCell)(({ theme }) => ({
  '& .success': {
    color: '#0077B5',
  },
  '& .fail': {
    color: '#B91C1C',
  },
  '& .update': {
    color: '#0D9488',
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',

    '.dark &': {
      backgroundColor: '#1b1b1b',
      border: '1px solid #484848',
    },
    '& .MuiMenu-list': {
      padding: '4px 0',
      border: 0,
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: '#ddd',
      },
    },
  },
}));

// 메인 컨텐츠
function BulkUploadSaved({ darkMode, message }) {
  const [channelMapStatus, setChannelMapStatus] = useState('T');
  const [channelMapCountryCode, setChannelMapCountryCode] = useState('');
  const [bulkFileName, setBulkFileName] = useState('');

  useEffect(() => {
    console.log('message', message);
    if (message) {
      setChannelMapCountryCode(message.countryCode);
      setChannelMapStatus('T');
      setBulkFileName('');
    }
  }, [message]);

  useEffect(() => {
    console.log('channelMapStatus:', channelMapStatus);
  }, [channelMapStatus]);

  const ChannelMapStatusOption = [
    { id: 'BulkStatus1', value: 'T', option: 'Testing', type: 'text' },
    { id: 'BulkStatus2', value: 'P', option: 'published', type: 'text' },
  ];

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Channel Mapping',
    normalMenu: ['Bulk Upload'],
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  // Table Body Data
  const sortedBulkUploadTdRows = sortRows([...BulkUploadTd], orderBy, order);
  const [openSearch, setOpenSearch] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Table view line
  const selOptionData = [
    { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
    { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
    { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
    { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
  ];

  const onFileChangeHandler = async (file) => {
    if (file) {
      let ext = file.name.split('.').pop().toLowerCase();
      if (!['xls', 'xlsx', 'csv'].includes(ext)) {
        alert('xls, xlsx 파일만 업로드 할수 있습니다.');
        return;
      }
      setBulkFileName(file.name);
      let requestBody = new FormData();
      requestBody.append('bulkFile', file);
      // TODO list
      const body = await multiRequest('POST', `/channel-map/bulk/${channelMapCountryCode}`, requestBody);
      if (body && body.result === 'SUCCESS') {
        let bulkFileList = body.data.map((item, index) => {
          const { physicalRowNum, logicalRowNum, columnLength, dataArray } = item;
          return {
            index: logicalRowNum,
            rowNumber: physicalRowNum,
            columnLength: columnLength,
            dataArray: dataArray,
          };
        });
        // TODO
        console.log('bulkFileList', bulkFileList);
      }
    }
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container bulk-upload' component='main'>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Bulk Upload
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <Box className='info-output-container'>
                <Box className='left-area'>
                  {/* 카테고리 정보 출력 영역 */}
                  <List className='info-list-container'>
                    {/* TODO
                    {infos.map((item) => (
                      <React.Fragment key={item.index}>
                        <ListItem className='list-item'>
                          <Typography className='label'>{item.label}</Typography>
                          <Typography className='value'>{item.value}</Typography>
                        </ListItem>
                        {item.divide && <Divider className='divider' />}
                      </React.Fragment>
                    ))} */}
                    <ListItem className='list-item'>
                      <Typography className='label'>#Country</Typography>
                      <Typography className='value'>{channelMapCountryCode}</Typography>
                    </ListItem>
                    <Divider className='divider' />
                  </List>
                </Box>
                <Box className='right-area'></Box>
              </Box>
            </Box>
            <Box className='right-area'></Box>
          </Box>
          <Box className='white-box single-box filter-box'>
            <Box gap={1} className='bulk-container'>
              <Box className='left-area'>
                <Box display='flex' gap={1}>
                  <SelectBox
                    id='Status-01'
                    fullWidth
                    required={false}
                    size='large'
                    maxWidth='244px'
                    selectOptions={ChannelMapStatusOption}
                    disabled={false}
                    label='Status'
                    isFirstSelected={false}
                    // open={openSelect1}
                    // setOpen={setOpenSelect1}
                    selected={channelMapStatus}
                    onChange={setChannelMapStatus}
                    shrink
                  />
                  <Button color='primary' type='box' size='medium' label='Download Testing channel Map' isIcon={false} autosize />
                </Box>
              </Box>

              <Box className='right-area'>
                <Box className='upload-area' display='flex' alignItems='center' sx={{ width: '540px' }}>
                  <FileUpload
                    id='bulkFileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
                    buttonLabel='Upload'
                    size='large'
                    placeholder='File Upload(Only .xlsx files can be uploaded.)'
                    value={bulkFileName}
                    onFileChange={(file) => onFileChangeHandler(file)}
                    accept='.xls, .xlsx, .csv'
                    style={{ width: '100%' }}
                  />

                  {/* Icon Tooltip */}
                  <Tooltip placement='top' title='Tooltip' disabled={true}>
                    <IconButton size='small' sx={{ width: '24px', height: '24px', marginLeft: '24px' }}>
                      <PrimaryInfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <List className='notice-text-list'>
                  <ListItem className='list-item'>*All existing data will be deleted, and Data from excel will be uploaded.</ListItem>
                  <ListItem className='list-item'>*Always download the latest channel map to app,modify, or delete channels.</ListItem>
                  <ListItem className='list-item'>
                    *The existing channel map will be downloaded in Testing/Published version, and when the file is uploaded, the data will be saved in the selected status
                  </ListItem>
                  <ListItem className='list-item'>*Items marked with * in excel are mandatory and cannot be left blank.</ListItem>
                  <ListItem className='list-item'>*Items marked with ** in excel are mandatory for DMA channels and cannot be left blank.</ListItem>
                </List>
              </Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                {/* left-area */}
                <Box className='left-area column'>
                  <Box display='flex' gap={1}>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        6
                      </Box>
                    </Box>
                    <Box className='sum-count-container'>
                      <Typography component='span' mr={1} className='text'>
                        Upload result :
                      </Typography>
                      <List className='count-list'>
                        <ListItem className='count-item'>
                          Success <span className='count-value green'>41</span>
                        </ListItem>
                        <ListItem className='count-item' sx={{ margin: '0 8px' }}>
                          |
                        </ListItem>
                        <ListItem className='count-item'>
                          Failed <span className='count-value red'>2</span>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SelectBox
                      id='select-01'
                      maxWidth='180px'
                      fullWidth
                      size='medium'
                      selectOptions={selectOption}
                      disabled={false}
                      label=''
                      isFirstSelected={true}
                      open={openSearch}
                      setOpen={setOpenSearch}
                      shrink
                    />

                    <SearchField id='search-1' placeholder='Please enter a search term' label='Ch.no or ch.Name' maxWidth='230px' size='medium' required={false} shrink={false} />
                  </Box>
                </Box>
                <Box className='right-area'>
                  <Button color='primary' type='box' size='small' label='Save' isIcon={false} autosize />
                  <div className='type-button-dropdown'>
                    <Button color='' type='only-icon' size='small' label='' isIcon={false} className='btn-layer-popup' onClick={handleClick} />

                    <StyledMenu
                      id='demo-customized-menu'
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      className='btn-drop-paper'
                      PaperProps={{
                        sx: {
                          maxHeight: '230px',
                        },
                      }}>
                      <li>
                        {/* Channel Count  */}
                        <div className='text-label'>
                          <span>
                            Add or remove columns <br /> (Channel map id)
                          </span>
                        </div>
                      </li>

                      {chCountListData.map((list) => (
                        <MenuItem key={list.id}>{list.type === 'checkbox' && <CheckBox label={list.label} value={list.label} defaultChecked={false} />}</MenuItem>
                      ))}
                    </StyledMenu>
                  </div>
                </Box>
                {/* right-area */}
              </Box>

              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {BulkUploadTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            <>
                              <TableSortLabel active={orderBy === column.id} direction={orderBy === column.id ? order : 'desc'} onClick={() => handleRequestSort(column.id)}>
                                {column.label}
                              </TableSortLabel>
                            </>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedBulkUploadTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {BulkUploadTh.map((column, colIndex) => (
                            <CellType key={column.id} className='td' align={column.align}>
                              {colIndex === 9 ? (
                                <span className={td.data10.type === 'success' ? 'success' : td.data10.type === 'fail' ? 'fail' : ''}>{td.data10.text}</span>
                              ) : (
                                td[`data${colIndex + 1}`]
                              )}
                            </CellType>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>
              <Pagination id='pagination-01' count={5} selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>

      {/*
      Toast Pop-Up
      severity: default, error, warning, info, success
      */}
      <Snackbar severity='warning' message='Status 와 파일명_상태가 일치해야합니다.' open={false} duration={1000} bottom={'8%'} darkMode={darkMode} />
    </Box>
  );
}
export default BulkUploadSaved;
