import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';

const ChannelFilter = React.memo(({
  regionOptions,
  countryOptions,
  deviceOptions,
  channelMapOptions,
  cpNameOptions,
  buttons,
  handleSelectChange
}) => {
  const selectFields = [
    {
      id: 'channel-filter-region',
      name: 'region',
      label: 'Region',
      options: regionOptions
    },
    {
      id: 'channel-filter-country',
      name: 'country',
      label: 'Country',
      options: countryOptions
    },
    {
      id: 'channel-filter-device',
      name: 'device',
      label: 'Device Type',
      options: deviceOptions
    },
    {
      id: 'channel-filter-channel-map',
      name: 'channelMap',
      label: 'Channel Map ID',
      options: channelMapOptions
    },
    {
      id: 'channel-filter-cp-name',
      name: 'cpName',
      label: 'CP Name',
      options: cpNameOptions
    }
  ];

  const textFields = [
    {placeholder: 'Channel No', name: 'channelNumber'},
    {placeholder: 'Channel ID', name: 'channelId'},
    {placeholder: 'Channel Name', name: 'channelName'}
  ];

  const handleSelectChangeWrapper = (name, value) => {
    try {
      handleSelectChange(name, value);
    } catch (error) {
      console.error(`Error handling select change for ${name}:`, error);
    }
  };

  return (
      <Box component='div' className='white-box filter-box'>
        <Box className='form-wrap-box'>
          <div className='form-area left'>
            <Box component='div' className='component-wrap col-5'>
              {selectFields.slice(0, 4).map(field => (
                  <Box component='div' className='col' key={field.id}>
                    <SelectBox
                        id={field.id}
                        name={field.name}
                        size='large'
                        shrink={true}
                        label={field.label}
                        selectOptions={field.options}
                        onChange={(e) => handleSelectChangeWrapper(field.name,
                            e)}
                        aria-label={field.label}
                    />
                  </Box>
              ))}
            </Box>
            <Box component='div' className='component-wrap col-5'>
              {textFields.map(field => (
                  <Box component='div' className='col' key={field.name}>
                    <TextField
                        type='outlined'
                        size='large'
                        placeholder={field.placeholder}
                        autoComplete='off'
                        InputLabelProps={{shrink: false}}
                        onChange={(e) => handleSelectChangeWrapper(field.name,
                            e.target.value)}
                        aria-label={field.placeholder}
                    />
                  </Box>
              ))}
              <Box component='div' className='col'>
                <SelectBox
                    id={selectFields[4].id}
                    name={selectFields[4].name}
                    fullWidth
                    size='large'
                    width='100%'
                    selectOptions={selectFields[4].options}
                    label={selectFields[4].label}
                    shrink
                    onChange={(e) => handleSelectChangeWrapper(
                        selectFields[4].name, e)}
                    aria-label={selectFields[4].label}
                />
              </Box>
            </Box>
          </div>
          <div className='form-area right'>
            <CustomButtonGroup buttons={buttons}/>
          </div>
        </Box>
      </Box>
  );
});

ChannelFilter.propTypes = {
  regionOptions: PropTypes.array.isRequired,
  countryOptions: PropTypes.array.isRequired,
  deviceOptions: PropTypes.array.isRequired,
  channelMapOptions: PropTypes.array.isRequired,
  cpNameOptions: PropTypes.array.isRequired,
  buttons: PropTypes.array.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
};

ChannelFilter.defaultProps = {
  regionOptions: [],
  countryOptions: [],
  deviceOptions: [],
  channelMapOptions: [],
  cpNameOptions: [],
  buttons: [],
  handleSelectChange: () => {
  },
};

export default ChannelFilter;