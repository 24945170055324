import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import SelectBox from "../../../components/component/BasicSelectBox";
import {SchedulesTh} from "./data/ChannelStatusData";
import DatePicker from "../../../components/component/BasicDatePicker";
import Button from "../../../components/component/BasicButton";
import CustomImage from "../../../components/component/BasicImage";
import SearchField from "../../../components/component/BasicSearchField";
import CheckBox from "../../../components/component/BasicCheckBox";
import TableSortLabel from "@mui/material/TableSortLabel";
import React, {useEffect, useState} from "react";
import { connect } from "react-redux";

import { styled } from '@mui/material/styles';
import dayjs from "dayjs";
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";
import PopProgramDetail from "./PopProgramDetail";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '',
  overflowY: 'auto',
}));

function ProgramScheduleList({ country, provider }) {

  const [countryByRegion, setCountryByRegion] = useState({});
  const [paramRegion, setParamRegion] = useState([]);
  const [paramCountry, setParamCountry] = useState([]);
  const [paramChannelMapId, setParamChannelMapId] = useState([]);
  const [paramProvider, setParamProvider] = useState([]);
  const [paramChannelName, setParamChannelName] = useState([]);
  const [paramDate, setParamDate] = useState(dayjs());
  const [paramProgramSearch, setParamProgramSearch] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState('All')
  const [selectedCountry, setSelectedCountry] = useState('US')
  const [selectedChannelMapId, setSelectedChannelMapId] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [selectedChannelName, setSelectedChannelName] = useState('All');
  const [selectedChannelInfo, setSelectedChannelInfo] = useState({});
  const [scheduleInfo, setScheduleInfo] = useState([]);
  const [scheduleTableInfo, setScheduleTableInfo] = useState([]);
  const [selectedProgramSearch, setSelectedProgramSearch] = useState('All');
  const [programSearchTextInput, setProgramSearchTextInput] = useState('');

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [programDetailOpen, setProgramDetailOpen] = useState(false);
  const [popupProgramId, setPopupProgramId] = useState('');

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedSchedulesTdRows = sortRows([...scheduleTableInfo], orderBy, order);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (country.serviceCountry) {
      let region = [...new Set(country.serviceCountry.map(area => area.bizArea))].sort();
      const regions = region.map(reg => ({id: reg, value: reg, option: reg, type: 'text'}));
      setParamRegion([{ id: 'All', value: 'All', option: 'All', type: 'text' }, ...regions])

      let reduce = country.serviceCountry.reduce((acc, cur) => {
        const { bizArea, countryCode } = cur;
        if (!acc[bizArea]) {
          acc[bizArea] = []
        }
        acc[bizArea].push(countryCode)
        acc['All'].push(countryCode)
        return acc
      }, {'All': []});
      Object.keys(reduce).forEach(region => {
        reduce[region].sort()
      })
      setCountryByRegion(reduce)

      let countries = reduce['All'].map(country => (
        { id: country, value: country, option: country, type: 'text' }
      ));
      setParamCountry(countries)
    }
  }, [country]);

  useEffect(() => {
    if (selectedRegion && countryByRegion[selectedRegion]) {
      const b = countryByRegion[selectedRegion].map(country => (
        { id: country, value: country, option: country, type: 'text'}
      ))
      setSelectedCountry(countryByRegion[selectedRegion][0])
      setParamCountry(b)
    }
    // eslint-disable-next-line
  }, [selectedRegion]);

  useEffect(() => {
    if (provider && provider.country) {
      let find = provider.country.find(item => item.countryCode === selectedCountry);
      if (find.providerList) {
        let providers = find.providerList.map(item => ({
          id: item.providerId,
          value: item.providerId,
          option: item.providerId,
          type: 'text'
        }));
        setParamProvider([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...providers])
      }
    }
    if (country && country.serviceCountry) {
      const find = country.serviceCountry.find(item => item.countryCode === selectedCountry);
      setParamChannelMapId(find.channelMapList.map(item => (
        { id: item.channelMapId, value: item.channelMapId, option: item.channelMapId, type: 'text' }
      )))
      setSelectedChannelMapId(find.channelMapList[0].channelMapId)
    }
    // eslint-disable-next-line
  }, [selectedCountry]);


  useEffect(() => {
    const channelNames = scheduleInfo.map(schedule => (
      { id: schedule.channelName, value: schedule.channelName, option: schedule.channelName, type: 'text' }
    ))
    setParamChannelName(channelNames)
    if (scheduleInfo.length > 0) {
      const channelName = scheduleInfo[0].channelName
      setSelectedChannelName(channelName);

      setChannelAndScheduleInfoByChannelName(channelName)
    }
    // eslint-disable-next-line
  }, [scheduleInfo]);

  useEffect(() => {
    setChannelAndScheduleInfoByChannelName(selectedChannelName)
    // eslint-disable-next-line
  }, [selectedChannelName]);


  const setChannelAndScheduleInfoByChannelName = (channelName) => {
    const channel = scheduleInfo.find(schedule => schedule.channelName === channelName);
    setSelectedChannelInfo(channel);

    if (channel && channel.schedules) {
      const tableInfo = channel.schedules.map((schedule, idx) => (
        {
          id: schedule.startDateTime, time: schedule.startDateTime.substring(11, 16), programId: schedule.programId,
          title: schedule.programTitle
        }
      ));
      setScheduleTableInfo(tableInfo);

      const titles = new Set(channel.schedules.map(schedule => schedule.programTitle))
      const programs = [...titles].map(title => (
        { id: title, value: title, option: title, type: 'text' }
      ));
      setParamProgramSearch([{ id: 'All', value: 'All', option: 'All', type: 'text' }, ...programs]);
    }
  }

  useEffect(() => {
    if (paramDate && selectedChannelMapId && selectedProvider) {
      getSchedules();
    }
    // eslint-disable-next-line
  }, [paramDate, selectedChannelMapId, selectedProvider]);

  const getSchedules = () => {
    const param = `?countryCode=${selectedCountry}&channelMapId=${selectedChannelMapId}&providerId=${selectedProvider}&date=${paramDate}`;
    fetch(`${BASE_URL}/channel-status/channel/schedules${param}`, {
      method: 'GET',
      headers: getHeaders()
    })
      .then(res => res.json())
      .then(body => {
        const schedules = body.data;
        // eslint-disable-next-line
        schedules.map(schedule => {
          schedule.schedules.sort((a, b) => new Date(a.endDateTime) - new Date(b.endDateTime))
        });
        setScheduleInfo(schedules);
      })
  }

  useEffect(() => {
    if (selectedChannelInfo && selectedChannelInfo.schedules) {
      if (selectedProgramSearch === 'All') {
        const tableInfo = selectedChannelInfo.schedules.map(schedule => (
          {
            id: schedule.startDateTime, time: schedule.startDateTime.substring(11, 16),
            programId: schedule.programId,
            title: schedule.programTitle
          }
        ));
        setScheduleTableInfo(tableInfo);
      } else {
        const tableInfo = selectedChannelInfo.schedules
          .filter(schedule => schedule.programTitle === selectedProgramSearch)
          .map(schedule => (
            {
              id: schedule.startDateTime, time: schedule.startDateTime.substring(11, 16),
              programId: schedule.programId,
              title: schedule.programTitle
            }
          ));
        setScheduleTableInfo(tableInfo);
      }
    }
    // eslint-disable-next-line
  }, [selectedProgramSearch]);

  useEffect(() => {
    if (selectedChannelInfo && selectedChannelInfo.schedules) {
      const filtered = selectedChannelInfo.schedules
        .filter(schedule => schedule.programId.toLowerCase().includes(programSearchTextInput.toLowerCase())
          || schedule.programTitle.toLowerCase().includes(programSearchTextInput.toLowerCase()))
        .map(schedule => ({
          id: schedule.startDateTime, time: schedule.startDateTime.substring(11, 16),
          programId: schedule.programId,
          title: schedule.programTitle
        }));
      setScheduleTableInfo(filtered);
    }
    // eslint-disable-next-line
  }, [programSearchTextInput]);

  const programDialogOpen = (programId) => {
    setPopupProgramId(programId);
    setProgramDetailOpen(true);
  }

  return (
    <div className='tab-panel schedule'>
      <Box className='panel-wrap'>
        {/* 필터영역 */}
        <Box component='div' className='white-box filter-box'>
          <Box className='form-wrap-box'>
            <Box className='form-area left'>
              {/* 필터영역 : 컴포넌트 개수에 따라 col-3,col-4, col-5  */}
              <Box className='component-wrap col-5'>
                <Box component='div' className='col'>
                  <SelectBox
                    id='select-business'
                    size='large'
                    shrink={true}
                    label='Region'
                    required={false}
                    selectOptions={paramRegion}
                    disabled={false}
                    placeholder='Region'
                    selected={selectedRegion}
                    onChange={setSelectedRegion}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='tab2-country'
                    size='large'
                    shrink={true}
                    label='Country'
                    required={true}
                    selectOptions={paramCountry}
                    disabled={false}
                    isFirstSelected={true}
                    placeholder='Country'
                    selected={selectedCountry}
                    onChange={setSelectedCountry}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='channel-map'
                    size='large'
                    shrink={true}
                    label='Channel Map ID'
                    required={false}
                    selectOptions={paramChannelMapId}
                    disabled={false}
                    placeholder='Channel Map ID'
                    selected={selectedChannelMapId}
                    onChange={setSelectedChannelMapId}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='select-cp'
                    size='large'
                    shrink={true}
                    label='CP Name'
                    selectOptions={paramProvider}
                    disabled={false}
                    placeholder='CP Name'
                    selected={selectedProvider}
                    onChange={setSelectedProvider}
                  />
                </Box>
                <Box component='div' className='col'>
                  <SelectBox
                    id='tab2-channel-name'
                    size='large'
                    shrink={true}
                    label='Channel Name'
                    selectOptions={paramChannelName}
                    disabled={false}
                    placeholder='Channel Name'
                    selected={selectedChannelName}
                    onChange={setSelectedChannelName}
                  />
                </Box>
                <Box component='div' className='col auto'>
                  <DatePicker
                    className='input-date-picker'
                    format='MM/DD/YYYY'
                    size='large'
                    shrink
                    label='Date'
                    singleDate={true}
                    sx={{width: '250px'}}
                    value={paramDate}
                    onChange={(newValue) => setParamDate(newValue)}
                  />
                  <Button
                    color='tertiary'
                    type='outline'
                    size='medium'
                    label='Today'
                    isIcon={false}
                    autosize
                    onClick={() => setParamDate(dayjs())}
                  />
                </Box>
              </Box>
            </Box>
            <div className='form-area right'></div>
          </Box>
        </Box>

        <Box className='white-box white-box-wrap'>
          <Box component='div' className='title-wrap space-between flex-end'>
            <Box component='div' className='left-area'>
              <Box component='div' className='monthly-picks-wrap'>
                <div className='img'>
                  <CustomImage
                    src={selectedChannelInfo ? selectedChannelInfo.channelLogoUrl : ''}
                    fit={true}
                    wrapWidth='120px'
                    wrapHeight='64px'
                    imgWidth='120px'
                    imgHeight='64px'
                    alt=''
                    rounded
                  />
                </div>
                <Box component='div' className='box-top-label'>
                  <Box component='span' className='label'>
                    {selectedChannelInfo ? selectedChannelInfo.channelName : ''}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box component='div' className='right-area'>
              <Box display='flex' justifyContent='flex-end' gap={1} sx={{width: '100%'}}>
                <SelectBox
                  id='select-search'
                  size='medium'
                  selected={selectedProgramSearch}
                  selectOptions={paramProgramSearch}
                  label=''
                  maxWidth='180px'
                  disabled={false}
                  placeholder=''
                  onChange={setSelectedProgramSearch}
                />
                {/* 검색영역 */}
                <SearchField
                  id='search-1'
                  maxWidth='230px'
                  placeholder='Please enter a search term'
                  size='medium'
                  value={programSearchTextInput}
                  onChange={(e) => {setProgramSearchTextInput(e.target.value)}}
                />
              </Box>
            </Box>
          </Box>

          <Box className='box-content'>
            <Box className='content-inner-wrap'>
              {/* Table */}
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  {/* table Head */}
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      {SchedulesTh.map((column) => (
                        <TableCell
                          key={column.id}
                          className='th'
                          align={column.align}
                          sx={{width: column.width}}
                        >
                          {column.checkbox ? (
                            <>
                              <CheckBox
                                label=''
                                value='select-all'
                                defaultChecked={false}
                                inputProps={{
                                  'aria-label': 'select all',
                                }}
                              />
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </>
                          ) : (
                            <>
                              {column.sortable ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* table Body */}
                  <TableBody className='tbody'>
                    {sortedSchedulesTdRows.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        {SchedulesTh.map(function (column, colIndex) {
                          return <TableCell key={column.id} className='td' align={column.align}>
                            {colIndex === 0 ? (
                              td[`${column.id}`]
                            ) : colIndex === 1 ? (
                              td[`${column.id}`]
                            ) : colIndex === 2 ? (
                              td[`${column.id}`]
                            ) : (
                              <Button
                                color='tertiary'
                                type='outline'
                                size='small'
                                label='Detail'
                                isIcon={false}
                                Icon=''
                                sx={{minWidth: 'auto'}}
                                onClick={() => programDialogOpen(td.programId)}
                              />
                            )}
                          </TableCell>;
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
              {/* // Table */}
            </Box>

            {/*<Pagination id='pagination-01' count={5} selOptionData={selOptionData}/>*/}
          </Box>
        </Box>
      </Box>
      <PopProgramDetail open={programDetailOpen} close={() => setProgramDetailOpen(false)} programId={popupProgramId} />
    </div>
  )
}

const mapStateToProps = state => ({
  country: state.common.country,
  provider: state.common.provider,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramScheduleList);