import imgDummy02 from 'assets/images/img_dummy_02.png';
import imgDummy03 from 'assets/images/img_dummy_03.png';
import imgDummy04 from 'assets/images/img_dummy_04.png';
import imgDummy05 from 'assets/images/img_dummy_05.png';
import imgDummy06 from 'assets/images/img_dummy_06.png';
import imgDummy07 from 'assets/images/img_dummy_07.png';

export const selOptionCheckData = [
  {
    id: 'all',
    value: 'all',
    option: 'All',
    type: 'checkbox',
    defaultChecked: false,
  },
  {
    id: 1,
    value: 'option1',
    option: 'Option 1',
    type: 'checkbox',
    defaultChecked: false,
  },
  {
    id: 2,
    value: 'option2',
    option: 'Option 2',
    type: 'checkbox',
    defaultChecked: false,
  },
  {
    id: 3,
    value: 'option3',
    option: 'Option 3',
    type: 'checkbox',
    defaultChecked: false,
  },
  {
    id: 4,
    value: 'option4',
    option: 'Option 4',
    type: 'checkbox',
    defaultChecked: false,
  },
];

export const selOptionBusinessData2 = [
  {
    id: 1,
    value: 'radio1',
    option: 'radio 1',
    type: 'radio',
    defaultChecked: true,
  },
  {
    id: 2,
    value: 'radio2',
    option: 'radio 2',
    type: 'radio',
    defaultChecked: false,
  },
  {
    id: 3,
    value: 'radio3',
    option: 'radio 3',
    type: 'radio',
    defaultChecked: false,
  },
];

export const selOptionBusinessData3 = [
  { id: 1, value: 'option1', option: 'Option 1', type: 'text' },
  { id: 2, value: 'option2', option: 'Option 2', type: 'text' },
  { id: 3, value: 'option3', option: 'Option 3', type: 'text' },
];

export const vodTabs = [
  { label: 'Basic Information', badgeType: '' },
  { label: 'Media & Image', badgeType: '' },
  { label: 'Season Information', badgeType: '' },
];

export const imageListData = [
  {
    group: 'Still Cut',
    size: '16x9 / 1920x1080',
    type: 'image / jpeg',
    imgUrl: imgDummy02,
    imgLink:
      'https://d31l2nn7dlh4li.cloudfront.net/amg00497/asset_artifacts/image/fetch_media/71550946fa0809b9/AllDogsGoToHeaven_1920x1080.jpg',
  },
  {
    group: 'Thumbnail',
    size: '16x9 / 432x768',
    type: 'image / jpeg',
    imgUrl: imgDummy03,
    imgLink:
      'https://d31l2nn7dlh4li.cloudfront.net/amg00497/asset_artifacts/image/fetch_media/a5d848d909fb4376/AllDogsGoToHeaven_768x432.jpg',
  },
  {
    group: 'Still Cut',
    size: '16x9 / 1920x1080',
    type: 'image / jpeg',
    imgUrl: imgDummy04,
    imgLink:
      'https://d31l2nn7dlh4li.cloudfront.net/amg00497/asset_artifacts/image/fetch_media/71550946fa0809b9/AllDogsGoToHeaven_1920x1080.jpg',
  },
  {
    group: 'Still Cut',
    size: '16x9 / 1920x1080',
    type: 'image / jpeg',
    imgUrl: imgDummy05,
    imgLink:
      'https://d31l2nn7dlh4li.cloudfront.net/amg00497/asset_artifacts/image/fetch_media/71550946fa0809b9/AllDogsGoToHeaven_1920x1080.jpg',
  },
  {
    group: 'Thumbnail',
    size: '16x9 / 432x768',
    type: 'image / jpeg',
    imgUrl: imgDummy06,
    imgLink:
      'https://d31l2nn7dlh4li.cloudfront.net/amg00497/asset_artifacts/image/fetch_media/a5d848d909fb4376/AllDogsGoToHeaven_768x432.jpg',
  },
];

export const mediaListData = [
  {
    container: 'HLS',
    mediaDrmFlag: 'False',
    type: 'image / jpeg',
    imgUrl: imgDummy07,
    imgLink:
      'https://ad-inserter.alphonso.tv/v2/vod/9af7e24f-d182-11ee-9447-a4badb444bd1/master.m3u8?acuid=979fa058-67bd-11ed-9447-a4badb444bd1&cid=22b22734-5d66-11ec-bf63-0242ac130002&device_id=[DEVICE_ID]&ifa=[IFA]&lat=[LMT]&dns=[DNS]&ua=[UA]&ip=[IP]&gdpr=[GDPR]&gdpr_consent=[GDPR_CONSENT]&country=[COUNTRY]&us_privacy=[US_PRIVACY]&app_store_url=[APP_STOREURL]&bundle_id=[APP_BUNDLE]&app_name=[APP_NAME]&ifa_type=[IFA_TYPE]&app_version=[APP_VERSION]&device_type=[DEVICE_TYPE]&device_make=[DEVICE_MAKE]&device_model=[DEVICE_MODEL]&target_ad=[TARGETAD_ALLOWED]',
  },
];

export const seasonData = {
  seasonID: 'WFkTWI0Bt8pUOqhbJHa3',
  seriesID: 'S1kTWI0Bt8pUOqhbIXZq',
  genreCode: '1',
  seasonNumber: '7',
  TotalEpisode: '17',
  CaptionFlag: 'false',
};

export const ChannelCountTh = [
  {
    id: 'countryCode',
    width: '8%',
    label: 'Country',
    align: 'left',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'providerId',
    width: '14%',
    label: 'CP Name',
    align: 'left',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelMapId',
    width: '20%',
    label: 'Channel Map ID',
    align: 'left',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'publishCount',
    width: '15%',
    label: 'Published',
    align: 'right',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'savedPublishCount',
    width: '15%',
    label: 'Save for Publish',
    align: 'right',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'testCount',
    width: '15%',
    label: 'Test',
    align: 'right',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'savedTestCount',
    width: '15%',
    label: 'Save for Test',
    align: 'right',
    checkbox: false,
    sortable: true,
  },
];

export const ChannelCountTd = [
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '1',
  },
  {
    data01: 'US',
    data02: 'AMG',
    data03: 'US-ME-6.0.0',
    data04: '1',
    data05: '1',
    data06: '1',
    data07: '10',
  },
];

export const channelData = {
  channelID: 'newid_rt036',
  channelNo: 135,
  channelName: '뭉쳐야 찬다3',
  cpName: 'Amagi',
  activeTimeStart: '-',
  activeTimeEnd: '-',
  ChannelGenreCode: '23',
  AdultFlag: 'false',
};

export const programData = {
  programID: '5f6241e3ae4310001abb2465',
  duration: 3600,
  title: 'Aurora',
  Description:
    'Lorenzo y Martín consiguen nuevamente salvar a César de la muerte. Aurora logra rehacer su vida con la promesa de hacer feliz al hombre que ama, pero antes necesita el perdón de Martín. Catalina no perdona que Martín haya reanimado a Pasión Urquijo.',

  ChannelGenreCode: '23',
  CPName: 'Amagi',
  EpisodeTitle: '11',
  EpisodeNo: '22',
  SeasonTitle: 'Lorem et erat aliquyam sed',
  SeasonNo: '73',
  FirstGenreCode: '0',
  SecondGenreCode: '0',
};
