// src/data.js

export const activityData = [
  {
    id: 201,
    user: 'Tail Lim',
    Ch_name: 'Channel Mapping',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Channel Mapping',
    updateContent: 'Country: US / Feed: [NEW] NEW ID / Channel Map: US-ME-6.0.0 / Channel map Status: Saved - Testing',
    date: '2024.07.16 6:35 PM',
    status: 'Testing',
    chip_type: 'testing',
    chip_label: 'Testing',
  },
  {
    id: 202,
    user: 'Tail Lim',
    Ch_name: 'Tab/Category',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Tab/Category',
    updateContent: 'Device Type: TV / Region: US(US) / Platform version: 3.4.0 / Status: Saved - Published',
    date: '2024.07.16 6:35 PM',
    chip_type: 'publishing',
    chip_label: 'Publishing',
  },
  {
    id: 203,
    user: 'Tail Lim',
    Ch_name: 'Channel Mapping',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Channel Mapping',
    updateContent: 'Country: US / Feed: [NEW] NEW ID / Channel Map: US-ME-6.0.0 / Channel map Status: Saved - Testing',
    date: '2024.07.16 6:35 PM',
    chip_type: 'testing',
    chip_label: 'Testing',
  },
  {
    id: 204,
    user: 'Tail Lim',
    Ch_name: 'Tab/Category',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Tab/Category',
    updateContent: 'Device Type: TV / Region: US(US) / Platform version: 3.4.0 / Status: Saved - Published',
    date: '2024.07.16 6:35 PM',
    chip_type: 'testing',
    chip_label: 'Testing',
  },
  {
    id: 205,
    user: 'Tail Lim',
    action: 'updated the Channel Mapping',
    location: 'HOME > ORGANIZATION > Channel Mapping',
    current_location: '',
    updateContent: 'Country: US / Feed: [NEW] NEW ID / Channel Map: US-ME-6.0.0 / Channel map Status: Saved - Testing',
    date: '2024.07.16 6:35 PM',
    chip_type: 'publishing',
    chip_label: 'Publishing',
  },
  {
    id: 206,
    user: 'Tail Lim',
    action: 'updated the Tab/Category',
    location: 'HOME > ORGANIZATION > ',
    current_location: 'Tab/Category',
    updateContent: 'Device Type: TV / Region: US(US) / Platform version: 3.4.0 / Status: Saved - Published',
    date: '2024.07.16 6:35 PM',
    chip_type: 'testing',
    chip_label: 'Testing',
  },
];

export const tabs = [
  { label: 'All', badgeType: 'new' },
  { label: 'Update', badgeType: 'new' },
  { label: 'Manual', badgeType: '' },
  { label: 'Service', badgeType: '' },
  { label: 'Work', badgeType: '' },
];

export const NotiDatas = [
  {
    id: 301,
    type: '[Update]',
    content: 'The channel mapping manual has been updated.',
    new: true,
    date: '2024.07.15',
    addFile: true,
  },
  {
    id: 302,
    type: '[service]',
    content: 'Version: 1.2.0',
    new: true,
    date: '2024.07.15',
    addFile: false,
  },
  {
    id: 303,
    type: '[Work]',
    content: 'The channel mapping manual has been updated.',
    new: false,
    date: '2024.07.15',
    addFile: true,
  },
  {
    id: 304,
    type: '[Manual]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
    addFile: false,
  },
  {
    id: 305,
    type: '[Manual]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
    addFile: false,
  },
  {
    id: 306,
    type: '[Manual]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
    addfile: false,
  },
  {
    id: 307,
    type: '[Manual]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
    addfile: false,
  },
  {
    id: 308,
    type: '[Manual]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
    addfile: false,
  },
  {
    id: 309,
    type: '[Manual]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
    addfile: false,
  },
  {
    id: 310,
    type: '[Manual]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
    addfile: false,
  },
];

export const NotiDatas2 = [
  {
    id: 31,
    type: '[Manual]',
    content: 'The channel mapping manual has been updated.',
    new: false,
    date: '2024.07.15',
  },
  {
    id: 32,
    type: '[Release]',
    content: 'Version: 1.2.0',
    new: true,
    date: '2024.07.15',
  },
  {
    id: 33,
    type: '[Manual]',
    content: 'The channel mapping manual has been updated.',
    new: false,
    date: '2024.07.15',
  },
];

export const NotiDatas3 = [
  {
    id: 34,
    type: '[Manual]',
    content: 'The channel mapping manual has been updated.',
    new: false,
    date: '2024.07.15',
  },
  {
    id: 35,
    type: '[Release]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
  },
  {
    id: 36,
    type: '[Manual]',
    content: 'The channel mapping manual has been updated.',
    new: false,
    date: '2024.07.15',
  },
  {
    id: 37,
    type: '[Release]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
  },
];

export const NotiDatas4 = [
  {
    id: 41,
    type: '[Manual]',
    content: 'The channel mapping manual has been updated.',
    new: false,
    date: '2024.07.15',
  },
  {
    id: 42,
    type: '[Release]',
    content: 'Version: 1.2.0',
    new: true,
    date: '2024.07.15',
  },
  {
    id: 43,
    type: '[Manual]',
    content: 'The channel mapping manual has been updated.',
    new: false,
    date: '2024.07.15',
  },
  {
    id: 44,
    type: '[Release]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
  },
  {
    id: 45,
    type: '[Release]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
  },
  {
    id: 46,
    type: '[Release]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
  },
  {
    id: 47,
    type: '[Release]',
    content: 'Version: 1.2.0',
    new: false,
    date: '2024.07.15',
  },
];
