import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import TextField from 'components/component/BasicTextField';
import SearchField from 'components/component/BasicSearchField';
import Button from 'components/component/BasicButton';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import { RefreshIcon } from 'components/component/BasicIcon';
// data
import { RoleOption, CPOption, selectOption, AdminTh, AdminTd } from 'pages/Configuration/Account/data/AccountData';

// Button
const FilterButtons = [
  {
    color: 'tertiary',
    type: 'outline icon',
    size: 'medium',
    label: 'Reset',
    isIcon: true,
    Icon: RefreshIcon,
    iconPos: 'left',
    autosize: false,
    minWidth: '107px',
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Search',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: false,
    minWidth: '120px',
  },
];
const crudButtons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Delete',
    isIcon: false,
    autosize: true,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Add',
    isIcon: false,
    autosize: true,
  },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function Account() {
  const optionMenu = {
    gnbMenu: 'Configuration',
    lnbMenu: 'Account',
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // 테이블 본문에 정렬된 데이터
  const sortedAccountTdRows = sortRows([...AdminTd], orderBy, order);

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container pr-page-container' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Account
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        {/*  //BreadCrumb Area */}

        <Box className='content-wrap ' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Role */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Role'
                      required={false}
                      selectOptions={RoleOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* CP Code */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='CP Code'
                      required={false}
                      selectOptions={CPOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* Id */}
                  <Box component='div' className='col'>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='ID'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      required={true}
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'>
                <CustomButtonGroup buttons={FilterButtons} />
              </div>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap'>
            <div className='box-content'>
              <Box className='white-box-content-top-area' alignItems='flex-end'>
                <Box className='left-area column'>
                  <Box className='total-count'>
                    <Box component='span' className='label'>
                      Total
                    </Box>
                    <Box component='span' className='value'>
                      6
                    </Box>
                  </Box>
                  <Box display='flex' gap={1}>
                    <SelectBox
                      id='select-search'
                      size='medium'
                      selectOptions={selectOption}
                      label=''
                      maxWidth='180px'
                      disabled={false}
                      placeholder=''
                    />

                    <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                  </Box>
                </Box>

                <Box className='right-area'>
                  <CustomButtonGroup buttons={crudButtons} justifyContent='flex-end' />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {AdminTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />

                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  // 체크박스만 있을 때도 label 표시
                                  <span className='chk-th'>{column.label}</span>
                                )}
                              </>
                            ) : column.sortable ? (
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            ) : (
                              column.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedAccountTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {AdminTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 0 ? (
                                <Box display='flex'>
                                  <CheckBox label='' value='value' defaultChecked={false} />
                                  <CustomImage
                                    src={td[`data0${colIndex + 1}`]}
                                    fit={false}
                                    alt=''
                                    wrapWidth='96px'
                                    wrapHeight='64px'
                                    rounded
                                  />
                                </Box>
                              ) : colIndex === 5 ? (
                                <Button
                                  color='tertiary'
                                  type='outline'
                                  size='small'
                                  label='CHANGE'
                                  isIcon={false}
                                  Icon=''
                                  iconPos={undefined}
                                  autosize
                                />
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Account;
