import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';

import {
  Box,
  Link,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Grid,
} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';

import { styled } from '@mui/material/styles';
// Icon

import { AnServiceResultTh, AnServiceResultTd } from 'pages/Organization/ANService/data/ANServicedata';
import {
  columns,
  columns2,
  columns3,
  columns4,
  tdData,
  dataset,
  chartSetting,
  dataRate,
  dataDevice,
} from 'pages/Organization/ANService/data/ANServiceResultdata';
import { useNavigate } from 'react-router-dom';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// DetailItem 컴포넌트 생성
const DetailItem = ({ label, value, maxThWidth = false, isButton }) => (
  <Box className='list-wrap'>
    <Grid display='flex' item xs={12} alignItems='center'>
      {maxThWidth === true ? (
        <Grid item xs={3}>
          <Typography component='span' className='label'>
            {label}
          </Typography>
        </Grid>
      ) : (
        <Typography component='span' className='label'>
          {label}
        </Typography>
      )}
      <Box className='value-wrap' display={'flex'}>
        <Box className='value'>
          <Typography component='span' className='text'>
            {value}
          </Typography>
        </Box>
        {isButton && <Button color='primary' type='box' size='small' label='Edit' isIcon={false} autosize />}
      </Box>
    </Grid>
  </Box>
);

// 메인 컨텐츠
function ANServiceResult() { // TODO add props
  const navigate = useNavigate();
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'AN Service',
    normalMenu: ['Segment', 'Segment Organize', 'AN Service Result'],
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const sortedTdRows = sortRows([...AnServiceResultTd], orderBy, order);

  // Basic Information Data
  const ANTestData = {
    title: 'genre-segment daily automation',
    Country: 'US',
    Category: 'C337 Featured Channel',
    SegmentID: 'AD-LGCH-MOVIE-0193',
    SegmentType: 'AdTS Segment',
    SegmentDescription:
      'Only the Movies Genre Watcher 1+ Hour in the last 30 days. (The Genre watched more than 10% of all genres in homeapp channel)',
    Time: '2024-09-30 02:00:00 ~ 2024-10-07  01:00:00 ',
    ContentPolicy: '-',
    Validity: '-',
    Reason: { value: '-', isButton: true },
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href='#'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/ANService', {
                    replace: true, state: {
                      move: true,
                      targetComponent: 'ANServiceSegment',
                      message: null // TODO add info
                    }
                  });
                }}
              >
                AN Service Result
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap height-auto' component='div'>
          {/* First */}
          <Box className='white-box white-box-wrap auto-box'>
            <Box className='box-content'>
              <Box className='content-inner-wrap no-page-number'>
                <Box className='detail-list-container'>
                  <Grid container columnSpacing={2.5}>
                    <Grid item xs={12}>
                      <DetailItem label='title' className='' value={ANTestData.title} />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailItem label='Country' className='' value={ANTestData.Country} />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailItem label='Category' className='' value={ANTestData.Category} />
                    </Grid>

                    <Grid item xs={6}>
                      <DetailItem label='Segment ID' className='' value={ANTestData.SegmentID} />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailItem label='Segment Type' className='' value={ANTestData.SegmentType} />
                    </Grid>
                    <Grid item xs={12}>
                      <DetailItem label='Segment Description' className='' value={ANTestData.SegmentDescription} />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailItem label='Time (UTC)' className='' value={ANTestData.Time} />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailItem label='Content Policy' className='' value={ANTestData.ContentPolicy} />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailItem label='Validity' className='' value={ANTestData.Validity} />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailItem
                        label='Reason'
                        value={ANTestData.Reason.value}
                        isButton={ANTestData.Reason.isButton}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap auto-box'>
            <Box className='box-content'>
              <Box className='content-inner-wrap no-page-number'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {AnServiceResultTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {AnServiceResultTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {colIndex === 8 ? (
                                <span className={td.data09 === 'ready' ? 'ready' : td.data09 === 'done' ? 'done' : ''}>
                                  {td.data09 === 'ready' ? 'Ready' : td.data09 === 'done' ? 'Done' : ''}
                                </span>
                              ) : colIndex === 9 ? (
                                <Box display='flex' gap={1}>
                                  {td.data10.map((buttonData, index) => (
                                    <Button
                                      key={index}
                                      color='tertiary'
                                      type='outline'
                                      size='small'
                                      label={buttonData.btnText}
                                      disabled={buttonData.disabled}
                                      isIcon={false}
                                      autosize
                                    />
                                  ))}
                                </Box>
                              ) : (
                                td[`data0${colIndex + 1}`]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>
            </Box>
          </Box>

          {/* Data Table */}
          <Box className='white-box white-box-wrap auto-box'>
            <Box className='box-content'>
              <Box className='content-inner-wrap no-page-number'>
                <Box className='data-table-container'>
                  <Box className='data-table-view'>
                    {/* Data */}

                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table data-table' stickyHeader style={{ overflowX: 'auto' }}>
                        <TableHead className='thead'>
                          <TableRow>
                            {columns.map((col, index) => (
                              <TableCell
                                className='th'
                                key={index}
                                rowSpan={col.rowspan}
                                colSpan={col.colspan}
                                align='center'
                                style={{ minWidth: col.minWidth, fontWeight: col.fontWeight }}
                              >
                                {col.label}
                              </TableCell>
                            ))}
                          </TableRow>

                          {/* Individual / Cumulative */}
                          <TableRow>
                            {columns2.map((col, index) => (
                              <TableCell
                                className='th'
                                key={index}
                                rowSpan={col.rowspan}
                                colSpan={col.colspan}
                                align='center'
                                style={{ minWidth: col.minWidth }}
                              >
                                {col.label}
                              </TableCell>
                            ))}
                          </TableRow>

                          {/* Header 3 */}
                          <TableRow>
                            {columns3.map((col, index) => (
                              <TableCell
                                className='th'
                                colSpan={col.colspan}
                                key={`ind-${index}`}
                                align='center'
                                style={{ minWidth: col.minWidth }}
                              >
                                {col.label}
                              </TableCell>
                            ))}
                          </TableRow>
                          {/* Header 4 */}
                          <TableRow>
                            {columns4.map((col, index) => (
                              <TableCell
                                className='th'
                                key={`ind-${index}`}
                                align='center'
                                style={{ minWidth: col.minWidth }}
                              >
                                {col.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody className='tbody'>
                          {tdData.map((row, index, bgColor) => (
                            <TableRow key={index}>
                              <TableCell className='td' align='center'>
                                {row.day}
                              </TableCell>
                              <TableCell className='td' align='left'>
                                {row.dayOfWeek}
                              </TableCell>
                              <TableCell className='td' align='center'>
                                {row.weekday ? <span className='greenCircle'></span> : ''}
                              </TableCell>
                              <TableCell className='td'>{row.startTime}</TableCell>
                              <TableCell className='td'>{row.endTime}</TableCell>

                              {/* Individual data */}
                              <TableCell className='td yellow' align='right'>
                                {row.individual?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.individual?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.individual?.C ?? 'N/A'}
                              </TableCell>
                              <TableCell
                                className='td blue'
                                align='right'
                                style={{ backgroundColor: 'rgba(33, 150, 243, 0.10)' }}
                              >
                                {row.individual?.AvsB ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.individual?.PValue ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.individual?.BvsC ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.individual?.PValue2 ?? 'N/A'}
                              </TableCell>

                              {/* Cumulative data */}
                              <TableCell className='td yellow' align='right'>
                                {row.cumulative?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.cumulative?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.cumulative?.C ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.cumulative?.AvsB ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.cumulative?.BvsC ?? 'N/A'}
                              </TableCell>

                              {/* Content Clicks > Category  */}
                              <TableCell className='td yellow' align='right'>
                                {row.category?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.category?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.category?.C ?? 'N/A'}
                              </TableCell>

                              {/* Content Clicks > Tab  */}
                              <TableCell className='td yellow' align='right'>
                                {row.tab?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.tab?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.tab?.C ?? 'N/A'}
                              </TableCell>

                              {/* Content Clicks > All  */}
                              <TableCell className='td yellow' align='right'>
                                {row.all?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.all?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.all?.C ?? 'N/A'}
                              </TableCell>

                              {/* Content Clicks > Clicks Rate  */}
                              <TableCell className='td yellow' align='right'>
                                {row.ClicksRate?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.ClicksRate?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.ClicksRate?.C ?? 'N/A'}
                              </TableCell>

                              {/* Content Clicks > Improved  */}
                              <TableCell className='td blue' align='right'>
                                {parseFloat(row.Improved?.AvsB) < 0 ? (
                                  <span className='zero-under'>{row.Improved?.AvsB}</span>
                                ) : (
                                  'N/A'
                                )}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.Improved?.PValue === 'x' ? <span className='iconX'></span> : 'N/A'}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.Improved?.BvsC ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.Improved?.PValue2 === 'x' ? <span className='iconX'></span> : 'N/A'}
                              </TableCell>

                              {/* Content Clicks > Cumulative data > Category */}
                              <TableCell className='td yellow' align='right'>
                                {row.ContentClicksCategory?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.ContentClicksCategory?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.ContentClicksCategory?.C ?? 'N/A'}
                              </TableCell>

                              {/* Content Clicks > Cumulative data > Tab */}
                              <TableCell className='td yellow' align='right'>
                                {row.ContentClicksTab?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.ContentClicksTab?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.ContentClicksTab?.C ?? 'N/A'}
                              </TableCell>

                              {/* Content Clicks > Cumulative data > All */}
                              <TableCell className='td yellow' align='right'>
                                {row.ContentClicksAll?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.ContentClicksAll?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.ContentClicksAll?.C ?? 'N/A'}
                              </TableCell>

                              {/* Content Clicks > Cumulative data > Clicks Rate */}
                              <TableCell className='td yellow' align='right'>
                                {row.ContentClicksClicksRate?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.ContentClicksClicksRate?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.ContentClicksClicksRate?.C ?? 'N/A'}
                              </TableCell>

                              {/* Content Clicks > Cumulative data > Improved */}
                              <TableCell className='td blue' align='right'>
                                {parseFloat(row.ContentClicksClicksImproved?.AvsB) < 0 ? (
                                  <span className='zero-under'>{row.ContentClicksClicksImproved?.AvsB}</span>
                                ) : (
                                  row.ContentClicksClicksImproved?.AvsB || 'N/A'
                                )}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {parseFloat(row.ContentClicksClicksImproved?.BvsC) < 0 ? (
                                  <span className='zero-under'>{row.ContentClicksClicksImproved?.BvsC}</span>
                                ) : (
                                  row.ContentClicksClicksImproved?.BvsC || 'N/A'
                                )}
                              </TableCell>

                              {/* Clicked Unique Device > Individual > Category */}
                              <TableCell className='td yellow' align='right'>
                                {row.UniqueDeviceCategory?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.UniqueDeviceCategory?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.UniqueDeviceCategory?.C ?? 'N/A'}
                              </TableCell>

                              {/* Clicked Unique Device > Individual > Tab */}
                              <TableCell className='td yellow' align='right'>
                                {row.UniqueDeviceTab?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.UniqueDeviceTab?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.UniqueDeviceTab?.C ?? 'N/A'}
                              </TableCell>

                              {/* Clicked Unique Device > Individual > Home-App */}
                              <TableCell className='td yellow' align='right'>
                                {row.UniqueDeviceHomeApp?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.UniqueDeviceHomeApp?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.UniqueDeviceHomeApp?.C ?? 'N/A'}
                              </TableCell>

                              {/* Clicked Unique Device > Individual > UD Rate */}
                              <TableCell className='td yellow' align='right'>
                                {row.UniqueDeviceUDRate?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.UniqueDeviceUDRate?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.UniqueDeviceUDRate?.C ?? 'N/A'}
                              </TableCell>

                              {/* Clicked Unique Device > Individual > Improved */}
                              <TableCell className='td blue' align='right'>
                                {row.UniqueDeviceImproved?.AvsB ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.UniqueDeviceImproved?.PValue === 'check' ? (
                                  <span className='iconCheck'></span>
                                ) : (
                                  'N/A'
                                )}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.UniqueDeviceImproved?.BvsC ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {row.UniqueDeviceImproved?.PValue2 === 'x' ? <span className='iconX'></span> : 'N/A'}
                              </TableCell>

                              {/* Clicked Unique Device > Cumulative > Category */}
                              <TableCell className='td yellow' align='right'>
                                {row.UniqueDeviceCumulativeCategory?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.UniqueDeviceCumulativeCategory?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.UniqueDeviceCumulativeCategory?.C ?? 'N/A'}
                              </TableCell>

                              {/* Clicked Unique Device > Cumulative > Tab */}
                              <TableCell className='td yellow' align='right'>
                                {row.UniqueDeviceCumulativeTab?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.UniqueDeviceCumulativeTab?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.UniqueDeviceCumulativeTab?.C ?? 'N/A'}
                              </TableCell>

                              {/* Clicked Unique Device > Cumulative > HomeApp */}
                              <TableCell className='td yellow' align='right'>
                                {row.UniqueDeviceCumulativeHomeApp?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.UniqueDeviceCumulativeHomeApp?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.UniqueDeviceCumulativeHomeApp?.C ?? 'N/A'}
                              </TableCell>

                              {/* Clicked Unique Device > Cumulative > UD Rate */}
                              <TableCell className='td yellow' align='right'>
                                {row.UniqueDeviceCumulativeUDRate?.A ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td red' align='right'>
                                {row.UniqueDeviceCumulativeUDRate?.B ?? 'N/A'}
                              </TableCell>
                              <TableCell className='td green' align='right'>
                                {row.UniqueDeviceCumulativeUDRate?.C ?? 'N/A'}
                              </TableCell>

                              {/* Clicked Unique Device > Cumulative > Improved */}
                              <TableCell className='td blue' align='right'>
                                {parseFloat(row.UniqueDeviceCumulativeImproved?.AvsB) < 0 ? (
                                  <span className='zero-under'>{row.UniqueDeviceCumulativeImproved?.AvsB}</span>
                                ) : (
                                  row.UniqueDeviceCumulativeImproved?.AvsB || 'N/A'
                                )}
                              </TableCell>
                              <TableCell className='td blue' align='right'>
                                {parseFloat(row.UniqueDeviceCumulativeImproved?.BvsC) < 0 ? (
                                  <span className='zero-under'>{row.UniqueDeviceCumulativeImproved?.BvsC}</span>
                                ) : (
                                  row.UniqueDeviceCumulativeImproved?.BvsC || 'N/A'
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Chart */}
          <Box display='flex' gap={1.5} flexWrap={'nowrap'} pb={3}>
            <Box flexGrow={1}>
              <Box className='white-box white-box-wrap chart-box'>
                <Box className='box-content chart-item'>
                  <BarChart
                    dataset={dataset}
                    height={350}
                    xAxis={[
                      {
                        label: 'Average Duration(in mins)',
                        scaleType: 'band',
                        dataKey: 'ave',
                        sx: {
                          '.MuiChartsAxis-label': {
                            transform: 'translateY(10px)',
                            fontSize: '16px',
                          },
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0,
                        max: 5,
                        stepSize: 0.5, // 간격
                        tickFormatter: (value) => `${value}`, // 단위 수정
                      },
                    ]}
                    series={[
                      { dataKey: 'A', label: 'A', color: '#FF6384' },
                      { dataKey: 'B', label: 'B', color: '#36A2EB' },
                      { dataKey: 'C', label: 'C', color: '#FFCE56' },
                    ]}
                    {...chartSetting}
                  />
                </Box>
              </Box>
            </Box>
            <Box flexGrow={1}>
              <Box className='white-box white-box-wrap chart-box'>
                <Box className='box-content chart-item'>
                  <BarChart
                    dataset={dataRate}
                    height={350}
                    xAxis={[
                      {
                        label: 'Content click Rate',
                        scaleType: 'band',
                        dataKey: 'ave',
                        sx: {
                          '.MuiChartsAxis-label': {
                            transform: 'translateY(10px)',
                            fontSize: '16px',
                          },
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0,
                        max: 50,
                        stepSize: 5,
                        tickFormatter: (value) => `${value}`, // 단위 수정
                      },
                    ]}
                    series={[
                      { dataKey: 'A', label: 'A', color: '#FF6384' },
                      { dataKey: 'B', label: 'B', color: '#36A2EB' },
                      { dataKey: 'C', label: 'C', color: '#FFCE56' },
                    ]}
                    {...chartSetting}
                  />
                </Box>
              </Box>
            </Box>
            <Box flexGrow={1}>
              <Box className='white-box white-box-wrap chart-box'>
                <Box className='box-content chart-item'>
                  <BarChart
                    dataset={dataDevice}
                    height={350}
                    xAxis={[
                      {
                        label: 'Click Unique Device Rate',
                        scaleType: 'band',
                        dataKey: 'ave',
                        sx: {
                          '.MuiChartsAxis-label': {
                            transform: 'translateY(10px)',
                            fontSize: '16px',
                          },
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0,
                        max: 10,
                        stepSize: 2,
                        tickFormatter: (value) => `${value}`, // 단위 수정
                      },
                    ]}
                    series={[
                      { dataKey: 'A', label: 'A', color: '#FF6384' },
                      { dataKey: 'B', label: 'B', color: '#36A2EB' },
                      { dataKey: 'C', label: 'C', color: '#FFCE56' },
                    ]}
                    {...chartSetting}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ANServiceResult;
