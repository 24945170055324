import React, { useMemo, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import CustomImage from 'components/component/BasicImage';
import DatePicker from 'components/component/BasicDatePicker';
import FileUpload from 'components/component/BasicFileUpload';
import TextField from 'components/component/BasicTextField';
import { Divider, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Typography, List, ListItem, Grid } from '@mui/material';

import { CloseIcon24Black } from 'components/component/BasicIcon';
import dayjs from 'dayjs';
import { saveBannerImage } from '../../api/restApi';

function PopAddCategoryBanner(props) {
  const { onClose, getAreaCategoryData, handelSetShowSnakBar, country, areaName, areaType, idx } = props;
  const [createCategory, setCreateCategory] = useState({
    webPromotionCategoryIdx: idx,
    categoryName: "",
    startDate: dayjs(new Date()),
    endDate: "",
    url: "",
    image: "",
    resultIdx: "",
    contentType: areaType,
    enable: "N",
  });
  const [actualSize, setActualSize] = useState({ width: 1920, height: 305 });
  const [tempImage, setTempImage] = useState(null);
  const today = dayjs(new Date());

  const infos = [
    {
      index: 1,
      label: '#Country',
      value: country,
      divide: true,
    },
    {
      index: 2,
      label: '#Selected Area ',
      value: areaName,
      divide: true,
    },
    {
      index: 3,
      label: '#Area Type',
      value: 'Banner(1920px x 305px)',
      divide: false,
    },
  ];

  const disablePastDates = createCategory.endDate
    ? (date) => dayjs(date).isAfter(dayjs(createCategory.endDate))
    : false;

  const disableFutureDates = createCategory.startDate
    ? (date) => dayjs(date).isBefore(dayjs(createCategory.startDate))
    : false;

  const onChangeCategoryName = (event) => {
    const value = event.target.value;
    setCreateCategory((pewvState) => ({
      ...pewvState,
      categoryName: value,
    }));
  };

  const onStartDateChange = (newDate) => {
    if (newDate && newDate.$d) {
      const formattedDate = dayjs(newDate.$d).format("MM/DD/YYYY");
      setCreateCategory((prevState) => ({
        ...prevState,
        startDate: formattedDate,
      }));
    } else {
      setCreateCategory((prevState) => ({
        ...prevState,
        startDate: null,
      }));
    }
  };

  const onEndDateChange = (newDate) => {
    if (newDate && newDate.$d) {
      const formattedDate = dayjs(newDate.$d).format("MM/DD/YYYY");
      setCreateCategory((prevState) => ({
        ...prevState,
        endDate: formattedDate,
      }));
    } else {
      setCreateCategory((prevState) => ({
        ...prevState,
        endDate: null,
      }));
    }
  };

  const onChangeImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setTempImage(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setCreateCategory((pewvState) => ({
      ...pewvState,
      image: file,
    }));
  };

  const onChangeURL = (event) => {
    const value = event.target.value;
    setCreateCategory((pewvState) => ({
      ...pewvState,
      url: value,
    }));
  };
  const isSizeDifferent =
    actualSize.width !== 1920 || actualSize.height !== 305;

  const clickSave = async () => {
    const createCategoryData = { ...createCategory };
    const res = await saveBannerImage(idx, createCategoryData);
    handleClose();
    getAreaCategoryData();
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const onCreateCategoryCancelBtn = () => {
    handleClose();
  };

  const onCreateCategorySaveBtn = () => {
    clickSave();
  };

  const isActive =
    createCategory.categoryName?.trim() !== "" &&
    createCategory.startDate &&
    createCategory.endDate &&
    createCategory.image;

  const dialogButtons = useMemo(() => {
    return [
      {
        width: "150px",
        size: "large",
        label: "Cancel",
        type: "outline",
        color: "tertiary",
        onClick: onCreateCategoryCancelBtn,
        disabled: false,
      },
      {
        width: "150px",
        size: "large",
        label: "Save",
        type: "box",
        color: "primary",
        onClick: onCreateCategorySaveBtn,
        disabled: !isActive,
      },
    ];
    // eslint-disable-next-line
  }, [isActive, createCategory]);

  const handleFileRemove = (event) => {
    setTempImage(null);
    setCreateCategory((prevState) => ({
      ...prevState,
      image: "",
    }));
    setActualSize({ width: 1920, height: 305 });
  };

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Add Category'
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium'
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <List className='info-list-container'>
              {infos.map((item) => (
                <React.Fragment key={item.index}>
                  <ListItem className='list-item'>
                    <Typography className='label'>{item.label}</Typography>
                    <Typography className='value'>{item.value}</Typography>
                  </ListItem>
                  {item.divide && <Divider className='divider' />}
                </React.Fragment>
              ))}
            </List>

            <Divider sx={{ marginBottom: '16px' }} />

            <Grid container columnSpacing={2.5}>
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <TextField
                    required={true}
                    type='outlined'
                    label='Category Name'
                    size='large'
                    placeholder='ex) Category Name'
                    defaultValue=''
                    autoComplete='off'
                    onChange={onChangeCategoryName}
                    InputLabelProps={{ shrink: true }}
                    maxLength={200}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box className='field-box' sx={{ display: 'flex', gap: '24px' }}>
                  <DatePicker
                    format='MM/DD/YYYY'
                    size='large'
                    singleDate={true}
                    label={'StartDate'}
                    required={true}
                    minDate={today}
                    maxDate={dayjs(createCategory.endDate).subtract(1, 'day')}
                    value={createCategory.startDate ? dayjs(createCategory.startDate) : null}
                    onChange={(newDate) => onStartDateChange(newDate)}
                    disableFutureDates={disableFutureDates}
                  />
                  <DatePicker
                    format='MM/DD/YYYY'
                    size='large'
                    singleDate={true}
                    label={'EndDate'}
                    required={true}
                    minDate={createCategory.startDate ? dayjs(createCategory.startDate).add(1, 'day') : dayjs(today).add(1, 'day')}
                    value={createCategory.endDate ? dayjs(createCategory.endDate) : dayjs(null)}
                    onChange={(newDate) => onEndDateChange(newDate)}
                    disablePastDates={disablePastDates}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <TextField
                    required={false}
                    type='outlined'
                    label='URL'
                    size='large'
                    placeholder='ex) URL'
                    defaultValue=''
                    autoComplete='off'
                    onChange={onChangeURL}
                    InputLabelProps={{ shrink: true }}
                    maxLength={1024}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box className='field-box'>
                  <FileUpload
                    id='fileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
                    buttonLabel='Upload'
                    size='large'
                    label='Image'
                    placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                    onFileChange={(file) => onChangeImageUpload(file)}
                    accept='.jpg,.png'
                    isSubText
                    subText=''
                    value={createCategory.image === '' ? '' : createCategory.image.name}
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Typography component='p' className='upload-info-text'>
                    Recommended Size: <strong>1920px X 305px</strong>, Actual Image Size: {' '}
                    <strong style={{ color: isSizeDifferent ? 'red' : 'inherit' }}>
                      {`${actualSize.width}px X ${actualSize.height}px`}
                    </strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className='upload-box'>
                  <Box className='upload-total-count'>File</Box>
                  <Box className='box-content' display='flex'>
                    <Box className='left-area'>
                      {tempImage && (
                        <CustomImage src={tempImage} fit={false} wrapWidth='120px' wrapHeight='64px' alt='' rounded />
                      )}
                    </Box>
                    <Box className='right-area'>
                      <Box component='div' className='upload-info-wrap'>
                        <Typography component='p' className='file-name'>
                          {tempImage ? 'Uploaded Image' : 'No file selected'}
                        </Typography>
                        <Box component='ul' className='file-list-area'>
                          <Box component='li' className='item'>
                            Recommended Size: 1920px X 305px
                          </Box>
                          <Box component='li' className='item'>
                            Actual Image Size: <span style={{ color: isSizeDifferent ? 'red' : 'inherit' }}>{`${actualSize.width}px X ${actualSize.height}px`}</span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <IconButton className='btn-close'>
                      <CloseIcon24Black
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                        onClick={handleFileRemove}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopAddCategoryBanner;
