import React, { useMemo, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import CustomImage from 'components/component/BasicImage';
import FileUpload from 'components/component/BasicFileUpload';
import TextField from 'components/component/BasicTextField';
import { Divider, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Typography, List, ListItem, Grid } from '@mui/material';

import { CloseIcon24Black } from 'components/component/BasicIcon';
import { saveCreateBannerImage } from '../../api/restApi';

function PopAddCategoryBranding(props) {
  const { onClose, handelSetShowSnakBar, country, areaName, areaType, idx, getBrandImageData } = props;
  const createBrandingImageInfos = [
    {
      index: 1,
      label: "#Country",
      value: country,
      divide: true,
    },
    {
      index: 2,
      label: "#Selected Area ",
      value: areaName,
      divide: true,
    },
    {
      index: 3,
      label: "#Area Type",
      value: `Branding Image - ${areaType === 'brand_a' ? "A Type (1920px X 350px)" : "B type (890px X 490px)"}`,
      divide: false,
    },
  ];
  const [createBrandingImage, setCreateBrandingImage] = useState({
    webPromotionCategoryIdx: idx,
    contentType: areaType,
    categoryName: "",
    startDate: "",
    endDate: "",
    url: "",
    image: "",
  });
  const [actualSize, setActualSize] = useState(areaType === 'brand_a' ? { width: 1920, height: 350 } : { width: 890, height: 490 });
  const [tempImage, setTempImage] = useState(null);
  const onChangeCategoryName = (event) => {
    const value = event.target.value;
    setCreateBrandingImage((prevState) => ({
      ...prevState,
      categoryName: value,
    }));
  };

  const isSizeDifferent = areaType === 'brand_a' ? (actualSize.width !== 1920 || actualSize.height !== 350) :
    (actualSize.width !== 890 || actualSize.height !== 490);

  const isActive =
    createBrandingImage.categoryName?.trim() !== "" && createBrandingImage.image;

  const onChangeImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setTempImage(reader.result);
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        setActualSize({ width: img.width, height: img.height });
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setCreateBrandingImage((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const clickSave = async () => {
    const createCategoryData = { ...createBrandingImage };
    const res = await saveCreateBannerImage(idx, createCategoryData);
    handleClose();
    getBrandImageData();
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const onCreateBrandImageCancelBtn = () => {
    handleClose();
  };

  const onCreateBrandImageSaveBtn = () => {
    clickSave();
  };

  const dialogButtons = useMemo(() => {
    return [
      {
        width: "150px",
        size: "large",
        label: "Cancel",
        type: "outline",
        color: "tertiary",
        onClick: onCreateBrandImageCancelBtn,
        disabled: false,
      },
      {
        width: "150px",
        size: "large",
        label: "Save",
        type: "box",
        color: "primary",
        onClick: onCreateBrandImageSaveBtn,
        disabled: !isActive,
      },
    ];
    // eslint-disable-next-line
  }, [isActive, createBrandingImage]);

  const handleFileRemove = (event) => {
    setTempImage(null);
    setCreateBrandingImage((prevState) => ({
      ...prevState,
      image: "",
    }));
    setActualSize(areaType === 'brand_a' ? { width: 1920, height: 350 } : { width: 890, height: 490 }); // initialize
  };

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Add Category'
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium'
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <List className='info-list-container'>
              {createBrandingImageInfos.map((item) => (
                <React.Fragment key={item.index}>
                  <ListItem className='list-item'>
                    <Typography className='label'>{item.label}</Typography>
                    <Typography className='value'>{item.value}</Typography>
                  </ListItem>
                  {item.divide && <Divider className='divider' />}
                </React.Fragment>
              ))}
            </List>
            <Divider sx={{ marginBottom: '16px' }} />

            <Grid container columnSpacing={2.5}>
              {/* Name */}
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <TextField
                    required={true}
                    type='outlined'
                    label='Name'
                    size='large'
                    placeholder='ex) Category Name'
                    defaultValue=''
                    autoComplete='off'
                    onChange={onChangeCategoryName}
                    InputLabelProps={{ shrink: true }}
                    maxLength={200}
                  />
                </Box>
              </Grid>

              {/* Image Upload */}
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box className='field-box'>
                  <FileUpload
                    id='fileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
                    buttonLabel='Upload'
                    size='large'
                    label='Image'
                    placeholder='File Upload(Only .jpeg, png files can be uploaded.)'
                    onFileChange={(file) => { onChangeImageUpload(file); }}
                    accept='.jpg,.png'
                    isSubText
                    subText=''
                    value={createBrandingImage.image === '' ? '' : createBrandingImage.image.name}
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Typography component='p' className='upload-info-text'>
                    Recommended Size: <strong>{areaType === 'brand_a' ? '1920px X 350px' : '890px X 490px'}</strong>, Actual Image Size: {' '}
                    <strong style={{ color: isSizeDifferent ? 'red' : 'inherit' }}>
                      {`${actualSize.width}px X ${actualSize.height}px`}
                    </strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className='upload-box'>
                  <Box className='upload-total-count'>File</Box>
                  <Box className='box-content' display='flex'>
                    <Box className='left-area'>
                      {tempImage && (
                        <CustomImage src={tempImage} fit={false} wrapWidth='120px' wrapHeight='64px' alt='' rounded />
                      )}
                    </Box>
                    <Box className='right-area'>
                      <Box component='div' className='upload-info-wrap'>
                        <Typography component='p' className='file-name'>
                          {tempImage ? 'Uploaded Image' : 'No file selected'}
                        </Typography>
                        <Box component='ul' className='file-list-area'>
                          <Box component='li' className='item'>
                            Recommended Size: {areaType === 'brand_a' ? '1920px X 350px' : '890px X 490px'}
                          </Box>
                          <Box component='li' className='item'>
                            Actual Image Size: <span style={{ color: isSizeDifferent ? 'red' : 'inherit' }}>{`${actualSize.width}px X ${actualSize.height}px`}</span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <IconButton className='btn-close'>
                      <CloseIcon24Black
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                        onClick={handleFileRemove}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopAddCategoryBranding;
