// null, empty, length 0 check
export const isEmpty = (input) => {
  return (
    typeof input === 'undefined' ||
    input === null ||
    input === 'null' ||
    input === '' ||
    input.length === 0 ||
    (typeof input === 'string' && input.trim().length === 0) ||
    (Array.isArray(input) && input.length === 0) ||
    (typeof input === 'object' && !Object.keys(input).length)
  );
};

// Image validation
export const isImage = (url) => {
  const img = new Image();
  img.onload = function () {
    return true;
  };
  img.onerror = function () {
    return false;
  };
  img.src = url;
  return img.complete;
};
