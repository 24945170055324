import React from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';

//sortable default false
function BasicTableSortLabel({ labelText, sortable, labelId, orderBy, setOrderBy, order, setOrder, sorting }) {
  const onClick = (labelId) => {
    if (sortable) {
      const isAsc = orderBy === labelId && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(labelId);
      sorting(labelId, isAsc ? 'desc' : 'asc');
    }
  };

  return (
    <TableSortLabel
      hideSortIcon={!sortable}
      active={orderBy === labelId}
      direction={orderBy === labelId ? order : 'desc'}
      onClick={() => onClick(labelId)}>
      {labelText}
    </TableSortLabel>
  );
}

export default BasicTableSortLabel;
