import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CustomTabs from 'components/component/BasicTabs';
import CheckBox from 'components/component/BasicCheckBox';
import SelectBox from 'components/component/BasicSelectBox';
import Snackbar from 'components/component/BasicSnackbar';
import TextLink from 'components/component/BasicTextLink';
import TextButton from 'components/component/BasicTextButton';
import Pagination from 'components/component/BasicPagination';
import SearchField from 'components/component/BasicSearchField';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Divider,
} from '@mui/material';
// Data
import {
  selectOption,
  NotificationTh,
  NotificationTd,
  NotificationFaqTh,
  NotificationFaqTd,
} from 'pages/Main/Support/data/NotificationData';

import {
  ArrowUpIcon,
  ArrowDownIcon,
  DownloadGrayIcon,
  DarkDownloadGrayIcon,
  ArrowUpDarkIcon,
  ArrowDownDarkIcon,
} from 'components/component/BasicIcon';

const SupportTab = [
  { label: 'Notification', badgeType: '' },
  { label: 'FAQ', badgeType: '' },
];

// 테이블 행 표시 개수 옵션 데이터
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// BreadCrumb
const optionMenu = {
  normalMenu: ['Support'],
};

// ************************** 메인 컨텐츠 ***********************************
function Notification(props) {
  const { darkMode } = props;
  const [value, setValue] = useState(0); // 탭 선택 상태를 관리하는 state
  const [order, setOrder] = useState('asc'); // 테이블 정렬 순서를 관리하는 state
  const [orderBy, setOrderBy] = useState(''); // 테이블 정렬 기준이 되는 컬럼명을 관리하는 state
  const [openRow, setOpenRow] = useState(null); // 현재 열려있는 테이블 행의 ID를 관리하는 state

  // Tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // 테이블 정렬 요청 핸들러 함수
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 테이블 정렬 함수
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // 테이블의 행 데이터 (드래그 앤 드롭 가능)
  const sortedTableTdRows = sortRows([...NotificationTd], orderBy, order);
  const sortedFaqTdRows = sortRows([...NotificationFaqTd], orderBy, order);

  // dropdown 클릭 이벤트
  const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);
  const renderIcon = (id) => {
    const isOpen = openRow === id;
    return isOpen ? (
      darkMode ? (
        <ArrowUpDarkIcon />
      ) : (
        <ArrowUpIcon />
      )
    ) : darkMode ? (
      <ArrowDownDarkIcon />
    ) : (
      <ArrowDownIcon />
    );
  };

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container inspect' component='main' sx={{ flexGrow: 1 }}>
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Support
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>

        <Box className='content-wrap ' component='div'>
          <Box className='notification-container'>
            <Box className='white-box white-box-wrap single-box '>
              <div className='tab-content-area'>
                <CustomTabs tabStyle='type-01' value={value} handleChange={handleChange} tabs={SupportTab} />
                <div className='box-content'>
                  {value === 0 && (
                    <div className='tab-panel'>
                      <div className='panel-wrap'>
                        <Box className='white-box-content-top-area' alignItems='flex-end'>
                          {/* left-area */}
                          <Box className='left-area column'>
                            <Box display='flex' gap={1}>
                              <SearchField
                                id='search-1'
                                placeholder='Please enter a search term'
                                label='Ch.no or ch.Name'
                                maxWidth='230px'
                                size='medium'
                                required={false}
                                shrink={false}
                              />
                            </Box>
                          </Box>
                          <Box className='right-area'></Box>
                          {/* right-area */}
                        </Box>
                        <Box className='content-inner-wrap'>
                          {/* Table */}
                          <CustomTableContainer className='table-container'>
                            {/* thead Th 줄바꿈 클래스: auto */}
                            <Table className='custom-table' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {NotificationTh.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <>
                                          <CheckBox
                                            label=''
                                            value='select-all'
                                            defaultChecked={false}
                                            inputProps={{
                                              'aria-label': 'select all',
                                            }}
                                          />
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        </>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'desc'}
                                              onClick={() => handleRequestSort(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}
                              <TableBody className='tbody'>
                                {sortedTableTdRows.map((td, index) => (
                                  <TableRow className='tr' key={td.id || index}>
                                    {NotificationTh.map((column, colIndex) => (
                                      <TableCell key={column.id} className='td' align={column.align}>
                                        {colIndex === 2 ? (
                                          <TextButton label={td[`data0${colIndex + 1}`]} icon={false} />
                                        ) : (
                                          td[`data0${colIndex + 1}`]
                                        )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                          {/* // Table */}

                          {/* Pagination */}
                          <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                        </Box>
                      </div>
                    </div>
                  )}
                  {value === 1 && (
                    <div className='tab-panel'>
                      <div className='panel-wrap'>
                        <Box className='white-box-content-top-area'>
                          <Box className='left-area'>
                            <SelectBox
                              id='select-search'
                              size='medium'
                              selectOptions={selectOption}
                              label=''
                              maxWidth='180px'
                              disabled={false}
                              placeholder=''
                            />

                            <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                          </Box>
                          <Box className='right-area'></Box>
                        </Box>
                        <Box className='content-inner-wrap'>
                          {/* Table */}
                          <CustomTableContainer className='table-container'>
                            {/* thead Th 줄바꿈 클래스: auto */}
                            <Table className='custom-table' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {NotificationFaqTh.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <>
                                          <CheckBox
                                            label=''
                                            value='select-all'
                                            defaultChecked={false}
                                            inputProps={{
                                              'aria-label': 'select all',
                                            }}
                                          />
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        </>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'desc'}
                                              onClick={() => handleRequestSort(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}
                              <TableBody>
                                {sortedFaqTdRows.map((td, index) => (
                                  <React.Fragment key={td.id}>
                                    <TableRow
                                      onClick={() => handleRowClick(td.id)}
                                      className={openRow === td.id ? 'expanded' : ''}
                                    >
                                      {NotificationFaqTh.map((column, colIndex) => (
                                        <TableCell key={column.id} align={column.align}>
                                          {colIndex === 0 ? (
                                            <>
                                              <IconButton onClick={() => handleRowClick(td.id)}>
                                                {renderIcon(td.id)}
                                              </IconButton>
                                              {td[`data0${colIndex + 1}`]}
                                            </>
                                          ) : colIndex === 2 ? (
                                            <TextButton label={td[`data0${colIndex + 1}`]} icon={false} />
                                          ) : (
                                            td[`data0${colIndex + 1}`]
                                          )}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow className='dropwdown-content-wrap'>
                                      <TableCell colSpan={NotificationFaqTh.length}>
                                        <Collapse
                                          in={openRow === td.id}
                                          timeout='auto'
                                          unmountOnExit
                                          className='dropwdown-box'
                                        >
                                          <Box className='dropwdown-content' margin={1}>
                                            <Typography className='faq-title' variant='p' gutterBottom component='div'>
                                              FAQ Title
                                            </Typography>
                                            <Box className='faq-text-body'>
                                              <Typography className='faq-text'>text text text text</Typography>
                                            </Box>
                                            <Divider className='divider' />
                                            <Box className='down-list-wrap'>
                                              <Box className='down-box-header'>
                                                <Box className='total-count'>
                                                  <Box component='span' className='label'>
                                                    Total
                                                  </Box>
                                                  <Box component='span' className='value'>
                                                    2
                                                  </Box>
                                                </Box>
                                                <Button
                                                  color=''
                                                  type='icon-text'
                                                  size='medium'
                                                  label='Download All'
                                                  isIcon={true}
                                                  Icon={darkMode ? DarkDownloadGrayIcon : DownloadGrayIcon}
                                                  iconPos='left'
                                                />
                                              </Box>
                                              <Box className='down-box-body'>
                                                <Box className='file-item'>
                                                  <TextLink
                                                    href=''
                                                    className='down-file-item'
                                                    text='01. CDP Admin Operation Policy_(ENG)20240719.xlsx'
                                                  />
                                                </Box>
                                                <Box className='file-item'>
                                                  <TextLink
                                                    href=''
                                                    className='down-file-item'
                                                    text='01. CDP Admin Operation Policy_(ENG)20240719.xlsx'
                                                  />
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                ))}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                          {/* // Table */}

                          {/* Pagination */}
                          <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                        </Box>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>

      {/*
        Toast Pop-Up
        - severity: default, error, warning, info, success
      */}
      <Snackbar severity='success' message='success' open={false} duration={1000} bottom={'8%'} darkMode={darkMode} />
    </Box>
  );
}

export default Notification;
