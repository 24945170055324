import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import BasicSwitches from 'components/component/BasicSwitch';
import TextLink from 'components/component/BasicTextLink';
import SearchField from 'components/component/BasicSearchField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import Tooltip from 'components/component/BasicTooltip';
import TextField from 'components/component/BasicTextField';
import CustomImage from 'components/component/BasicImage';
import CustomBasicMenu from 'components/component/BasicMenu';
import DatePicker from 'components/component/BasicDatePicker';
import SelectBox from 'components/component/BasicSelectBox';
import CheckBox from 'components/component/BasicCheckBox';
import Snackbar from 'components/component/BasicSnackbar';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getRequest } from 'utils/Request';
import { styled } from '@mui/material/styles';
import { Box, Typography, Link, Grid, ListItem, List, Divider, TableSortLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { BtnArrowRight } from 'components/component/BasicIcon';
import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import { FreeonResultTh, SelectedContentTh} from './data/FreeOnData';
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import Dialog from "../../../components/component/BasicDialog";

// 메인 컨텐츠
function FreeOnLGChannelsShelf(props) {
  const { darkMode, message, providerByCountry } = props;
  const navigate = useNavigate();
  // 화면에 보이는 필터 정보
  const [optionCountryList, setOptionCountryList] = useState([]);
  const [optionTypeList, setOptionTypeList] = useState([]);
  const [optionProviderNameList, setOptionProviderNameList] = useState([]);
  const [optionStatusList, setOptionStatusList] = useState([]);
  // 선택된 필터값
  const defaultCountryCode = 'US';
  const [selectedCountry, setSelectedCountry] = useState(defaultCountryCode);
  const [selectedType, setSelectedType] = useState('All');
  const [selectedProviderId, setSelectedProviderId] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('Saved For Testing');
  // Recommend Month Select box
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];
  const [contentList, setContentList] = useState([]);
  // Scheduled Content List영역
  const [noData, setNoData] = useState(true);
  const [scheduleList, setScheduleList] = useState([]);
  const [leftCheckedItems, setLeftCheckedItems] = useState({});
  const [rightCheckedItems, setRightCheckedItems] = useState({});
  const [isAllLeftChecked, setIsAllLeftChecked] = useState(false);
  const [isAllRightChecked, setIsAllRightChecked] = useState(false);
  const [dragDropDisabled, setDragDropDisabled] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);

  //화면 안보이면 로딩바
  const [isDisplayContent, setIsDisplayContent] = useState(false);

  // Paging 기능에서 사용하는 데이터
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  // 토스트 팝업
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);

  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Free On LG Channels Shelf',
  };
  const infos = [
    {
      index: 1,
      label: '#Current Time ',
      value: '2024-07-29 02:06:30',
      divide: true,
    },
    {
      index: 2,
      label: '#Contents Preparing Batch Time',
      value: '2024-07-29 09:00:00',
      divide: true,
    },
    {
      index: 3,
      label: '#Contents Displaying Batch Time',
      value: '2024-07-29 13:00:00',
      divide: false,
    },
  ];
  // Styled
  const IconDraggable = styled('span')`
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url(${IconDraggableImg});

    .dark & {
      background: url(${IconDraggableImgDark});
    }
  `;

  // 테이블
  const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    height: '100%',
    overflowY: 'auto',
  }));

  const typeList = ['All', 'tvshow', 'movie', 'tvshow-series'];
  const statusList = ['Saved For Testing', 'Saved For Published'];

  useEffect(() => {
    if (message) {
      if (message.filter) {
        setSelectedCountry(message.filter.selectedCountry);
        setSelectedType(message.filter.selectedType);
        setSelectedProviderId(message.filter.selectedProviderId);
        setSelectedStatus(message.filter.selectedStatus);
        setSelectedDate(message.filter.selectedDate);
      }
    }
  }, [message]);

  // 필터 초기 세팅 useEffect
  useEffect(() => {
    if (selectedCountry && providerByCountry) {
      const countryCodeList = providerByCountry.map(item => item.countryCode).sort();
      setOptionCountryList(countryCodeList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      })));

      let tempProviderList = providerByCountry
        .filter(item => item.countryCode === selectedCountry)
        .map(item => item.providerList)
        .reduce((acc, curr) => acc.concat(curr), []);
      setOptionProviderNameList([
        { id: 'All', value: 'All', option: 'All', type: 'text' },
        ...tempProviderList.map(item => ({
          id: item.providerId,
          value: item.providerId,
          option: item.providerName,
          type: 'text'
        }))
      ]);
      setOptionTypeList(typeList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      })));
      setOptionStatusList(statusList.map(item => ({
        id: item,
        value: item,
        option: item,
        type: 'text'
      })));
    }
    // eslint-disable-next-line
  }, [selectedCountry, providerByCountry]);

  useEffect(() => {
    setIsDisplayContent(false);
    getContentList();
    // eslint-disable-next-line
  }, [selectedCountry, selectedType, selectedProviderId]);

  // paging
  useEffect(() => {
    if (contentList && contentList.length > 0) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newPaginatedData = contentList.slice(startIndex, endIndex);
      setPaginatedData([...newPaginatedData]);
      setIsDisplayContent(true);
    }
    // eslint-disable-next-line
  }, [isDisplayContent, page, itemsPerPage]);

  useEffect(() => {
    if (!scheduleList || scheduleList.length === 0) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [scheduleList]);

  useEffect(() => {
    if (isAllRightChecked) {
      setDragDropDisabled(false);
    } else {
      setDragDropDisabled(true);
    }
  }, [isAllRightChecked]);

  useEffect(() => {
    const allUnchecked = Object.values(rightCheckedItems).every(value => value === false);
    if (allUnchecked || Object.keys(rightCheckedItems).length === 0) {
      setDeleteDisabled(true);
    } else {
      setDeleteDisabled(false);
    }
  }, [rightCheckedItems]);

  // 테이블 내림, 오름차순
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...contentList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setContentList([...sortedData]);
  };

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(scheduleList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const updatedItems = items.map((itme, index) => ({ ...itme, resultOrder: index + 1 }));
    setScheduleList(updatedItems);
  };

  // Recommend Month Select box
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const handleSelectedCountry = (country) => {
    const providerNameList = providerByCountry.find(item => item.countryCode === country)?.providerList.map(item => item.providerName).sort();
    setOptionProviderNameList([
      { id: 'All', value: 'All', option: 'All', type: 'text' },
      ...providerNameList.map(item => ({
        id: item.providerId,
        value: item.providerId,
        option: item.providerName,
        type: 'text'
      }))
    ]);
    setSelectedCountry(country);
    setSelectedProviderId('All');
  };

  const handleSelectedProvider = (provider) => {
    if (provider && provider.length > 0) {
      setSelectedProviderId(provider);
    }
  };

  const handleSelectedStatus = (value) => {
    setSelectedStatus(value);
  };

  const handleSelectedType = (value) => {
    setSelectedType(value);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleScheduleList = () => {
    let count = scheduleList.length;
    const newItems = paginatedData
      .filter((item) => leftCheckedItems[item.contentId] === true)
      .map((item) => ({ ...item, resultOrder: ++count }));

    const updatedScheduleList = [
      ...scheduleList,
      ...newItems
    ];

    let score = updatedScheduleList.length;
    const finalScheduleList = updatedScheduleList.map(item => {
      if (item.score === null || item.score !== score - item.resultOrder) {
        return { ...item, score: score - item.resultOrder };
      }
      return item;
    });

    setScheduleList(finalScheduleList);
    setNoData(finalScheduleList.length === 0);
  };

  const handleCheckboxChange = (event, id, position) => {
    event.preventDefault();
    event.stopPropagation();

    if (position === 'left') {
      setLeftCheckedItems((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    } else if (position === 'right') {
      setRightCheckedItems((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    }
  };

  const handleCheckboxAllChange = (position) => {
    const newCheckedItems = {};
    if (position === 'left') {
      contentList.forEach((item) => {
        newCheckedItems[item.contentId] = !isAllLeftChecked;
      });
      setLeftCheckedItems(newCheckedItems);
      setIsAllLeftChecked(!isAllLeftChecked);
    } else if (position === 'right') {
      scheduleList.forEach((item) => {
        newCheckedItems[item.contentId] = !isAllRightChecked;
      });
      setRightCheckedItems(newCheckedItems);
      setIsAllRightChecked(!isAllRightChecked);
    }
  };

  const handleChangedValue = (contentId, value) => {
    setScheduleList(prev =>
      prev.map(item => ({
        ...item,
        fixFlag: item.contentId === contentId ? value ? true : false : item.fixFlag
      }))
    );
  };

  const getContentList = async () => {
    let paramProviderId = selectedProviderId === 'All' ? '' : selectedProviderId;
    let paramSaveType = selectedStatus === 'Saved For Testing' ? 'S-T' : 'S-P';
    let date = new Date(selectedDate);
    let formattedDate = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}`;
    const body = await getRequest(`/free-on-lgchannels/search?countryCode=${selectedCountry}&vodType=${selectedType}&providerId=${paramProviderId}&recommendMonth=${formattedDate}&saveType=${paramSaveType}`, null);
    if (body && body.result === 'SUCCESS') {
      setContentList(body.data);
      let filteredData = body.data.filter(item => item.resultOrder != null);
      setScheduleList(filteredData);
      setLeftCheckedItems(filteredData.reduce((acc, curr) => {
        acc[curr.contentId] = true;
        return acc;
      }, {}));
      if (filteredData.length > 0) {
        setNoData(false);
      }
      setIsDisplayContent(true);
    }
  };

  const handleConfirmUpdate = (buttonStatus) => {
    setConfirmOpen(false);
    let paramSaveType = '';
    let date = new Date(selectedDate);
    let formattedDate = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}`;
    if (buttonStatus === 'save') {
      paramSaveType = selectedStatus === 'Saved For Testing' ? 'S-T' : 'S-P';
    } else if (buttonStatus === 'testing') {
      paramSaveType = selectedStatus === 'Saved For Testing' ? 'T' : 'P';
    }
    fetch(`${BASE_URL}/free-on-lgchannels/countryCode/${selectedCountry}/recommendMonth/${formattedDate}/saveType/${paramSaveType}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(scheduleList.filter(item => rightCheckedItems[item.contentId] === true))
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setAlertTitle('Info');
        setAlertMessage('Successfully Update.');
        setAlertOpen(true);
      } else {
        throw new Error(`[Free on LGChannelsShelf] Save error`);
      }
    })
    .catch(error => {
      setSnackbarMessage(error.message.replace('Error: ', ''));
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSaveButton = () => {
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to save the selected items?');
    setConfirmAction(() => () => handleConfirmUpdate('save'));
  };

  // check dialog 팝업으로 변경 필요
  const handleTestButton = () => {
    const action = selectedStatus === 'Saved For Testing' ? 'Testing' : 'Publish';
    setConfirmOpen(true);
    setConfirmMessage(`Are you sure you want to ${action} the selected items?`);
    setConfirmAction(() => () => handleConfirmUpdate('testing'));
  };

  const handleDeleteButton = () => {
    setConfirmOpen(true);
    setConfirmMessage('Are you sure you want to delete the selected items?');
    setConfirmAction(() => () => handleConfirmDelete());
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmDelete = () => {
    setConfirmOpen(false);
    let date = new Date(selectedDate);
    let formattedDate = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}`;
    fetch(`${BASE_URL}/free-on-lgchannels/countryCode/${selectedCountry}/recommendMonth/${formattedDate}`, {
      method: 'DELETE',
      headers: getHeaders(),
      body: JSON.stringify(
        Object.keys(rightCheckedItems)
          .filter(key => rightCheckedItems[key] === true)
          .map(key => key)
      )
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setScheduleList(scheduleList.filter(item => !rightCheckedItems[item.contentId]));
        setRightCheckedItems({});
        if (isAllRightChecked) {
          setIsAllRightChecked(false);
        }
        setAlertTitle('Info');
        setAlertMessage('Successfully deleted.');
        setAlertOpen(true);
      } else {
        throw new Error(`[Free on LGChannelsShelf] Delete error`);
      }
    })
    .catch(error => {
      setSnackbarMessage(error.message.replace('Error: ', ''));
      setSnackbarOpen(true);
    });
  };

  const handleHistory = () => {
    navigate('/FreeOnLGChannelsShelf',
      {  replace: true, state:
        { move: true,
          targetComponent: 'History',
          message:
          {
            // filter 의 용도는 전달화면에서 사용하고, 그 뒤에 back 했을 때 main으로 돌려 주기 위한 용도임
            filter : {
              selectedCountry: selectedCountry,
              selectedType: selectedType,
              selectedProviderId: selectedProviderId,
              selectedStatus: selectedStatus,
            },
          }
        }
      }
    );
  };

  // Button Data
  const ScheduledButtons = [
    {
      index: 1,
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Top',
      isIcon: false,
      autosize: true,
      disabled: false,
    },
    {
      index: 2,
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Bottom',
      isIcon: false,
      autosize: true,
      disabled: false,
    },
    {
      index: 3,
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Download',
      isIcon: false,
      autosize: true,
      disabled: true,
    },
    {
      index: 4,
      color: 'tertiary',
      type: 'outline',
      size: 'small',
      label: 'Delete',
      isIcon: false,
      autosize: true,
      disabled: deleteDisabled,
      onClick: handleDeleteButton,
    },
    {
      index: 5,
      color: 'tertiary',
      type: 'box',
      size: 'small',
      label: 'History',
      isIcon: false,
      autosize: true,
      onClick: handleHistory,
    },
    {
      index: 6,
      color: 'primary',
      type: 'box',
      size: 'small',
      label: 'Save',
      autosize: true,
      onClick: () => handleSaveButton(),
    },
    {
      index: 7,
      color: 'primary',
      type: 'box',
      size: 'small',
      label: selectedStatus === 'Saved For Testing' ? 'Testing' : 'Publish',
      autosize: true,
      onClick: () => handleTestButton(),
    },
  ];

  const confirmDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleConfirmClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'box',
      color: 'primary',
      autosize: true,
      onClick: confirmAction
    },
  ];

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Free On LG Channels shelf
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        {isDisplayContent && <Box className='content-wrap program-detail' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-5'>
                  {/* Country */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={optionCountryList}
                      disabled={false}
                      selected={selectedCountry}
                      onChange={handleSelectedCountry}
                    />
                  </Box>
                  {/* Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-Slider'
                      size='large'
                      shrink={true}
                      label='Type'
                      required={false}
                      selectOptions={optionTypeList}
                      disabled={false}
                      selected={selectedType}
                      onChange={handleSelectedType}
                    />
                  </Box>
                  {/* CP Name */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-Name'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      required={false}
                      selectOptions={optionProviderNameList}
                      disabled={false}
                      selected={selectedProviderId}
                      onChange={handleSelectedProvider}
                    />
                  </Box>
                  {/* Status */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='slt-Content'
                      size='large'
                      shrink={true}
                      label='Status'
                      required={false}
                      selectOptions={optionStatusList}
                      disabled={false}
                      selected={selectedStatus}
                      onChange={handleSelectedStatus}
                    />
                  </Box>

                  {/* Month Picker */}
                  <Box component='div' className='col'>
                    <DatePicker
                      views={['year', 'month']}
                      className='input-date-picker'
                      format='YYYY/MM'
                      size='large'
                      shrink
                      label='Recommend Month'
                      value={selectedDate}
                      onChange={handleDateChange}
                      singleDate={true}
                      slots={{
                        TextField: (params) => <TextField {...params} size='large' />,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>

          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {/* Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Result for Content</Typography>
                      <Typography component='p' className='select-count-wrap'>
                        Select <span className='set-color'>5</span> item
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label=''
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <Button
                        color='tertiary'
                        type='box'
                        size='small'
                        label='Select Ch Input'
                        isIcon={true}
                        autosize
                        Icon={BtnArrowRight}
                        iconPos='right'
                        onClick={() => handleScheduleList()}
                      />
                    </Box>
                  </Box>

                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {FreeonResultTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={isAllLeftChecked}
                                        onChange={(event) => handleCheckboxAllChange('left')}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '24px' }}
                                      />
                                      {index === 0 && (
                                        <CustomBasicMenu
                                          type='icon'
                                          title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                          options={selPageData}
                                        />
                                      )}
                                    </Box>
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {paginatedData.map((td, index) => (
                            <TableRow className='tr' key={td.contentId || index}>
                              {FreeonResultTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  {colIndex === 0 ? (
                                    <CheckBox label={td[column.id]} value='value' defaultChecked={false}
                                    onChange={(event) => handleCheckboxChange(event, td.contentId, 'left')}
                                    checked={!!leftCheckedItems[td.contentId]}/>
                                  ) : colIndex === 1 ? (
                                    <TextLink href='' text={td[column.id]} />
                                  ) : colIndex === 3 ? (
                                    <CustomImage
                                      src={td[column.id]}
                                      fit={false}
                                      alt=''
                                      wrapWidth='55px'
                                      wrapHeight='64px'
                                      rounded
                                    />
                                  ) : colIndex === 4 ? (
                                    td.toolTip && td.toolTip.includes(',') ? (
                                      <div className='td-tooltip-wrap'>
                                        <Tooltip
                                          title={`Genre : ${td['toolTip']}`}
                                          placement='top'
                                          className='tooltip-wrap'
                                        >
                                          <Box display='flex'>
                                            <span className='tooltip-text'>{td[column.id]}</span>
                                            <span className='tooltip-icon'></span>
                                          </Box>
                                        </Tooltip>
                                      </div>
                                    ) : (
                                      <span>{td[column.id]}</span>
                                    )
                                  ) : (
                                    td[column.id]
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  <Pagination
                    id='pagination'
                    count={Math.ceil(contentList.length / itemsPerPage)}
                    selected={itemsPerPage}
                    onChangeHandle={handlePagination}
                    page={page}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Scheduled Content List</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label=''
                        size='medium'
                        width='120px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={ScheduledButtons} justifyContent='center' style={{ gap: '4px' }} />
                    </Box>
                  </Box>

                  {/* Selected Content */}
                  {/* 241011 no-page-number 클래스 추가하였습니다. */}
                  <Box className='content-inner-wrap no-page-number'>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table' isDropDisabled={!dragDropDisabled}>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table auto' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {SelectedContentTh.map((column, index) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                          <Box
                                            component='div'
                                            sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                          >
                                            <CheckBox
                                              label=''
                                              value='select-all'
                                              defaultChecked={isAllRightChecked}
                                              //onChange={handleCheckboxAllChange('right')}
                                              onChange={() => handleCheckboxAllChange('right')}
                                              inputProps={{
                                                'aria-label': 'select all',
                                              }}
                                              style={{ width: '24px' }}
                                            />
                                            {index === 0 && (
                                              <CustomBasicMenu
                                                type='icon'
                                                title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                                options={selPageData}
                                              />
                                            )}
                                          </Box>
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'desc'}
                                              onClick={() => handleRequestSort(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}

                              <TableBody className='tbody'>
                                {noData ? (
                                  <TableRow>
                                    <TableCell
                                      className={noData ? 'noData' : ''}
                                      colSpan={SelectedContentTh.length}
                                      align='center'
                                    >
                                      <Box className='no-data-area'>
                                        <Typography className='text'>
                                          Expose in order of popularity when not organized
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  scheduleList.map((td, index) => (
                                    <Draggable
                                      key={td.contentId || index}
                                      draggableId={td.contentId || index.toString()}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <TableRow
                                          className='tr'
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          {SelectedContentTh.map((column, colIndex) => (
                                            <TableCell
                                              key={column.id}
                                              className='td'
                                              align={column.align}
                                              sx={{ height: '88px' }}
                                            >
                                              {colIndex === 0 ? (
                                                <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                  <IconDraggable className='icon-draggable' />
                                                  <CheckBox
                                                    label={td[column.id]}
                                                    value='value'
                                                    defaultChecked={false}
                                                    onChange={(event) => handleCheckboxChange(event, td.contentId, 'right')}
                                                    checked={!!rightCheckedItems[td.contentId]}
                                                  />
                                                </Box>
                                              ) : colIndex === 3 ? (
                                                <TextLink href='' text={td[column.id]} />
                                              ) : colIndex === 5 ? (
                                                <BasicSwitches
                                                  id={td.contentId}
                                                  onOffMode={true}
                                                  setSwitchOnOff={(value) => handleChangedValue(td.contentId, value)}
                                                  isLabelText={true}
                                                  onText=''
                                                  offText=''
                                                />
                                              ) : colIndex === 6 ? (
                                                <CustomImage
                                                  src={td[column.id]}
                                                  fit={false}
                                                  alt=''
                                                  wrapWidth='64px'
                                                  wrapHeight='56px'
                                                  rounded
                                                />
                                              ) : colIndex === 7 ? (
                                                td.toolTip && td.toolTip.includes(',') ? (
                                                  <div className='td-tooltip-wrap'>
                                                    <Tooltip
                                                      title={`Genre : ${td['toolTip']}`}
                                                      placement='top'
                                                      className='tooltip-wrap'
                                                    >
                                                      <Box display='flex'>
                                                        <span className='tooltip-text'>{td[column.id]}</span>
                                                        <span className='tooltip-icon'></span>
                                                      </Box>
                                                    </Tooltip>
                                                  </div>
                                                ) : (
                                                  <span>{td[column.id]}</span>
                                                )
                                              ) : (
                                                td[column.id]
                                              )}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      )}
                                    </Draggable>
                                  ))
                                )}
                                {provided?.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>}
      </Box>
      <Dialog
        open={isAlertOpen}
        handleClose={handleAlertClose}
        closeX={false}
        size='small'
        title={alertTitle}
        contentTitle={false}
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
      <Dialog
        open={isConfirmOpen}
        handleClose={handleConfirmClose}
        closeX={false}
        size='small'
        title='Confirm'
        contentTitle={false}
        bottomDivider={false}
        contentText={confirmMessage}
        buttons={confirmDialogButtons}
      />
      <Snackbar
        severity='warning'
        message={snackbarMessage}
        open={isSnackbarOpen}
        duration={1000}
        bottom={'8%'}
        darkMode={darkMode}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
}
FreeOnLGChannelsShelf.propTypes = {
  providerByCountry: PropTypes.array.isRequired
};
const mapStateToProps = state => ({
  providerByCountry: state.common.provider.country
});

export default connect(mapStateToProps)(FreeOnLGChannelsShelf);
