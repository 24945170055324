import React, { useEffect, useState, Fragment } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
  Table,
  TableBody,
  Divider,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogActions,
} from '@mui/material';

import Snackbar from 'components/component/BasicSnackbar';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';
// css
import 'assets/scss/page/main.scss';
import SortLabel from 'components/component/BasicTableSortLabel';
import { getRequest, putRequest } from 'utils/Request';
import { isEmpty } from 'utils/Valid';
import BasicDialog from 'components/component/BasicDialog';

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1000px',
    maxWidth: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
  display: flex;
  gap: 16px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  display: flex;
  gap: 20px;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '340px',
  overflowY: 'auto',
}));

const CellType = styled(TableCell)(({ theme }) => ({
  '& .added': {
    color: '#0077B5',
  },
  '& .removed': {
    color: '#B91C1C',
  },
  '& .update': {
    color: '#0D9488',
  },
}));

const StatusCheckBox = styled('div')`
  padding: 8px 12px;
  margin-top: 8px;
  background: #f0ece4;
  border-radius: 4px;
  border: 1px solid #d9d4c9;

  .dark & {
    background: rgba(234, 218, 187, 0.2);
    border-color: rgba(217, 212, 201, 0.2);
  }

  .MuiList-root {
    display: flex;
  }
  .MuiListItem-root {
    width: auto;

    &:not(:last-child):after {
      content: '/';
      display: inline-block;
      margin: 0 8px;
      font-size: 14px;
      color: #a50034;
    }
  }
  .status-data {
    font-size: 14px;
    color: #a50034;

    .dark & {
      color: #fd312e;
    }

    &.label {
      &:after {
        content: ':';
      }
    }
  }
`;
const ToBeArea = styled('div')`
  margin-top: 24px;
  margin-bottom: 8px;

  .area-title {
    font-size: 16px;
    font-weight: 700;
    color: #525252;
  }
  .update-time {
    font-size: 12px;
    color: #909090;

    .dark & {
      color: #909090;
    }
  }
  .time-data {
    padding-left: 8px;
    font-size: 12px;
    color: #525252;

    .dark & {
      color: #fff;
    }
  }
`;

// 메인 컨텐츠
function PopCheckDialog({ isDialogOpen, previewCountry, previewChannelMapId, previewSaveType, closeHandler }) {
  const defaultPopupType = {
    alarm: false,
    title: '',
    content: '',
  };
  const [isOpen, setIsOpen] = useState(true);
  const [previewContentList, setPreviewContentList] = useState([]);
  const [addedList, setAddedList] = useState([]);
  const [removedList, setRemovedList] = useState([]);
  const [updateList, setUpdateList] = useState([]);
  const [changeDate, setChangeDate] = useState('');
  const [popupType, setPopupType] = useState(defaultPopupType);

  // channelSort
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const previewChannelSort = (col, desc) => {
    setPreviewContentList(sortRows([...previewContentList], col, desc));
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  useEffect(() => {
    if (isDialogOpen) {
      if (!isEmpty(previewChannelMapId) && !isEmpty(previewSaveType)) {
        getPreview(previewChannelMapId, previewSaveType);
      }
    }
    setIsOpen(isDialogOpen);
    // eslint-disable-next-line
  }, [isDialogOpen]);

  const onClose = () => {
    if (closeHandler) {
      setIsOpen(false);
      closeHandler();
    }
  };

  const getPreview = async (channelMapId, saveType) => {
    const body = await getRequest(`/channel-map/${channelMapId}/preview?saveType=${saveType}`, null);
    if (body && body.result === 'SUCCESS') {
      let previewList = body.data.map((item, index) => {
        const { channelNumber, channelName, channelId, dmaGroupName, previewLastChgDate, editAction } = item;
        return {
          channelNumber: channelNumber,
          channelName: channelName,
          channelId: channelId,
          dmaGroupName: dmaGroupName,
          lastChgDate: previewLastChgDate,
          editAction: editAction,
        };
      });
      if (!isEmpty(previewList)) {
        setPreviewContentList(previewList);
        setAddedList(previewList.filter((f) => f.editAction === 'added').map((m) => m.channelNumber));
        setRemovedList(previewList.filter((f) => f.editAction === 'removed').map((m) => m.channelNumber));
        setUpdateList(previewList.filter((f) => f.editAction === 'update').map((m) => m.channelNumber));
        setChangeDate(
          previewList.reduce((prev, curr) => {
            return prev.lastChgDate <= curr.lastChgDate ? curr : prev;
          }).lastChgDate
        );
      } else {
        setPreviewContentList([]);
        setAddedList([]);
        setRemovedList([]);
        setUpdateList([]);
      }
      if (previewSaveType === 'P') {
        setDialogButton([cancelButton, { ...deployButton, label: 'Published', disabled: isEmpty(previewList) }]);
      } else {
        setDialogButton([cancelButton, { ...deployButton, disabled: isEmpty(previewList) }]);
      }
    }
  };

  const deploySavedChannelSave = async () => {
    setDialogButton([cancelButton, { ...deployButton, disabled: true }]);
    const requestBody = {
      countryCode: previewCountry,
      channelMapId: previewChannelMapId,
      saveType: previewSaveType,
    };
    const body = await putRequest(`/channel-map/${previewChannelMapId}/channel`, requestBody, null);
    let title = '';
    let content = '';
    if (body && body.result === 'SUCCESS') {
      onClose();
      title = body.result;
      content = 'Publish successfully.';
    } else if (body && body.result === 'FAILED') {
      title = body.result;
      content = body.message;
    } else {
      title = body.error;
      content = 'Contact your administrator!';
    }
    setPopupType({ ...defaultPopupType, alarm: true, title: title, content: content });
  };

  const deployHandler = () => {
    deploySavedChannelSave();
  };

  const closeDialog = () => {
    setPopupType(defaultPopupType);
  };

  const cancelButton = {
    minWidth: '150px',
    color: 'tertiary',
    type: 'outline',
    size: 'large',
    label: 'Cancel',
    onClick: closeHandler,
  };

  const deployButton = {
    minWidth: '150px',
    color: 'primary',
    type: 'box',
    size: 'large',
    label: 'Testing',
    disabled: true,
    onClick: deployHandler,
  };

  const [dialogButton, setDialogButton] = useState([cancelButton, deployButton]);

  const closeButton = {
    buttonId: 'closeButton',
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    isIcon: false,
    Icon: null,
    fontWeight: 'bold',
    disabled: false,
    label: 'close',
    onClick: closeDialog,
  };

  const channelRemoveActionButtons = [closeButton];
  return (
    <div className='component-view'>
      <Dialog open={isOpen} size='medium' fullWidth className='pop-channel-count'>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 18,
          }}>
          <CloseIcon />
        </IconButton>
        <HeaderArea className='header-wrap'>
          <DialogTitle>Check Dialog</DialogTitle>
        </HeaderArea>
        <DialogContent className='pop-content check-dialog'>
          <BoxContainer>
            <Box className='content-inner-wrap'>
              <StatusCheckBox className='status-check-box'>
                <List disablePadding>
                  {isEmpty(previewContentList) ? (
                    <ListItem disablePadding>
                      <Typography component='span' className='status-data'>
                        There is no changes.
                      </Typography>
                    </ListItem>
                  ) : (
                    <Fragment>
                      {/* TODO */}
                      {!isEmpty(addedList) && (
                        <ListItem disablePadding>
                          <Typography component='span' className='status-data label'>
                            added
                          </Typography>
                          <Typography component='span' className='status-data'>
                            {addedList.toString()}
                          </Typography>
                        </ListItem>
                      )}
                      {!isEmpty(removedList) && (
                        <ListItem disablePadding>
                          <Typography component='span' className='status-data label'>
                            removed
                          </Typography>
                          <Typography component='span' className='status-data'>
                            {removedList.toString()}
                          </Typography>
                        </ListItem>
                      )}
                      {!isEmpty(updateList) && (
                        <ListItem disablePadding>
                          <Typography component='span' className='status-data label'>
                            update
                          </Typography>
                          <Typography component='span' className='status-data'>
                            {updateList.toString()}
                          </Typography>
                        </ListItem>
                      )}
                    </Fragment>
                  )}
                </List>
              </StatusCheckBox>
              <ToBeArea className='toBe-area' sx={{ marginTop: '24px' }}>
                <Typography className='area-title'>To-Be</Typography>
                <Typography component='p' className='update-time'>
                  Last Update date
                  <Typography component='span' className='time-data'>
                    {changeDate}
                  </Typography>
                </Typography>
              </ToBeArea>
              {/* Table */}
              <CustomTableContainer className='table-container'>
                <Table className='custom-table' aria-label='table' stickyHeader>
                  {/* table Head */}
                  <TableHead className='thead'>
                    <TableRow className='tr'>
                      <TableCell key={'preview-number'} className='th' align={'left'} sx={{ width: '7%' }}>
                        <SortLabel
                          labelText={'Ch.No'}
                          labelId={'channelNumber'}
                          sortable={true}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          order={order}
                          setOrder={setOrder}
                          sorting={previewChannelSort}
                        />
                      </TableCell>
                      <TableCell key={'preview-name'} className='th' align={'left'} sx={{ width: '17%' }}>
                        <SortLabel
                          labelText={'Ch.Name'}
                          labelId={'channelName'}
                          sortable={true}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          order={order}
                          setOrder={setOrder}
                          sorting={previewChannelSort}
                        />
                      </TableCell>
                      <TableCell key={'preview-id'} className='th' align={'left'} sx={{ width: '25%' }}>
                        <SortLabel labelText={'Ch.ID'} labelId={'channelId'} sortable={true} orderBy={orderBy} setOrderBy={setOrderBy} order={order} setOrder={setOrder} sorting={previewChannelSort} />
                      </TableCell>
                      <TableCell key={'preview-dma'} className='th' align={'left'} sx={{ width: '20%' }}>
                        <SortLabel
                          labelText={'DMA Group Name'}
                          labelId={'dmaGroupName'}
                          sortable={true}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          order={order}
                          setOrder={setOrder}
                          sorting={previewChannelSort}
                        />
                      </TableCell>
                      <TableCell key={'preview-date'} className='th' align={'left'} sx={{ width: '20%' }}>
                        <SortLabel
                          labelText={'LastChgDate'}
                          labelId={'lastChgDate'}
                          sortable={true}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          order={order}
                          setOrder={setOrder}
                          sorting={previewChannelSort}
                        />
                      </TableCell>
                      <TableCell key={'preview-action'} className='th' align={'left'} sx={{ width: 'auto' }}>
                        <SortLabel
                          labelText={'Event'}
                          labelId={'editAction'}
                          sortable={true}
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          order={order}
                          setOrder={setOrder}
                          sorting={previewChannelSort}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* table Body */}
                  {isEmpty(previewContentList) ? (
                    <TableBody className='tbody'></TableBody>
                  ) : (
                    <TableBody className='tbody'>
                      {previewContentList.map((row, index) => (
                        <TableRow className={`tr ${row.editAction}`} key={`preview-${index}`}>
                          <CellType key={`preview-number-${index}`} className='td' align={'left'}>
                            {row.channelNumber}
                          </CellType>
                          <CellType key={`preview-name-${index}`} className='td' align={'left'}>
                            {row.channelName}
                          </CellType>
                          <CellType key={`preview-id-${index}`} className='td' align={'left'}>
                            {row.channelId}
                          </CellType>
                          <CellType key={`preview-dma-${index}`} className='td' align={'left'}>
                            {isEmpty(row.dmaGroupName) ? '' : row.dmaGroupName}
                          </CellType>
                          <CellType key={`preview-date-${index}`} className='td' align={'left'}>
                            {row.lastChgDate}
                          </CellType>
                          <CellType key={`preview-action-${index}`} className='td' align={'left'}>
                            <span className={row.editAction}>{row.editAction}</span>
                          </CellType>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </CustomTableContainer>
            </Box>
          </BoxContainer>
        </DialogContent>
        <Divider sx={{ margin: '24px 0' }} />
        <DialogActions className='align-center' sx={{ padding: 0 }}>
          <CustomButtonGroup buttons={dialogButton} justifyContent='center' />
        </DialogActions>
      </Dialog>
      {/* severity: default, error, warning, info, success */}
      <Snackbar severity='warning' message='*You can have up to 10 favorite menus.' open={false} Duration='1000' bottom={'8%'} />
      {/* handleClose={closeDialog} buttons={channelRemoveActionButtons} */}
      {popupType.alarm && (
        <BasicDialog title={popupType.title} contentTitle={popupType.content} size={'small'} closeX={true} open={true} handleClose={closeDialog} buttons={channelRemoveActionButtons} />
      )}
    </div>
  );
}

export default PopCheckDialog;
