import React, {useEffect, useState} from 'react';
import Dialog from 'components/component/BasicDialog';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import { connect } from "react-redux";

import { Grid, Box, Typography } from '@mui/material';

// 메인 컨텐츠
function PopEditCPName({ openEditCp, closeEditCp, providerInfo, provider }) {

  const boolOptions = [
    { id: 'Y', value: 'Y', option: 'Y', type: 'text' },
    { id: 'N', value: 'N', option: 'N', type: 'text' }
  ]

  // TODO 현 컴포넌트 미완성 상태. state 사용 예정
  // eslint-disable-next-line
  const [selectedCpParents, setSelectedCpParents] = useState('');
  const [selectedLgPlayerFlag, setSelectedLgPlayerFlag] = useState('N');
  // eslint-disable-next-line
  const [paramInteractiveUrl, setParamInteractiveUrl] = useState('');
  // eslint-disable-next-line
  const [paramCpLogoUrl, setParamCpLogoUrl] = useState('');
  const [allCpOption, setAllCpOption] = useState([]);

  const handleClose = () => {
    closeEditCp()
  };

  useEffect(() => {
    setSelectedCpParents(providerInfo.parentProviderId)
    setSelectedLgPlayerFlag(providerInfo.lgPlayerFlag)
    setParamInteractiveUrl(providerInfo.interactiveUrl)
    setParamCpLogoUrl(providerInfo.logoUrl)

  }, [providerInfo]);

  useEffect(() => {
    if (provider && provider.entire) {
      let map = provider.entire.map(cp => ({ id: cp.providerId, value: cp.providerId, option: cp.providerId, type: 'text' }));
      setAllCpOption([{ id: 'None', value: 'None', option: 'None', type: 'text' },...map]);
    }
  }, [provider]);

  // Button Data
  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      disabled: false,
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={openEditCp}
        closeX={true}
        title='Edit CP Name' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-addChannel'
        contentTextProps={
          <Grid container item xs={12}>
            {/* CP Code */}
            <Grid item xs={12}>
              <Box className='detail-list-container'>
                <Box item xs={12} className='list-wrap'>
                  <Box className='info-value-wrap'>
                    <Box component='div' className='label'>
                      <span className='text'>CP Code</span>
                    </Box>

                    <div className='value'>
                      <Typography component='span' className='text'>
                        {providerInfo.providerId}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* CP Name */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <TextField
                  id='cp-name'
                  type='outlined'
                  size='large'
                  placeholder='ex)Rakuten'
                  defaultValue={providerInfo.providerName}
                  label='CP Name'
                  autoComplete='off'
                  required={true}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Grid>

            {/* CP Parents */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='admin-Parents'
                  size='large'
                  shrink={true}
                  label='CP Parents'
                  required={false}
                  selectOptions={allCpOption}
                  disabled={false}
                  placeholder='Select'
                  selected={providerInfo.parentProviderId}
                />
              </Box>
            </Grid>

            {/* Use LGPlayer* */}
            <Grid item xs={12} mb={1}>
              <Box component='div' className='field-box'>
                <SelectBox
                  id='admin-LGPlayer'
                  size='large'
                  shrink={true}
                  label='Use LGPlayer'
                  required={true}
                  selectOptions={boolOptions}
                  disabled={false}
                  placeholder='Select'
                  selected={selectedLgPlayerFlag}
                />
              </Box>
            </Grid>

            {/* Interactive URL */}
            <Grid item xs={12} mt={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='text'
                  label='Interactive URL'
                  size='large'
                  fullWidth
                  placeholder='Http://'
                  name=''
                  id='InteractiveURL'
                  required={false}
                  error={false}
                  InputLabelProps={{ shrink: true }}
                  defaultValue={providerInfo.interactiveUrl}
                />
                <Typography className='input-sub-text'>
                  If LG Player is not used, this Interactive URL is mandatory
                </Typography>
              </Box>
            </Grid>

            {/* CP Logo URL */}
            <Grid item xs={12} mt={1}>
              <Box component='div' className='field-box'>
                <TextField
                  type='text'
                  label='CP Logo URL'
                  size='large'
                  fullWidth
                  placeholder='Http://'
                  name=''
                  id='LogoURL'
                  required={true}
                  error={false}
                  InputLabelProps={{ shrink: true }}
                  defaultValue={providerInfo.logoUrl}
                />
              </Box>
            </Grid>
          </Grid>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  provider: state.common.provider,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PopEditCPName);
