import { React, useEffect, useMemo, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import TextField from 'components/component/BasicTextField';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { getAreaCodesByCountry, getAreaTypesByCountry, saveCreateArea } from '../api/restApi';
import { areaTypeOption } from '../data/OrganizationData';

// 메인 컨텐츠
function PopCreateArea(props) {
  const { selectedCountry, regionOption, countryOption, selectedRegion, areaCodeList, onClose, handelSetShowSnakBar, getTData } = props;
  const [createPRArea, setcreatePRArea] = useState({
    region: selectedRegion,
    country: selectedCountry,
    areaCode: "",
    areaName: "",
    areaType: areaTypeOption[0].value,
  });
  const [countryAreaCodes, setCountryAreaCodes] = useState([]);
  const [countryAreaTypes, setCountryAreaTypes] = useState([]);

  const getAreaCodesByCountryData = async (country) => {
    let areaCodes = [];
    let areaCode = "";
    const resAreaCode = await getAreaCodesByCountry(country);
    areaCodeList.forEach((c) => {
      areaCode =
        !areaCode && resAreaCode.data.indexOf(c.value) === -1
          ? c.value
          : areaCode;

      areaCodes.push({
        ...c,
        disabled: resAreaCode.data.indexOf(c.value) > -1 || false,
      });
    });
    setCountryAreaCodes(areaCodes);
    setcreatePRArea((pewvState) => ({
      ...pewvState,
      areaCode: areaCode,
    }));
  };

  const onChangeRegion = (eValue) => {
    const value = eValue;
    let defaultCountry = countryOption[value][0].value;
    if (value === "All") {
      defaultCountry =
        countryOption[value][countryOption[value].length - 1].value;
    }
    setcreatePRArea((pewvState) => ({
      ...pewvState,
      region: value,
      country: defaultCountry,
    }));
  };

  const onChangeCountry = (value) => {
    setcreatePRArea((pewvState) => ({
      ...pewvState,
      country: value,
    }));
  };

  const onChangeAreaCode = (value) => {
    setcreatePRArea((pewvState) => ({
      ...pewvState,
      areaCode: value,
    }));
  };

  const onChangeAreaName = (event) => {
    const value = event.target.value;
    setcreatePRArea((pewvState) => ({
      ...pewvState,
      areaName: value,
    }));
  };

  const onChangeAreaType = (value) => {
    setcreatePRArea((pewvState) => ({
      ...pewvState,
      areaType: value,
    }));
  };

  const clickSave = async () => {
    const createAreaData = { ...createPRArea };
    const isValidate = await validateSave();
    if (!isValidate) {
      return;
    } else {
      const res = await saveCreateArea(createAreaData);
      handleClose();
      getTData();
      handelSetShowSnakBar({
        open: true,
        message: res.message,
        severity: res.severity,
      });
    }
  };

  const handleClose = () => {
    onClose();
  };

  const getAreaTypesByCountryData = async (country) => {
    const resAreaTypes = await getAreaTypesByCountry(country);
    setCountryAreaTypes(resAreaTypes);
  };

  const validateSave = async () => {
    if (countryAreaTypes.data.includes(createPRArea.areaType)) {
      handelSetShowSnakBar({
        open: true,
        message: "Same Area Type already exists",
        severity: "error",
      });
      return false;
    }
    return true;
  };

  const onCreateAreaCancelBtn = () => {
    handleClose();
  };

  const onCreateAreaSaveBtn = () => {
    clickSave();
  };

  const isActive =
    createPRArea.region &&
    createPRArea.country &&
    createPRArea.areaCode &&
    createPRArea.areaType &&
    createPRArea.areaName?.trim() !== "";

  const dialogButtons = useMemo(() => {
    return [
      {
        width: "150px",
        size: "large",
        label: "Cancel",
        type: "outline",
        color: "tertiary",
        onClick: onCreateAreaCancelBtn,
        disabled: false,
      },
      {
        width: "150px",
        size: "large",
        label: "Save",
        type: "box",
        color: "primary",
        onClick: onCreateAreaSaveBtn,
        disabled: !isActive,
      },
    ];
    // eslint-disable-next-line
  }, [isActive, createPRArea]);

  useEffect(() => {
    getAreaCodesByCountryData(createPRArea.country);
    getAreaTypesByCountryData(createPRArea.country);
    // eslint-disable-next-line
  }, [createPRArea.country]);

  return (
    <div className='component-view'>
      <Dialog
        open={true}
        closeX={true}
        title='Create Area'
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate='2024-07-26'
        handleClose={handleClose}
        size='medium'
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={6} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-01'
                    fullWidth
                    size='large'
                    width='100%'
                    selectOptions={regionOption}
                    disabled={false}
                    label='Region'
                    selected={createPRArea.region}
                    onChange={onChangeRegion}
                    shrink
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-02'
                    fullWidth
                    size='large'
                    width='100%'
                    require
                    selectOptions={countryOption[createPRArea.region]}
                    disabled={false}
                    required={true}
                    label='Country'
                    selected={createPRArea.country}
                    onChange={onChangeCountry}
                    shrink
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-0e'
                    label='Area Code'
                    selected={createPRArea.areaCode}
                    onChange={onChangeAreaCode}
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    selectOptions={countryAreaCodes}
                    disabled={false}
                    shrink
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '8px' }}>
                <Box component='div' className='field-box'>
                  <TextField
                    type='outlined'
                    size='large'
                    required
                    placeholder='ex) Area Name'
                    label='Area Name'
                    defaultValue={createPRArea.areaName}
                    onChange={onChangeAreaName}
                    autoComplete='off'
                    InputLabelProps={{ shrink: true }}
                    maxLength={200}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box component='div' className='field-box'>
                  <SelectBox
                    id='select-0e'
                    fullWidth
                    size='large'
                    width='100%'
                    required={true}
                    selectOptions={areaTypeOption}
                    disabled={false}
                    label='Area Type'
                    selected={createPRArea.areaType}
                    onChange={onChangeAreaType}
                    placeholder={'Select area type'}
                    shrink
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        }
        buttons={dialogButtons}
      />
    </div>

  );
}

export default PopCreateArea;