import React, { useState } from 'react';
import Button from 'components/component/BasicButton';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from 'components/component/BasicPagination';
import FileUpload from 'components/component/BasicFileUpload';
import CheckBox from 'components/component/BasicCheckBox';
import SearchField from 'components/component/BasicSearchField';
import Snackbar from 'components/component/BasicSnackbar';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { styled } from '@mui/material/styles';
// data
import {
  selectOption,
  codeDMATh,
  codeDMATd,
} from 'pages/Configuration/CodeManagement/data/CodeDmaData';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function CodeManagementDMA() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedTdRows = sortRows([...codeDMATd], orderBy, order);

  return (
    <Box className='white-box white-box-wrap'>
      <div className='box-content'>
        <Box className='white-box-content-top-area' alignItems='flex-end'>
          <Box className='left-area column'>
            <Box className='total-count'>
              <Box component='span' className='label'>
                Total
              </Box>
              <Box component='span' className='value'>
                6
              </Box>
            </Box>
            <Box display='flex' gap={1}>
              <SelectBox
                  id='select-search'
                  size='medium'
                  selectOptions={selectOption}
                  label=''
                  maxWidth='180px'
                  disabled={false}
                  placeholder=''
              />

              <SearchField placeholder='Please enter a search term' maxWidth='230px' />
            </Box>
          </Box>

                <Box className='right-area'>
                  <Box display={'flex'} flexGrow={'1'} justifyContent={'flex-end'} gap={1}>
                    <Button color='tertiary' type='outline' size='medium' label='Template download' isIcon={false} />
                    <Box flexGrow='1' maxWidth={450}>
                      <FileUpload
                        id='fileUpload' // 싱글 웹페이지에 멀티 업로드 구현 시 ID 값을 상이하게 정의해야 동작
                        buttonLabel='Upload'
                        size='large'
                        placeholder='File Upload(Only .xlsx files can be uploaded.)'
                        onFileChange={(file) => file}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {codeDMATh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                              />

                              {column.sortable ? (
                                  <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                              ) : (
                                  // 체크박스만 있을 때도 label 표시
                                  <span className='chk-th'>{column.label}</span>
                              )}
                            </>
                        ) : column.sortable ? (
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                        ) : (
                            column.label
                        )}
                      </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* table Body */}
              <TableBody className='tbody'>
                {sortedTdRows.map((td, index) => (
                    <TableRow className='tr' key={td.id || index}>
                      {codeDMATh.map((column, colIndex) => (
                          <TableCell key={column.id} className='td' align={column.align}>
                            {td[`data0${colIndex + 1}`]}
                          </TableCell>
                      ))}
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
          {/* // Table */}
        </Box>

        {/* Pagination */}
        <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
      </div>
      {/* severity: default, error, warning, info, success */}
      <Snackbar severity='warning' message='Excel upload failed.' open={true} Duration='1000' bottom={'8%'} />
    </Box>
  );
}

export default CodeManagementDMA;
