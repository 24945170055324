export const APIOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: '/programschedules', value: '/programschedules', option: '/programschedules', type: 'text' },
  { id: '/channels', value: '/channels', option: '/channels', type: 'text' },
  { id: '/vods', value: '/vods', option: '/vods', type: 'text' },
  { id: '/programs', value: '/programs', option: '/programs', type: 'text' },
  { id: '/schedules', value: '/schedules', option: '/schedules', type: 'text' },
];

export const StatusOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'success', value: 'success', option: 'success', type: 'text' },
  { id: 'fail', value: 'fail', option: 'fail', type: 'text' },
];

export const selectOption = [
  { id: 'All', value: 'All', option: 'All', type: 'text' },
  { id: 'file', value: 'file', option: 'file', type: 'text' },
  { id: 'message', value: 'message', option: 'message', type: 'text' },
];

export const ApiCallLogTitle = [
  {
    id: 'providerName',
    align: 'left',
    label: 'CP',
    width: '7%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'apiName',
    align: 'left',
    label: 'API',
    width: '10%',
    checkbox: false,
    sortable: true,
  },

  {
    id: 'file',
    align: 'left',
    label: 'File',
    width: '22%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'successFlag',
    align: 'left',
    label: 'Status',
    width: '5%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'message',
    align: 'left',
    label: 'Message',
    width: '28%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'crtDate',
    align: 'left',
    label: 'Start Date',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'lastChgDate',
    align: 'left',
    label: 'End Date',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'action',
    align: 'left',
    label: 'Action',
    width: 'auto',
    checkbox: false,
    sortable: false,
  },
];
