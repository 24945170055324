import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CheckBox from 'components/component/BasicCheckBox';
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { styled } from '@mui/material/styles';

// data
import { BannerOrderTh } from 'pages/Organization/WebService/data/CategoryListData';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import { useNavigate } from 'react-router-dom';
import BasicDialog from 'components/component/BasicDialog';
import BasicSnackbar from 'components/component/BasicSnackbar';
import { reOrderCategoryList } from '../../api/restApi';

// Styled
const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function BannerOrder(props) {
  const { message } = props;
  const { areaId, country, areaName, areaType, data = [], region, selectedStatus } = message;
  const filteredYRows = data.filter(row => row.enable === 'ON') || [];
  const filteredNRows = data.filter(row => row.enable === 'OFF') || [];
  const [rows, setRows] = useState([...filteredYRows, ...filteredNRows]);
  const [actionDialog, setActionDialog] = useState("");
  const [showSnakBar, setShowSnakBar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [params, setParams] = useState({
    order_type: "asc",
    order: "categoryOrder",
    region: "",
    page: 1,
    size: 10,
  });

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const navigate = useNavigate();

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Web Service',
    normalMenu: ['Category List', 'Category Details'],
  };

  const crudButtons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Cancel',
      isIcon: false,
      Icon: null,
      autosize: true,
      onClick: () => navigate('/WebService', {
        replace: true, state: {
          move: true,
          targetComponent: 'ListBanner',
          message: {
            idx: areaId,
            areaName: areaName,
            areaType: areaType,
            country: country,
            region: region,
            selectedStatus: selectedStatus
          }
        }
      })
    },
    {
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Save',
      isIcon: false,
      Icon: null,
      autosize: true,
      onClick: () => setActionDialog('Save')
    },
  ];

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    let items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items = items.map((item, index) => {
      item.categoryOrder = index + 1;
      return item;
    });
    setRows(items);
  };

  const filteredRows = rows.filter(row => row.enable === 'ON') || [];
  let sortedPrTdRows = sortRows([...filteredRows], params.order, params.order_type);

  const onSaveOKBtn = () => {
    onClickSaveButton();
  };
  const onActionCancelBtn = () => {
    setActionDialog("");
  };

  const onClickSaveButton = async () => {
    const res = await reOrderCategoryList(data);
    setActionDialog("");
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    navigate("/WebService", {
      replace: true,
      state: {
        move: true,
        targetComponent: "ListBanner",
        message: {
          idx: areaId,
          areaName: areaName,
          areaType: areaType,
          country: country,
          selectedStatus: selectedStatus,
          region: region,
        },
      },
    });
  };

  const handelSetShowSnakBar = ({ open, message, severity }) => {
    setShowSnakBar({
      open,
      message,
      severity,
    });
    setParams({ ...params });
  };

  const saveActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onSaveOKBtn,
    },
  ];

  const infos = [
    {
      index: 1,
      label: "#Country",
      value: country,
      divide: true,
    },
    {
      index: 2,
      label: "#Selected Area",
      value: areaName,
      divide: true,
    },
    {
      index: 3,
      label: "#Area Type",
      value: "Banner (1920px X 305px)",
      divide: false,
    },
  ];

  const handleSnackbarClose = () => {
    setShowSnakBar({ open: false, message: "", severity: "" });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container pr-page-container' component='main'>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link
                className='title link'
                to='#'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/WebService', {
                    replace: true, state: {
                      move: true,
                      targetComponent: 'ListBanner',
                      message: {
                        idx: areaId,
                        areaName: areaName,
                        areaType: areaType,
                        country: country,
                        region: region,
                        selectedStatus: selectedStatus
                      }
                    }
                  })
                }}>                                Category List
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap category-list' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'>
              <CustomButtonGroup buttons={crudButtons} />
            </Box>
          </Box>

          <Box className='white-box white-box-wrap single-box'>
            <div className='box-content'>
              <Box className='content-inner-wrap'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId='droppable-table'>
                    {(provided) => (
                      <CustomTableContainer
                        className='table-container'
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <Table className='custom-table' aria-label='table' stickyHeader>
                          {/* table Head */}
                          <TableHead className='thead'>
                            <TableRow className='tr'>
                              {BannerOrderTh.map((column) => (
                                <TableCell
                                  key={column.id}
                                  className='th'
                                  align={column.align}
                                  sx={{ width: column.width }}
                                >
                                  {column.checkbox ? (
                                    <Box display='flex' alignContent='center'>
                                      <IconDraggable className='icon-draggable' sx={{ flexShrink: 0 }} />
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                      />
                                      {column.sortable && <TableSortLabel
                                        active={params.order === column.id}
                                        direction={params.order === column.id ? params.order_type : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>}
                                    </Box>
                                  ) : (
                                    <>
                                      {column.sortable ? (
                                        <TableSortLabel
                                          active={params.order === column.id}
                                          direction={params.order === column.id ? params.order_type : 'desc'}
                                          onClick={() => handleRequestSort(column.id)}
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      ) : (
                                        column.label
                                      )}
                                    </>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          {/* table Body */}
                          <TableBody className='tbody'>
                            {sortedPrTdRows.map((td, index) => (
                              <Draggable key={td.id || index} draggableId={td.id || index.toString()} index={index}>
                                {(provided) => (
                                  <TableRow
                                    className='tr'
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {BannerOrderTh.map((column, colIndex) => (
                                      <TableCell
                                        key={column.id}
                                        className='td'
                                        align={column.align}
                                      >
                                        {colIndex === 0 ? (
                                          <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                            <IconDraggable className='icon-draggable' />
                                            {td.categoryOrder}
                                          </Box>
                                        ) : (
                                          td[column.id]
                                        )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </TableBody>
                        </Table>
                      </CustomTableContainer>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>

              <div>
                {(actionDialog === 'Save') && <BasicDialog
                  title={actionDialog === 'Save' ? 'Re-order List' : ''}
                  contentTitle={actionDialog === 'Save' ? ' Are you sure to re-order?' : ''}
                  size={'small'}
                  closeX={true}
                  open={true}
                  handleClose={() => setActionDialog('')}
                  buttons={actionDialog === 'Delete' ? '' : actionDialog === 'Save' ? saveActionButtons : []}
                />}
              </div>
              <div>
                <BasicSnackbar onClose={handleSnackbarClose} severity={showSnakBar.severity} message={showSnakBar.message} open={showSnakBar.open} duration={1000} />
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BannerOrder;
