import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import { Box, Typography } from '@mui/material';
import PRPage from './PRPage/PRPage';

function WebService(props) {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabs = [
    { label: "PR Page" },
    { label: "Display Page" },
    { label: "Playback Page" },
  ];

  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Web Service',
  };

  return (
    <Box sx={{ display: 'flex' }} className='wrapper'>
      <Box className='main-container pr-page-container' component='main'>
        {/* BreadCrumb Area */}
        <div className='top-title-container'>
          <div className='top-title-box'>
            <div className='title-area'>
              <Typography component='p' className='title'>
                Web Service
              </Typography>
            </div>
            <div className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </div>
          </div>
        </div>
        {/*  //BreadCrumb Area */}

        <Box className='content-wrap ' component='div'>
          <div className='tab-container'>
            <CustomTabs tabStyle='type-01' value={tabValue} handleChange={handleTabChange} tabs={tabs} />
            {tabValue === 0 && (
              <PRPage webServiceProps={props} />
            )}
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default WebService;
