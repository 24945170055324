import React, { useState, useEffect } from 'react';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions,
  Box,
  IconButton,
  Divider,
  Typography,
  Grid,
} from '@mui/material';

import Button from 'components/component/BasicButton';
import Snackbar from 'components/component/BasicSnackbar';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import { styled } from '@mui/system';
// Icon
import CloseIcon32 from 'assets/images/icon/ic_close_32_outline.svg';

// Images
import { BtnTeritaryOutlineRight } from 'components/component/BasicIcon';
import {BASE_URL} from "../../../constants";
import {getHeaders} from "../../../utils/Header";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '1118px', //팝업사이즈
    maxWidth: 'none',
    maxHeight: 'none',
    padding: '16px 32px 32px',
    borderRadius: '20px',
  },
}));
const HeaderArea = styled('div')`
  padding: 10px 0;
`;

const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 36px;
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
  flex-shrink: 0;
`;
const BoxContainer = styled(Box)`
  width: 100%;
  display: flex;
  gap: 20px;
`;

const CloseIcon = styled('span')({
  display: 'block',
  width: '32px',
  height: '32px',
  backgroundImage: `url(${CloseIcon32})`,
  backgroundRepeat: 'no-repeat',
});

// DetailItem 컴포넌트 생성
const DetailItem = ({ label, value, xs, className }) => (

  <Grid item xs={xs} className={`list-wrap ${className}`}>
    <Box className='info-value-wrap' pb={1}>
      <Typography component='span' className='label'>
        {label}
      </Typography>
      <div className='value'>
        <Typography component='span' className='text'>
          {value}
        </Typography>
      </div>
    </Box>
  </Grid>
);

const openImgNewWindow = (url) => {
  window.open(url);
}

const DetailImageItem = ({ label, xs, img }) => {

  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);

  useEffect(() => {
    const logoImg = new Image();
    logoImg.src = img;
    logoImg.onload = () => {
      setImgWidth(logoImg.width);
      setImgHeight(logoImg.height);
    };
  }, [img]);

  return (
  <Grid item alignItems='center' className='list-wrap'>
    <Box className='image-value-area'>
      <Typography component='span' className='label'>
        {label}
      </Typography>
      <Box className='value'>
        <Box className='image-wrap'>
          <CustomImage src={img} fit={false} wrapHeight='100px' alt='' rounded
                       bgDel={true}/>
          <CustomImage src={img} fit={false} wrapHeight='100px' alt='' rounded/>
        </Box>
        <Box className='img-url-wrap' display='flex'
             justifyContent='space-between'>
          <Typography className='img-size'>{imgWidth}px X {imgHeight}px</Typography>
          <Button
              color='tertiary'
              type='outline'
              size='small'
              label='Image URL'
              isIcon={true}
              Icon={BtnTeritaryOutlineRight}
              iconPos='right'
              href='#'
              onClick={() => openImgNewWindow(img)}
          />
        </Box>
      </Box>
    </Box>
  </Grid>
  )};

function PopChannelDetail({ channelId, channelDetailOpen, closeChannelDetail }) {

  const [channelInfo, setChannelInfo] = useState({});
  const [repreImgSize, setRepreImgSize] = useState({width: 0, height: 0})

  const handleClose = () => {
    closeChannelDetail();
  };

  useEffect(() => {
    fetch(`${BASE_URL}/channel-status/channel/detail/${channelId}`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setChannelInfo(body.data)
        if (body.data.repreImageUrl) {
          const img = new Image();
          img.src = body.data.repreImageUrl;
          img.onload = () => {
            setRepreImgSize({width: img.width, height: img.height});
          };
        }
      }
    });
  }, [channelId]);

  const buttons = [
    {
      minWidth: '150px',
      color: 'primary',
      type: 'box',
      size: 'large',
      label: 'OK',
      isIcon: 'false',
      IconType: '',
      iconPos: undefined,
      className: 'btn',
      onClick: handleClose
    },
  ];

  return (
      <div className='component-view'>
        <Dialog open={channelDetailOpen} onClose={handleClose} size='large'
                className='pop-channel-detail' sx={{position:'absolute', overflowY: 'auto'}}>
          <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 24,
                top: 18,
              }}
          >
            <CloseIcon/>
          </IconButton>
          <HeaderArea className='header-wrap'>
            <DialogTitle>Channel Detail</DialogTitle>
          </HeaderArea>

          <DialogContent className='pop-content'>
            <BoxContainer>
              <Box className='detail-content'>
                {/* Basic Information */}
                <Box className='detail-list-container'>
                  <Grid className='section' container columnSpacing={2.5}>
                    <Grid item xs={6}>
                      {/*
                      DetailItem - 굵은 폰트 클래스 : bold / 컬러 폰트 클래스 : red
                    */}
                      <DetailItem label='Channel ID' className=''
                                  value={channelInfo.channelId}/>
                      <DetailItem label='Channel Name' className=''
                                  value={channelInfo.channelName}/>
                      <DetailItem label='Channel No' className=''
                                  value={channelInfo.channelNumber}/>
                      <DetailItem label='CP Name' className=''
                                  value={channelInfo.providerName}/>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomImage src={channelInfo.repreImageUrl} fit={false}
                                   wrapWidth='517px' wrapHeight='202px' alt=''
                                   rounded/>
                      <Box className='img-url-wrap' display='flex'
                           justifyContent='space-between'>
                        <Typography className='img-size'>{repreImgSize.width}px
                          X {repreImgSize.height}px</Typography>
                        <Button
                            color='tertiary'
                            type='outline'
                            size='small'
                            label='Image URL'
                            isIcon={true}
                            Icon={BtnTeritaryOutlineRight}
                            iconPos='right'
                            href='#'
                            onClick={() => openImgNewWindow(channelInfo.repreImageUrl)}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container columnSpacing={2.5}>
                    {/* 241029 Media 추가 */}
                    {/* Media */}
                    <DetailItem label='Media' className='' value={channelInfo.mediaStaticUrl} xs={12} />

                    {/* Active time Period Start */}
                    <DetailItem
                        label='Active time Period Start'
                        className=''
                        value={channelInfo.activeTimePeriodStart}
                        xs={6}
                    />

                    {/* Active Time Period End */}
                    <DetailItem label='Active Time Period End' className=''
                                value={channelInfo.activeTimePeriodEnd} xs={6}/>

                    <DetailImageItem xs={12} label='Label'
                                     img={channelInfo.logoUrl}>
                      <Box className='img-url-wrap' display='flex'
                           justifyContent='space-between'></Box>
                    </DetailImageItem>

                    <DetailItem xs={6} label='Channel Genre Code' className=''
                                value={channelInfo.channelGenreCode}/>
                    <DetailItem xs={6} label='Adult Flag' className=''
                                value={channelInfo.adultFlag === '0' ? 'False'
                                    : 'True'}/>
                  </Grid>
                </Box>
              </Box>
            </BoxContainer>
          </DialogContent>

          <Divider sx={{margin: '24px 0'}}/>
          <DialogActions className='align-center' sx={{padding: 0}}>
            <CustomButtonGroup buttons={buttons} justifyContent='center'/>
          </DialogActions>
        </Dialog>

        {/* severity: default, error, warning, info, success */}
        <Snackbar
            severity='warning'
            message='*You can have up to 10 favorite menus.'
            open={false}
            duration='1000'
            bottom={'8%'}
        />
      </div>
  );
}

export default PopChannelDetail;
