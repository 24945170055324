import React from 'react';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import TextField from 'components/component/BasicTextField';
import { Box, Grid, Typography } from '@mui/material';

import {
  DtypeOption,
  PlatformOption,
  CategoryOption,
  TabOption,
  StartTimeOption,
  EndTimeOption,
} from 'pages/Organization/ANTest/data/ANTestdata';

// 메인 컨텐츠
function PopEditANTest(props) {
  const { isOpen, setIsOpen } = props;  // TODO add selected AN Test info
  // const [selectedDeviceType, setSelectedDeviceType] = useState('auto'); // TODO set selectd ANTest deviceType value to default value
  // const [selectedPlatformVersion, setSelectedPlatformVersion] = useState(''); // TODO set selectd ANTest platformVersion value to default value
  // const [selectedTab, setSelectedTab] = useState(''); // TODO set selectd ANTest tab value to default value
  // const [selectedCategory, setSelectedCategory] = useState(''); // TODO set selectd ANTest category value to default value
  // const [title, setTitle] = useState(''); // TODO set selectd ANTest title value to default value
  // const [selectedStartDate, setSelectedStartDate] = useState(dayjs());  // TODO set selectd ANTest startDate value to default value
  // const [selectedEndDate, setSelectedEndDate] = useState(dayjs());  // TODO set selectd ANTest endDate value to default value
  // const [selectedStartTime, setSelectedStartTime] = useState('00:00');  // TODO set selectd ANTest startTime value to default value
  // const [selectedEndTime, setSelectedEndTime] = useState('00:00');  // TODO set selectd ANTest endTime value to default value

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleUpdate = () => {
    console.log('Update');
    // TODO add Update logic
    // setIsOpen(false);
  }

  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'Update',
      type: 'box',
      color: 'primary',
      onClick: handleUpdate
    },
  ];

  const fields = [
    // Country
    {
      xs: 12,
      isLabel: true,
      isBorder: true,
      label: 'Country',
      value: (
        <Typography component='span' className='text'>
          Us
        </Typography>
      ),
    },

    // Device Type
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='Device Type'
            required={true}
            size='large'
            placeholder='Select'
            selectOptions={DtypeOption}
            disabled={true}
            shrink
          />
        </>
      ),
    },

    // Platform version
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='Platform version'
            required={true}
            size='large'
            placeholder='Select'
            selectOptions={PlatformOption}
            disabled={true}
            shrink
          />
        </>
      ),
    },

    // Tab
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='Tab'
            required={true}
            size='large'
            selectOptions={TabOption}
            disabled={false}
            shrink
          />
        </>
      ),
    },

    // Category
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='Category'
            required={true}
            size='large'
            selectOptions={CategoryOption}
            disabled={false}
            shrink
          />
        </>
      ),
    },

    // Title
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <TextField
            type='outlined'
            size='large'
            placeholder=''
            defaultValue=''
            label='Title'
            autoComplete='off'
            required={true}
            InputLabelProps={{ shrink: true }}
          />
        </>
      ),
    },

    // Start Date
    {
      xs: 6,
      label: 'Start Date',
      required: true,
      value: <DatePicker label='Start Date' size='large' shrink required={true} singleDate={true} />,
    },
    // End Date
    {
      xs: 6,
      label: 'End Date',
      required: true,
      value: <DatePicker label='End Date' size='large' shrink required={true} singleDate={true} />,
    },

    // Start Time
    {
      xs: 6,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='Start Time'
            required={true}
            size='large'
            selectOptions={StartTimeOption}
            disabled={false}
            shrink
          />
        </>
      ),
    },
    {
      xs: 6,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='End Time'
            required={true}
            size='large'
            selectOptions={EndTimeOption}
            disabled={false}
            shrink
          />
        </>
      ),
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={isOpen}
        closeX={true}
        title='Edit A/N Test' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <Box className='layout-label-container'>
            <Box className='detail-list-container'>
              <Grid container columnSpacing={3}>
                {fields.map(({ xs, label, required, value, isLabel, isBorder }, index) => (
                  <Grid item xs={xs} className='list-wrap' key={index}>
                    <Box mb={1} className='info-value-wrap'>
                      {isLabel && (
                        <Typography component='span' className={`label ${required ? 'required' : ''}`}>
                          <span className='text'>{label}</span>
                        </Typography>
                      )}
                      <div className={`value ${isBorder ? 'border' : ''}`}>{value}</div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopEditANTest;
