import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import BasicSwitches from 'components/component/BasicSwitch';
import TextLink from 'components/component/BasicTextLink';
import SearchField from 'components/component/BasicSearchField';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import Tooltip from 'components/component/BasicTooltip';
import CustomImage from 'components/component/BasicImage';
import TextField from 'components/component/BasicTextField';
import SelectBox from 'components/component/BasicSelectBox';
import CheckBox from 'components/component/BasicCheckBox';
import CustomBasicMenu from 'components/component/BasicMenu';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Typography,
  Link,
  Grid,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// ICON
import { BtnArrowRight } from 'components/component/BasicIcon';

// Data
import {
  CountryData,
  SliderData,
  contentTypeOption,
  cpNameOption,
  ContentResultTh,
  ContentResultTd,
  SelectedContentTh,
  SelectedContentTd,
} from 'pages/Organization/TabCategory/data/TabCategoryData';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';

// Icon
import { RefreshIcon } from 'components/component/BasicIcon';

const selPageData = [{ text: 'Select This Page' }, { text: 'Select All Page' }];

// Button Data
const buttons = [
  {
    color: 'tertiary',
    type: 'outline',
    size: 'medium',
    label: 'Reset',
    isIcon: true,
    Icon: RefreshIcon,
    iconPos: 'left',
    className: 'custom-save-icon',
    width: 107,
  },
  {
    color: 'primary',
    type: 'box',
    size: 'medium',
    label: 'Search',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
  },
];

const ScheduledButtons = [
  {
    index: 1,
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Delete',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    index: 2,
    color: 'tertiary',
    type: 'outline',
    size: 'small',
    label: 'Reset',
    isIcon: false,
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    index: 3,
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Upload Image',
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
  {
    index: 4,
    color: 'primary',
    type: 'box',
    size: 'small',
    label: 'Save',
    Icon: null,
    iconPos: undefined,
    autosize: true,
  },
];

// Styled
const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// ********* 메인 컨텐츠 *********
function OrganizeSlider() {
  // Select
  const [openSelect1, setOpenSelect1] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);
  const [openContentType, setOpenContentType] = useState(false);
  const [openCPName, setOpenCPName] = useState(false);

  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Tab/Category',
    normalMenu: ['Category List','Category Organize'],
  };

  const infos = [
    {
      index: 1,
      label: '#Tab',
      value: 'VOD',
      divide: true,
    },
    {
      index: 2,
      label: '#Category',
      value: 'Slider',
      divide: true,
    },
    {
      index: 3,
      label: '#Content Type',
      value: 'tvshow,movie',
      divide: false,
    },
  ];

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };
  // Table Body Data
  const sortedContentTdRows = sortRows([...ContentResultTd], orderBy, order);
  const [rows, setRows] = useState([...SelectedContentTd]);

  // Draggable Table
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRows(items);
  };

  const [isSwitchOn, setIsSwitchOn] = useState(false);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                Category Organize
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box'>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap col-6'>
                  {/* Country */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-country'
                      size='large'
                      shrink={true}
                      label='Country'
                      required={false}
                      selectOptions={CountryData}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openSelect1}
                      setOpen={setOpenSelect1}
                    />
                  </Box>
                  {/* Slider Type */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-Slider'
                      size='large'
                      shrink={true}
                      label='Slider Type'
                      required={false}
                      selectOptions={SliderData}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openSlider}
                      setOpen={setOpenSlider}
                    />
                  </Box>
                  {/* Content Type */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-Content'
                      size='large'
                      shrink={true}
                      label='Content Type'
                      required={false}
                      selectOptions={contentTypeOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openContentType}
                      setOpen={setOpenContentType}
                    />
                  </Box>
                  {/* CP Name */}
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <SelectBox
                      id='slt-Name'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      required={false}
                      selectOptions={cpNameOption}
                      disabled={false}
                      isFirstSelected={true}
                      placeholder=''
                      open={openCPName}
                      setOpen={setOpenCPName}
                    />
                  </Box>
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='ID'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      required={false}
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                  <Box component='div' className='col' sx={{ position: 'relative' }}>
                    <TextField
                      type='outlined'
                      size='large'
                      placeholder='Name'
                      defaultValue=''
                      label=''
                      autoComplete='off'
                      required={false}
                      InputLabelProps={{ shrink: false }}
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'>
                <CustomButtonGroup buttons={buttons} />
              </div>
            </Box>
          </Box>

          {/* content-pack-area 클래스 추가 되었습니다 */}
          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {/* Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Result for Content</Typography>
                      <Typography component='p' className='select-count-wrap'>
                        Select <span className='set-color'>5</span> item
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label=''
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <Button
                        color='tertiary'
                        type='box'
                        size='small'
                        label='Select Ch Input'
                        isIcon={true}
                        autosize
                        Icon={BtnArrowRight}
                        iconPos='right'
                      />
                    </Box>
                  </Box>

                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {ContentResultTh.map((column, index) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                    <Box
                                      component='div'
                                      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                    >
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                        style={{ width: '24px' }}
                                      />
                                      {index === 0 && (
                                        <CustomBasicMenu
                                          type='icon'
                                          title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                          options={selPageData}
                                        />
                                      )}
                                    </Box>
                                    <TableSortLabel
                                      active={orderBy === column.id}
                                      direction={orderBy === column.id ? order : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                  </Box>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {sortedContentTdRows.map((td, index) => (
                            <TableRow className='tr' key={td.id || index}>
                              {ContentResultTh.map((column, colIndex) => (
                                <TableCell key={column.id} className='td' align={column.align}>
                                  {colIndex === 0 ? (
                                    <CheckBox label={td[`data0${colIndex + 1}`]} value='value' defaultChecked={false} />
                                  ) : colIndex === 1 ? (
                                    <TextLink href='' text={td[`data0${colIndex + 1}`]} />
                                  ) : colIndex === 3 ? (
                                    <CustomImage
                                      src={td[`data0${colIndex + 1}`]}
                                      fit={false}
                                      alt=''
                                      wrapWidth='88px'
                                      wrapHeight='64px'
                                      rounded
                                    />
                                  ) : colIndex === 4 ? (
                                    td.tooltip ? (
                                      <div className='td-tooltip-wrap'>
                                        <Tooltip
                                          title='Genre : Crime, Biography'
                                          placement='top'
                                          className='tooltip-wrap'
                                        >
                                          <Box display='flex'>
                                            <span className='tooltip-text'>{td[`data0${colIndex + 1}`]}</span>
                                            <span className='tooltip-icon'></span>
                                          </Box>
                                        </Tooltip>
                                      </div>
                                    ) : (
                                      <span>{td[`data0${colIndex + 1}`]}</span>
                                    )
                                  ) : (
                                    td[`data0${colIndex + 1}`]
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>
                  {/* Pagination */}
                  <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
                </Box>
              </Box>
            </Grid>

            {/* Scheduled Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Scheduled Content List</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        20
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label=''
                        size='medium'
                        width='220px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                      />
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={ScheduledButtons} justifyContent='center' />
                    </Box>
                  </Box>

                  {/* Selected Content */}
                  {/* 241011 no-page-number 클래스 추가하였습니다. */}
                  <Box className='content-inner-wrap no-page-number'>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table'>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {SelectedContentTh.map((column, index) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box component='div' sx={{ display: 'flex', gap: '8px' }}>
                                          <Box
                                            component='div'
                                            sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                          >
                                            <IconDraggable className='icon-draggable' />
                                            <CheckBox
                                              label=''
                                              value='select-all'
                                              defaultChecked={false}
                                              inputProps={{
                                                'aria-label': 'select all',
                                              }}
                                              style={{ width: '24px' }}
                                            />
                                            {index === 0 && (
                                              <CustomBasicMenu
                                                type='icon'
                                                title={<ArrowDropDownIcon sx={{ fontSize: '18px', color: '#000' }} />}
                                                options={selPageData}
                                              />
                                            )}
                                          </Box>
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                            onClick={() => handleRequestSort(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={orderBy === column.id}
                                              direction={orderBy === column.id ? order : 'desc'}
                                              onClick={() => handleRequestSort(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}
                              <TableBody className='tbody'>
                                {rows.map((td, index) => (
                                  <Draggable key={td.id || index} draggableId={td.id || index.toString()} index={index}>
                                    {(provided) => (
                                      <TableRow
                                        className='tr'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {SelectedContentTh.map((column, colIndex) => (
                                          <TableCell
                                            key={column.id}
                                            className='td'
                                            align={column.align}
                                            sx={{ height: '88px' }}
                                          >
                                            {colIndex === 0 ? (
                                              <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                <IconDraggable className='icon-draggable' />

                                                <CheckBox
                                                  label={td[`data0${colIndex + 1}`]}
                                                  value='value'
                                                  defaultChecked={false}
                                                />
                                              </Box>
                                            ) : colIndex === 4 ? (
                                              <BasicSwitches
                                                disabled={false}
                                                onOffMode={isSwitchOn}
                                                setSwitchOnOff={setIsSwitchOn}
                                                isLabelText={false}
                                                onText=''
                                                offText=''
                                              />
                                            ) : colIndex === 5 ? (
                                              <CustomImage
                                                src={td[`data0${colIndex + 1}`]}
                                                fit={false}
                                                alt=''
                                                wrapWidth='106px'
                                                wrapHeight='64px'
                                                rounded
                                              />
                                            ) : colIndex === 6 ? (
                                              <CustomImage
                                                src={td[`data0${colIndex + 1}`]}
                                                fit={false}
                                                alt=''
                                                wrapWidth='106px'
                                                wrapHeight='64px'
                                                rounded
                                              />
                                            ) : (
                                              td[`data0${colIndex + 1}`]
                                            )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default OrganizeSlider;
