import React, {useEffect, useState} from 'react';
import Button from 'components/component/BasicButton';
import SelectBox from 'components/component/BasicSelectBox';
import TextLink from 'components/component/BasicTextLink';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import SearchField from 'components/component/BasicSearchField';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

// data
import { codeHomeTh, HomeAppCountrySearchOption } from 'pages/Configuration/CodeManagement/data/CodehomeAppData';
import { BASE_URL } from "../../../constants";
import { getHeaders } from "../../../utils/Header";
import PopHandleCountry from "./PopHandleCountry";

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function CodeManagementHomeAppCountry() {

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const [countryInfo, setCountryInfo] = useState([]);
  const [countryTableData, setCountryTableData] = useState([]);
  const [searchOption, setSearchOption] = useState('All');
  const [searchTextInput, setSearchTextInput] = useState('');
  const [isOpenPopHandleCountry, setIsOpenPopHandleCountry] = useState(false);
  const [popupType, setPopupType] = useState('Add');
  const [countryToEdit, setCountryToEdit] = useState({});

  const searchCountry = () => {
    fetch(`${BASE_URL}/code-management/country`, {
      method: 'GET',
      headers: getHeaders()
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        setCountryInfo(body.data);
      }
    })
  }

  useEffect(() => {
    searchCountry();
  }, []);

  useEffect(() => {
    setCountryTableData(countryInfo);
  }, [countryInfo]);

  useEffect(() => {
    if (searchOption === 'Country Name') {
      setCountryTableData(countryInfo.filter(
          (item) => item.countryName.toLowerCase().includes(
              searchTextInput.toLowerCase())));
    } else if (searchOption === 'Country Code') {
      setCountryTableData(countryInfo.filter(
          (item) => item.countryCode.toLowerCase().includes(
              searchTextInput.toLowerCase())));
    } else {
      setCountryTableData(countryInfo.filter(
          (item) => item.countryName.toLowerCase().includes(searchTextInput.toLowerCase())
              || item.countryCode.toLowerCase().includes(searchTextInput.toLowerCase())));
    }
    // eslint-disable-next-line
  }, [searchTextInput]);

  const openAddPopup = () => {
    setPopupType('Add');
    setIsOpenPopHandleCountry(true);
  }

  const openEditPopup = (country) => {
    setCountryToEdit(country);
    setPopupType('Edit');
    setIsOpenPopHandleCountry(true);
  }

  // TableBody Row
  const sortedTdRows = sortRows([...countryTableData], orderBy, order);

  return (
      <Box className='white-box white-box-wrap'>
        <div className='box-content'>
          <Box className='white-box-content-top-area' alignItems='flex-end'>
            <Box className='left-area column'>
              <Box display='flex' alignItems={'center'} gap={1}>
                <Box className='total-count'>
                  <Box component='span' className='label'>
                    Total
                  </Box>
                  <Box component='span' className='value'>
                    {countryTableData.length}
                  </Box>
                </Box>
                <Typography className='sm-sub-text' ml={1}>
                  * If existing country information needs to be updated, please contact us via Harmony.
                </Typography>
              </Box>
              <Box display='flex' gap={1}>
                <SelectBox
                    id='select-search'
                    size='medium'
                    selectOptions={HomeAppCountrySearchOption}
                    label=''
                    maxWidth='180px'
                    disabled={false}
                    placeholder=''
                    selected={searchOption}
                    onChange={setSearchOption}
                />

                <SearchField placeholder='Please enter a search term' maxWidth='230px'
                             value={searchTextInput}
                             onChange={(e) => setSearchTextInput(e.target.value)} />
              </Box>
            </Box>

            <Box className='right-area'>
              <Button color='primary' type='box' size='medium' label='Add' isIcon={false} autosize
                      onClick={openAddPopup} />
            </Box>
          </Box>
          <Box className='content-inner-wrap'>
            {/* Table */}
            <CustomTableContainer className='table-container'>
              <Table className='custom-table' aria-label='table' stickyHeader>
                {/* table Head */}
                <TableHead className='thead'>
                  <TableRow className='tr'>
                    {codeHomeTh.map((column) => (
                        <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                          {column.checkbox ? (
                              <>
                                <CheckBox
                                    label=''
                                    value='select-all'
                                    defaultChecked={false}
                                    inputProps={{
                                      'aria-label': 'select all',
                                    }}
                                />

                                {column.sortable ? (
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>
                                ) : (
                                    // 체크박스만 있을 때도 label 표시
                                    <span className='chk-th'>{column.label}</span>
                                )}
                              </>
                          ) : column.sortable ? (
                              <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                          ) : (
                              column.label
                          )}
                        </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {/* table Body */}
                <TableBody className='tbody'>
                  {sortedTdRows.map((td, index) => (
                      <TableRow className='tr' key={td.id || index}>
                        <TableCell key={'countryCode'} className='td' align={'left'}>
                          {td[`lastChgUsrNo`] ? (
                              <TextLink href='' text={td[`countryCode`]} onClick={() => openEditPopup(td)} />
                          ) : (
                              td[`countryCode`]
                          )}
                        </TableCell>
                        <TableCell key={'countryName'} className='td' align={'left'}>
                          {td[`countryName`]}
                        </TableCell>
                        <TableCell key={'countryTime'} className='td' align={'left'}>
                          {parseInt(td[`countryTime`]) >= 0 ? 'UTC+' + td[`countryTime`] : 'UTC' + td[`countryTime`]}
                        </TableCell>
                        <TableCell key={'ric'} className='td' align={'left'}>
                          {td[`ric`]}
                        </TableCell>
                        <TableCell key={'primaryLanguage'} className='td' align={'left'}>
                          {td[`primaryLanguage`]}
                        </TableCell>
                        <TableCell key={'secondaryLanguage'} className='td' align={'left'}>
                          {td[`secondaryLanguageList`].join(', ')}
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
            {/* // Table */}
          </Box>

          {/* Pagination */}
          <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
        </div>
        <PopHandleCountry isOpen={isOpenPopHandleCountry} onClose={() => setIsOpenPopHandleCountry(false)}
                          afterSave={searchCountry} popupType={popupType} countryToEdit={countryToEdit} />
      </Box>
  );
}

export default CodeManagementHomeAppCountry;
