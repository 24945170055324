import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Pagination from 'components/component/BasicPagination';
import CustomImage from 'components/component/BasicImage';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import CheckBox from 'components/component/BasicCheckBox';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Link,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

// Data
import { CategoryListTableTh } from 'pages/Organization/WebService/data/CategoryListData';
import BasicSnackbar from 'components/component/BasicSnackbar';
import BasicDialog from 'components/component/BasicDialog';
import { useNavigate } from 'react-router-dom';
import PopAddCategoryBranding from './PopAddCategoryBranding';
import { deleteWebLiveContentCategory, getWebPromotionAreaCategory } from '../../api/restApi';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function BrandingImageA(props) {
  const { message } = props;
  const { region, idx, areaName, areaType, country, selectedStatus, } = message;
  const [brandImageAData, setBrandImageAData] = useState({
    data: [],
    total: 0,
  });
  const [actionDialog, setActionDialog] = useState("");
  const [showSnakBar, setShowSnakBar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [checkedBrandImageAData, setCheckedBrandImageAData] = useState([]);
  const [params, setParams] = useState({
    idx: idx,
    page: 1,
    size: 10,
  });
  const navigate = useNavigate();

  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Web Service',
    normalMenu: ['Category List'],
  };

  const handelLinkClick = (rowData) => {
    navigate('/WebService', {
      replace: true, state: {
        move: true,
        targetComponent: 'CategoryDetailBrandingA',
        message: {
          idx: rowData.categoryIdx,
          seletedRow: rowData,
          areaId: idx,
          country: country,
          areaName: areaName,
          areaType: areaType,
          selectedStatus: selectedStatus,
          region: region,
        }
      }
    });
  };

  const brandImageAButtons = [
    {
      index: 1,
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Delete",
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: true,
      disabled: checkedBrandImageAData.length !== 0 ? false : true,
      onClick: () => setActionDialog("Delete")
    },
    {
      index: 2,
      color: "tertiary",
      type: "box",
      size: "medium",
      label: "Add Category",
      isIcon: false,
      Icon: null,
      iconPos: undefined,
      autosize: true,
      onClick: () => setActionDialog("Add Category")
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = params.order === property && params.order_type === "asc";
    setParams((prevState) => ({
      ...prevState,
      order: property,
      order_type: isAsc ? "desc" : "asc"
    }));
  };

  const onDeleteOKBtn = async () => {
    const res = await deleteWebLiveContentCategory(idx, checkedBrandImageAData);
    setActionDialog("");
    getBrandImageData();
    setShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });
    setParams({ ...params });
  };

  const onActionCancelBtn = () => {
    setActionDialog("");
  };

  const handelSetShowSnakBar = ({ open, message, severity }) => {
    setShowSnakBar({
      open,
      message,
      severity,
    });
    setParams({ ...params });
  };

  const handleSnackbarClose = () => {
    setShowSnakBar({ open: false, message: "", severity: "" });
  };

  const deleteActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onDeleteOKBtn,
    },
  ];

  const brandIamgeAInfos = [
    {
      index: 1,
      label: "#Country",
      value: country,
      divide: true,
    },
    {
      index: 2,
      label: "#Selected Area",
      value: areaName,
      divide: true,
    },
    {
      index: 3,
      label: "#Area Type",
      value: "Branding Image - A Type (1920px X 350px)",
      divide: false,
    },
  ];
  // Function to handle the button click event
  const handleButtonClick = (label) => {
    setActionDialog(label);
  };

  const handlePaginationChange = ({ type, value }) => {
    if (type === "page") {
      setParams((prevState) => ({
        ...prevState,
        page: value,
      }));
    } else if (type === "size") {
      setParams((prevState) => ({
        ...prevState,
        page: 1,
        size: parseInt(value),
      }));
    }
  };

  const handleCheckBox = (event) => {
    let idxs = [];
    if (event.target.checked && event.target.name === "select-all") {
      idxs = brandImageAData.data.map((row) => row.categoryIdx);
    } else if (!event.target.checked && event.target.name === "select-all") {
      idxs = [];
    } else if (event.target.checked) {
      idxs = [...checkedBrandImageAData, parseInt(event.target.name)];
    } else if (!event.target.checked) {
      idxs = checkedBrandImageAData.filter(
        (row) => row !== parseInt(event.target.name)
      );
    }
    setCheckedBrandImageAData(idxs);
  };
  const getBrandImageData = async () => {
    const res = await getWebPromotionAreaCategory(idx);
    setCheckedBrandImageAData([]);
    setBrandImageAData(res);
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === "asc") {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  let sortedBrandImageTdRows = sortRows([...brandImageAData.data], params.order, params.order_type);
  const count = Math.ceil(brandImageAData.total / params.size);
  const pageIndex = params.page > 1 ? (params?.page - 1) * params?.size : 0;
  const sizeIndex = params?.size || 10;
  sortedBrandImageTdRows = sortedBrandImageTdRows.splice(pageIndex, sizeIndex);

  useEffect(() => {
    getBrandImageData(idx);
    // eslint-disable-next-line
  }, []);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area' sx={{ cursor: 'pointer' }}>
              <Link className='title link' to='#'
                onClick={(e) => {
                  e.preventDefault();
                  // navigate(-1);
                  navigate('/WebService', {
                    replace: true, state: {
                      move: true,
                      targetComponent: 'WebService',
                      message: {
                        country: country,
                        region: region,
                        status: selectedStatus
                      }
                    }
                  });
                }}>
                Category List
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {brandIamgeAInfos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'>
              <CustomButtonGroup buttons={brandImageAButtons.filter(button => !(selectedStatus === "P"))}
                justifyContent='flex-end'
                onClick={(button) => handleButtonClick(button)}
              />
            </Box>
          </Box>

          {/* Content List */}
          <Box className='white-box white-box-wrap'>
            {/* Box Content Area */}
            <Box className='box-content'>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {CategoryListTableTh.map((column) => (
                          <TableCell
                            key={column.id}
                            className='th'
                            align={column.align}
                            sx={{ width: column.width }}
                          >
                            {column.checkbox ? (
                              <>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  checked={checkedBrandImageAData.length !== 0 && brandImageAData.total === checkedBrandImageAData.length}
                                  onChange={handleCheckBox}
                                  indeterminate={checkedBrandImageAData.length !== 0 && brandImageAData.total > checkedBrandImageAData.length}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                {column.sortable && <TableSortLabel
                                  active={params.order === column.id}
                                  direction={params.order === column.id ? params.order_type : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>}
                              </>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={params.order === column.id}
                                    direction={params.order === column.id ? params.order_type : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedBrandImageTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {CategoryListTableTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align} sx={{ height: '88px' }}>
                              {colIndex === 0 ? (
                                <CheckBox
                                  label={td.categoryOrder}
                                  value={td['categoryIdx']}
                                  onChange={handleCheckBox}
                                  checked={checkedBrandImageAData.indexOf(td['categoryIdx']) > -1}
                                />
                              ) : colIndex === 2 ? (
                                <span onClick={() => handelLinkClick(td)} style={{ color: '#2196f3', cursor: 'pointer' }} >
                                  {td[column.id]}
                                </span>
                              ) : colIndex === 3 ? (
                                <CustomImage src={td.img} fit={true} alt='' rounded
                                  wrapWidth='100%' wrapHeight='72px' imgWidth='100%' imgHeight='72px' />
                              ) : (
                                td[column.id]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
                <div>
                  {actionDialog === 'Add Category' && (
                    <PopAddCategoryBranding
                      onClose={() => setActionDialog('')}
                      handelSetShowSnakBar={handelSetShowSnakBar}
                      country={country}
                      areaName={areaName}
                      areaType={areaType}
                      idx={idx}
                      getBrandImageData={getBrandImageData}
                    />
                  )}
                </div>
                <div>
                  {actionDialog === 'Delete' && <BasicDialog
                    title={actionDialog === 'Delete' ? 'Delete Area' : ''}
                    contentTitle={actionDialog === 'Delete' ? ' Are you sure to delete?' : ''}
                    size={'small'}
                    closeX={true}
                    open={true}
                    handleClose={() => setActionDialog('')}
                    buttons={actionDialog === 'Delete' ? deleteActionButtons : []}
                  />}
                </div>
                <div>
                  <BasicSnackbar onClose={handleSnackbarClose} severity={showSnakBar.severity} message={showSnakBar.message} open={showSnakBar.open} duration={1000} />
                </div>
              </Box>
              {/* Pagination */}
              <Pagination id='pagination-01' count={count} selected={params.size} onChangeHandle={handlePaginationChange} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BrandingImageA;
