export const CPNameOption = [
  { id: 'cp-All', value: 'All', option: 'All', type: 'text' },
  { id: 'cp-op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'cp-op2', value: 'op2', option: 'op2', type: 'text' },
];
export const CountryOption = [
  { id: 'All-2', value: 'All', option: 'All', type: 'text' },
  { id: 'api-op-2', value: 'op1', option: 'op1', type: 'text' },
];

export const DeviceOption = [
  { id: 'Type-All', value: 'All', option: 'All', type: 'text' },
  { id: 'Type-op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'Type-op2', value: 'op2', option: 'op2', type: 'text' },
];

export const typeOption = [
  { id: 'Type-All', value: 'All', option: 'All', type: 'text' },
  { id: 'Type-op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'Type-op2', value: 'op2', option: 'op2', type: 'text' },
];

export const StatusOption = [
  { id: 'Status-All', value: 'All', option: 'All', type: 'text' },
  { id: 'Status-1', value: 'Status1', option: 'Status1', type: 'text' },
  { id: 'Status-2', value: 'Status2', option: 'Status2', type: 'text' },
];

export const PlatformOption = [
  { id: 'Status-All', value: 'All', option: 'All', type: 'text' },
  { id: 'Status-1', value: 'Status1', option: 'Status1', type: 'text' },
  { id: 'Status-2', value: 'Status2', option: 'Status2', type: 'text' },
];

export const selectOption = [
  { id: 'sel-all', value: 'All', option: 'All', type: 'text' },
  { id: 'sel-option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'sel-option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const chartSetting = {};

export const piechartData = [
  {
    label: 'aaa',
    value: 34.72,
    color: '#FF5778',
  },
  {
    label: 'bbb',
    value: 18.38,
    color: '#1AD2A0',
  },
  {
    label: 'ccc',
    value: 18.38,
    color: '#1579EB',
  },
  {
    label: 'ddd',
    value: 3.83,
    color: '#FFCF4F',
  },
  {
    label: 'eee',
    value: 8.42,
    color: '#9761a3',
  },
  {
    label: 'fff',
    value: 7.65,
    color: '#ff9d68',
  },
];

export const platforms = [
  {
    label: 'Mobile',
    value: 59.12,
  },
  {
    label: 'Desktop',
    value: 40.88,
  },
];

const normalize = (v: number, v2: number) => Number.parseFloat(((v * v2) / 100).toFixed(2));

export const PopularGenreData = [
  ...piechartData.map((v) => ({
    ...v,
    label: v.label === 'Other' ? 'Other (Desktop)' : v.label,
    value: normalize(v.value, platforms[1].value),
  })),
];

export const valueFormatter = (item: { value: number }) => `${item.value}%`;

export const vodTh = [
  {
    id: 'vodTh-1',
    align: 'left',
    label: 'Type',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTh-1',
    align: 'left',
    label: 'VOD Name',
    width: '14%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTh-2',
    align: 'left',
    label: 'Country',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTh-5',
    align: 'left',
    label: 'Platform',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTh-6',
    align: 'left',
    label: 'Device type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTh-7',
    align: 'left',
    label: 'Unique Device',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTh-8',
    align: 'left',
    label: 'Play',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTh-8',
    align: 'left',
    label: 'Play per Ud',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTh-8',
    align: 'left',
    label: 'Total Duration (hr)',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'vodTh-8',
    align: 'left',
    label: 'Total Duration per Ud',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const vodTd = [
  {
    id: 'vodTd-1',
    data1: 'tvshow',
    data2: 'Quantum Leap',
    data3: 'US',
    data4: 'webOS23',
    data5: 'LG TV',
    data6: '-',
    data7: '2712',
    data8: '-',
    data9: '1409.7',
    data10: '-',
  },
  {
    id: 'vodTd-2',
    data1: 'tvshow',
    data2: 'Quantum Leap',
    data3: 'US',
    data4: 'webOS23',
    data5: 'LG TV',
    data6: '-',
    data7: '2712',
    data8: '-',
    data9: '1409.7',
    data10: '-',
  },
  {
    id: 'vodTd-3',
    data1: 'movie',
    data2: 'Die Abtrünnigen des Teufels',
    data3: 'US',
    data4: 'webOS23',
    data5: 'LG TV',
    data6: '-',
    data7: '2712',
    data8: '-',
    data9: '1409.7',
    data10: '-',
  },
];
