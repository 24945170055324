import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@mui/material';

const DetailItem = React.memo(
    ({label, xs, value, maxThWidth = false, content}) => (
        <Grid item xs={xs} className='list-wrap'>
          <Grid display='flex' item xs={12} alignItems='center'>
            {maxThWidth ? (
                <Grid item xs={3}>
                  <Typography component='span' className='label'>
                    {label}
                  </Typography>
                </Grid>
            ) : (
                <Typography component='span' className='label'>
                  {label}
                </Typography>
            )}
            <div className='value-wrap'>
              <div className='value'>
                {content ? (
                    content
                ) : (
                    <Typography component='span' className='text'>
                      {value}
                    </Typography>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
    ));

DetailItem.propTypes = {
  label: PropTypes.node.isRequired,
  xs: PropTypes.number.isRequired,
  value: PropTypes.string,
  maxThWidth: PropTypes.bool,
  content: PropTypes.node,
};

export default DetailItem;