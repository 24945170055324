import React, { useEffect, useMemo, useState } from 'react';
import Dialog from 'components/component/BasicDialog';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import TextField from 'components/component/BasicTextField';
import { Box, Grid, Typography } from '@mui/material';

import {
  DtypeOption,
  PlatformVersionOption,
} from 'pages/Organization/ANTest/data/ANTestdata';
import dayjs from 'dayjs';

// 메인 컨텐츠
function PopAddANTest(props) {
  const { isOpen, setIsOpen, tabSelectOptions } = props;  // TODO add Info (e.g. country, ...)
  const [selectedDeviceType, setSelectedDeviceType] = useState('auto');
  const [selectedPlatformVersion, setSelectedPlatformVersion] = useState('');
  const [selectedTab, setSelectedTab] = useState(''); // TODO add default data
  const [selectedCategory, setSelectedCategory] = useState(''); // TODO add default data
  const [title, setTitle] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs());
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());
  const [selectedStartTime, setSelectedStartTime] = useState('00:00');
  const [selectedEndTime, setSelectedEndTime] = useState('00:00');

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    console.log('Save');
    // TODO add Save logic
    // setIsOpen(false);
  };

  const onChangeDeviceType = (value) => {
    setSelectedDeviceType(value);
  }

  const onChangeTab = (value) => {
    setSelectedTab(value);
  }

  const platformVersionSelectOptions = useMemo(() => {
    if (selectedDeviceType in PlatformVersionOption) {
      return PlatformVersionOption[selectedDeviceType];
    } else {
      return [];
    }
  }, [selectedDeviceType]);

  useEffect(() => {
    if (platformVersionSelectOptions.length > 0) {
      setSelectedPlatformVersion(platformVersionSelectOptions[0].value);
    } else {
      setSelectedPlatformVersion('');
    }
  }, [platformVersionSelectOptions]);

  const categorySelectOptions = useMemo(() => {
    // TODO: get category list by selectedTab
    return [{ id: 'Category1', value: 'Category1', option: 'Category1', type: 'text' }];

    // TODO: delete the line below (use 'selectedTab' in this useMemo)
    // eslint-disable-next-line
  }, [selectedTab]);

  const timeSelectOptions = useMemo(() => {
    const times = [];
    for (let i=0; i<24; i++) {
      const hour = i.toString().padStart(2, '0');
      times.push({ id: `${hour}:00`, value: `${hour}:00`, option: `${hour}:00`, type: 'text' })
    }
    return times;
  }, []);

  const dialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'Cancel',
      type: 'outline',
      color: 'tertiary',
      onClick: handleClose
    },
    {
      width: '150px',
      size: 'large',
      label: 'Save',
      type: 'box',
      color: 'primary',
      onClick: handleSave
    },
  ];

  const fields = [
    // Country
    {
      xs: 12,
      isLabel: true,
      isBorder: true,
      label: 'Country',
      value: (
        <Typography component='span' className='text'>
          Us
        </Typography>
      ),
    },

    // Device Type
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='Device Type'
            required={true}
            size='large'
            placeholder='Select'
            selectOptions={DtypeOption}
            disabled={false}
            shrink
            selected={selectedDeviceType}
            onChange={onChangeDeviceType}
          />
        </>
      ),
    },

    // Platform version
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='Platform version'
            required={true}
            size='large'
            placeholder='Select'
            selectOptions={platformVersionSelectOptions}
            disabled={false}
            shrink
            selected={selectedPlatformVersion}
            onChange={(value) => setSelectedPlatformVersion(value)}
          />
        </>
      ),
    },

    // Tab
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='Tab'
            required={true}
            size='large'
            selectOptions={tabSelectOptions}
            disabled={false}
            shrink
            selected={selectedTab}
            onChange={onChangeTab}
          />
        </>
      ),
    },

    // Category
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-Dtype'
            fullWidth
            label='Category'
            required={true}
            size='large'
            selectOptions={categorySelectOptions}
            disabled={false}
            shrink
            selected={selectedCategory}
            onChange={(value) => setSelectedCategory(value)}
          />
        </>
      ),
    },

    // Title
    {
      xs: 12,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <TextField
            type='outlined'
            size='large'
            placeholder=''
            defaultValue=''
            label='Title'
            autoComplete='off'
            required={true}
            InputLabelProps={{ shrink: true }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </>
      ),
    },

    // Start Date
    {
      xs: 6,
      label: 'Start Date',
      required: true,
      value: <DatePicker label='Start Date' size='large' shrink required={true} singleDate={true}
        value={selectedStartDate} onChange={(value) => setSelectedStartDate(value)} />,
    },
    // End Date
    {
      xs: 6,
      label: 'End Date',
      required: true,
      value: <DatePicker label='End Date' size='large' shrink required={true} singleDate={true}
        value={selectedEndDate} onChange={(value) => setSelectedEndDate(value)} />,
    },

    // Start Time
    {
      xs: 6,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-StartTime'
            fullWidth
            label='Start Time'
            required={true}
            size='large'
            selectOptions={timeSelectOptions}
            disabled={false}
            shrink
            selected={selectedStartTime}
            onChange={(value) => setSelectedStartTime(value)}
          />
        </>
      ),
    },

    // End Time
    {
      xs: 6,
      isLabel: false,
      label: '',
      required: true,
      alignTop: '',
      value: (
        <>
          <SelectBox
            id='PopAN-EndTime'
            fullWidth
            label='End Time'
            required={true}
            size='large'
            selectOptions={timeSelectOptions}
            disabled={false}
            shrink
            selected={selectedEndTime}
            onChange={(value) => setSelectedEndTime(value)}
          />
        </>
      ),
    },
  ];

  return (
    <div className='component-view'>
      <Dialog
        open={isOpen}
        closeX={true}
        title='Add A/N Test' // Popup Title
        noticeBgBox={false}
        noticeBoxTitle=''
        noticeDate=''
        handleClose={handleClose}
        size='medium' // large, medium, small
        contentTitle={false}
        bottomDivider={true}
        className='popup-notification'
        contentTextProps={
          <Box className='layout-label-container'>
            <Box className='detail-list-container'>
              <Grid container columnSpacing={3}>
                {fields.map(({ xs, label, required, value, isLabel, isBorder }, index) => (
                  <Grid item xs={xs} className='list-wrap' key={index}>
                    <Box mb={1} className='info-value-wrap'>
                      {isLabel && (
                        <Typography component='span' className={`label ${required ? 'required' : ''}`}>
                          <span className='text'>{label}</span>
                        </Typography>
                      )}
                      <div className={`value ${isBorder ? 'border' : ''}`}>{value}</div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        }
        buttons={dialogButtons}
      />
    </div>
  );
}

export default PopAddANTest;
