import React, { useState } from 'react';
import Button from 'components/component/BasicButton';
import TextField from 'components/component/BasicTextField';
import { Box, FormControl } from '@mui/material';
import styled from '@emotion/styled';

// STYLED
const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;

    .input-area {
      width: 100%;
    }

    &.large {
      input {
        height: 4rem;
        padding: 1rem 1.2rem;
      }
    }

    &.medium {
      & .MuiInputLabel-root {
        margin-top: -9px;
      }
      & .Mui-focused {
        &.MuiInputLabel-root {
          margin-top: 0;
        }
      }
      & input {
        height: 3.2rem;
        padding: 0.6rem 1.2rem;
      }
    }

    &.small {
      & .MuiInputLabel-root {
        margin-top: -9px;
      }
      & .Mui-focused {
        &.MuiInputLabel-root {
          margin-top: 0;
        }
      }
      & input {
        height: 2.8rem;
        padding: 0.6rem 1.2rem;
        font-size: 1.2rem;
      }
    }

    &:hover {
      .Mui-disabled {
        .dark & {
          .MuiOutlinedInput-notchedOutline {
            border-color: rgba(163, 163, 163, 0.4);
          }
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #000;

        .dark & {
          border-color: #fff;
          color: #fff;
        }
      }
    }

    .MuiInputBase-root {
      .MuiInputBase-input {
        -webkit-text-fill-color: initial;

        &::placeholder {
          color: #6b6b6b;

          .dark & {
            color: #fff;
          }
        }
      }
      &.Mui-disabled {
        .MuiInputBase-input {
          &::placeholder {
            .dark & {
              color: #818181;
            }
          }
        }
      }
    }

    .MuiFormLabel-root {
      .dark & {
        color: #424242;
      }
    }

    .MuiInputLabel-shrink {
      position: absolute;
      left: 0;
      padding: 0 3px;
      background: #fff;
      font-size: 1.4rem;
      line-height: 1;
      z-index: 10;
      color: #6b6b6b;
      .dark & {
        background: #202027;
        color: #b1b1b1;
      }

      &.MuiInputLabel-sizeLarge,
      &.MuiInputLabel-sizeMedium,
      &.MuiInputLabel-sizeSmall {
        top: 0;
        margin: 0;
      }
    }
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;

  .MuiInputBase-input {
    width: 100%;
    box-sizing: border-box;
    font-size: 1.4rem;
    color: #4e5258;
    border-color: #a3a3a3;
    border-radius: 4px;

    &::placeholder {
      color: #bbb;

      .dark & {
        color: #424242;
      }
    }

    .dark & {
      background: #1b1b1b;
      color: #fff;
    }
  }

  .MuiOutlinedInput-root {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: #2196f3;
    }

    &.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #dfdfdf;

        .dark & {
          border-color: rgba(223, 223, 223, 0.1);
        }
      }

      .MuiInputBase-input {
        background: #ececec;

        .dark & {
          background: rgba(236, 236, 236, 0.15);
          color: #6a6a6a;
        }
      }
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: #b91c1c;

      .dark & {
        border-color: #b91c1c;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #a3a3a3;

      .dark & {
        border-color: rgba(163, 163, 163, 0.4);
      }
    }

    .MuiOutlinedInput-input:not(.Mui-disabled):read-only {
      background: #fff;
      .dark & {
        background: #1b1b1b;
        color: #424242;
      }

      & + .MuiOutlinedInput-notchedOutline {
        border: 1px solid #a3a3a3;

        .dark & {
          border: 1px solid rgba(163, 163, 163, 0.4);
        }
      }
      &::placeholder {
        color: #525252;

        .dark & {
          color: #fff;
        }
      }
    }

    & + .MuiFormHelperText-root {
      font-size: 1.2rem;
      line-height: 1.2rem;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 4px;

      .dark & {
        color: #b1b1b1;
      }

      &.Mui-error {
        color: #b91c1c;
      }
    }
  }
`;

const UploadContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  & + .sub-text {
    font-size: 12px;
    color: #909090;
    padding: 4px 0 0;
    strong {
      font-weight: 700;
      color: #000;
    }
  }
`;

const FileInput = styled('input')`
  display: none;
`;
// STYLED END

// ** FileUpload
function BasicFileUpload({
  // 호출하는 컴포넌트에서 멀티 파일 업로드 구현 시 ID 값이 상이해야 동작 함.
  id,
  buttonLabel = 'Upload',
  onFileChange,
  size,
  placeholder,
  isSubText,
  label,
  subText,
  disabled,
  required,
  children,
  accept = '.jpeg, .jpg, .png',
  ...props
}) {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      if (onFileChange) onFileChange(selectedFile);
    }

    event.target.value = null;
  };
  return (
    <UploadContainer className='file-upload-container'>
      <StyledFormControl className={size}>
        <div className='input-area'>
          <label htmlFor='file-upload' />
          <FileInput id={id} type='file' accept={accept} onChange={handleFileChange} />
          <StyledTextField
            type='outlined'
            size='large'
            placeholder={placeholder}
            defaultValue={fileName}
            disabled={disabled}
            label={label}
            required={required}
            readOnly
            autoComplete='off'
            {...props}
          />
        </div>

        <Button
          minWidth='90'
          color='tertiary'
          type='box'
          size='medium'
          label={buttonLabel}
          isIcon={false}
          disabled={disabled}
          autosize
          Icon=''
          iconPos={undefined}
          onClick={() => document.getElementById(id).click()}
        />
      </StyledFormControl>
      {isSubText && children}
    </UploadContainer>
  );
}

export default BasicFileUpload;
