import * as React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

// 스타일이 적용된 버튼 컴포넌트
const CustomButton = styled(Button)({
  '&&': {
    backgroundColor: 'initial',
    boxShadow: 'initial',
    color: '#2196F3',
    fontSize: '14px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'none',
      textDecoration: 'underline',
    },
  },
});

const IconWrapper = styled('span')({
  display: 'inline-block',
  marginRight: '8px', // 아이콘과 텍스트 사이 간격
});

function BasicTextButton({ label, isIcons, icon: IconImage, underline, iconWidth = '24px', iconHeight = '24px' }) {
  return (
    <CustomButton variant='text'>
      {isIcons && IconImage && (
        <IconWrapper>
          <IconImage
            style={{
              width: iconWidth,
              height: iconHeight,
              display: 'block',
            }}
          />
        </IconWrapper>
      )}
      <span
        style={{
          textDecoration: underline ? 'underline' : 'none', // 텍스트에 언더라인 적용
        }}
      >
        {label}
      </span>
    </CustomButton>
  );
}

export default BasicTextButton;
