import React from 'react';
import Main from 'pages/Main/Launch/Main';
import Login from 'pages/Main/Login/Login';
import PartnerMain from 'partner_pages/Main/PartnerMain';

function Entry(props) {
  const { isAuthenticated
        , message
        , currentUser
        , darkMode
        , isPartnerPageShow
        , recentlyUsedMenu
      } = props;

  if (isAuthenticated) {
    if (isPartnerPageShow) {
      return <PartnerMain currentUser={currentUser} darkMode={darkMode} recentlyUsedMenu={recentlyUsedMenu} />;
    } else {
      return <Main currentUser={currentUser} recentlyUsedMenu={recentlyUsedMenu} />;
    }
  } else {
    return <Login message={message} currentUser={currentUser}/>;
  }
}

export default Entry;