import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

// Styled
const SwitchContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  .mode-label {
    font-size: 14px;
    color: #c6c6c6;
    margin-left: 2px;

    &.on {
      color: #2196f3;
    }
  }
`;

const FormSwitchStyled = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    margin: 0,
  },

  '& .MuiSwitch-root': {
    width: '48px',
    height: '28px',
  },
  '& .MuiButtonBase-root': {
    padding: '4px 6px',
    '& .MuiSwitch-thumb': {
      border: '1px solid #ddd',

      '.dark &': {
        border: '1px solid #262626',
        background: '#000',
      },
    },
    '& + .MuiSwitch-track': {
      opacity: 1,
      height: '5px',
      background: '#C6C6C6',

      '.dark &': {
        background: '#484848',
      },
    },
    '&.Mui-checked': {
      color: '#A50034',
      '.dark &': {
        color: '#A50034',
      },
      '& .MuiSwitch-thumb': {
        border: 0,

        '.dark &': {
          background: '#A50034',
        },
      },
      '& + .MuiSwitch-track': {
        background: '#EE93B3',
      },
      '&.Mui-disabled': {
        color: '#E4E7EA',
        '& + .MuiSwitch-track': {
          background: '#ddd',
          opacity: 0.6,
        },
      },
    },
    '&.Mui-disabled': {
      color: '#E4E7EA',
      '& .MuiSwitch-thumb': {
        border: 0,
      },
      '& + .MuiSwitch-track': {
        background: '#E4E7EA',
      },
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: '#ddd',
    },
  },
  '& .MuiTypography-root': {
    fontSize: '14px',
    color: '#000',
    fontWeight: 400,
  },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: '70px',
  height: '28px',
  padding: '0',

  '& .MuiSwitch-switchBase': {
    color: '#fff',
    padding: '2px',
    // transform: 'translateX(40px)',

    '& .MuiSwitch-input': {
      left: '0',
      width: '250%',
    },
    '&.Mui-checked': {
      transform: 'translateX(41px)',
    },
  },
  '& .MuiSwitch-thumb': {
    width: '24px',
    height: '24px',
  },
  '& .MuiSwitch-track': {
    borderRadius: '34px',
    backgroundColor: '#A50034', // 기본 배경색
    opacity: 1,
    position: 'relative',
    '&::before': {
      content: '""', // 스위치가 꺼졌을 때의 텍스트
      position: 'absolute',
      left: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#FFF',
      fontSize: '14px',
    },
  },

  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#525252', // 켜졌을 때 배경색
    '&::before': {
      content: '""', // 스위치가 켜졌을 때의 텍스트
      left: 'calc(100% - 60px)', // 텍스트 위치 조정
    },
  },
}));

const SwitchInnerText = styled('div')({
  position: 'relative',

  '& .mode-label': {
    position: 'absolute',
    left: '30px',
    top: '6px',
    fontSize: '10px',
    color: '#fff',

    '&.on': {
      left: '5px',
      color: '#fff',
    },
  },
});

// 메인 컨텐츠
function BasicSwitch({
  disabled,
  className,
  width,
  onOffMode,
  setSwitchOnOff,
  isLabelText,
  isTextInner,
  onText,
  offText,
}) {
  const handleOnOff = (event) => {
    setSwitchOnOff(event.target.checked);
  };

  const handleLabel = onOffMode ? onText : offText;

  return (
    <SwitchContainer sx={{ width: width }} className={className}>
      {isLabelText && (
        <>
          <FormSwitchStyled control={<Switch checked={onOffMode} onChange={handleOnOff} disabled={disabled} />} />
          <span className={`mode-label ${onOffMode ? 'on' : ''}`}>{handleLabel}</span>
        </>
      )}
      {isTextInner && (
        <SwitchInnerText className='switch-inner-text'>
          <StyledSwitch checked={onOffMode} onChange={handleOnOff} disabled={disabled} />
          <span className={`mode-label ${onOffMode ? 'on' : ''}`} checked={onOffMode}>
            {handleLabel}
          </span>
        </SwitchInnerText>
      )}
    </SwitchContainer>
  );
}
BasicSwitch.propTypes = {
  disabled: PropTypes.bool,
  onOffMode: PropTypes.bool,
  setSwitchOnOff: PropTypes.func,
  isLabelText: PropTypes.bool,
  onText: PropTypes.string,
  offText: PropTypes.string,
};

export default BasicSwitch;
