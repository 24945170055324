import * as actions from 'actions/actionTypes';

const initialState = [];

export function regionList(state = initialState, action) {
  switch (action.type) {
    case actions.SET_REGION:
      if (action.payload.status) return [];
      else if (action.payload) {
        const result = action.payload.reduce((ac, a) => {
          const findType = ac.find(n => n.deviceType === a.deviceType);
          if (findType) findType.list = [...findType.list, { region: a.region, countryCode:a.countryCode.split(',') }];
          else ac = [...ac, { deviceType: a.deviceType, list: [{ region: a.region, countryCode:a.countryCode.split(',') }] }];
          return ac;
        }, []);
        return result;
      } else return [];
    default:
      return state;
  }
}