import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import CheckBox from 'components/component/BasicCheckBox';
import SearchField from 'components/component/BasicSearchField';
import SelectBox from 'components/component/BasicSelectBox';
import DatePicker from 'components/component/BasicDatePicker';
import Pagination from 'components/component/BasicPagination';
import dayjs from 'dayjs';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import {
  Box,
  Link,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { PieChart } from '@mui/x-charts/PieChart';

import { styled } from '@mui/material/styles';
// Icon

import {
  CPNameOption,
  CountryOption,
  selectOption,
  DeviceOption,
  PlatformOption,
  typeOption,
  PopularGenreData,
  valueFormatter,
  vodTh,
  vodTd,
} from 'partner_pages/Statistics/data/VodData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController
);

// Unique Device
const DeviceData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      type: 'bar',
      label: 'Values',
      barPercentage: 0.8,
      data: [45805, 68700, 132456, 190280, 158710, 99805, 68700, 132456, 87504, 78710, 59801, 101215],
      datalabels: {
        align: 'end',
        anchor: 'end',
      },
      backgroundColor: '#FFCF4F',
      order: 2,
    },
    {
      type: 'line',
      label: 'Percentage',
      data: [0, -10, -26, -20, 1, -15, -5, -28, -74, -10, -25, 18],
      borderColor: '#FF5778',
      borderWidth: 2,
      pointBackgroundColor: '#FF5778',
      yAxisID: 'y2',
      order: 1,
    },
  ],
};

const DeviceOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,

      max: 200000,
      ticks: {
        stepSize: 30000,
      },
    },
    y2: {
      beginAtZero: false,
      min: -100, // 최소값 설정
      max: 100, // 최대값 설정
      position: 'right',
      ticks: {
        stepSize: 30,
        callback: function (value) {
          return `${value}%`;
        },
      },
    },
  },
};

// Top 10 Content Chart
const top10Data = {
  labels: ['LG 1+1', 'FailArmy', 'Stingray', 'LiveNOW', 'Channel name 1', 'Channel name 2', 'Channel name3'],
  datasets: [
    {
      axis: 'y',
      label: 'Content Value',
      data: [112000, 165425, 111555, 127546, 200000, 94100, 48500],
      backgroundColor: [
        'rgba(255, 99, 132, 0.8)',
        'rgba(54, 162, 235, 0.8)',
        'rgba(255, 206, 86, 0.8)',
        'rgba(75, 192, 192, 0.8)',
        'rgba(153, 102, 255, 0.8)',
        'rgba(201, 203, 207, 0.8)',
        'rgba(189, 163, 33, 0.8)',
      ],

      borderWidth: 0,
    },
  ],
};

const config = {
  indexAxis: 'y', // 'y'로 설정하여 가로 막대 차트로 변경
  responsive: true,
  plugins: {
    legend: {
      display: false, // 범례 숨기기
    },
    tooltip: {
      callbacks: {
        label: (context) => `${context.raw} units`,
      },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      max: 300000,
      ticks: {
        stepSize: 100000,
      },
    },
  },
};

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 데이터를 객체 배열로 정의
const selectBtnData = [
  { label: 'Unique Device', value: '671', active: false, disabled: true },
  { label: 'Total Duration(hr)', value: '9,868.6', active: true, disabled: false },
  { label: 'Total Duration per Ud', value: '14.7', active: false, disabled: true },
  { label: 'Play', value: '18,988', active: false, disabled: false },
  { label: 'Play per Ud', value: '28.31', active: false, disabled: true },
];

// ************************** 메인 컨텐츠 ***********************************
function Vod() {
  const optionMenu = {
    gnbMenu: 'Statistics',
    lnbMenu: 'Vod',
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  const sortedTdRows = sortRows([...vodTd], orderBy, order);

  // Multi Language Button
  const [activeButton, setActiveButton] = useState('Daily');
  const handleButtonClick = (label) => {
    setActiveButton(label);
  };

  // Data Select Button
  const [activeChartButton, setActiveChartButton] = useState(0);
  useEffect(() => {
    const initialActiveTab = selectBtnData.findIndex((tab) => tab.active);
    if (initialActiveTab !== -1) {
      setActiveChartButton(initialActiveTab);
    }
  }, []);

  const handleTabClick = (index) => {
    setActiveChartButton(index);
  };

  // 날짜 상태 관리를 위한 useState
  const [selectedDate, setSelectedDate] = useState(dayjs()); // 초기값을 오늘 날짜로 설정

  // 날짜 변경 핸들러
  const handleDateChange = (newValue) => {
    if (dayjs.isDayjs(newValue) && newValue.isValid()) {
      setSelectedDate(newValue); // selectedDate 상태 업데이트
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className='main-container partner-statistic' component='main'>
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                VOD
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap' component='div'>
          {/* 필터영역 */}
          <Box component='div' className='white-box filter-box '>
            <Typography className='notice-text' mb={2}>
              ※ Only VOD Total Duration and Play data are provided for the US. / Please refer to the VOD page for US
              date
            </Typography>
            <Box className='form-wrap-box'>
              <Box className='form-area left'>
                <Box className='component-wrap'>
                  {/* CP Name */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='vod-CP'
                      size='large'
                      shrink={true}
                      label='CP Name'
                      selectOptions={CPNameOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Country */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='vod-Country'
                      size='large'
                      shrink={true}
                      label='Country'
                      selectOptions={CountryOption}
                      disabled={false}
                      placeholder=''
                    />
                  </Box>

                  {/* Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='vod-Type'
                      size='large'
                      shrink={true}
                      label='Type'
                      selectOptions={typeOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Device Type */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='vod-Device'
                      size='large'
                      shrink={true}
                      label='Device Type'
                      selectOptions={DeviceOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>

                  {/* Platform */}
                  <Box component='div' className='col'>
                    <SelectBox
                      id='vod-Platform'
                      size='large'
                      shrink={true}
                      label='Platform'
                      selectOptions={PlatformOption}
                      disabled={false}
                      placeholder=''
                      value=''
                    />
                  </Box>
                </Box>

                <Box className='component-wrap'>
                  <Box component='div' className='col col-toggle'>
                    <Box className='toggle-btn-wrap time-period' display='flex' gap={1}>
                      <Button
                        color={activeButton === 'Daily' ? 'tertiary' : 'tertiary'}
                        type={activeButton === 'Daily' ? 'box' : 'outline'}
                        size='medium'
                        label='Daily'
                        isIcon={false}
                        autosize
                        onClick={() => handleButtonClick('Daily')}
                      />
                      <Button
                        color={activeButton === 'Weekly' ? 'tertiary' : 'tertiary'}
                        type={activeButton === 'Weekly' ? 'box' : 'outline'}
                        size='medium'
                        label='Weekly'
                        isIcon={false}
                        autosize
                        onClick={() => handleButtonClick('Weekly')}
                      />
                      <Button
                        color={activeButton === 'Monthly' ? 'tertiary' : 'tertiary'}
                        type={activeButton === 'Monthly' ? 'box' : 'outline'}
                        size='medium'
                        label='Monthly'
                        isIcon={false}
                        autosize
                        onClick={() => handleButtonClick('Monthly')}
                      />
                    </Box>
                  </Box>

                  {/* Datepiceker */}
                  <Box component='div' className='col col-picker'>
                    <DatePicker
                      className='input-date-picker'
                      format='MM/DD/YYYY'
                      size='large'
                      maxWidth='180px'
                      singleDate={activeButton === 'Daily'}
                      monthlyDate={activeButton === 'Monthly'}
                      weeklyDate={activeButton === 'Weekly'}
                      value={selectedDate} // selectedDate는 dayjs 객체여야 합니다.
                      onChange={handleDateChange} // 날짜 변경 핸들러
                    />
                  </Box>
                </Box>
              </Box>
              <div className='form-area right'></div>
            </Box>
          </Box>

          <Box className='chart-board-container'>
            <Box className='board-btn-wrap'>
              <Box className='button-area'>
                {selectBtnData.map((tab, index) => (
                  <Box
                    key={index}
                    className={`tab-box ${activeChartButton === index ? 'active' : ''}`}
                    component='button'
                    onClick={() => handleTabClick(index)}
                    disabled={tab.disabled}
                  >
                    <Box className='box-wrap'>
                      <span className='label'>{tab.label}</span>
                      <span className='value'>{tab.value}</span>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className='board-chart-wrap'>
              <Box className='chart-area'>
                {/* Unique Device */}
                <Box className='white-box white-box-wrap chart-box'>
                  <Box className='title-wrap'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Unique Device</Typography>
                    </Box>
                  </Box>

                  <Box className='box-content chart-item'>
                    {/* Chart */}
                    <Box className='bar-line-chart' height={420}>
                      <Bar data={DeviceData} options={DeviceOptions} />;
                    </Box>
                  </Box>
                </Box>

                {/* Top 10 Content */}
                <Box className='white-box white-box-wrap'>
                  {/* Box Title Area */}
                  <Box className='title-wrap '>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Top 10 Content</Typography>
                      <Typography component='p' className='select-count-wrap'>
                        (Based on Unique Devices)
                      </Typography>
                    </Box>
                  </Box>

                  {/* Box Content Area */}
                  <Box className='box-content'>
                    <Box className='bar-hor-chart' height={400}>
                      <Bar data={top10Data} options={config} />
                    </Box>
                  </Box>
                </Box>

                {/* Popular Genre */}
                <Box className='white-box white-box-wrap'>
                  {/* Box Title Area */}
                  <Box className='title-wrap '>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Poppular Genre</Typography>
                      <Typography component='p' className='select-count-wrap'>
                        (Based on Unique Devices)
                      </Typography>
                    </Box>
                  </Box>

                  {/* Box Content Area */}
                  <Box className='box-content'>
                    <Box width={'85%'}>
                      <PieChart
                        height={400}
                        margin={{ left: 10, right: 100 }}
                        series={[
                          {
                            data: PopularGenreData,
                            innerRadius: 80,
                            arcLabel: (params) => `${params.label}: ${params.value}%`,
                            arcLabelMinAngle: 10,
                            valueFormatter,
                          },
                        ]}
                        sx={{ fontSize: '12px' }}
                        slotProps={{
                          legend: {
                            direction: 'column',
                            position: { vertical: 'middle', horizontal: 'right' },
                            padding: 0,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className='white-box white-box-wrap' mb={3}>
            <div className='box-content'>
              <Box className='white-box-content-top-area'>
                <Box className='left-area'>
                  <Box className='box-title-area' display='flex' alignItems='center'>
                    <Box className='total-count'>
                      <Box component='span' className='label'>
                        Total
                      </Box>
                      <Box component='span' className='value'>
                        445
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className='right-area'>
                  <Button color='tertiary' type='outline' size='small' label='Excel Dounload' isIcon={false} autosize />
                  <SelectBox
                    id='select-search'
                    size='medium'
                    selectOptions={selectOption}
                    label=''
                    maxWidth='180px'
                    disabled={false}
                    placeholder=''
                  />

                  <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                </Box>
              </Box>
              <Box className='content-inner-wrap'>
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {vodTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            {column.checkbox ? (
                              <Box display='flex' alignContent='center'>
                                <CheckBox
                                  label=''
                                  value='select-all'
                                  defaultChecked={false}
                                  inputProps={{
                                    'aria-label': 'select all',
                                  }}
                                />
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'desc'}
                                  onClick={() => handleRequestSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              </Box>
                            ) : (
                              <>
                                {column.sortable ? (
                                  <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={orderBy === column.id ? order : 'desc'}
                                    onClick={() => handleRequestSort(column.id)}
                                  >
                                    {column.label}
                                  </TableSortLabel>
                                ) : (
                                  column.label
                                )}
                              </>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTdRows.map((td, index) => (
                        <TableRow className='tr' key={td.id || index}>
                          {vodTh.map((column, colIndex) => (
                            <TableCell key={column.id} className='td' align={column.align}>
                              {td[`data${colIndex + 1}`]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Vod;
