import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import SearchField from 'components/component/BasicSearchField';
import Pagination from 'components/component/BasicPagination';
import CustomImage from 'components/component/BasicImage';
import SelectBox from 'components/component/BasicSelectBox';
import CheckBox from 'components/component/BasicCheckBox';
import Tooltip from 'components/component/BasicTooltip';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Link,
  Grid,
  ListItem,
  List,
  Divider,
  TableSortLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// ICON
import { BtnArrowRight } from 'components/component/BasicIcon';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import BasicDialog from 'components/component/BasicDialog';
import BasicSnackbar from 'components/component/BasicSnackbar';
// Data
import {
  ContentListTh,
  SelectedContentTh,
  selectTypeOption,
} from 'pages/Organization/WebService/data/ContentListData';

//image
import IconDraggableImg from 'assets/images/icon/ic_handle_24_outline.svg';
import IconDraggableImgDark from 'assets/images/icon/ic_handle_24_outline_darkmode.svg';
import { useNavigate } from 'react-router-dom';
import { ContentTypeVodList } from '../../data/PopAddCategoryData';
import { getWebContentOrganizeData, getWebContentOrganizeResult, saveWebContentOrganizeResult } from '../../api/restApi';

const IconDraggable = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url(${IconDraggableImg});
  opacity: ${props => props.disabled ? 0.3 : 1};

  .dark & {
    background: url(${IconDraggableImgDark});
  }
`;

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));

// 메인 컨텐츠
function CategoryOrganize(props) {
  const { message } = props;
  const { idx, contentType, areaType, category, areaName, country, selectedStatus, region } = message;
  const navigate = useNavigate();
  const [actionDialog, setActionDialog] = useState("");
  const [showSnakBar, setShowSnakBar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  // 테이블 내림, 오름차순
  const [webOrganizeResult, setWebOrganizeResult] = useState({
    data: [],
    total: 0,
  });
  const [webOrganizeData, setWebOrganizeData] = useState({
    data: [],
    total: 0,
  });
  const [selectCpOption, setSelectCpOption] = useState([]);
  const defaultCp = selectCpOption.length > 0 ? selectCpOption[0].value : "All";
  const [selectedCp, setSelectedCp] = useState(defaultCp);
  const contentTypeList = contentType.split(",");
  const requiredContentTypeVodList = ContentTypeVodList
    .filter((data) => contentTypeList.includes(data.value))
    .map(item => ({ ...item, type: "text" }));
  const defaultType =
    areaType === "content_vod"
      ? contentTypeList.length > 0
        ? contentTypeList[0]
        : ""
      : selectTypeOption.length > 0
        ? selectTypeOption.find((item) => item.value === contentType)?.value || ""
        : "";
  const [selectedType, setSelectedType] = useState(defaultType);
  const [checkedWebOrganizeResult, setCheckedWebOrganizeResult] = useState({
    data: [],
    total: 0,
  });
  const [checkedWebOrganizeData, setCheckedWebOrganizeData] = useState({
    data: [],
    total: 0,
  });
  const [originalWebOrganizeData, setOriginalWebOrganizeData] = useState(webOrganizeData);
  const [originalWebOrganizeResult, setOriginalWebOrganizeResult] = useState(webOrganizeResult);
  const [searchWebContent, setSearchWebContent] = useState({
    cp: selectedCp,
    type: selectedType,
    country: country,
    id: "",
    name: "",
    status: "S",
    page: 1,
    size: 10,
  });
  const [params, setParams] = useState({
    idx: idx,
    page: 1,
    size: 10,
  });

  const [paramsRes, setParamsRes] = useState({
    idx: idx,
    page: 1,
    size: 10,
  });

  const totalSearchCount = webOrganizeData.total || 0;
  const [addedWebOrganizeResult, setAddedWebOrganizeResult] = useState({
    data: [],
    total: 0,
  });
  const [isAllowMove, setIsAllowMove] = useState(false);

  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Organization',
    lnbMenu: 'Web Service',
    normalMenu: ['Category List', 'Category Organize'],
  };

  const organizeInfos = [
    {
      index: 1,
      label: "#Country",
      value: country,
      divide: true,
    },
    {
      index: 2,
      label: "#Area Type",
      value:
        areaType === "content_channel" ? "Content - Channel" : "Content - VOD",
      divide: true,
    },
    {
      index: 3,
      label: "#Content Type",
      value: contentType,
      divide: false,
    },
  ];

  const organizeButtons = [
    {
      index: 1,
      color: "tertiary",
      type: "outline",
      size: "small",
      label: "Top",
      isIcon: false,
      autosize: true,
      disabled: selectedStatus === "P",
      onClick: () => onClickTopBottom("Top")
    },
    {
      index: 2,
      color: "tertiary",
      type: "outline",
      size: "small",
      label: "Bottom",
      isIcon: false,
      autosize: true,
      disabled: selectedStatus === "P",
      onClick: () => onClickTopBottom("Bottom")
    },
    {
      index: 3,
      color: "tertiary",
      type: "outline",
      size: "small",
      label: "Delete",
      isIcon: false,
      Icon: null,
      iconPos: false,
      disabled: checkedWebOrganizeResult.length === 0 || selectedStatus === "P",
      autosize: true,
      onClick: () => setActionDialog("Delete")
    },
    {
      index: 4,
      color: "primary",
      type: "box",
      size: "small",
      label: "Save",
      isIcon: false,
      Icon: null,
      iconPos: false,
      autosize: true,
      disabled: selectedStatus === "P",
      onClick: () => setActionDialog("Save")
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = params.order === property && params.order_type === "asc";
    setParams((prevState) => ({
      ...prevState,
      order: property,
      order_type: isAsc ? "desc" : "asc"
    }));
  };

  const handleRequestSortRes = (property) => {
    const isAsc = paramsRes.order === property && paramsRes.order_type === "asc";
    setParamsRes((prevState) => ({
      ...prevState,
      order: property,
      order_type: isAsc ? "desc" : "asc"
    }));
  };

  const onDeleteOKBtn = async () => {
    if (
      !Array.isArray(checkedWebOrganizeResult) ||
      checkedWebOrganizeResult.length === 0
    ) {
      return;
    }
    const updatedWebOrganizeResult = webOrganizeResult.data.filter(
      (item) => !checkedWebOrganizeResult.includes(item.id)
    );
    const selectedItems = webOrganizeResult.data.filter((item) =>
      checkedWebOrganizeResult.includes(item.id)
    );
    updatedWebOrganizeResult.forEach((item, idx) => item.resultOrder = (idx + 1));
    setWebOrganizeResult((prev) => ({
      ...prev,
      data: updatedWebOrganizeResult,
      total: updatedWebOrganizeResult.length || 0,
    }));
    setWebOrganizeData((prev) => {
      const existingIds = prev.data.map((item) => item.id);
      const nonDuplicateItems = selectedItems.filter(
        (item) => !existingIds.includes(item.id)
      );
      const newData = [...prev.data, ...nonDuplicateItems];

      return {
        ...prev,
        data: newData,
        total: newData.length || 0,
      };
    });

    setAddedWebOrganizeResult((prev) => {
      return {
        ...prev,
        data: updatedWebOrganizeResult,
        total: updatedWebOrganizeResult.length || 0,
      };
    });
    const updatedOrganizeResult = originalWebOrganizeResult.data.filter(
      (item) => !checkedWebOrganizeResult.includes(item.id)
    );

    setOriginalWebOrganizeResult((prev) => ({
      ...prev,
      data: updatedOrganizeResult,
      total: updatedOrganizeResult.length,
    }));
    setCheckedWebOrganizeResult([]);
    setActionDialog("");
    setShowSnakBar({
      open: true,
      message: "Successfully Deleted Selected Categories!",
      severity: "success",
    });
  };

  const onActionCancelBtn = () => {
    setActionDialog("");
  };

  const onSaveOKBtn = () => {
    onClickSaveButton();
  };

  const onClickSaveButton = async () => {
    const webOrganizeResultData = { ...originalWebOrganizeResult };
    const result = webOrganizeResultData.data.map((item, index) => ({
      webPromotionCategoryIdx: parseInt(idx),
      resultOrder: index + 1,
      contentType: item.type,
      contentId: item.id,
      providerId: item.cp,
    }));

    const res = await saveWebContentOrganizeResult(category, idx, result);
    setActionDialog("");
    handelSetShowSnakBar({
      open: true,
      message: res.message,
      severity: res.severity,
    });

    getWebOrganizeResultData(category, idx);
  };
  const handelSetShowSnakBar = ({ open, message, severity }) => {
    setShowSnakBar({
      open,
      message,
      severity,
    });
    setParams({ ...params });
    setParamsRes({ ...paramsRes });
  };

  const handleSnackbarClose = () => {
    setShowSnakBar({ open: false, message: "", severity: "" });
  };

  const deleteActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onDeleteOKBtn,
    },
  ];

  const saveActionButtons = [
    {
      color: "tertiary",
      type: "outline",
      size: "medium",
      label: "Cancel",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 85,
      disabled: false,
      onClick: onActionCancelBtn,
    },
    {
      color: "primary",
      type: "box",
      size: "medium",
      label: "OK",
      isIcon: false,
      Icon: null,
      iconPos: false,
      minWidth: 103,
      disabled: false,
      onClick: onSaveOKBtn,
    },
  ];

  const handlePaginationChange = ({ type, value }) => {
    if (type === "page") {
      setParams((prevState) => ({
        ...prevState,
        page: value,
      }));
    } else if (type === "size") {
      setParams((prevState) => ({
        ...prevState,
        page: 1,
        size: parseInt(value),
      }));
    }
  };

  const handlePaginationChangeRes = ({ type, value }) => {
    if (type === "page") {
      setParamsRes((prevState) => ({
        ...prevState,
        page: value,
      }));
    } else if (type === "size") {
      setParamsRes((prevState) => ({
        ...prevState,
        page: 1,
        size: parseInt(value),
      }));
    }
  };

  const handleCheckBoxResult = (event) => {
    let idxs = [];
    if (event.target.checked && event.target.name === "select-all") {
      idxs = webOrganizeResult.data.map((row) => row.id);
    } else if (!event.target.checked && event.target.name === "select-all") {
      idxs = [];
    } else if (event.target.checked) {
      idxs = [...checkedWebOrganizeResult, event.target.name];
    } else if (!event.target.checked) {
      idxs = checkedWebOrganizeResult.filter(
        (row) => row !== event.target.name
      );
    }
    setCheckedWebOrganizeResult(idxs);
  };

  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === "asc") {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  let sortedContentListTdRows = sortRows([...webOrganizeResult.data], paramsRes.order, paramsRes.order_type);
  const countRes = Math.ceil(webOrganizeResult.total / paramsRes.size);
  const pageIndexRes = paramsRes.page > 1 ? (paramsRes?.page - 1) * paramsRes?.size : 0;
  const sizeIndexRes = paramsRes?.size || 10;
  sortedContentListTdRows = sortedContentListTdRows.splice(pageIndexRes, sizeIndexRes);

  const resultIds = new Set(webOrganizeResult.data.map(resultRow => resultRow.id));
  const contentCountExceptSelectedContent = webOrganizeData.data.reduce((acc, row) => {
    return resultIds.has(row.id) ? acc : acc + 1;
  }, 0);

  const handleCheckBoxContent = (event) => {
    let idxs = [];
    if (event.target.checked && event.target.name === "select-all") {
      idxs = webOrganizeData.data
        .filter(row => !resultIds.has(row.id))  // filter content in "Selected Content"
        .map(item => item.id);
    } else if (!event.target.checked && event.target.name === "select-all") {
      idxs = [];
    } else if (event.target.checked) {
      idxs = [...checkedWebOrganizeData, event.target.name];
    } else if (!event.target.checked) {
      idxs = checkedWebOrganizeData.filter((row) => row !== event.target.name);
    }
    setCheckedWebOrganizeData(idxs);
  };

  const getWebOrganizeResultData = async (params) => {
    const res = await getWebContentOrganizeResult(idx, params);
    setCheckedWebOrganizeResult([]);
    setWebOrganizeResult(res);
    setOriginalWebOrganizeResult(res);
    setAddedWebOrganizeResult(res);
  };

  let sortedWebDataTdRows = sortRows([...webOrganizeData.data], params.order, params.order_type);
  const count = Math.ceil(webOrganizeData.total / params.size);
  const total = webOrganizeResult.total || 0;
  const pageIndex = params.page > 1 ? (params?.page - 1) * params?.size : 0;
  const sizeIndex = params?.size || 10;
  sortedWebDataTdRows = sortedWebDataTdRows.splice(pageIndex, sizeIndex);

  const getWebContentOrganizeSearch = async (searchWebContentData) => {
    const res = await getWebContentOrganizeData(searchWebContentData);
    setCheckedWebOrganizeData([]);
    setWebOrganizeData(res);
    setOriginalWebOrganizeData(res);
    const sortCpData = sortRows([...res.data], params.order, params.order_type);
    const cpList = [...new Set(sortCpData.map((data) => data.cp))];
    if (searchWebContentData.cp === "All") {
      let selectCpOption = [];
      cpList.forEach((data) => {
        selectCpOption.push({
          id: data,
          value: data,
          option: data,
          type: "text",
        });
      });
      selectCpOption = [
        { id: "All", value: "All", option: "All", type: "text" },
        ...selectCpOption,
      ];
      setSelectCpOption(selectCpOption);
    }
  };

  const onClickTopBottom = (label) => {
    const checked = webOrganizeResult.data.filter((item) =>
      checkedWebOrganizeResult.includes(item.id)
    );

    const unchecked = webOrganizeResult.data.filter(
      (item) => !checkedWebOrganizeResult.includes(item.id)
    );

    let reorderedList;
    if (label === "Top") {
      reorderedList = [...checked, ...unchecked];
    } else {
      // Bottom
      reorderedList = [...unchecked, ...checked];
    }

    reorderedList.forEach((item, index) => {
      item.resultOrder = index + 1;
    });
    setWebOrganizeResult((prev) => ({
      ...prev,
      data: reorderedList,
    }));
    setOriginalWebOrganizeResult((prev) => ({
      ...prev,
      data: reorderedList,
    }));
  };

  const isSortedByResultOrder = (arr) => {
    for (let i = 1; i < arr.length; i++) {
      if (arr[i - 1].resultOrder > arr[i].resultOrder) {
        return false;
      }
    }
    return true;
  }

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index) return;
    const updatedWebOrganizeData = [...webOrganizeResult.data];
    const [removedRow] = updatedWebOrganizeData.splice(source.index, 1);
    updatedWebOrganizeData.splice(destination.index, 0, removedRow);
    const reorderedData = updatedWebOrganizeData.map((item, index) => ({
      ...item,
      resultOrder: index + 1,
    }));
    setWebOrganizeResult((prev) => ({
      ...prev,
      data: reorderedData,
    }));
    setOriginalWebOrganizeResult((prev) => ({
      ...prev,
      data: reorderedData,
    }));
  };

  const onChangeCp = (eValue) => {
    const value = eValue;
    setSearchWebContent((prevState) => ({
      ...prevState,
      cp: value,
    }));
    setSelectedCp(value);
  };

  const onChangeType = (value) => {
    setSearchWebContent((pewvState) => ({
      ...pewvState,
      type: value,
    }));
    setSelectedType(value);
  };

  const onChangeSelectedInput = () => {
    if (addedWebOrganizeResult.total + checkedWebOrganizeData.length > 20) {
      handelSetShowSnakBar({
        open: true,
        message: "A maximum of 20 items can be added.",
        severity: "error",
      });
    } else {
      let startOrder =
        Math.max(...webOrganizeResult.data.map((n) => n.resultOrder)) + 1;
      startOrder = startOrder === -Infinity ? 1 : startOrder;
      const selectedItems = webOrganizeData.data
        .filter((item) => checkedWebOrganizeData.includes(item.id))
        .map((item, index) => ({
          ...item,
          resultOrder: startOrder + index,
          type: selectedType,
        }));
      setAddedWebOrganizeResult((prev) => ({
        ...prev,
        data: [...prev.data, ...selectedItems],
        total: (prev.total || 0) + selectedItems.length,
      }));
      setWebOrganizeResult((prev) => ({
        ...prev,
        data: [...prev.data, ...selectedItems],
        total: (prev.total || 0) + selectedItems.length,
      }));
      setOriginalWebOrganizeResult((prev) => ({
        ...prev,
        data: [...prev.data, ...selectedItems],
        total: (prev.total || 0) + selectedItems.length,
      }));
      setCheckedWebOrganizeData([]);
    }
  };

  const onChangeSearchContentList = (event) => {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey === "") {
      setWebOrganizeData(originalWebOrganizeData);
      return;
    }
    const filteredData = webOrganizeData.data.filter((item) => {
      return Object.entries(item)
        .filter(([key, value]) => key !== "image")
        .values(item)
        .some((value) => String(value).toLowerCase().includes(searchKey));
    });

    setWebOrganizeData((prev) => ({
      ...prev,
      data: filteredData,
      total: filteredData.length,
    }));
  };

  const onChangeSearchSelectedContent = (event) => {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey === "") {
      setWebOrganizeResult(originalWebOrganizeResult);
      return;
    }
    const filteredData = webOrganizeResult.data.filter((item) => {
      return Object.entries(item)
        .filter(([key, value]) => key !== "image")
        .values(item)
        .some((value) => String(value).toLowerCase().includes(searchKey));
    });
    setWebOrganizeResult((prev) => ({
      ...prev,
      data: filteredData,
      total: filteredData.length,
    }));
  };

  useEffect(() => {
    if (selectedStatus === "P" || !isSortedByResultOrder(sortedContentListTdRows)) {
      setIsAllowMove(false);
    } else {
      setIsAllowMove(true);
    }
  }, [selectedStatus, sortedContentListTdRows]);

  useEffect(() => {
    getWebOrganizeResultData(category, idx);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getWebContentOrganizeSearch(searchWebContent);
    // eslint-disable-next-line
  }, [searchWebContent]);

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container category-organize-container' component='main' sx={{ flexGrow: 1 }}>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area' sx={{ cursor: 'pointer' }}>
              <Link
                className='title link'
                to='#'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/WebService', {
                    replace: true, state: {
                      move: true,
                      targetComponent: 'WebLiveManual',
                      message: {
                        idx: category,
                        areaName: areaName,
                        areaType: areaType,
                        country: country,
                        selectedStatus: selectedStatus,
                        region: region
                      }
                    }
                  });
                }}>
                Category Organize
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          {/* 선택한 카테고리 정보 출력 영역 */}
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {organizeInfos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          <Grid container columnSpacing={2.5} className='content-pack-area'>
            {/* Content List */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area' sx={{ flexGrow: 2 }}>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Content List</Typography>
                      <Typography component='p' className='select-count-wrap'>
                        Select <span className='set-color'>{checkedWebOrganizeData.length}</span> item
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {totalSearchCount || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Box Content Area */}
                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SelectBox
                        id='cp'
                        maxWidth='120px'
                        fullWidth
                        size='medium'
                        selectOptions={selectCpOption}
                        disabled={selectedStatus === 'P'}
                        label='CP'
                        selected={selectedCp}
                        onChange={onChangeCp}
                        shrink={true}
                      />;
                      <SelectBox
                        id='type'
                        maxWidth='150px'
                        size='medium'
                        selectOptions={areaType === 'content_vod' ? requiredContentTypeVodList : selectTypeOption}
                        label='Type'
                        disabled={selectedStatus === 'P'}
                        selected={selectedType}
                        onChange={onChangeType}
                        placeholder=''
                        shrink={true}
                      />
                      <SearchField
                        id='search-1'
                        placeholder='Please enter a search term'
                        label='Ch.no or ch.Name'
                        maxWidth='230px'
                        size='medium'
                        required={false}
                        shrink={false}
                        onChange={onChangeSearchContentList}
                        disabled={selectedStatus === 'P'}
                      />
                    </Box>
                    <Box className='right-area'>
                      <Button
                        color='tertiary'
                        type='box'
                        size='small'
                        label='Select Ch Input'
                        isIcon={true}
                        autosize
                        Icon={BtnArrowRight}
                        iconPos='right'
                        onClick={onChangeSelectedInput}
                        disabled={selectedStatus === 'P'}
                      />
                    </Box>
                  </Box>

                  <Box className='content-inner-wrap'>
                    {/* Table */}
                    <CustomTableContainer className='table-container'>
                      <Table className='custom-table auto' aria-label='table' stickyHeader>
                        {/* table Head */}
                        <TableHead className='thead'>
                          <TableRow className='tr'>
                            {ContentListTh.map((column) => (
                              <TableCell
                                key={column.id}
                                className='th'
                                align={column.align}
                                sx={{ width: column.width }}
                              >
                                {column.checkbox ? (
                                  <>
                                    <CheckBox
                                      label=''
                                      value='select-all'
                                      checked={checkedWebOrganizeData.length !== 0 && contentCountExceptSelectedContent === checkedWebOrganizeData.length}
                                      onChange={handleCheckBoxContent}
                                      indeterminate={checkedWebOrganizeData.length !== 0 && webOrganizeData.total > checkedWebOrganizeData.length}
                                      inputProps={{
                                        'aria-label': 'select all',
                                      }}
                                    />
                                    {column.sortable && <TableSortLabel
                                      active={params.order === column.id}
                                      direction={params.order === column.id ? params.order_type : 'desc'}
                                      onClick={() => handleRequestSort(column.id)}
                                    >
                                      {column.label}
                                    </TableSortLabel>}
                                  </>
                                ) : (
                                  <>
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={params.order === column.id}
                                        direction={params.order === column.id ? params.order_type : 'desc'}
                                        onClick={() => handleRequestSort(column.id)}
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    ) : (
                                      column.label
                                    )}
                                  </>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* table Body */}
                        <TableBody className='tbody'>
                          {sortedWebDataTdRows.map((td, index) => {
                            const isInSelectedContentList = webOrganizeResult.data.some(contentItem => contentItem.id === td.id);
                            return (
                              <TableRow className='tr' key={td.id || index}
                                style={{
                                  pointerEvents: isInSelectedContentList ? 'none' : 'auto',
                                }}
                              >
                                {ContentListTh.map((column, colIndex) => (
                                  <TableCell key={column.id} className='td' align={column.align} sx={{ height: '88px' }}>
                                    {colIndex === 0 ? (
                                      <CheckBox
                                        label={selectedType}
                                        value={td['id']}
                                        onChange={handleCheckBoxContent}
                                        checked={checkedWebOrganizeData.indexOf(td['id']) > -1}
                                        disabled={isInSelectedContentList}
                                      />
                                    ) : colIndex === 4 ? (
                                      <CustomImage
                                        src={td['image']}
                                        fit={false}
                                        alt=''
                                        wrapWidth='122px'
                                        wrapHeight='64px'
                                        rounded
                                      />
                                    ) : (
                                      td[column.id]
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                    {/* // Table */}
                  </Box>

                  {/* Pagination */}
                  <Pagination id='pagination-01' count={count} selected={params.size} onChangeHandle={handlePaginationChange} />
                </Box>
              </Box>
            </Grid>

            {/* Selected Content */}
            <Grid item xs={6}>
              <Box className='white-box white-box-wrap'>
                {/* Box Title Area */}
                <Box className='title-wrap space-between'>
                  <Box className='left-area'>
                    <Box className='box-title-area' display='flex' alignItems='center'>
                      <Typography variant='h2'>Selected Content</Typography>
                    </Box>
                  </Box>
                  <Box className='right-area'>
                    <Box className='show-count-wrap'>
                      <Typography component='span' className='label'>
                        Showing All
                      </Typography>
                      <Typography component='span' className='value'>
                        {total || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className='box-content'>
                  <Box className='white-box-content-top-area'>
                    <Box className='left-area'>
                      <SearchField
                        id='search-2'
                        label='Ch.no or ch.Name'
                        size='medium'
                        maxWidth='230px'
                        placeholder='Please enter a search term'
                        required={false}
                        shrink={false}
                        onChange={onChangeSearchSelectedContent}
                      />
                    </Box>
                    <Box className='right-area'>
                      <CustomButtonGroup buttons={organizeButtons} />
                    </Box>
                  </Box>

                  {/* Selected Content */}
                  <Box className='content-inner-wrap'>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='droppable-table'>
                        {(provided) => (
                          <CustomTableContainer
                            className='table-container'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <Table className='custom-table' aria-label='table' stickyHeader>
                              {/* table Head */}
                              <TableHead className='thead'>
                                <TableRow className='tr'>
                                  {SelectedContentTh.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      className='th'
                                      align={column.align}
                                      sx={{ width: column.width }}
                                    >
                                      {column.checkbox ? (
                                        <Box display='flex' alignContent='center'>
                                          <IconDraggable className='icon-draggable' sx={{ flexShrink: 0 }} />
                                          <CheckBox
                                            label=''
                                            value='select-all'
                                            checked={checkedWebOrganizeResult.length !== 0 && webOrganizeResult.total === checkedWebOrganizeResult.length}
                                            onChange={handleCheckBoxResult}
                                            indeterminate={checkedWebOrganizeResult.length !== 0 && webOrganizeResult.total > checkedWebOrganizeResult.length}
                                            inputProps={{
                                              'aria-label': 'select all',
                                            }}
                                          />
                                          {column.sortable && <TableSortLabel
                                            active={paramsRes.order === column.id}
                                            direction={paramsRes.order === column.id ? paramsRes.order_type : 'desc'}
                                            onClick={() => handleRequestSortRes(column.id)}
                                          >
                                            {column.label}
                                          </TableSortLabel>}
                                        </Box>
                                      ) : (
                                        <>
                                          {column.sortable ? (
                                            <TableSortLabel
                                              active={paramsRes.order === column.id}
                                              direction={paramsRes.order === column.id ? paramsRes.order_type : 'desc'}
                                              onClick={() => handleRequestSortRes(column.id)}
                                            >
                                              {column.label}
                                            </TableSortLabel>
                                          ) : (
                                            column.label
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* table Body */}
                              <TableBody className='tbody'>
                                {sortedContentListTdRows.map((td, index) => (
                                  <Draggable
                                    key={td.id || index}
                                    draggableId={td.id || index.toString()}
                                    index={index}
                                    isDragDisabled={isAllowMove ? false : true}
                                  >
                                    {(provided) => (
                                      <TableRow
                                        className='tr'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {SelectedContentTh.map((column, colIndex) => (
                                          <TableCell
                                            key={column.id}
                                            className='td'
                                            align={column.align}
                                            sx={{ height: '88px' }}
                                          >
                                            {colIndex === 0 ? (
                                              <Box display='flex' alignItems='center' {...provided.dragHandleProps}>
                                                <Tooltip
                                                  title={isAllowMove ? '' : selectedStatus === "P"? 'You can only change the order when the Status is Save.' : 'You can only change the order when the sorting is in ascending order.'}
                                                  placement='top'
                                                >
                                                  <IconDraggable
                                                    className='icon-draggable'
                                                    disabled={isAllowMove ? false : true}
                                                  />
                                                </Tooltip>

                                                <CheckBox
                                                  onChange={handleCheckBoxResult}
                                                  label={td.resultOrder}
                                                  value={td['id']}
                                                  checked={checkedWebOrganizeResult.indexOf(td['id']) > -1}
                                                  defaultChecked={false}
                                                />
                                              </Box>
                                            ) : (
                                              td[column.id]
                                            )}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </CustomTableContainer>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>

                  {/* Pagination */}
                  <Pagination id='pagination-01' count={countRes} selected={paramsRes.size} onChangeHandle={handlePaginationChangeRes} />
                  <div>
                    {(actionDialog === 'Delete' || actionDialog === 'Save') && <BasicDialog
                      title={actionDialog === 'Delete' ? 'Delete Result' : actionDialog === 'Save' ? 'Save Result' : ''}
                      contentTitle={actionDialog === 'Delete' ? ' Are you sure to delete?' : actionDialog === 'Save' ? ' Are you sure to save?' : ''}
                      size={'small'}
                      closeX={true}
                      open={true}
                      handleClose={() => setActionDialog('')}
                      buttons={actionDialog === 'Delete' ? deleteActionButtons : actionDialog === 'Save' ? saveActionButtons : []}
                    />}
                  </div>
                  <div>
                    <BasicSnackbar onClose={handleSnackbarClose} severity={showSnakBar.severity} message={showSnakBar.message} open={showSnakBar.open} duration={1000} />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default CategoryOrganize;