import React, { useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import Button from 'components/component/BasicButton';
import Pagination from 'components/component/BasicPagination';
import SelectBox from 'components/component/BasicSelectBox';
import SearchField from 'components/component/BasicSearchField';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
  Box,
  Typography,
  Link,
  ListItem,
  List,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { styled } from '@mui/material/styles';

// Data

import {
  selectOption,
  ShelfHistoryDetailTh,
  ShelfHistoryDetailTd,
} from './data/FreeOnData';

// 테이블
const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
}));
const CellType = styled(TableCell)(({ theme }) => ({
  '& .deleted': {
    color: '#B91C1C',
  },
  '& .create': {
    color: '#0077B5',
  },
  '& .update': {
    color: '#0D9488',
  },
}));

// Table view line
const selOptionData = [
  { id: 1, value: '10 rows', option: '10 rows', type: 'text' },
  { id: 2, value: '20 rows', option: '20 rows', type: 'text' },
  { id: 3, value: '30 rows', option: '30 rows', type: 'text' },
  { id: 4, value: '50 rows', option: '50 rows', type: 'text' },
];

// 메인 컨텐츠
function FreeOnLGChannelsShelfHistoryDetail() {
  // BreadCrumb
  const optionMenu = {
    gnbMenu: 'Promotion',
    lnbMenu: 'Free On LG Channels Shelf',
    normalMenu: ['Free On LG Channels Shelf History', 'History Detail'],
  };

  // 테이블 내림, 오름차순
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortRows = (rows, orderBy, order) => {
    return rows.sort((a, b) => {
      if (orderBy) {
        if (order === 'asc') {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  // TableBody Row
  const sortedTdRows = sortRows([...ShelfHistoryDetailTd], orderBy, order);

  // Area 정보 데이터
  const infos = [
    {
      index: 1,
      label: '#Change Date',
      value: '2024-08-23 00:34:29',
      divide: true,
    },
    {
      index: 2,
      label: '#Category Code',
      value: 'C-Favorite',
      divide: true,
    },
    {
      index: 3,
      label: '#Category Name',
      value: 'My Favorite',
      divide: true,
    },
    {
      index: 4,
      label: '#Content Type',
      value: 'live',
      divide: true,
    },
    {
      index: 4,
      label: '#Mapping Type',
      value: 'Manual',
      divide: false,
    },
  ];

  return (
    <Box display='flex' className='wrapper'>
      <Box className='main-container web-live' component='main'>
        {/* BreadCrumb Area */}
        <Box className='top-title-container'>
          <Box className='top-title-box'>
            <Box className='title-area'>
              <Link className='title link' href=''>
                History Detail
              </Link>
            </Box>
            <Box className='bread-area'>
              <BreadCrumb optionMenu={optionMenu} />
            </Box>
          </Box>
        </Box>

        <Box className='content-wrap program-detail' component='div'>
          <Box className='info-output-container'>
            <Box className='left-area'>
              {/* 카테고리 정보 출력 영역 */}
              <List className='info-list-container'>
                {infos.map((item) => (
                  <React.Fragment key={item.index}>
                    <ListItem className='list-item'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='value'>{item.value}</Typography>
                    </ListItem>
                    {item.divide && <Divider className='divider' />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box className='right-area'></Box>
          </Box>

          <Box className='white-box white-box-wrap '>
            <Box className='box-content'>
              <Box className='white-box-content-top-area' alignItems='center'>
                {/* left-area */}
                <Box className='left-area'>
                  <SelectBox
                    id='select-search'
                    size='medium'
                    selectOptions={selectOption}
                    label=''
                    maxWidth='180px'
                    disabled={false}
                    placeholder=''
                  />

                  <SearchField placeholder='Please enter a search term' maxWidth='230px' />
                </Box>
                <Box className='right-area'>
                  <Button color='tertiary' type='outline' size='small' label='Excel Download' isIcon={false} autosize />
                </Box>
                {/* right-area */}
              </Box>
              <Box className='content-inner-wrap'>
                {/* Table */}
                <CustomTableContainer className='table-container'>
                  <Table className='custom-table' aria-label='table' stickyHeader>
                    {/* table Head */}
                    <TableHead className='thead'>
                      <TableRow className='tr'>
                        {ShelfHistoryDetailTh.map((column) => (
                          <TableCell key={column.id} className='th' align={column.align} sx={{ width: column.width }}>
                            <>
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'desc'}
                                onClick={() => handleRequestSort(column.id)}
                              >
                                {column.label}
                              </TableSortLabel>
                            </>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* table Body */}
                    <TableBody className='tbody'>
                      {sortedTdRows.map((td, index) => (
                        // 240926 추가수정 - TableRow 클래스 추가
                        <TableRow className='tr' key={td.id || index}>
                          {ShelfHistoryDetailTh.map((column, colIndex) => (
                            <CellType key={column.id} className='td' align={column.align}>
                              {colIndex === 7 ? (
                                <span
                                  className={
                                    td.data8 === 'Deleted'
                                      ? 'deleted'
                                      : td.data8 === 'Create'
                                      ? 'create'
                                      : td.data8 === 'Updated'
                                      ? 'update'
                                      : ''
                                  }
                                >
                                  {td[`data${colIndex + 1}`]}
                                </span>
                              ) : (
                                td[`data${colIndex + 1}`]
                              )}
                            </CellType>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableContainer>
                {/* // Table */}
              </Box>

              {/* Pagination */}
              <Pagination count={5} id='pagination-01' selOptionData={selOptionData} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default FreeOnLGChannelsShelfHistoryDetail;
