import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';

// Styled
const IconButtonArrow = styled(IconButton)`
  .dark & {
    .MuiSvgIcon-root {
      path {
        fill: #808080;
      }
    }
  }
`;

function BasicMenu(props) {
  const {title, options, onClick} = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckBoxTitle = (value) => {
    onClick(value);
    handleClose();
  };

  return (
    <>
      {typeof title === 'string' && (
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {props.title}
        </Button>
      )}
      {typeof title === 'object' && (
        <IconButtonArrow
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          size='small'
          sx={{ width: '18px', height: '18px', padding: 0, marginLeft: '4px' }}
          onClick={handleClick}
        >
          {props.title}
        </IconButtonArrow>
      )}
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option, i) => (
          <MenuItem onClick={() => handleCheckBoxTitle(option.text)} key={`option-${i}`}>
            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default BasicMenu;
