export const areaTypeOption = [
  {
    id: 1,
    value: "banner",
    option: "Banner (1920px X 305px)",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 2,
    value: "content_channel",
    option: "Content - Channel",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 3,
    value: "content_vod",
    option: "Content - VOD",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 5,
    value: "brand_a",
    option: "Branding Image - A Type (1920px X 350px)",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 6,
    value: "brand_b",
    option: "Branding image - B type (890px X 490px)",
    type: "text",
    defaultChecked: false,
  },
];

export const Region = [
  { id: "all", value: "All", option: "All", type: "text" },
  { id: "Eu", value: "Eu", option: "Eu", type: "text" },
  { id: "Asia", value: "Asia", option: "Asia", type: "text" },
];

export const statusData = [
  { id: "save", value: "S", option: "Save", type: "text" },
  { id: "publish", value: "P", option: "Publish", type: "text" },
];

export const DeviceTypeData = [
  { id: "device-1", value: "TV", option: "TV", type: "text" },
  { id: "device-2", value: "device-2", option: "device-2", type: "text" },
  { id: "device-3", value: "device-3", option: "device-3", type: "text" },
];

export const PlatformVersionData = [
  { id: "platform-version-1", value: "3.4.0", option: "3.4.0", type: "text" },
  { id: "platform-version-2", value: "3.3.5", option: "3.3.5", type: "text" },
  { id: "platform-version-3", value: "3.3.0", option: "3.3.0", type: "text" },
];

export const PrTh = [
  {
    id: "areaCode",
    align: "left",
    label: "Area Code",
    width: "10%",
    checkbox: false,
    sortable: true,
  },
  {
    id: "areaName",
    align: "left",
    label: "Area Name",
    width: "auto",
    checkbox: false,
    sortable: true,
  },
  {
    id: "areaType",
    align: "left",
    label: "Area Type",
    width: "24%",
    checkbox: false,
    sortable: true,
  },

  {
    id: "enable",
    align: "left",
    label: "Enable",
    width: "10%",
    checkbox: false,
    sortable: false,
  },
  {
    id: "action",
    align: "left",
    label: "Action",
    width: "12%",
    checkbox: false,
    sortable: false,
  },
];

export const RegionSelectData = [
  {
    id: 1,
    value: "option1",
    option: "Option 1",
    type: "text",
    defaultChecked: true,
  },
  {
    id: 2,
    value: "option2",
    option: "Option 2",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 3,
    value: "option3",
    option: "Option 3",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 4,
    value: "option4",
    option: "Option 4",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 5,
    value: "option5",
    option: "Option 5",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 6,
    value: "option6",
    option: "Option 6",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 7,
    value: "option7",
    option: "Option 7",
    type: "text",
    defaultChecked: false,
  },
];

export const CountrySelectData = [
  {
    id: 1,
    value: "option1",
    option: "Option 1",
    type: "text",
    defaultChecked: true,
  },
  {
    id: 2,
    value: "option2",
    option: "Option 2",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 3,
    value: "option3",
    option: "Option 3",
    type: "text",
    defaultChecked: false,
  },
  {
    id: 4,
    value: "option4",
    option: "Option 4",
    type: "text",
    defaultChecked: false,
  },
];
