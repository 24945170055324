import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/component/BasicBreadCrumb';
import CustomTabs from 'components/component/BasicTabs';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectBox from 'components/component/BasicSelectBox';
import TableSortLabel from '@mui/material/TableSortLabel';
import CustomButtonGroup from 'components/component/BasicButtonGroup';
import Pagination from 'components/component/BasicPagination';
import CheckBox from 'components/component/BasicCheckBox';
import Button from 'components/component/BasicButton';
import TextField from 'components/component/BasicTextField';
import SearchField from 'components/component/BasicSearchField';
import CustomImage from 'components/component/BasicImage';
import TextLink from 'components/component/BasicTextLink';
import Dialog from 'components/component/BasicDialog';
import { styled } from '@mui/material/styles';
import { BASE_URL } from 'constants/index';
import { getHeaders } from 'utils/Header';
import { selectOption, MetaChannelTh, MetaProgramTh, MetaVodTh, MetaSeriesTh } from 'pages/Inspect/Metadata/data/MetaData';
import { RefreshIcon } from 'components/component/BasicIcon';
import { useNavigate } from 'react-router-dom';

const tabs = [{ label: 'Channel' }, { label: 'Program' }, { label: 'VOD' }, { label: 'Series' }];

function Metadata (props) {
  const { serviceCountry, provider, message } = props;
  const navigate = useNavigate();
  const [selectRegionList, setSelectRegionList] = useState([]);
  const [selectCountryList, setSelectCountryList] = useState([]);
  const [selectChannelMapIdList, setSelectChannelMapIdList] = useState([]);
  const [selectProviderList, setSelectProviderList] = useState([]);
  const [selectTypeList, setSelectTypeList] = useState([]);
  const [selectDeviceTypeList, setSelectDeviceTypeList] = useState([]);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [dataList, setDataList] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const defaultCountryCode = 'US';
  const defaultChannelMapId = 'US-webOS-4.9.0';
  const defaultDeviceType = 'TV';
  const defaultType = 'tvshow';
  const [filters, setFilters] = useState({
    region: 'All',
    countryCode: defaultCountryCode,
    deviceType: defaultDeviceType,
    channelMapId: defaultChannelMapId,
    channelNumber: '',
    channelId: '',
    providerId: 'All',
    channelName: '',
    programId: '',
    type: defaultType,
    vodId: '',
    vodTitle: '',
    seriesId: '',
    seriesTitle: '',
    seasonId: '',
    seasonTitle: ''
  });
  const [dataCount, setDataCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [isDisplay, setIsDisplay] = useState(false);
  const optionMenu = {
    gnbMenu: 'Inspection',
    lnbMenu: 'Metadata',
  };
  const [tabValue, setTabValue] = useState(0);
  const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    height: '100%',
    overflowY: 'auto',
  }));

  useEffect(() => {
    if (message) {
      if (message.filters) {
        setFilters(message.filters);
      }
      if (message.tabValue) {
        setTabValue(message.tabValue);
      }
    }
  }, [message]);

  //drop down list
  useEffect(() => {
    if (serviceCountry && filters && filters.region && filters.countryCode) {
      if (filters.region !== 'All') {
        let tempCountryList = serviceCountry.filter(item => item.ricCode === filters.region).map(item => item.countryCode).sort();
        setSelectCountryList(tempCountryList.map(item => ({id: item, value: item, option: item, type: 'text'})));
      } else {
        const updatedCountryList = serviceCountry.map(item => item.countryCode).sort();
        setSelectCountryList(updatedCountryList.map(item => ({id: item, value: item, option: item, type: 'text'})));
      }
      let tempProviderList = provider.filter(item => item.countryCode === filters.countryCode).map(item => item.providerList).reduce((acc, curr) => acc.concat(curr), []);
      setSelectProviderList([{id: 'All', value: 'All', option: 'All', type: 'text'}, ...tempProviderList.map(item => ({id: item.providerId, value: item.providerId, option: item.providerName, type: 'text'}))]);
      let tempChannelMapIdList = serviceCountry.filter(item => item.countryCode === filters.countryCode).map(item => item.channelMapList).reduce((acc, curr) => acc.concat(curr), []);
      setSelectChannelMapIdList(tempChannelMapIdList.map(item => ({id: item.channelMapId, value: item.channelMapId, option: item.channelMapId, type: 'text'})));
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (serviceCountry && provider && filters) {
      const updatedSelectRegionList = ['All', ...Array.from(new Set(serviceCountry.map(item => item.ricCode))).sort()].map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));
      const updatedSelectCountryList = serviceCountry.map(item => item.countryCode).sort().map(item => ({
        id: item, value: item, option: item, type: 'text'
      }));

      let tempChannelMapIdList = serviceCountry
        .filter(item => item.countryCode === filters.countryCode)
        .map(item => item.channelMapList)
        .reduce((acc, curr) => acc.concat(curr), []);
      const updatedChannelMapIdList = [...tempChannelMapIdList.map(item => ({
        id: item.channelMapId,
        value: item.channelMapId,
        option: item.channelMapId,
        type: 'text'
      }))];

      let tempProviderList = provider
        .filter(item => item.countryCode === filters.countryCode)
        .map(item => item.providerList)
        .reduce((acc, curr) => acc.concat(curr), []);
      const updatedProviderList = [
        { id: 'All', value: 'All', option: 'All', type: 'text' },
        ...tempProviderList.map(item => ({
          id: item.providerId,
          value: item.providerId,
          option: item.providerName,
          type: 'text'
        }))
      ];
      setSelectCountryList(updatedSelectCountryList);
      setSelectChannelMapIdList(updatedChannelMapIdList);
      setSelectProviderList(updatedProviderList);
      setSelectRegionList(updatedSelectRegionList);
      setSelectDeviceTypeList(['TV', 'auto', 'mobile', 'pc'].map(item => ({ id: item, value: item, option: item, type: 'text' })));
      setSelectTypeList(['tvshow', 'movies', 'livestream'].map(item => ({ id: item, value: item, option: item, type: 'text' })));
    }
    // eslint-disable-next-line
  }, [serviceCountry, provider]);

  useEffect(() => {
    if (!message.tabValue || message.tabValue === tabValue) {
      if (message && message.filters) {
        searchMetadata(message.filters);
        message.filters = null;
        message.tabValue = null;
      } else {
        searchMetadata({
          region: 'All',
          countryCode: defaultCountryCode,
          deviceType: defaultDeviceType,
          channelMapId: defaultChannelMapId,
          channelNumber: '',
          channelId: '',
          providerId: 'All',
          channelName: '',
          programId: '',
          type: defaultType,
          vodId: '',
          vodTitle: '',
          seriesId: '',
          seriesTitle: '',
          seasonId: '',
          seasonTitle: ''
        });
      }
    }
    // eslint-disable-next-line
  }, [tabValue]);

  useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newPaginatedData = dataList.slice(startIndex, endIndex);
    setPaginatedData([...newPaginatedData]);
    setIsDisplay(true);
  }, [dataList, page, itemsPerPage]);

  const searchMetadata = (paramFilters) => {
    setIsDisplay(false);
    const urlParam = new URLSearchParams();
    for (const [key, value] of Object.entries(paramFilters)) {
      if (value !== '' && value !== 'All' && key !== 'region') {
        urlParam.append(key, value);
      }
    }
    let url = `${BASE_URL}/metadata/${tabs[tabValue].label.toLowerCase()}?${urlParam.toString()}`;
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
    })
    .then(res => res.json())
    .then(body => {
      if (body.result === 'SUCCESS') {
        handleData(body.data);
      } else {
        throw new Error(`[Metadata] ${tabs[tabValue].label} request error`);
      }
    })
    .catch(error => {
      setAlertTitle('Warning');
      setAlertMessage(error.message.replace('Error: ', ''));
      setAlertOpen(true);
    }).finally(() => {
    });
  };

  const buttons = [
    {
      color: 'tertiary',
      type: 'outline',
      size: 'medium',
      label: 'Reset',
      isIcon: true,
      Icon: RefreshIcon,
      iconPos: 'left',
      className: 'custom-save-icon',
      autosize: true,
    },
    {
      minWidth: '120px',
      color: 'primary',
      type: 'box',
      size: 'medium',
      label: 'Search',
      isIcon: false,
      onClick: () => searchMetadata(filters),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setIsDisplay(false);
    if (message && message.filter) {
      message.filters = null;
    }
    if (message && message.tabValue) {
      message.tabValue = null;
    }
    setTabValue(newValue);
    handleSelectMultiFilter({
      region: 'All',
      countryCode: defaultCountryCode,
      deviceType: defaultDeviceType,
      channelMapId: defaultChannelMapId,
      channelNumber: '',
      channelId: '',
      providerId: 'All',
      channelName: '',
      programId: '',
      type: defaultType,
      vodId: '',
      vodTitle: '',
      seriesId: '',
      seriesTitle: '',
      seasonId: '',
      seasonTitle: ''
    });
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...dataList].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setDataList([...sortedData]);
  };

  const handleSelectRegion = (region) => {
    if (region) {
      if (region !== 'All') {
        let tempCountryList = serviceCountry
          .filter(item => item.ricCode === region)
          .map(item => item.countryCode).sort();
        handleSelectCountry(tempCountryList[0], region);
      } else {
        handleSelectCountry(defaultCountryCode, region);
      }
    }
  };

  const handleSelectCountry = (country, region) => {
    if (message && message.filters) {
      message.filters = null;
    }
    if (message && message.tabValue) {
      message.tabValue = null;
    }
    let tempChannelMapIdList = serviceCountry.filter(item => item.countryCode === country).map(item => item.channelMapList).reduce((acc, curr) => acc.concat(curr), []);
    let channelMapId = tempChannelMapIdList.find(item => item.channelMapId.indexOf(country) === 0).channelMapId;
    handleSelectMultiFilter({countryCode: country, channelMapId: channelMapId, providerId: 'All', region: region});
  };

  const handleSelectFilter = (name, value) => {
    if (value !== null && value !== undefined) {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const handleSelectMultiFilter = (json) => {
    const newFilters = {...filters, ...json};
    setFilters(newFilters);
  };

  const handleData = (data) => {
    setDataList(data);
    setDataCount(Object.keys(data).length);
  };

  const handlePagination = (pagingValue) => {
    if (pagingValue) {
      if (pagingValue.type === 'page') {
        setPage(pagingValue.value);
      } else if (pagingValue.type === 'size') {
        setItemsPerPage(pagingValue.value);
        setPage(1);
      }
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleDetailPage = (contentId) => {
    const targetComponentList = ['ChannelDetail', 'ProgramDetail', 'VodDetail', 'SeriesDetail'];
    navigate('/Metadata',
      { replace: true, state:
        { move: true,
          targetComponent: targetComponentList[tabValue],
          message:
          {
            contentId: contentId,
            countryCode: filters.countryCode,
            channelMapId: filters.channelMapId,
            filters: filters,
            tabValue: tabValue,
          }
        }
      }
    );
  };

  const handleVodDetailPage = (vodType, vodId) => {
    let targetComponent = 'VodDetailTVShow';
    if (vodType === 'movies') {
      targetComponent = 'VodDetailMovie';
    } else if (vodType === 'livestream') {
      targetComponent = 'VodDetailLiveStream';
    }
    navigate('/Metadata',
      { replace: true, state:
        { move: true,
          targetComponent: targetComponent,
          message:
          {
            contentId: vodId,
            countryCode: filters.countryCode,
            filters: filters,
            tabValue: tabValue,
          }
        }
      }
    );
  };

  const alertDialogButtons = [
    {
      width: '150px',
      size: 'large',
      label: 'OK',
      type: 'outline',
      color: 'tertiary',
      autosize: true,
      onClick: handleAlertClose
    },
  ];

  return (
  <Box display='flex' className='wrapper'>
    <Box className='main-container inspect' component='main'>
      {/* BreadCrumb Area */}
      <div className='top-title-container'>
        <div className='top-title-box'>
          <div className='title-area'>
            <Typography className='title'>Metadata</Typography>
          </div>
          <div className='bread-area'>
            <BreadCrumb optionMenu={optionMenu} />
          </div>
        </div>
      </div>

      <Box className='content-wrap' component='div'>
        <div className='tab-container'>
          <CustomTabs
            tabStyle='type-01'
            value={tabValue}
            handleChange={handleTabChange}
            tabs={tabs}
          />
          {tabValue === 0 && (
            // Tab 1
            <div className='tab-panel'>
              <div className='panel-wrap'>
                <Box component='div' className='white-box filter-box'>
                  <Box className='form-wrap-box'>
                    <div className='form-area left'>
                      <Box component='div' className='component-wrap col-5'>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab1-Region'
                            size='large'
                            shrink={true}
                            label='Region'
                            required={false}
                            selectOptions={selectRegionList}
                            disabled={false}
                            onChange={value => handleSelectRegion(value)}
                            selected={filters.region}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab1-Country'
                            size='large'
                            shrink={true}
                            label='Country'
                            selectOptions={selectCountryList}
                            disabled={false}
                            onChange={value => handleSelectCountry(value, filters.region)}
                            selected={filters.countryCode}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab1-Device'
                            fullWidth
                            size='large'
                            width='100%'
                            selectOptions={selectDeviceTypeList}
                            disabled={false}
                            required={false}
                            label='Device Type'
                            shrink
                            onChange={value => handleSelectFilter('deviceType', value)}
                            selected={filters.deviceType}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab1-ChannelMapId'
                            fullWidth
                            size='large'
                            width='100%'
                            selectOptions={selectChannelMapIdList}
                            disabled={false}
                            required={false}
                            label='Channel Map ID'
                            shrink
                            onChange={value => handleSelectFilter('channelMapId', value)}
                            selected={filters.channelMapId}
                          />
                        </Box>
                      </Box>

                      <Box component='div' className='component-wrap col-5'>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab1-ChannelNumber'
                            type='outlined'
                            size='large'
                            placeholder='Channel No'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={event => handleSelectFilter('channelNumber', event.target.value)}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab1-ChannelId'
                            type='outlined'
                            size='large'
                            placeholder='Channel ID'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={event => handleSelectFilter('channelId', event.target.value)}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab1-ChannelName'
                            type='outlined'
                            size='large'
                            placeholder='Channel Name'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={event => handleSelectFilter('channelName', event.target.value)}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab1-CPName'
                            fullWidth
                            size='large'
                            width='100%'
                            selectOptions={selectProviderList}
                            disabled={false}
                            required={false}
                            label='CP Name'
                            shrink
                            onChange={value => handleSelectFilter('providerId', value)}
                            selected={filters.providerId}
                          />
                        </Box>
                      </Box>
                    </div>
                    <div className='form-area right'>
                      <CustomButtonGroup buttons={buttons} />
                    </div>
                  </Box>
                </Box>

                {isDisplay && (
                  <Box className='white-box white-box-wrap'>
                    <div className='box-content'>
                      <Box className='white-box-content-top-area'>
                        <Box className='left-area'>
                          <Box className='total-count'>
                            <Box component='span' className='label'>
                              Total
                            </Box>
                            <Box component='span' className='value'>
                              {dataCount}
                            </Box>
                          </Box>
                        </Box>
                        <Box className='right-area'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='small'
                            label='Excel Dounload'
                            isIcon={false}
                            autosize
                          />
                          <SelectBox
                            id='tab1-Search'
                            size='medium'
                            selectOptions={selectOption}
                            label=''
                            maxWidth='180px'
                            disabled={false}
                          />
                          <SearchField
                            id='search-2'
                            size='medium'
                            maxWidth='230px'
                            placeholder='Please enter a search term'
                          />
                        </Box>
                      </Box>
                      <Box className='content-inner-wrap'>
                        <CustomTableContainer className='table-container'>
                          <Table
                            className='custom-table'
                            aria-label='table'
                            stickyHeader
                          >
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {MetaChannelTh.map(column => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all'
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={
                                            orderBy === column.id
                                              ? order
                                              : 'desc'
                                          }
                                          onClick={() =>
                                            handleRequestSort(column.id)
                                          }
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={
                                              orderBy === column.id
                                                ? order
                                                : 'desc'
                                            }
                                            onClick={() =>
                                              handleRequestSort(column.id)
                                            }
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            <TableBody className='tbody'>
                              {paginatedData.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {MetaChannelTh.map((column, colIndex) => (
                                    <TableCell
                                      key={column.id}
                                      className='td'
                                      align={column.align}
                                    >
                                      {colIndex === 0 ? (
                                        <CustomImage
                                          src={td[column.id]}
                                          fit={false}
                                          alt=''
                                          wrapWidth='122px'
                                          wrapHeight='64px'
                                          rounded
                                        />
                                      ) : colIndex === 1 ? (
                                        <TextLink
                                          href='/Metadata'
                                          onClick={() => handleDetailPage(td['channelId'])}
                                          text={td[column.id]}
                                        />
                                      ) : colIndex === 6 ? (
                                        <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='DETAIL'
                                          isIcon={false}
                                          Icon=''
                                          iconPos={undefined}
                                          sx={{ minWidth: 'auto' }}
                                          onClick={() => handleDetailPage(td['channelId'])}
                                        />
                                      ) : (
                                        td[column.id]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                      </Box>

                      <Pagination
                        id='pagination-01'
                        count={Math.ceil(dataList.length / itemsPerPage)}
                        selected={itemsPerPage}
                        onChangeHandle={handlePagination}
                        page={page}
                      />
                    </div>
                  </Box>
                )}
              </div>
            </div>
          )}
          {tabValue === 1 && (
            // Tab 2
            <div className='tab-panel'>
              <div className='panel-wrap'>
                <Box component='div' className='white-box filter-box'>
                  <Box className='form-wrap-box'>
                    <div className='form-area left'>
                      <Box className='component-wrap col-5'>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab2-Region'
                            size='large'
                            shrink={true}
                            label='Region'
                            required={false}
                            selectOptions={selectRegionList}
                            disabled={false}
                            onChange={value => handleSelectRegion(value)}
                            selected={filters.region}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab2-Country'
                            size='large'
                            shrink={true}
                            label='Country'
                            selectOptions={selectCountryList}
                            disabled={false}
                            onChange={value => handleSelectCountry(value, filters.region)}
                            selected={filters.countryCode}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab2-CPName'
                            size='large'
                            shrink={true}
                            label='CP Name'
                            selectOptions={selectProviderList}
                            disabled={false}
                            onChange={value => handleSelectFilter('providerId', value)}
                            selected={filters.providerId}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab2-ChannelName'
                            type='outlined'
                            size='large'
                            placeholder='Channel Name'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={event => handleSelectFilter('channelName', event.target.value)}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab2-ProgramId'
                            type='outlined'
                            size='large'
                            placeholder='Program ID'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={event => handleSelectFilter('programId', event.target.value)}
                          />
                        </Box>
                      </Box>
                    </div>
                    <div className='form-area right'>
                      <CustomButtonGroup buttons={buttons} />
                    </div>
                  </Box>
                </Box>

                {isDisplay && (
                  <Box className='white-box white-box-wrap'>
                    <div className='box-content'>
                      <Box className='white-box-content-top-area'>
                        <Box className='left-area'>
                          <Box className='total-count'>
                            <Box component='span' className='label'>
                              Total
                            </Box>
                            <Box component='span' className='value'>
                              {dataCount}
                            </Box>
                          </Box>
                        </Box>
                        <Box className='right-area'>
                          <Button
                            color='tertiary'
                            type='outline'
                            size='small'
                            label='Excel Dounload'
                            isIcon={false}
                            autosize
                          />
                          <SelectBox
                            id='tab2-Search'
                            size='medium'
                            selectOptions={selectOption}
                            label=''
                            maxWidth='180px'
                            disabled={false}
                          />
                          <SearchField
                            id='search-2'
                            size='medium'
                            maxWidth='230px'
                            placeholder='Please enter a search term'
                          />
                        </Box>
                      </Box>
                      <Box className='content-inner-wrap'>
                        <CustomTableContainer className='table-container'>
                          <Table
                            className='custom-table'
                            aria-label='table'
                            stickyHeader
                          >
                            <TableHead className='thead'>
                              <TableRow className='tr'>
                                {MetaProgramTh.map(column => (
                                  <TableCell
                                    key={column.id}
                                    className='th'
                                    align={column.align}
                                    sx={{ width: column.width }}
                                  >
                                    {column.checkbox ? (
                                      <>
                                        <CheckBox
                                          label=''
                                          value='select-all'
                                          defaultChecked={false}
                                          inputProps={{
                                            'aria-label': 'select all'
                                          }}
                                        />
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={
                                            orderBy === column.id
                                              ? order
                                              : 'desc'
                                          }
                                          onClick={() =>
                                            handleRequestSort(column.id)
                                          }
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      </>
                                    ) : (
                                      <>
                                        {column.sortable ? (
                                          <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={
                                              orderBy === column.id
                                                ? order
                                                : 'desc'
                                            }
                                            onClick={() =>
                                              handleRequestSort(column.id)
                                            }
                                          >
                                            {column.label}
                                          </TableSortLabel>
                                        ) : (
                                          column.label
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            <TableBody className='tbody'>
                              {paginatedData.map((td, index) => (
                                <TableRow className='tr' key={td.id || index}>
                                  {MetaProgramTh.map((column, colIndex) => (
                                    <TableCell
                                      key={column.id}
                                      className='td'
                                      align={column.align}
                                    >
                                      {colIndex === 2 ? (
                                        <CustomImage
                                          src={td[column.id]}
                                          fit={false}
                                          alt=''
                                          wrapWidth='122px'
                                          wrapHeight='64px'
                                          rounded
                                        />
                                      ) : colIndex === 3 ? (
                                        <TextLink
                                          href='/Metadata'
                                          onClick={() => handleDetailPage(td['programId'])}
                                          text={td[column.id]}
                                        />
                                      ) : colIndex === 8 ? (
                                        <Button
                                          color='tertiary'
                                          type='outline'
                                          size='small'
                                          label='DETAIL'
                                          isIcon={false}
                                          Icon=''
                                          iconPos={undefined}
                                          sx={{ minWidth: 'auto' }}
                                          onClick={() => handleDetailPage(td['programId'])}
                                        />
                                      ) : (
                                        td[column.id]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </CustomTableContainer>
                      </Box>

                      <Pagination
                        id='pagination-02'
                        count={Math.ceil(dataList.length / itemsPerPage)}
                        selected={itemsPerPage}
                        onChangeHandle={handlePagination}
                        page={page}
                      />
                    </div>
                  </Box>
                )}
              </div>
            </div>
          )}
          {tabValue === 2 && (
            // Tab 3
            <div className='tab-panel'>
              <div className='panel-wrap'>
                <Box component='div' className='white-box filter-box'>
                  <Box className='form-wrap-box'>
                    <div className='form-area left'>
                      <Box className='component-wrap col-6'>
                        <Box
                          component='div'
                          className='col'
                          sx={{ position: 'relative' }}
                        >
                          <SelectBox
                            id='tab3-Region'
                            size='large'
                            shrink={true}
                            label='Region'
                            required={false}
                            selectOptions={selectRegionList}
                            disabled={false}
                            onChange={value => handleSelectRegion(value)}
                            selected={filters.region}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab3-Country'
                            size='large'
                            shrink={true}
                            required={false}
                            label='Country'
                            selectOptions={selectCountryList}
                            disabled={false}
                            onChange={value => handleSelectCountry(value, filters.region)}
                            selected={filters.countryCode}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab3-CPName'
                            fullWidth
                            size='large'
                            width='100%'
                            required={false}
                            selectOptions={selectProviderList}
                            disabled={false}
                            label='CP Name'
                            shrink
                            onChange={value => handleSelectFilter('providerId', value)}
                            selected={filters.providerId}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab3-Type'
                            fullWidth
                            size='large'
                            width='100%'
                            required={false}
                            selectOptions={selectTypeList}
                            disabled={false}
                            label='Type'
                            shrink
                            onChange={value => handleSelectFilter('type', value)}
                            selected={filters.type}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab3-VODID'
                            type='outlined'
                            size='large'
                            placeholder='VOD ID'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={(event) => handleSelectFilter('vodId', event.target.value)}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab3-VODTitle'
                            type='outlined'
                            size='large'
                            placeholder='VOD Title'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={(event) => handleSelectFilter('vodTitle', event.target.value)}
                          />
                        </Box>
                      </Box>
                    </div>
                    <div className='form-area right'>
                      <CustomButtonGroup buttons={buttons} />
                    </div>
                  </Box>
                </Box>

                {isDisplay && <Box className='white-box white-box-wrap'>
                  <div className='box-content'>
                    <Box className='white-box-content-top-area'>
                      <Box className='left-area'>
                        <Box className='total-count'>
                          <Box component='span' className='label'>
                            Total
                          </Box>
                          <Box component='span' className='value'>
                            {dataCount}
                          </Box>
                        </Box>
                      </Box>
                      <Box className='right-area'>
                        <Button
                          color='tertiary'
                          type='outline'
                          size='small'
                          label='Excel Dounload'
                          isIcon={false}
                          autosize
                        />
                        <SelectBox
                          id='tab3-Search'
                          size='medium'
                          selectOptions={selectOption}
                          label=''
                          maxWidth='180px'
                          disabled={false}
                        />
                        <SearchField
                          id='search-2'
                          size='medium'
                          maxWidth='230px'
                          placeholder='Please enter a search term'
                        />
                      </Box>
                    </Box>
                    <Box className='content-inner-wrap'>
                      <CustomTableContainer className='table-container'>
                        <Table
                          className='custom-table'
                          aria-label='table'
                          stickyHeader
                        >
                          <TableHead className='thead'>
                            <TableRow className='tr'>
                              {MetaVodTh.map(column => (
                                <TableCell
                                  key={column.id}
                                  className='th'
                                  align={column.align}
                                  sx={{ width: column.width }}
                                >
                                  {column.checkbox ? (
                                    <>
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all'
                                        }}
                                      />
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={
                                          orderBy === column.id ? order : 'desc'
                                        }
                                        onClick={() =>
                                          handleRequestSort(column.id)
                                        }
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    </>
                                  ) : (
                                    <>
                                      {column.sortable ? (
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={
                                            orderBy === column.id
                                              ? order
                                              : 'desc'
                                          }
                                          onClick={() =>
                                            handleRequestSort(column.id)
                                          }
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      ) : (
                                        column.label
                                      )}
                                    </>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody className='tbody'>
                            {paginatedData.map((td, index) => (
                              <TableRow className='tr' key={td.id || index}>
                                {MetaVodTh.map((column, colIndex) => (
                                  <TableCell
                                    key={column.id}
                                    className='td'
                                    align={column.align}
                                  >
                                    {colIndex === 2 ? (
                                      <TextLink href='/Metadata' onClick={() => handleVodDetailPage(td['type'], td['vodId'])} text={td[column.id]} />
                                    ) : colIndex === 7 ? (
                                      <Button
                                        color='tertiary'
                                        type='outline'
                                        size='small'
                                        label='DETAIL'
                                        isIcon={false}
                                        Icon=''
                                        iconPos={undefined}
                                        sx={{ minWidth: 'auto' }}
                                        onClick={() => handleVodDetailPage(td['type'], td['vodId'])}
                                      />
                                    ) : (
                                      td[column.id]
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </CustomTableContainer>
                    </Box>

                    <Pagination
                      id='pagination-03'
                      count={Math.ceil(dataList.length / itemsPerPage)}
                      selected={itemsPerPage}
                      onChangeHandle={handlePagination}
                      page={page}
                    />
                  </div>
                </Box>}
              </div>
            </div>
          )}
          {tabValue === 3 && (
            // Tab 4
            <div className='tab-panel'>
              <div className='panel-wrap'>
                <Box component='div' className='white-box filter-box'>
                  <Box className='form-wrap-box'>
                    <div className='form-area left'>
                      <Box component='div' className='component-wrap col-5'>
                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab4-Region'
                            size='large'
                            shrink={true}
                            label='Region'
                            required={false}
                            selectOptions={selectRegionList}
                            disabled={false}
                            onChange={value => handleSelectRegion(value)}
                            selected={filters.region}
                          />
                        </Box>

                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab4-Country'
                            size='large'
                            shrink={true}
                            label='Country'
                            selectOptions={selectCountryList}
                            disabled={false}
                            onChange={value => handleSelectCountry(value, filters.region)}
                            selected={filters.countryCode}
                          />
                        </Box>

                        <Box component='div' className='col'>
                          <SelectBox
                            id='tab4-CPName'
                            size='large'
                            shrink={true}
                            label='CP Name'
                            selectOptions={selectProviderList}
                            disabled={false}
                            onChange={(value) => handleSelectFilter('providerId', value)}
                            selected={filters.providerId}
                          />
                        </Box>
                      </Box>

                      <Box component='div' className='component-wrap col-5'>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab4-SeriesId'
                            type='outlined'
                            size='large'
                            placeholder='Series ID'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={(event) => handleSelectFilter('seriesId', event.target.value)}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab4-SeriesTitle'
                            type='outlined'
                            size='large'
                            placeholder='Series Title'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={(event) => handleSelectFilter('seriesTitle', event.target.value)}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab4-SeasonId'
                            type='outlined'
                            size='large'
                            placeholder='Season ID'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={(event) => handleSelectFilter('seasonId', event.target.value)}
                          />
                        </Box>
                        <Box component='div' className='col'>
                          <TextField
                            id='tab4-SeasonTitle'
                            type='outlined'
                            size='large'
                            placeholder='Season Title'
                            defaultValue=''
                            label=''
                            autoComplete='off'
                            InputLabelProps={{ shrink: false }}
                            onChange={(event) => handleSelectFilter('seasonTitle', event.target.value)}
                          />
                        </Box>
                      </Box>
                    </div>
                    <div className='form-area right'>
                      <CustomButtonGroup buttons={buttons} />
                    </div>
                  </Box>
                </Box>

                {isDisplay && <Box className='white-box white-box-wrap'>
                  <div className='box-content'>
                    <Box className='white-box-content-top-area'>
                      <Box className='left-area'>
                        <Box className='total-count'>
                          <Box component='span' className='label'>
                            Total
                          </Box>
                          <Box component='span' className='value'>
                            {dataCount}
                          </Box>
                        </Box>
                      </Box>
                      <Box className='right-area'>
                        <Button
                          color='tertiary'
                          type='outline'
                          size='small'
                          label='Excel Dounload'
                          isIcon={false}
                          autosize
                        />
                        <SelectBox
                          id='select-search'
                          size='medium'
                          selectOptions={selectOption}
                          label=''
                          maxWidth='180px'
                          disabled={false}
                        />
                        <SearchField
                          id='search-2'
                          size='medium'
                          maxWidth='230px'
                          placeholder='Please enter a search term'
                        />
                      </Box>
                    </Box>
                    <Box className='content-inner-wrap'>
                      <CustomTableContainer className='table-container'>
                        <Table
                          className='custom-table'
                          aria-label='table'
                          stickyHeader
                        >
                          <TableHead className='thead'>
                            <TableRow className='tr'>
                              {MetaSeriesTh.map(column => (
                                <TableCell
                                  key={column.id}
                                  className='th'
                                  align={column.align}
                                  sx={{ width: column.width }}
                                >
                                  {column.checkbox ? (
                                    <>
                                      <CheckBox
                                        label=''
                                        value='select-all'
                                        defaultChecked={false}
                                        inputProps={{
                                          'aria-label': 'select all'
                                        }}
                                      />
                                      <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={
                                          orderBy === column.id ? order : 'desc'
                                        }
                                        onClick={() =>
                                          handleRequestSort(column.id)
                                        }
                                      >
                                        {column.label}
                                      </TableSortLabel>
                                    </>
                                  ) : (
                                    <>
                                      {column.sortable ? (
                                        <TableSortLabel
                                          active={orderBy === column.id}
                                          direction={
                                            orderBy === column.id
                                              ? order
                                              : 'desc'
                                          }
                                          onClick={() =>
                                            handleRequestSort(column.id)
                                          }
                                        >
                                          {column.label}
                                        </TableSortLabel>
                                      ) : (
                                        column.label
                                      )}
                                    </>
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody className='tbody'>
                            {paginatedData.map((td, index) => (
                              <TableRow className='tr' key={td.id || index}>
                                {MetaSeriesTh.map((column, colIndex) => (
                                  <TableCell
                                    key={column.id}
                                    className='td'
                                    align={column.align}
                                  >
                                    {colIndex === 3 ? (
                                      <TextLink
                                        href='/Metadata'
                                        onClick={() => handleDetailPage(td['seasonId'])}
                                        text={td[column.id]}
                                      />
                                    ) : (
                                      td[column.id]
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </CustomTableContainer>
                    </Box>

                    <Pagination
                      id='pagination-04'
                      count={Math.ceil(dataList.length / itemsPerPage)}
                      selected={itemsPerPage}
                      onChangeHandle={handlePagination}
                      page={page}
                    />
                  </div>
                </Box>}
              </div>
            </div>
          )}
        </div>
      </Box>
    </Box>
    <Dialog
        open={isAlertOpen}
        handleClose={handleAlertClose}
        closeX={false}
        size='small'
        title={alertTitle}
        contentTitle={false}
        bottomDivider={false}
        contentText={alertMessage}
        buttons={alertDialogButtons}
      />
  </Box>
)
};

Metadata.propTypes = {
  serviceCountry: PropTypes.array.isRequired,
  provider: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  serviceCountry: state.common.country.serviceCountry,
  provider: state.common.provider.country,
});

export default connect(mapStateToProps)(Metadata);