export const summaryCPNameOption = [
  { id: 'cp-All', value: 'All', option: 'All', type: 'text' },
  { id: 'cp-op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'cp-op2', value: 'op2', option: 'op2', type: 'text' },
];
export const summaryCountryOption = [
  { id: 'All-2', value: 'All', option: 'All', type: 'text' },
  { id: 'api-op-2', value: 'op1', option: 'op1', type: 'text' },
];

export const summaryDeviceOption = [
  { id: 'Type-All', value: 'All', option: 'All', type: 'text' },
  { id: 'Type-op1', value: 'op1', option: 'op1', type: 'text' },
  { id: 'Type-op2', value: 'op2', option: 'op2', type: 'text' },
];

export const StatusOption = [
  { id: 'Status-All', value: 'All', option: 'All', type: 'text' },
  { id: 'Status-1', value: 'Status1', option: 'Status1', type: 'text' },
  { id: 'Status-2', value: 'Status2', option: 'Status2', type: 'text' },
];

export const summaryPlatformOption = [
  { id: 'Status-All', value: 'All', option: 'All', type: 'text' },
  { id: 'Status-1', value: 'Status1', option: 'Status1', type: 'text' },
  { id: 'Status-2', value: 'Status2', option: 'Status2', type: 'text' },
];

export const selectOption = [
  { id: 'sel-all', value: 'All', option: 'All', type: 'text' },
  { id: 'sel-option-2', value: 'option-2', option: 'option-2', type: 'text' },
  { id: 'sel-option-3', value: 'option-3', option: 'option-3', type: 'text' },
];

export const chartSetting = {};

export const piechartData = [
  {
    label: 'aaa',
    value: 34.72,
    color: '#FF5778',
  },
  {
    label: 'bbb',
    value: 18.38,
    color: '#1AD2A0',
  },
  {
    label: 'ccc',
    value: 18.38,
    color: '#1579EB',
  },
  {
    label: 'ddd',
    value: 3.83,
    color: '#FFCF4F',
  },
  {
    label: 'eee',
    value: 8.42,
    color: '#9761a3',
  },
  {
    label: 'fff',
    value: 7.65,
    color: '#ff9d68',
  },
];

export const platforms = [
  {
    label: 'Mobile',
    value: 59.12,
  },
  {
    label: 'Desktop',
    value: 40.88,
  },
];

const normalize = (v: number, v2: number) => Number.parseFloat(((v * v2) / 100).toFixed(2));

export const PopularGenreData = [
  ...piechartData.map((v) => ({
    ...v,
    label: v.label === 'Other' ? 'Other (Desktop)' : v.label,
    value: normalize(v.value, platforms[1].value),
  })),
];

export const valueFormatter = (item: { value: number }) => `${item.value}%`;

export const channelTh = [
  {
    id: 'channelTh-1',
    align: 'left',
    label: 'Channel Name',
    width: '18%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelTh-2',
    align: 'left',
    label: 'Country',
    width: '6%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelTh-5',
    align: 'left',
    label: 'Platform',
    width: '8%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelTh-6',
    align: 'left',
    label: 'Device type',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelTh-7',
    align: 'left',
    label: 'Unique Device',
    width: '12%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelTh-8',
    align: 'left',
    label: 'Play',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelTh-9',
    align: 'left',
    label: 'Play per Ud',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelTh-10',
    align: 'left',
    label: 'Total Duration (hr)',
    width: '10%',
    checkbox: false,
    sortable: true,
  },
  {
    id: 'channelTh-11',
    align: 'left',
    label: 'Total Duration per Ud',
    width: 'auto',
    checkbox: false,
    sortable: true,
  },
];
export const channelTd = [
  {
    id: 'channelTd-row-1',
    data1: '21 Jump Street',
    data2: 'US',
    data3: 'webOS23',
    data4: 'LG TV',
    data5: '11.8',
    data6: '1100',
    data7: '4.0',
    data8: '1400.1',
    data9: '2.1',
  },
  {
    id: 'channelTd-row-2',
    data1: '21 Jump Street',
    data2: 'US',
    data3: 'webOS23',
    data4: 'LG TV',
    data5: '5.1',
    data6: '888',
    data7: '4.0',
    data8: '1400.1',
    data9: '2.1',
  },
  {
    id: 'channelTd-row-3',
    data1: '21 Jump Street',
    data2: 'US',
    data3: 'webOS23',
    data4: 'LG TV',
    data5: '11.8',
    data6: '1100',
    data7: '4.0',
    data8: '1400.1',
    data9: '2.1',
  },
  {
    id: 'channelTd-row-4',
    data1: '21 Jump Street',
    data2: 'US',
    data3: 'webOS23',
    data4: 'LG TV',
    data5: '5.1',
    data6: '888',
    data7: '4.0',
    data8: '1400.1',
    data9: '2.1',
  },
  {
    id: 'channelTd-row-5',
    data1: '21 Jump Street',
    data2: 'US',
    data3: 'webOS23',
    data4: 'LG TV',
    data5: '11.8',
    data6: '1100',
    data7: '4.0',
    data8: '1400.1',
    data9: '2.1',
  },
  {
    id: 'channelTd-row-6',
    data1: '21 Jump Street',
    data2: 'US',
    data3: 'webOS23',
    data4: 'LG TV',
    data5: '5.1',
    data6: '888',
    data7: '4.0',
    data8: '1400.1',
    data9: '2.1',
  },
];
